<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Dashboard </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Password Manager</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <form>
        <div class="card-box">
          <h1 class="card-title">
            Pin <span class="pin">{{ maskedNumbercont }} </span
            ><span
              class="fa fa-eye-slash view_pin"
              v-if="pin_revealed"
              @click="showm()"
            ></span>
            <span class="fa fa-eye" v-else></span>
          </h1>
          <br />
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 80px"
                >
                  <label>Old Pin<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPin2 ? 'text' : 'password'"
                    v-model="inputed_old_pin"
                    @input="toggle_input_type(3)"
                    maxlength="6"
                  />
                  <div class="profile-views">
                    <i
                      class="fa fa-eye toggle-sm"
                      v-if="showPin2"
                      @click="toggle_input_type(2)"
                    ></i>
                    <i
                      class="fa fa-eye-slash toggle"
                      v-else
                      @click="toggle_input_type(2)"
                    ></i>
                  </div>
                  <div class="errorcont">
                    <span class="small_error" v-if="checkpins">
                      <i class="fa fa-x"></i> Wrong pin.
                    </span>
                  </div>
                </div>

                <h3 class="card-title">Edit Pin</h3>

                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 80px"
                >
                  <label>New Pin<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPin1 ? 'text' : 'password'"
                    v-model="new_pin"
                    @input="validatepassword"
                    maxlength="6"
                  />
                  <div class="profile-views">
                    <i
                      class="fa fa-eye toggle-sm"
                      v-if="showPin1"
                      @click="toggle_input_type(1)"
                    ></i>
                    <i
                      class="fa fa-eye-slash toggle"
                      v-else
                      @click="toggle_input_type(1)"
                    ></i>
                  </div>
                  <div class="errorcont">
                    <span class="small_error" v-if="containsAlphabets">
                      <i class="fa fa-x"></i> Your pin cannot have an alphabet.
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 80px"
                >
                  <label>Re-Type Pin<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPin1 ? 'text' : 'password'"
                    v-model="retype_pin"
                    @input="validatepassword"
                    :style="{ borderColor: is_not_matched ? 'red' : '' }"
                    maxlength="6"
                  />
                  <div class="errorcont">
                    <span v-if="is_not_matched" class="small_error">
                      <i class="fa fa-x"></i> Pins do not match.
                    </span>
                  </div>
                </div>

                <div class="doctor-submit text-start">
                  <span
                    @click="update_credential('Pin')"
                    class="btn btn-primary submit-form me-2"
                    >Change Pin</span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 80px"
                >
                  <label>Old Password<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPassword2 ? 'text' : 'password'"
                    v-model="old_password"
                    @input="toggle_password_input_type(3)"
                  />
                  <div class="profile-views">
                    <i
                      class="fa fa-eye toggle-sm"
                      v-if="showPassword2"
                      @click="toggle_password_input_type(2)"
                    ></i>
                    <i
                      class="fa fa-eye-slash toggle"
                      v-else
                      @click="toggle_password_input_type(2)"
                    ></i>
                  </div>
                  <div class="errorcont">
                    <span class="small_error" v-if="checkpasswords">
                      <i class="fa fa-x"></i> Wrong password.
                    </span>
                  </div>
                </div>

                <h3 class="card-title">Edit Password</h3>

                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 80px"
                >
                  <label>New Password<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPassword1 ? 'text' : 'password'"
                    v-model="new_password"
                    @input="Pvalidatepassword"
                  />
                  <div class="profile-views">
                    <i
                      class="fa fa-eye toggle-sm"
                      v-if="showPassword1"
                      @click="toggle_password_input_type(1)"
                    ></i>
                    <i
                      class="fa fa-eye-slash toggle"
                      v-else
                      @click="toggle_password_input_type(1)"
                    ></i>
                  </div>
                </div>
                <br />
                <br />
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 80px"
                >
                  <label
                    >Re-Type Password<span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    :type="showPassword1 ? 'text' : 'password'"
                    v-model="retype_password"
                    @input="Pvalidatepassword"
                    :style="{ borderColor: is_not_matched2 ? 'red' : '' }"
                  />
                  <div class="errorcont">
                    <span v-if="is_not_matched2" class="small_error">
                      <i class="fa fa-x"></i> Passwords do not match.
                    </span>
                  </div>
                </div>

                <div class="doctor-submit text-end">
                  <span
                    @click="update_credential('Password')"
                    class="btn btn-primary submit-form me-2"
                    >Change Password</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="text-center">
              <button class="btn btn-primary submit-btn mb-4" type="button">Save</button>
            </div> -->
      </form>

      <div
        id="con-close-modal"
        class="modal modal-lg animate__animated animate__zoomIn animate__faster add_password_to_see_pin"
        style="margin-top:200px;margin-left:100px;margin-right"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Input your password</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <div class="form-header">
                <!-- <h2></h2> -->
                <p style="font-weight: lighter">
                  Input your correct password to see your pin.
                </p>
              </div>
              <form>
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <div
                      class="input-block local-forms"
                      style="height: 40px; margin-bottom: 20px"
                    >
                      <label>Password<span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        :type="showPin0 ? 'text' : 'password'"
                        v-model="password_to_open_pin"
                      />
                      <div class="profile-views">
                        <i
                          class="fa fa-eye toggle"
                          v-if="showPin0"
                          @click="toggle_input_type(0)"
                        ></i>
                        <i
                          class="fa fa-eye-slash toggle"
                          v-else
                          @click="toggle_input_type(0)"
                        ></i>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div class="doctor-submit text-end">
                      <span
                        type="submit"
                        class="btn btn-primary submit-form me-2"
                        @click="comparePassword()"
                      >
                        Submit
                      </span>
                      <span
                        class="btn btn-primary cancel_form bg-danger text-light"
                        >Cancel</span
                      >
                    </div>
                  </div>
                </div>
              </form>

              <!-- body ends here -->
            </div>
          </div>
          <!-- /.modal -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      // reveal data
      user_pin: localStorage.getItem("decrypted_pin"),
      maskedNumbercont: "",
      password_to_open_pin: "",
      user_password: localStorage.getItem("password"),
      pin_revealed: true,
      showPin0: false,
      showPin2: false,
      checkpins: false,

      showPassword0: false,
      showPassword2: false,
      checkpasswords: false,
      showPassword1: false,
      //pin data
      new_pin: null,
      retype_pin: null,
      showPin1: false,
      inputed_old_pin: null,

      old_password: null,
      retype_password: null,
      new_password: null,
      is_not_matched: 0,
      is_not_matched2: 0,
      containsAlphabets: 0,
    };
  },

  methods: {
    ...mapActions({
      _update_credential: "general/update_credential",
    }),
    toggle_input_type(v) {
      if (v == 0) {
        this.showPin0 = !this.showPin0;
      }
      if (v == 1) {
        this.showPin1 = !this.showPin1;
      }
      if (v == 2) {
        this.showPin2 = !this.showPin2;
      }

      if (v == 3) {
        this.checkpins = this.user_pin !== this.inputed_old_pin;
      }
    },
    toggle_password_input_type(v) {
      if (v == 0) {
        this.showPassword0 = !this.showPassword0;
      }
      if (v == 1) {
        this.showPassword1 = !this.showPassword1;
      }
      if (v == 2) {
        this.showPassword2 = !this.showPassword2;
      }

      if (v == 3) {
        this.checkpasswords = this.user_password !== this.old_password;
      }
    },
    validatepassword() {
      this.is_not_matched = this.new_pin !== this.retype_pin;
      this.containsAlphabets = /[a-zA-Z]/.test(this.new_pin);
    },

    Pvalidatepassword() {
      this.is_not_matched2 = this.new_password !== this.retype_password;
    },
    comparePassword() {
      if (this.user_password == this.password_to_open_pin) {
        $(".add_password_to_see_pin").modal("hide");
        this.maskedNumbercont = this.user_pin;
        this.pin_revealed = !this.pin_revealed;
        this.password_to_open_pin = "";
        this.showPin0 = false;
        setTimeout(() => {
          this.maskedNumbercont =
            "*".repeat(5) + this.user_pin.toString().slice(-1);
          this.pin_revealed = !this.pin_revealed;
        }, 5000);
      } else {
        this.$toast.error("Wrong password, Retry.");
      }
    },
    showm() {
      $(".add_password_to_see_pin").modal("show");
    },
    update_credential(type) {
      var check_if_pin =
        this.new_pin == null &&
        this.inputed_old_pin == null &&
        this.retype_pin == null;
      var check_if_pass =
        this.old_password == null &&
        this.new_password == null &&
        this.retype_password == null;
      // if (check_if_pin && check_if_pass) {
      if (check_if_pin && type == "Pin") {
        this.$toast.error("Fill all the pin inputs");
      } else if (check_if_pass && type == "Password") {
        this.$toast.error("Fill all the password inputs");
      } else {
        $(".inloader").show();
        this._update_credential({
          type: type,
          new_pin: this.new_pin,
          current_pin: this.inputed_old_pin,
          current_password: this.old_password,
          new_password: this.new_password,
        })
          .then((response) => {
            $(".inloader").hide();
            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
              location.reload();
              if (type == "Pin") {
                localStorage.setItem("decrypted_pin", this.new_pin);
              } else if (type == "Password") {
                localStorage.setItem("password", this.new_password);
              }
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }
          });
      }
    },
  },

  mounted() {
    this.maskedNumbercont = "*".repeat(5) + this.user_pin.toString().slice(-1);
    $(".cancel_form").click(function () {
      $(".add_password_to_see_pin").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 30px;
}
.pin {
  display: inline-block;
  margin: 5px 20px;
  padding: 10px 0px;
  letter-spacing: 10px;
  display: inline-block;
}
.toggle {
  font-size: 18px;
  color: black;
  padding-top: 40px;
}

.errorcont {
  font-size: 12px;
  color: red;
  margin-bottom: 0px;
  margin-top: 10px;
  padding-left: 5px;
  /* text-align: center; */
}

.small_error {
  margin-bottom: 10px;
  margin-top: 0px;
  display: inline-block;
}

.fa-check {
  font-size: 20px;
  margin-top: 10px;
  display: inline-block;
  color: green;
}
.toggle-sm {
  font-size: 18px;
  color: black;
  padding-top: 30px;
}
.fa {
  cursor: pointer;
}
</style>
