<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster nurse_note_modal"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Patient Summary</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">

                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <!-- <label>Note </label> -->
                    <textarea id="summary" rows="4" cols="80" class="form-control summernote"
                      placeholder="Enter your message here" v-model="summary"></textarea>

                  </div>
                </div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-6">
                  <div class="doctor-submit text-end">
                    <span type="submit" class="btn btn-primary submit-form me-2" @click="add_patient_summary()">
                      Submit
                    </span>
                    <span class="btn btn-primary cancel_nurse_note bg-danger text-light">Cancel</span>
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},
  data() {
    return {
      summary: ""
    };
  },

  methods: {
    ...mapActions({
      _add_patientSummary: "patient/add_patientSummary",
    }),

    add_patient_summary() {
      $(".inloader").show();
      $(".nurse_note_modal").modal("hide");
      const bioDataPayload = {
        summary: this.summary
      };

      const appointmentId = localStorage.getItem("apt_id");
      this._add_patientSummary({ appointmentId, payload: bioDataPayload })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // this.$router.push({ name: "Patients" });
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    initNote() {
      $('#summary').summernote({
        height: 200, // Set the height of the editor
        callbacks: {
          onChange: (contents) => {
            this.summary = contents; // Update Vue's data property when Summernote content changes
          },
        },
      });
    }

  },
  mounted() {
    this.initNote();
    $(".cancel_nurse_note").click(function () {
      $(".nurse_note_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}
</style>
