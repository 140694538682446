<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Appointment </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Appointment List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Appointment</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <!-- <a
                                href="add-appointment.html"
                                class="btn btn-primary add-pluss ms-2"
                                ><img src="../../assets/assets/img/icons/plus.svg" alt=""
                              /></a> -->
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsiv" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Consulting Doctor</th>
                      <th>Type</th>
                      <th>Treatment</th>
                      <!-- <th>Mobile</th> -->
                      <!-- <th>Email</th> -->
                      <th>Date</th>
                      <th>Time</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in main_data" :key="index">
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          {{ data.full_name }}</a
                        >
                      </td>
                      <td v-if="data.doctor_booking == 1">
                        {{ data.doctor.first_name ?? "" }}
                        <span> {{ data.doctor.last_name ?? "" }}</span>
                      </td>
                      <td v-if="data.doctor_booking == 0">Not assigned</td>
                      <td>{{ data.appointment_type }}</td>
                      <td>{{ data.issue }}</td>
                      <!-- <td>{{ data.phone_number }}</td> -->
                      <!-- <td>example@email.com</td> -->
                      <td>{{ data.date }}</td>
                      <td>{{ data.time }}</td>
                      <!-- <td class="text-end">
                            <div class="dropdown dropdown-action">
                              <a
                                href="#"
                                class="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><i class="fa fa-ellipsis-v"></i
                              ></a>
                              <div class="dropdown-menu dropdown-menu-end">
                                <a class="dropdown-item" href="edit-appointment.html"
                                  ><i class="fa fa-pen-to-square m-r-5"></i> Edit</a
                                >
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_patient"
                                  ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                                >
                              </div>
                            </div>
                          </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      reponse: "",
      main_data: [],
      no_of_appointments: 0,
    };
  },
  methods: {
    ...mapActions({
      _getAppontmentList: "general/getAppontmentList",
    }),
    getAppontmentList() {
      this._getAppontmentList()
        .then((response) => {
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            this.main_data = this.response.data.slice(0, 23);
            this.no_of_appointments = this.response.data.length;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAppontmentList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
