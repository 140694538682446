<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Subscription </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Plans</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Plans</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link to="/Add-Subscription" class="btn btn-primary add-pluss ms-2"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></router-link>
                          <!-- <a href="#" class="btn btn-primary ms-2"
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></a> -->
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow-x: scroll">
                <a-table :dataSource="subscriptions" :columns="columns">
                  <template #bodyCell="{ column, record }">

                    <template v-if="column.key == 'monthly_price'">
                      ₦
                      {{ formatNumberWithCommas(record.monthly_price) }}
                    </template>
                    <template v-if="column.key == 'yearly_price'">
                      ₦
                      {{ formatNumberWithCommas(record.yearly_price) }}
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a class="dropdown-item" @click="edit_subscription(record)"><i
                              class="fa fa-pen-to-square m-r-5"></i>
                            Edit</a>
                          <a class="dropdown-item" @click="delete_record(record)"><i class="fa fa-x m-r-5"></i>
                            Delete</a>

                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- second modal for confirmation -->
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Monthly Price",
    key: "monthly_price",
    dataIndex: "monthly_price",
  },
  {
    title: "Yearly Price",
    dataIndex: "yearly_price",
    key: "yearly_price",

  },

  {
    title: "Date created",
    dataIndex: "created_at",
    key: "created_at",
  },


  {
    // title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,

  },

  data() {
    return {
      subscriptions: null,
      search: "",
      subscriptions_reserve: [],
      state: 0,
      dataTransport: {},
      columns,
    };
  },

  methods: {
    ...mapActions({
      _get_subscription: "subscription/get_subscription",
      _deletePlan: "general/deletePlan",


    }),
    get_subscription() {
      this._get_subscription()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.subscriptions = this.response.data;
            this.subscriptions = this.subscriptions;
            this.patient_reserve = this.subscriptions;
            console.log(this.subscriptions);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    showmodal: function (id) {
      $("#con-close-modal").modal("show");
      this.doctor_id = id;
      // alert(this.doctor_id);
    },


    search_the_list() {
      // this.hospital_list = this.hospital_list_reserve;
      var searchLetter = this.search.toLowerCase();
      var searchResult = [];
      for (var i = 0; i < this.hospital_list.length; i++) {
        var name = this.hospital_list[i].name.toLowerCase();
        var email = this.hospital_list[i].email.toLowerCase();
        // var state = this.hospital_list[i]?.state.toLowerCase();
        // var country = this.hospital_list[i].country.toLowerCase();
        if (
          name.includes(searchLetter) ||
          email.includes(searchLetter)
          // ||
          // state.includes(searchLetter) ||
          // country.includes(searchLetter)
        ) {
          searchResult.push(this.hospital_list[i]);
        }
        // if (searchResult == []) {
        //   this.hospital_list = this.hospital_list;
        // }
      }

      if (this.search == "") {
        this.hospital_list = this.hospital_list_reserve;
      } else {
        this.hospital_list = searchResult;
      }
    },

    edit_subscription(subscription) {
      window.location = "/Edit-Subscription";
      localStorage.setItem("subscriptionData", JSON.stringify(subscription));
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    deleteService(id) {
      $(".inloader").show();

      this._deleteService(id)
        .then((response) => {
          $(".inloader").hide();
          $("#con-close-modal").modal("hide");
          this.getServiceList();
          this.response = response.data;
          if (this.response["status"] === true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

  },

  mounted() {
    this.get_subscription();


    $(".cancel_change_cat").click(function () {
      $("#con-close-modal").modal("hide");
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.dropdown-menu {
  height: auto;
  width: 100%;
  border-radius: 8px;
}

.dm {
  height: 250px;
  border-radius: 8px;
  overflow-x: scroll;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

.fa{
  color: #131313;
}

</style>
