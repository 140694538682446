<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="staffs">Staffs </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Staffs</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Staffs Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>First Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="staff.first_name" required minlength="8" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Last Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="staff.last_name" required minlength="8" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>User Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="staff.username" required minlength="8" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="staff.phone_number" @input="limitInput(this)"
                        maxlength="11" id="phone" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Email <span class="login-danger">*</span></label>
                      <input class="form-control" type="email" v-model="staff.email" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" value="Male" v-model="staff.sex" />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" class="form-check-input" value="Female" v-model="staff.sex" />Female
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Date Of Birth
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" v-model="staff.date_of_birth" :max="current_date" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Role <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="role_id">
                        <a-select-option value="Choose a Role" disabled selected>
                          Choose a Role
                        </a-select-option>
                        <a-select-option :value="data.id" v-for="data in role" :key="data.id">
                          {{ data.role }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4" v-if="
                    type == 'Doctor' || type == 'Radiology' || type == 'Lab'
                  ">
                    <div class="input-block local-forms">
                      <label>Category <span class="login-danger">*</span></label>

                      <a-select class="w-100" v-model:value="category_id">
                        <a-select-option value="Choose a Category" disabled selected>
                          Choose a Category
                        </a-select-option>
                        <a-select-option :value="data.id" v-for="data in category" :key="data.id">
                          {{ data.category }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Country <span class="login-danger">*</span></label>
                      <countries v-model:value="staff.country" />

                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3" v-if="staff.country == 'Nigeria'">
                    <div class="input-block local-forms">
                      <label>State <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="state_id" showSearch
                        :filterOption="(input, option) => filterObject(option, input, 'key')">
                        <a-select-option disabled>Select State</a-select-option>
                        <a-select-option v-for="state in state_list" v-bind:key="state.name" :value="state.id">
                          {{ state.name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3" v-if="staff.country == 'Nigeria'">
                    <div class="input-block local-forms">
                      <label>LGA <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="lga"
                        :filterOption="(input, option) => filterObject(option, input, 'key')" showSearch>
                        <a-select-option disabled>Select Local Government</a-select-option>
                        <a-select-option v-for="lga in lga_list" v-bind:key="lga.name" :value="lga.id">
                          {{ lga.name }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>City <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="staff.city" placeholder="Enter your city" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Zip Code <span class="login-danger">*</span></label>
                      <input class="form-control" type="number" v-model="staff.zip_code" maxLength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <textarea class="form-control" rows="3" cols="30" v-model="staff.address"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span @click="Edit_staff()" class="btn btn-primary submit-form me-2">Submit</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { filterObject } from "@/utils/helpers";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },

  data() {
    return {
      filterObject,
      staff: JSON.parse(localStorage.getItem("staffData")),
      current_date: new Date().toISOString().split("T")[0],
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      category_id: "Choose a Category",
      category: [],
      // education: "",
      hospital_address: "",
      hospital_id: 0,
      hospital: [],
      // consult_fee: "",
      // experience: "",
      role_id: "Choose a Role",
      role: [],
      selectedRole: {},
      address: "",
      city: "",
      country: "Select a Country",
      state_id: null,
      lga: "Select LGA",
      postal_code: "",
      username: "",
      dob: "",
      sex: "",
      status: "Active",
      state_list: [],
      lga_list: [],
      type: "",
      // data: null,
    };
  },

  methods: {
    gethospitaladress(address) {
      this.hospital_address = address;
      console.log(this.hospital_address);
    },
    ...mapActions({
      _Edit_staff: "general/Edit_staff",
      _getcategory: "general/global_category",
      _getrole: "general/getrole",
      _gethospital: "general/gethospital",
      _getState: "general/getStateList",
    }),
    change_type() {
      // console.log(event,'ev');
      this.selectedRole = this.role.find((role) => role.id == this.role_id);
      this.type = this.selectedRole?.role;
      // console.log(this.selectedRole);
      this.getcategory();
    },

    Edit_staff() {
      if (
        this.type !== "Doctor" &&
        this.type !== "Lab" &&
        this.type !== "Radiology"
      ) {
        this.type = null;
      }
      var inputs = document.querySelectorAll("input");

      for (var i = 0; i < inputs.length; i++) {
        var inputValue = inputs[i].value;
      }
      if (inputValue === "") {
        this.$toast.error("Please fill in all fields.");
      } else if (
        this.staff.phone_number.length < 11 ||
        this.staff.phone_number.length > 11
      ) {
        //alert(this.phone.length)
        this.$toast.error(
          "Phone number cannot be more than or less than 11 digits"
        );
      } else if (isNaN(this.staff.phone_number)) {
        this.$toast.error("This phone number input only accepts digits");
      } else if (
        this.staff.first_name.length < 3 ||
        this.staff.last_name.length < 3
      ) {
        this.$toast.error("Name field cannot be less than 3 in length");
      } else {
        $(".inloader").show();
        // alert(this.dob)
        this._Edit_staff({
          id: this.staff.id,
          payload: {
            email: this.staff.email,
            first_name: this.staff.first_name,
            last_name: this.staff.last_name,
            phone_number: this.staff.phone_number,
            category_id:
              this.category_id == "Choose a Category" ? null : this.category_id,
            // education: this.education,
            // hospital_address: this.hospital_address,
            // hospital_id: this.hospital_id,
            // consult_fee: this.consult_fee,
            // experience: this.experience,
            role_id: this.role_id,
            // designation:this.designation,
            address: this.staff.address,
            city: this.staff.city,
            country: this.staff.country,
            state_id: this.state_id,
            lga_id: this.lga,
            zip_code: this.staff.zip_code,
            username: this.staff.username,
            dob: this.staff.date_of_birth,
            sex: this.staff.sex,
            status: this.status,
            type: this.type,
          },
        })
          .then((response) => {
            this.response = response.data;
            $(".inloader").hide();
            this.$router.push({ name: "Staffs" });
            this.$toast.success(this.response["message"]);

            // this.$toast.success('Account Created');
          })
          .catch((error) => {
            // console.log(error);
            $(".inloader").hide();

            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422) {
                this.$toast.error(error.response.data.message);
              } else if (status == 500) {
                this.$toast.error("Failed to edit pateint");
              }
              // } else if (status === 401) {
              //   // Handle specific status code (e.g., Unauthorized)
              //   console.error("Unauthorized:", data.error);
              // }
            }
          });
      }
    },
    getcategory() { 
      this._getcategory({ type: this.type })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.category = this.response.data;
            console.log(this.category);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },
    getrole() {
      this._getrole()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.role = this.response.data;
            console.log(this.role);

            this.selectedRole = this.role.find((role) => role.id == this.role_id);
            this.type = this.selectedRole?.role;
            // console.log(this.selectedRole);
            this.getcategory();
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },
    gethospital() {
      this._gethospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.hospital = this.response.data;
            console.log(this.hospital);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },
    getState() {
      this._getState()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.state_list = this.response.data;
            console.log(this.state_list);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    getLGA() {
      this.selectedLGA = this.state_list.find(
        (state) => state.id == this.state_id
      );

      this.lga_list = this.selectedLGA?.lga;
    },

    limitInput() {
      var input = document.getElementById("phone");
      // input.value = input.value.replace(/[^0-9]/g, ''); // Allow only numbers
      input.value = input.value.replace(/\D/g, "");
      if (input.value.length > input.maxLength) {
        input.value = input.value.slice(0, input.maxLength);
      }
    },

    initial_assignment() {
      var state = this.state_list.find(
        (state) => state.name == this.staff.state
      );
      this.lga_list = state?.lga;
      if (this.lga_list) {
        var main_lga = this.lga_list.find((lga) => lga.name == this.staff?.lga);

        this.category_id = this.staff.category_id;
        this.role_id = this.staff.role_id;
        this.state_id = state.id;
        this.lga = main_lga.id;
      }
    },
  },
  watch: {
    role_id: {
      handler(roleId) {
        if (roleId) {
          this.change_type();
        }
      },
      immediate: true,
    },
    state_id: {
      handler(stateId) {
        if (stateId) {
          this.getLGA();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.getcategory();
    this.getrole();
    // this.gethospital();
    this.getState();

    setTimeout(() => {
      this.initial_assignment();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
