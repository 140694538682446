<template>
  <div>
    <div class="header" style="background-color: #14a79e; color: white">
      <div class="header-left">
        <router-link to="" class="logo" style="color: white">
          <img src="../../assets/assets/img/main_site.png" width="55" height="55" alt="" style="padding-bottom: 10px" />
          <span class="logo-text" style="
              color: white;
              font-size: 24px;
              margin-top: -5px;
              float: right;
            ">StellaSync</span>
        </router-link>
      </div>
      <a id="toggle_btn" href="javascript:void(0);"><i class="fa fa-bars" style="
            font-size: 25px;
            color: white;
            display: inline-block;
            padding-top: 23px;
          "></i></a>
      <a id="mobile_btn_" class="mobile_btn_ float-start" @click="toggleSidebar()"><i class="fa fa-bars"
          style="font-size: 25px; color: white; margin-top: -30px"></i></a>

      <Responsive_Sidebar id="sidebar_res" />

      <marquee class="ib" direction="left">Streamline your practice with StellaSync: Where Efficiency Meets Innovation.</marquee>
      <!-- <marquee class="ib" direction="left">System maintenance has been successfully completed. Thank you for your patience!</marquee> -->
      <!-- <marquee class="ib" direction="left">StellaSync: The Future of Healthcare Technology!</marquee> -->
      <!-- <div class="top-nav-search mob-view">
      <form>
        <input type="text" class="form-control" placeholder="Search here" />
        <a class="btn"
          ><img src="../../assets/assets/img/icons/search-normal.svg" alt=""
        /></a>
      </form>
    </div> -->
      <ul class="nav user-menu float-end">
        <!-- <li class="nav-item dropdown d-none d-md-block">
        <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown"
          ><img src="../../assets/assets/img/icons/note-icon-02.svg" alt="" /><span
            class="pulse"
          ></span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span>Notifications</span>
          </div>
          <div class="drop-scroll">
            <ul class="notification-list">
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">
                      <img
                        alt="John Doe"
                        src="../../assets/assets/img/user.jpg"
                        class="img-fluid"
                      />
                    </span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">John Doe</span> added new task
                        <span class="noti-title">Patient appointment booking</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">4 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">V</span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">Tarah Shropshire</span> changed the task
                        name
                        <span class="noti-title"
                          >Appointment booking with payment gateway</span
                        >
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">6 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">L</span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">Misty Tison</span> added
                        <span class="noti-title">Domenic Houston</span> and
                        <span class="noti-title">Claire Mapes</span> to project
                        <span class="noti-title">Doctor available module</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">8 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">G</span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">Rolland Webber</span> completed task
                        <span class="noti-title"
                          >Patient and Doctor video conferencing</span
                        >
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">12 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="activities.html">
                  <div class="media">
                    <span class="avatar">V</span>
                    <div class="media-body">
                      <p class="noti-details">
                        <span class="noti-title">Bernardo Galaviz</span> added new task
                        <span class="noti-title">Private chat module</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">2 days ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="activities.html">View all Notifications</a>
          </div>
        </div>
      </li> -->
        <!-- <li class="nav-item dropdown d-none d-md-block">
        <a href="javascript:void(0);" id="open_msg_box" class="hasnotifications nav-link">
          <img src="../../assets/assets/img/icons/note-icon-01.svg" alt="" /><span
            class="pulse"
          ></span>
        </a>
      </li> -->

        <!-- Working here -->
        <li class="nav-item dropdown has-arrow user-profile-list" id="upl" style="max-width:375px;">
          <a href="#" class="dropdown-toggle nav-link user-link justify-end" data-bs-toggle="dropdown" id="upl">
            <div class="user-names" style="color: white">
              <h5 style="color: white; width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;overflow: hidden;">
                {{ name }}
              </h5>
              <span style="color: white;color: white; width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;overflow: hidden;">{{ hospital_name }}
                <span style="color: white" v-if="role != 'Admin' && role != 'Super Admin'">-</span>
                {{ role }}</span>
            </div>
            <span class="user-img">
              <i class="fa fa-user" v-if="avatar == '' || avatar == null" style="font-size: 35px"></i>
              <img :src="avatar" v-else />
            </span>
          </a>
          <div class="dropdown-menu" style="width: 100%">
            <router-link class="dropdown-item" to="/Profile">My Profile</router-link>
            <router-link class="dropdown-item" to="/Password-Manager">Pin and Password
            </router-link>
            <router-link class="dropdown-item" to="/Edit-profile" hidden="">Edit Profile</router-link>



            <span class="dropdown-item" @click="logout()">Logout</span>

            <router-link to="/Doctor-Dashboard"></router-link>
          </div>
        </li>

        <!--  -->

        <!-- <li class="nav-item">
       
      </li> -->
      </ul>
      <div class="dropdown mobile-user-menu float-end" style="width: 100%">
        <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" id="threedots"><i
            class="fa fa-ellipsis-vertical"></i></a>
        <div class="dropdown-menu dropdown-menu-end" style="width: 100%">
          <router-link class="dropdown-item" to="/Profile">My Profile</router-link>
          <router-link class="dropdown-item" to="/Edit-profile">Pin and Password
          </router-link>
          <!-- <router-link class="dropdown-item" to="/Edit-profile" 
            >Edit Profile</router-link
          > -->

          <a href="" class="dropdown-item" @click="logout()">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { store } from "../../store";
import { mapActions } from "vuex";
import Responsive_Sidebar from "../../components/plugins/Side_bar_responsive.vue";
import { flutterEventKeys, postFlutterEvent } from "@/utils/flutterEvents";

export default {
  name: "Header",
  components: {
    Responsive_Sidebar,
  },
  data() {
    return {
      // isSidebarOpen: false,
      name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      role: localStorage.getItem("role"),
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),
      // avatar:
      //   JSON.parse(localStorage.getItem("ADMIN_DETAILS"))?.avatar == null ||
      //   typeof JSON.parse(localStorage.getItem("ADMIN_DETAILS"))?.avatar == "undefined"
      //     ? ""
      //     : JSON.parse(localStorage.getItem("ADMIN_DETAILS"))?.avatar,
    };
  },
  computed: {
    avatar() {
      return JSON.parse(localStorage.getItem("ADMIN_DETAILS"))?.avatar ==
        null ||
        typeof JSON.parse(localStorage.getItem("ADMIN_DETAILS"))?.avatar ==
        "undefined"
        ? ""
        : JSON.parse(localStorage.getItem("ADMIN_DETAILS"))?.avatar;
    },
  },
  methods: {
    toggleSidebar() {
      $("#sidebar_res").toggle();
      // this.isSidebarOpen = !this.isSidebarOpen;
    },

    ...mapActions({
      // _set_satus: "general/set_satus",
    }),
    // set_satus() {
    //   this._set_satus({
    //     status: "offline",
    //   })
    //     .then((response) => {
    //       this.response = response.data;
    //       if (this.response["status"] == true) {
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       if (error.response) {
    //         const status = error.response.status;
    //         const data = error.response.data;

    //         if (status == 400 || status == 422 || status == 401) {
    //           this.$toast.error(error.response.data.message);
    //         } else if (status === 500) {
    //           this.$toast.error(
    //             "Network Error, Check your internet conectivity."
    //           );
    //         }
    //       }
    //     });
    // },
    logout() {
      if (this.$store.state.isOnFlutter) {
        postFlutterEvent(flutterEventKeys.logout)
      } else {
        localStorage.clear();
        this.$router.push({ name: "Welcome" });
      }

    },
  },
  mounted() {
    // store.dispatch("getAdminDetails");
    // console.log(localStorage.getItem("ADMIN_DETAILS"), "oppp");
    if (this.role == "Admin") {
      $(".headhide").show();
    }

    $(".mobile-user-menu").click(function () {
      $("#sidebar_res").hide();
    });
    // alert(this.avatar);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.headhide {
  display: none;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-menu {
  width: 100%;
}

.hide_for_now {
  display: none;
}

.ib {
  display: inline-block;
  position: absolute;
  font-size: 22px;
  width: 50%;
  left: 25%;
  padding-top: 17px;
}

#upl:hover,
#upl:focus {
  background-color: #14a79e;
}

/* @media(max-width: 1000px) {

} */

a.mobile_btn_ {
  /* border: 1px solid red; */
  color: #fff;
  font-size: 24px;
  height: 70px;
  left: 0;
  line-height: 70px;
  padding: 0 20px;
  position: absolute;
  top: 0;
  width: 60px;
  z-index: 999;
  display: none;
}

#sidebar_res {
  display: none;
}

#threedots {
  display: none;
}

@media (max-width: 1280px) {
  .user-menu.nav li {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .ib {
    display: none;
  }
}

@media (max-width: 800px) {
  #threedots {
    display: block !important;
  }

  .user-menu.nav li {
    width: 100% !important;
  }

  a.mobile_btn_ {
    /* border: 1px solid red; */
    color: #fff;
    font-size: 24px;
    height: 70px;
    left: 0;
    line-height: 70px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 999;
    display: block;
  }
}

@media (max-width: 480px) {
  #threedots {
    display: block !important;
  }

  .dropdown {
    box-shadow: 0px 2px 4px rgba(8, 5, 3, 0.1);
    top: -3px;
  }

  a.mobile_btn_ {
    /* border: 1px solid red; */
    color: #fff;
    font-size: 24px;
    height: 70px;
    left: 0;
    line-height: 70px;
    padding: 0 20px;
    position: absolute;
    top: 0;
    width: 60px;
    z-index: 999;
    display: block;
  }
}
</style>
