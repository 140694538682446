const axios = require("axios");
import { api, url } from "../api";
// let socketUrl = "ws://192.168.1.113:8765";
const bearerToken = localStorage.getItem("accessToken");
let socketUrl;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  socketUrl = `wss://192.168.1.113:8765?token=${bearerToken}`;
} else {
  socketUrl = `wss://fzp4yi8tsi.execute-api.eu-west-2.amazonaws.com/Stellasync_Production?token=${bearerToken}`;
}

let isAuth = !!localStorage.getItem("accessToken");
let socket = null;

const config = {
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
};

if (isAuth) {
  socket = new WebSocket(socketUrl);

  const updateConnectionId = (connectionID) => {
    axios
      .post(url(`update/connectionId`), { connectionId: connectionID }, config)
      .then((response) => {
        // Handle success
        console.log("Response:", response.data);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  socket.addEventListener("close", (event) => {});
  socket.addEventListener("open", (event) => {
    // socket.send(
    //   JSON.stringify({
    //     action: "getConnectionId",
    //   })
    // );
  });

  // socket.addEventListener("message", (event) => {
  //   let message = JSON.parse(event.data);
  //   switch (message.channel) {
  //     case "connectionId":
  //       updateConnectionId(message.connectionId);
  //       break;
  //     // case "chat":
  //     //   updateConnectionId(message.connectionId);
  //     //   break;
  //     default:
  //       break;
  //   }
  // });
}
export { socket };
