<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="card invoice-info-card">
            <div class="card-body">
              <div class="invoice-item invoice-item-one">
                <div class="row">
                  <div class="col-md-6">
                    <div class="invoice-logo">
                      <img src="../../assets/assets/img/logo-dark.png" alt="logo" />
                    </div>
                    <div class="invoice-head">
                      <h2>Invoice</h2>
                      <p>Invoice Number: <b>{{invoice.reference_id}}</b></p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="invoice-info">
                      <!-- <strong class="customer-text-one"></strong> -->
                      <h6 class="invoice-name">{{hospital_name}}</h6>
                      <p class="invoice-details">
                        9087484288 <br />
                        {{hospital_address}}<br />
                       
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Invoice Item -->
              <div class="invoice-item invoice-item-two">
                <div class="row">
                  <div class="col-md-6">
                    <div class="invoice-info">
                      <strong class="customer-text-one">Billed to</strong>
                      <h5 class="invoice-name">{{invoice.patient}}</h5>
                      <p class="invoice-details invoice-details-two">
                        {{invoice.patient_mobile_number}} <br />
                        {{invoice.patient_address}} <br />
                        <!-- {{ hospital_country  }} - Country -->
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="invoice-info invoice-info2">
                      <strong class="customer-text-one">Payment Method</strong>
                      <p class="invoice-details">
                     From:   {{invoice.payment_method}}
                      </p>
                      <!-- <div class="invoice-item-box">
                        <p>Recurring : 15 Months</p>
                        <p class="mb-0">PO Number : 54515454</p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Invoice Item -->

              <!-- Invoice Item -->
              <div class="invoice-issues-box">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="invoice-issues-date">
                      <p>Payment Date : {{getDatetime(invoice.payment_date)}}</p>
                    </div>
                  </div>
                
                  <div class="col-lg-6 col-md-6">
                    <div class="invoice-issues-date">
                      <p> Amount : ₦ {{formatNumberWithCommas(invoice.amount)}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Invoice Item -->

              <!-- Invoice Item -->
              <div class="invoice-item invoice-table-wrap">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive" style="overflow: visible">
                      <table class="invoice-table table table-center mb-0">
                        <thead>
                          <tr>
                            <th>Target</th>
                            <th>Category</th>
                            <th class="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{invoice.target}}</td>
                            <td>{{invoice.category}}</td>
                            <td class="text-end">₦ {{formatNumberWithCommas(invoice.amount)}}</td>
                          </tr>
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Invoice Item -->

              <!-- <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-6">
                  <div class="invoice-terms">
                    <h6>Notes:</h6>
                    <p class="mb-0">Enter customer notes or any other details</p>
                  </div>
                  <div class="invoice-terms">
                    <h6>Terms and Conditions:</h6>
                    <p class="mb-0">Enter customer notes or any other details</p>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="invoice-total-card">
                    <div class="invoice-total-box">
                      <div class="invoice-total-inner">
                        <p>Taxable <span>$6,660.00</span></p>
                        <p>Additional Charges <span>$6,660.00</span></p>
                        <p>Discount <span>$3,300.00</span></p>
                        <p class="mb-0">Sub total <span>$3,300.00</span></p>
                      </div> 
                      <div class="invoice-total-footer">
                        <h4>Total Amount <span>$143,300.00</span></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="invoice-sign text-end">
                This is an Electronic generated invoice
                <!-- <img
                  class="img-fluid d-inline-block"
                  src="assets/img/signature.png"
                  alt="sign"
                /> -->
                <!-- ../../assets/ -->
                <span class="d-block">{{hospital_name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      invoice: JSON.parse(localStorage.getItem('invoice')),
      hospital_address:localStorage.getItem("hospital_address"),
      hospital_name:localStorage.getItem("hospital"),
      // hospital_country:localStorage.getItem("hospital_country"),
    };
  },

  methods: {
      getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
     formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
