import axios from "axios";
import { showLoader, updatePendingRequest } from "./apiUtils";

let baseURL;
if (process.env.NODE_ENV === "production") {
  baseURL = "https://cloudxyz.stellasync.com/api/v1/admin/";
} else if (process.env.NODE_ENV === "demo") {
  baseURL = "https://stellasyncnet.gtcomnet.com/api/v1/admin/";
} else {
   baseURL = "http://192.168.1.105:8000/api/v1/admin/";

}
const url = (path) => {
  return baseURL + path;
};

const api = axios.create();
api.interceptors.request.use((request) => {
  if (showLoader(request?.url)) {
    updatePendingRequest();
  }
  const bearerToken = localStorage.getItem("accessToken");
  request.headers.Authorization = `Bearer ${bearerToken}`;
  request.headers["Content-Type"] = "multipart/form-data";
  return request;
});

api.interceptors.response.use(
  function (config) {
    if (showLoader(config?.config?.url)) {
      updatePendingRequest(false);
    }
    return config;
  },
  function (error) {
    if (showLoader(error?.config?.url)) {
      updatePendingRequest(false);
    }
    throw error;
  }
);
export { baseURL, url, api };
