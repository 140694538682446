<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Quick Services </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Booking List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <filter-table :filter-by="filterTableBy" :search-keys="['patient_name', 'service_name']" :columns="columns" :loading="isloading"
          :data="bookings" title="Booking List">
          <template #rightContent="_data"> </template>
          <template #addGroup>
            <router-link to="/Add-Booking" class="bntn btn-primary add-pluss ms-2"><img
                src="../../assets/assets/img/icons/plus.svg" alt="" /></router-link>
          </template>

          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'service'">
              <span>
                {{ capitalize(record.service_type.replace("_", " ")) }}(<b>{{
                  record.service.name ?? "-"
                }}</b>)
              </span>
            </template>
            <template v-if="column.key == 'amount'">
              <span> ₦ {{ formatAmount(record.amount) }} </span>
            </template>
            <template v-if="column.key == 'status'">
              <span :class="{ badge: true, 'badge-success': record.status != 'Unpaid' }">{{ record.status }}</span>
            </template>
            <template v-if="column.key == 'date'">
              <span>
                {{ getDatetime(record.created_at) }}
              </span>
            </template>
            <template v-if="column.key == 'report'">


              <button v-if="record.service_record.note" class="btn btn-outline-primary btn-sm d-flex align-items-center"
                title="Print Report" @click="downloadBookingReport(record.id)">
                <i class="fa fa-print m-r-5"></i>Print</button>

              <i v-else>No Report Yet</i>
            </template>
            <template v-if="column.key == 'action'">
              <div class="dropdown dropdown-action">
                <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                    class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <!-- <span
                    class="dropdown-item"
                    title="Collect Sample"
                    
                    v-if="record.service_record.note"
                  >
                    <i class="fa fa-print m-r-5"></i>Print Report</span
                  > -->

                  <!-- <span class="dropdown-item" @click="completed(record.id)"><i class="fa fa-check m-r-5"></i>
                    Completed</span> -->
                </div>
              </div>
            </template>
          </template>
        </filter-table>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
import { formatAmount } from "@/utils/formatter";
import { capitalize } from "vue";

const columns = [
  {
    title: "Patient",
    dataIndex: "patient_name",
  },
  {
    title: "Service",
    key: "service",
  },
  {
    title: "Ref. Doctor",
    dataIndex: "ref_doctor",
  },
  {
    title: "Ref. Hospital",
    dataIndex: "ref_hospital",
  },
  {
    title: "Status",
    key: "status",
  },
  {
    title: "Date",
    key: "date",
  },
  {
    title: "Print Report",
    key: "report",
  },
  // {
  //   title: "Action",
  //   key: "action",
  // },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },
  data() {
    return {
      reponse: "",
      bookings: [],
      no_of_trans: 0,
      search: "",
      main_data_reserve: [],
      columns,
      targets: [],
      target_name: null,
      types: [],
      type_name: null,
      filterDate: null,
      formatAmount,
      capitalize,
      filterTableBy: [
        { title: "Paid Bookings", key: "status", exclude: "Unpaid" },
        { title: "Unpaid Bookings", key: "status", match: "Unpaid" },
      ],
      isloading:null

    };
  },

  methods: {
    ...mapActions({
      _fetchBookedServices: "quickService/fetchBookedServices",
      _downloadBookingReport: "quickService/downloadBookingReport",
    }),
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    downloadBookingReport(id) {
      this._downloadBookingReport(id);
    },
    fetchBookedServices() {
      this.isloading=true

      this._fetchBookedServices()
        .then((response) => {
          this.isloading=false

          this.response = response.data;
          if (this.response["status"] == true) {
            this.bookings = this.response.data.map((quickService) => ({
              ...quickService,
              patient_name: quickService.patient.name,
              service_name: quickService.service.name,
            }));
          }
          this.bookings
        })
        .catch((error) => {
          this.isloading=false

          console.log(error);
          console.log("Error fetching data", error);
        });
    },
  },
  mounted() {
    this.fetchBookedServices();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  inline-size: 100%;
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.dropdown-menu {
  inline-size: 100%;
}

.dropdown-item {
  cursor: pointer;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}
</style>
