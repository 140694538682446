<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Staffs </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Staff List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center" id="arrangestaff">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Staffs List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link to="Add-Staff" class="btn btn-primary add-pluss ms-2"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></router-link>
                          <!-- <a href="#" class="btn btn-primary ms-2"
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 ms-auto" id="roleplay">
                    <div class="btn-group selectbtn">
                      <button type="button" class="btn dropdown-toggle btn-primary btn-xl" data-bs-toggle="dropdown">
                        {{ list_selector || "Permissions" }}
                      </button>
                      <div class="dropdown-menu dm">
                        <span class="dropdown-item text-bg-secondary">Select List</span>
                        <span class="dropdown-item" v-for="(role, index) in option_list" v-bind:key="index"
                          @click="selectList(role)">{{ role }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="department_list" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'name'">
                      <span>
                        <img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg"
                          class="rounded-circle m-r-5" alt="" />
                        {{ record.first_name + " " + record.last_name }}
                      </span>
                    </template>
                    <template v-if="column.key == 'type'">
                      <button class="custom-badge status-green" v-if="record.type == 'Full-Time'">
                        {{ record.type }}
                      </button>
                      <button class="custom-badge status-orange" v-if="record.type == 'Part-Time'">
                        {{ record.type }}
                      </button>
                    </template>
                    <template v-if="column.key == 'gender'">
                      <button class="custom-badge status-purple" v-if="record.sex == 'Male' || record.sex == 'male'">
                        {{ record.sex }}
                      </button>
                      <button class="custom-badge status-orange"
                        v-if="record.sex == 'Female' || record.sex == 'female'">
                        {{ record.sex }}
                      </button>
                    </template>
                    <template v-if="column.key == 'status'">
                      <div v-if="record.is_active == 1" :id="`toggleContainer${record.id}`" class="toggle-container on"
                        @click="change_acount_status(record.id)">
                        <div class="toggle-circle"></div>
                      </div>

                      <div v-if="record.is_active == 0" :id="`toggleContainer${record.id}`" class="toggle-container off"
                        @click="change_acount_status(record.id)">
                        <div class="toggle-circle"></div>
                      </div>
                    </template>

                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <!-- <span class="dropdown-item" @click="staffDash()"
                                  ><i class="fa fa-tachometer m-r-5"></i
                                  >DashBoard</span
                                > -->
                          <!-- if it a doctor show dashboard option -->
                          <!-- <span class="dropdown-item" @click="staffData(); viewStaffProfile(staff)"
                                  ><i class="fa fa-user m-r-5"></i>Profile</span
                                > -->
                          <span class="dropdown-item" @click="showmodal(record.id)" v-if="
                            list_selector == 'Doctor' ||
                            list_selector == 'Lab' ||
                            list_selector == 'Radiology'
                          "><i class="fa fa-pen-to-square m-r-5"></i>Change Category</span>
                          <a class="dropdown-item" @click="edit_staff(record)"><i class="fa fa-pen-to-square m-r-5"></i>
                            Edit Staff</a>
                          <!-- <span class="dropdown-item" href="#"
                                  ><i key="" class="fa fa-trash-alt m-r-5"></i>
                                  Delete</span
                                > -->
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="con-close-modal"
        class="modal modal-lg animate__animated animate__zoomIn animate__faster change_cat_modal" tabindex="-1"
        role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Doctor Category</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Edit Category
                        <span class="login-danger">*</span></label>
                      <a-select class="" v-model:value="category_id">
                        <a-select-option value="Choose a Category" disabled selected>
                          Choose a Category
                        </a-select-option>
                        <a-select-option :value="data.id" v-for="data in category" :key="data.id">
                          {{ data.category }}
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2" @click="change_doctor_category()">
                        Submit
                      </span>
                      <span
                        class="btn btn-primary cancel_change_cat cancel-form bg-danger text-light cancel-form">Cancel</span>
                    </div>
                  </div>
                </div>
              </form>

              <!-- body ends here -->
            </div>
          </div>
          <!-- /.modal -->
        </div>
      </div>
    </div>
  </div>
  <!-- second modal for confirmation -->
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Type",
    key: "type",
  },
  {
    title: "Gender",
    key: "gender",
  },
  {
    // title: "Status",
    key: "status",
  },
  {
    // title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      main_data: [],
      option_list: [],
      department_list: [],
      list_selector: "All Staffs",
      category_id: "Choose a Category",
      category: [],
      doctor_id: null,

      search: "",
      department_list_reserve: [],
      state: 0,
      columns,
    };
  },

  methods: {
    ...mapActions({
      _getStaffList: "staff/getStaffList",
      _getcategory: "general/global_category",
      _change_doctor_category: "staff/change_doctor_category",
      _change_acount_status: "general/change_acount_status",
    }),
    getStaffList() {
      this._getStaffList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            // this.main_data=this.main_data
            // this.department_list_reserve = this.main_data;
            this.option_list = [];
            for (const obj of this.main_data) {
              this.option_list.push(obj.role);
            }


            this.main_data.map((roleCategory) => {
              const roleName = roleCategory.role; // Get the role name
              roleCategory.staff = roleCategory.staff.map((staffMember) => ({
                ...staffMember, // Spread the existing staff member's data
                department: roleName, // Add the role as a new property
              }));
            });


            var whoisLoggedin = localStorage.getItem("role");
            if (whoisLoggedin == "Admin") {
              this.option_list = this.option_list.filter(
                (item) => item != "Clinic" && item != "Maternity"
              );
            }
            if (this.list_selector !== "All Staffs") {
              this.department_list = this.main_data.filter(
                (obj) => obj.role == this.list_selector
              );
              this.department_list = this.department_list[0].staff;
              this.department_list_reserve = this.department_list;
            }

            else {
              this.department_list = this.main_data.flatMap(item => item.staff);

            }
            // console.log(this.main_data);
            // alert(this.option_list)
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    selectList(role_name) {
      this.list_selector = role_name;
      this.department_list = this.main_data.filter(
        (obj) => obj.role == this.list_selector
      );
      this.department_list = this.department_list[0].staff;
      this.getcategory();
    },
    staffData() {
      window.location = "Staff-Profile";
    },
    showmodal: function (id) {
      $("#con-close-modal").modal("show");
      this.doctor_id = id;
      // alert(this.doctor_id);
    },

    // for other functions
    getcategory() {
      this._getcategory({ type: this.list_selector })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.category = this.response.data;
            console.log(this.category);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },
    change_doctor_category() {
      this._change_doctor_category({
        id: this.doctor_id,
        payload: { type: this.list_selector, category_id: this.category_id },
      })
        .then((response) => {
          $("#con-close-modal").modal("hide");
          this.getStaffList();
          this.response = response.data;
          // if (this.response["status"] == true) {
          this.$toast.success(this.response["message"]);
          // }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "An error occured, try again."
              );
            }
          }
        });
    },

    viewStaffProfile(staff) {
      this.$router.push({ name: "StaffProfile", params: { staff } });
    },
    search_the_list() {
      // this.department_list = this.department_list_reserve;
      var searchLetter = this.search.toLowerCase();
      var searchResult = [];
      for (var i = 0; i < this.department_list.length; i++) {
        var last_name = this.department_list[i].last_name.toLowerCase();
        var first_name = this.department_list[i].first_name.toLowerCase();
        var type = this.department_list[i].type.toLowerCase();
        var category = this.department_list[i].category.toLowerCase();
        var sex = this.department_list[i].sex.toLowerCase();
        if (
          last_name.includes(searchLetter) ||
          first_name.includes(searchLetter) ||
          type.includes(searchLetter) ||
          category.includes(searchLetter) ||
          sex.includes(searchLetter)
        ) {
          searchResult.push(this.department_list[i]);
        }
        // if (searchResult == []) {
        //   this.department_list = this.department_list;
        // }
      }

      if (this.search == "") {
        this.department_list = this.department_list_reserve;
      } else {
        this.department_list = searchResult;
      }
    },

    toggle(id) {
      this.state = 1 - this.state; // Toggle this.state between 0 and 1
      const container = document.getElementById(`toggleContainer${id}`);

      if (this.state === 1) {
        container.classList.remove("off");
        container.classList.add("on");
      } else {
        container.classList.remove("on");
        container.classList.add("off");
      }
    },
    change_acount_status(id) {
      this._change_acount_status({
        id: id,
        payload: { hospital: null },
      })
        .then((response) => {
          this.toggle(id);
          this.getStaffList();
          this.response = response.data;
          this.$toast.success(this.response["message"]);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "An error occured, try again."
              );
            }
          }
        });
    },
    edit_staff(staff) {
      window.location = "/Edit-Staff";
      localStorage.setItem("staffData", JSON.stringify(staff));
    },
  },

  mounted() {
    this.getStaffList();
    this.getcategory();
    // this.toggle()

    $(".cancel_change_cat").click(function () {
      $("#con-close-modal").modal("hide");
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa {
  color: #131313;
}

.selectbtn {
  inline-size: 100%;
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.dropdown-menu {
  block-size: auto;
  inline-size: 100%;
  border-radius: 8px;
}

.dm {
  block-size: 250px;
  border-radius: 8px;
  overflow-x: scroll;
}

.toggle-container {
  display: flex;
  align-items: center;
  inline-size: 60px;
  block-size: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  inline-size: 28px;
  block-size: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  inset-block-start: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

#con-close-modal {
  margin-block-start: 200px;
  margin-inline-start: 100px;
}

@media (max-width: 800px) {
  #con-close-modal {
    margin-inline-start: 0px !important;
  }
}

@media (max-width: 480px) {
  #con-close-modal {
    margin-inline-start: 0px !important;
  }

  #arrangestaff {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  #roleplay {
    margin-left: 0 !important;
    width: 100%;
    margin-bottom: 20%;
  }
}
</style>
