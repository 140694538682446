export function Heart_rate_chart(Series_data,xlabels) {
 return {
   options: {
     chart: {
       id: "heart_rate",
       zoom: {
        enabled: true,
        type: 'x', // Enable zooming on the x-axis
        autoScaleYaxis: true,
        zoomedArea: {
          fill: {
            color: '#90CAF9', // Color of the zoomed area
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1', // Color of the border around the zoomed area
            opacity: 0.4,
            width: 1
          }
        }}
     },

     xaxis: {
       categories: xlabels,
       tickPlacement: 'on',
      //  tickAmount: 12,
      range: 8,
       labels: {
        rotate: -45, // Rotate x-axis labels if necessary
        style: {
          fontSize: '12px',
          marginLeft:'14px'
        }
      },
     
     },
     responsive: [
       {
         breakpoint: 100,
         options: +{
           chart: {
             width: 100,
           },
           legend: {
             position: "bottom",
           },
         },
       },
     ],
     plotOptions: {
       bar: {
         horizontal: false,
         columnWidth: "100%",
         dataLabels: {
           total: {
             enabled: true,
             offsetX: 0,
             style: {
               fontSize: "8px",
               fontWeight: 900,
             },
           },
         },
       },
     },
   },
   series: [
     {
       name: "Heart Rate",
       data: Series_data,
       color: "#14a79e",
     },
   ],
 };}