<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Patient Biodata</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">
                {{ chart_view }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center" id="obserchartview">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>{{ chart_view }}</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-2 col-md-12 col-sm-12 float-end"
                    id="nextlineobserve"
                    style="margin-top: 20px;"
                   >
                    <a-select
                      class="float-end"
                      style="width: 70%"
                      v-model:value="chart_view"
                      @change="switch_table()"
                    >
                      <a-select-option value="Observation Chart"
                        >Observation Chart</a-select-option
                      >
                      <a-select-option value="Fluid Balance Chart_in"
                        >Fluid Balance (in)</a-select-option
                      >
                      <a-select-option value="Fluid Balance Chart_out"
                        >Fluid Balance (out)</a-select-option
                      >
                    </a-select>
                  </div>
                </div>
              </div>
              <!-- /Table Header table-bordered-->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="data" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'fluid_intake_time'">
                      <span style="font-weight: bold">
                        {{ convertTo12HourFormat(record.fluid_intake_time) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'fluid_output_time'">
                      <span style="font-weight: bold">
                        {{ convertTo12HourFormat(record.fluid_output_time) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'time'">
                      <span
                        style="
                          font-weight: bold;
                          width: max-content !important;
                          display: block;
                        "
                      >
                        {{ formatDate(record.created_at, true) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'date'">
                      <span
                        style="
                          font-weight: bold;
                          width: max-content !important;
                          display: block;
                        "
                      >
                        {{ formatDate(record.created_at) }}
                      </span>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jquery from "jquery";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import { formatDate } from "@/utils/formatter";

const columns1 = [
  {
    title: "Date/Time",
    key: "time",
  },
  {
    title: "Temperature (°C)",
    dataIndex: "temperature",
    key: "temperature",
  },
  {
    title: "Pulse (BPM)",
    dataIndex: "pulse",
  },

  {
    title: "Respiration (cm3/min)",
    key: "respiration",
    dataIndex: "respiration",
  },

  {
    title: "Blood Pressure (mmHg)",
    dataIndex: "blood_pressure",
  },
  {
    title: "Oxygen",
    dataIndex: "oxygen",
  },

  {
    title: "Response to Call",
    dataIndex: "response_to_call",
  },
  {
    title: "Response to Stimuli",
    dataIndex: "response_to_stimuli",
  },
];

const columns2 = [
  {
    title: "Date",
    key: "date",
  },
  {
    title: "Time",
    dataIndex: "fluid_intake_time",
    key: "fluid_intake_time",
  },

  {
    title: "Route",
    dataIndex: "fluid_intake_route",
    key: "fluid_intake_route",
  },
  {
    title: "Amount",
    dataIndex: "fluid_intake_amount",
    key: "fluid_intake_amount",
  },
  {
    title: "Type of Fluid",
    dataIndex: "fluid_intake_type",
    key: "fluid_intake_type",
  },
];

const columns3 = [
  {
    title: "Date",
    key: "date",
  },
  {
    title: "Time",
    dataIndex: "fluid_output_time",
    key: "fluid_output_time",
  },

  {
    title: "Urine",
    dataIndex: "fluid_output_urine",
    key: "fluid_output_urine",
  },
  {
    title: "Drain",
    dataIndex: "fluid_output_drain",
    key: "fluid_output_drain",
  },
  {
    title: "NG Tube",
    dataIndex: "fluid_output_ng_tube",
    key: "fluid_output_ng_tube",
  },
  {
    title: "Vomit",
    dataIndex: "fluid_output_vomit",
    key: "fluid_output_vomit",
  },
  {
    title: "Feaces",
    dataIndex: "fluid_output_faeces",
    key: "fluid_output_faeces",
  },
];

export default {
  name: "Index_Page",

  data() {
    return {
      data: null,
      data1: null,
      data2: null,
      data3: null,
      chart_view: "Observation Chart",
      columns: columns1,
      columns1,
      columns2,
      columns3,
      id: localStorage.getItem("this_patient_id"),
      formatDate,
    };
  },

  methods: {
    ...mapActions({
      _get_bioData: "patient/get_bioData",
    }),

    get_bioData1() {
      this._get_bioData({
        id: this.id,
        params: {
          type: "observation_chart",
          count: "all",
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data1 = this.response.data;
            this.data = this.data1;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    get_bioData2() {
      this._get_bioData({
        id: this.id,
        params: {
          type: "fluid_balance_intake",
          count: "all",
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data2 = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    get_bioData3() {
      this._get_bioData({
        id: this.id,
        params: {
          type: "fluid_balance_output",
          count: "all",
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.data3 = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    switch_table() {
      if (this.chart_view == "Observation Chart") {
        this.columns = this.columns1;
        this.data = this.data1;
      } else if (this.chart_view == "Fluid Balance Chart_in") {
        this.columns = this.columns2;
        this.data = this.data2;
      } else if (this.chart_view == "Fluid Balance Chart_out") {
        this.columns = this.columns3;
        this.data = this.data3;
      }
    },
    convertTo12HourFormat(time) {
      const [hours, minutes] = time.split(":").map(Number);
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      return `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    },
  },

  mounted() {
    this.get_bioData1();
    this.get_bioData2();
    this.get_bioData3();

    // this.switch_table()
  },
};
</script>

<style scoped>
@media (max-width: 1280px) {
  .doctor-table-blk {
    margin-bottom: 20px;
  }
  .top-nav-search form {
    width: 750px;
  }

  
}

@media (max-width: 800px) {
  #obserchartview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  .top-nav-search form {
    width: 520px;
  }

  .doctor-search-blk .top-nav-search {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #obserchartview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }

  #nextlineobserve {
    width: 100% !important;
  }

  .top-nav-search form {
    width: 100%;
  }

  .doctor-search-blk .top-nav-search {
    width: 100%;
  }
}
</style>
