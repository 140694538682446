<template>
  <div class="cont_forscale">
    <Loader id="loadercomp" />
    <Inloader class="inloader" id="inloader" />
    <!-- Main Wrapper -->
    <div class="main-wrapper login-body">
      <div class="container-fluid px-0">
        <div class="row">
          <!-- Login logo -->
          <div class="col-lg-6 login-wrap">
            <div class="login-sec">
              <div class="log-img">
                <img
                  class="img-fluid"
                  src="../../assets/assets/img/login-02.png"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
          <!-- /Login logo -->

          <!-- Login Content -->
          <div class="col-lg-6 login-wrap-bg">
            <div class="login-wrapper">
              <div class="loginbox">
                <div class="login-right">
                  <div class="login-right-wrap">
                    <div class="account-logo">
                      <img
                        src="../../assets/assets/img/login-logo.png"
                        alt=""
                        style="padding-bottom: 10px"
                      />
                      <span class="logo-text text-30">StellaSync</span>
                    </div>
                    <h2>Forget Password</h2>
                    <!-- Form -->
                    <div class="input-block">
                      <label>OTP <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="otp" />
                    </div>
                    <div class="input-block">
                      <label>Email <span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="email"
                        v-model="email"
                      />
                    </div>

                    <div class="input-block">
                      <label
                        >Password <span class="login-danger">*</span></label
                      >
                      <input
                        class="form-control pass-input"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="password"
                        @change="removeerror()"
                      />
                      <div class="profile-views">
                        <i
                          class="fa fa-eye toggle"
                          v-if="showPassword"
                          @click="toggle_input_type"
                        ></i>
                        <i
                          class="fa fa-eye-slash toggle"
                          v-else
                          @click="toggle_input_type"
                        ></i>
                      </div>
                    </div>
                    <div class="input-block">
                      <label
                        >Retype Password<span class="login-danger"
                          >*</span
                        ></label
                      >
                      <input
                        class="form-control pass-input"
                        :type="showPassword ? 'text' : 'password'"
                        v-model="retype_password"
                        :style="{ borderColor: is_not_matched ? 'red' : '' }"
                        @input="validatepassword"
                      />
                      <div class="errorcont">
                        <span v-if="is_not_matched" class="small_error">
                          <i class="fa fa-x"></i> Passwords do not match.
                        </span>
                      </div>
                    </div>

                    <div class="input-block login-btn">
                      <div
                        class="btn btn-primary btn-block"
                        id="subbutton"
                        @click="reset_password()"
                      >
                        <span id="btntext">Reset Password</span>
                        <div id="samall_loader"></div>
                      </div>
                    </div>
                    <!-- /Form -->

                    <div class="next-sign">
                      <p class="account-subtitle">
                        Sign in as a different user?
                        <router-link to="/">Login</router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Login Content -->
        </div>
      </div>
    </div>
    <!-- /Main Wrapper -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { store } from "@/store";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Forgot Password",
  components: {
    Loader,
    Inloader,
  },
  data() {
    return {
      email: "",
      otp: "",
      password: "",
      retype_password: "",
      response: "",
      showPassword: false,
      is_not_matched: 0,
    };
  },
  methods: {
    ...mapActions({
      _reset_password: "general/reset_password",
    }),
    toggle_input_type() {
      this.showPassword = !this.showPassword;
    },
    validatepassword() {
      this.is_not_matched = this.password !== this.retype_password;
    },
    reset_password() {
      if (
        this.email == "" &&
        this.password == "" &&
        this.otp == "" &&
        this.retype_password == ""
      ) {
        this.$toast.error("Inputs cannnot be empty");
        $(".form-control").css("border-color", "red");
      } else if (this.password !== this.retype_password) {
        this.$toast.error("passwords must be same.");
      } else {
        $(".subbutton").css("background-color", "black");
        $("#btntext").hide();
        document.getElementById("samall_loader").style.display = "inline-block";
        this._reset_password({
          email: this.email,
          otp: this.otp,
          password: this.password,
        })
          .then((response) => {
            document.getElementById("samall_loader").style.display = "none";
            $("#btntext").show();

            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              window.location = "/";

              this.$toast.success(this.response.message);
            }
          })
          .catch((error) => {
            console.log("Error logging in", error);
            document.getElementById("samall_loader").style.display = "none";
            $("#btntext").show();
            console.log(error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }
          });
      }
    },
    removeerror() {
      $(".form-control").css("border-color", "#f6f7fb");
    },
  },
  mounted() {
    $(document.body).css("zoom", "180%");
    // localStorage.clear();
    // (async function () {
    //   localStorage.clear();
    // })();
    $("#loadercomp").hide();
    // $('#btntext').hide()

    document.addEventListener("DOMContentLoaded", function () {
      // Password
      // if ($(".toggle-password").length > 0) {
      $(".toggle-passwordd").on("click", function () {
        $(this).toggleClass("feather-eye-off feather-eye");
        var input = $(".pass-input");
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
      // }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#samall_loader {
  display: none;
  border: 6px solid #f3f3f3;
  border-top: 4px solid #14a79e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cont_forscale {
  /* zoom: 50%; */
  zoom: 60%;
}

.logo-text {
  font-size: 30px;
  color: black;
  padding-top: 0px;
  margin-left: 10px;
  font-weight: bolder;
}

.toggle {
  font-size: 18px;
  color: black;
  margin-top: -20px;
}

.errorcont {
  font-size: 12px;
  color: red;
  margin-bottom: 0px;
  margin-top: 10px;
  padding-left: 5px;
  /* text-align: center; */
}

.small_error {
  margin-bottom: 10px;
  margin-top: 0px;
  display: inline-block;
}
</style>
