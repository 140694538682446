<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">App </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Bio data</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <!-- Lightbox -->
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mb-0">Bio Data</h4>
            </div>
            <div class="card-body">
              <div class="wizard">
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                  <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Basic">
                    <a class="nav-link active rounded-circle mx-auto d-flex doctor-submit text-end align-items-center justify-content-center"
                      href="#step1" id="step1-tab" data-bs-toggle="tab" role="tab" aria-controls="step1"
                      aria-selected="true" :style="{ backgroundColor: is_step1 ? '#e9e9ef' : '' }">
                      <i>1</i>
                    </a>
                  </li>
                  <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Advanced">
                    <a class="nav-link rounded-circle mx-auto d-flex doctor-submit text-end align-items-center justify-content-center"
                      href="#step2" id="step2-tab" data-bs-toggle="tab" role="tab" aria-controls="step2"
                      aria-selected="false" :style="{ backgroundColor: is_step2 ? '#e9e9ef' : '' }">
                      <i>2</i>
                    </a>
                  </li>
                  <li class="nav-item flex-fill" role="presentation" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Summary">
                    <a class="nav-link rounded-circle mx-auto d-flex doctor-submit text-end align-items-center justify-content-center"
                      href="#step3" id="step3-tab" data-bs-toggle="tab" role="tab" aria-controls="step3"
                      aria-selected="false" :style="{ backgroundColor: is_step3 ? '#e9e9ef' : '' }">
                      <i>3</i>
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" role="tabpanel" id="step1" aria-labelledby="step1-tab">
                    <div class="mb-4">
                      <h3><strong>Basic Vital Sign Details</strong></h3>
                    </div>
                    <form>
                      <div class="row">
                        <div class="col-lg-6" hidden>
                          <div class="input-block mb-3">
                            <label for="basicpill-firstname-input" class="form-label">Blood Pressure (mmHg)<span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id='bp' placeholder="000/000" v-model="blood_pressure"
                              @input="separator()" maxlength="7" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-lastname-input" class="form-label">Glucose Level <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-lastname-input" placeholder="mg/dL"
                              v-model="glucose_level" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="smoking-status-select" class="form-label">Smoking Status<span
                                class="login-danger">*</span></label>
                            <a-select id="smoking-status-select" class="w-100" v-model:value="smoking_status">
                              <a-select-option value="" disabled>Please Select</a-select-option>
                              <a-select-option value="Non-smoker">Non-Smoker</a-select-option>
                              <a-select-option value="Occasional Smoker">Occasional Smoker</a-select-option>
                              <a-select-option value="Regular Smoker">Regular Smoker</a-select-option>
                              <!-- Add more options as needed -->
                            </a-select>
                          </div>
                        </div>

                        <div class="col-lg-6" >
                          <div class="input-block mb-3">
                            <label for="basicpill-lastname-input" class="form-label">Blood Count <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-lastname-input"
                              placeholder="million/mm3" v-model="blood_count" />
                          </div>
                        </div>
                   
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-phoneno-input" class="form-label">Heart Rate <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-phoneno-input" placeholder="BPM"
                              v-model="heart_rate" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-email-input" class="form-label">Body Mass Index<span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-email-input" placeholder="BMI"
                              v-model="body_mass_index" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-email-input" class="form-label">Height <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-email-input" placeholder="cm"
                              v-model="height" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-email-input" class="form-label">Weight <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-email-input" placeholder="kg"
                              v-model="weight" />
                          </div>
                        </div>
                        <div class="col-lg-6" hidden>
                          <div class="input-block mb-3">
                            <label for="basicpill-email-input" class="form-label">Temperature <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-email-input" placeholder="°C" 
                              v-model="temperature" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-email-input" class="form-label">Fever <span
                                class="login-danger">*</span></label>
                            <a-select id="smoking-status-select" class="w-100" v-model:value="fever">
                              <a-select-option value="" selected disabled>Please Select</a-select-option>
                              <a-select-option value="Yes">Yes</a-select-option>
                              <a-select-option value="No">No</a-select-option>
                            </a-select>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="d-flex doctor-submit text-end" @click="step(1)">
                      <span type="submit" class="btn btn-primary submit-form me-2 next">Next</span>
                    </div>
                  </div>
                  <div class="tab-pane fade" role="tabpanel" id="step2" aria-labelledby="step2-tab">
                    <div class="mb-4">
                      <h3><strong>Advanced Vital Sign Details</strong></h3>
                    </div>
                    <form>
                      <div class="row">
                        <div class="col-lg-6" hidden>
                          <div class="input-block mb-3">
                            <label for="basicpill-pancard-input" class="form-label">Oxygen <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-pancard-input" placeholder="%"
                              v-model="oxygen" />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-vatno-input" class="form-label">Carbon <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-vatno-input" placeholder="%"
                              v-model="carbon" />
                          </div>
                        </div>
                     
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-cstno-input" class="form-label">Hydrogen <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-cstno-input" placeholder="%"
                              v-model="hydrogen" />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-servicetax-input" class="form-label">Nitrogen <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-servicetax-input" placeholder="%"
                              v-model="nitrogen" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-servicetax-input" class="form-label">Calcium <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-servicetax-input" placeholder="%"
                              v-model="calcium" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-servicetax-input" class="form-label">Lung <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-servicetax-input" placeholder="%"
                              v-model="lung" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-servicetax-input" class="form-label">Stomach <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-servicetax-input" placeholder="%"
                              v-model="stomach" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-servicetax-input" class="form-label">Liver <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-servicetax-input" placeholder="%"
                              v-model="liver" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-servicetax-input" class="form-label">Brain <span
                                class="login-danger">*</span></label>
                            <input type="number" class="form-control" id="basicpill-servicetax-input" placeholder="%"
                              v-model="brain" />
                          </div>
                        </div>
                        <!-- <div class="col-lg-6">
                              <div class="input-block mb-3">
                                <label
                                  for="basicpill-servicetax-input"
                                  class="form-label"
                                  >Nitrogen  <span class="login-danger">*</span></label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="basicpill-servicetax-input"
                                  placeholder="Nitrogen"
                                />
                              </div>
                            </div> -->
                      </div>
                    </form>
                    <div class="d-flex doctor-submit text-end">
                      <span class="btn btn btn-primary previous me-2" @click='step(-1)'> Back</span>
                      <span class="btn btn btn-primary next" @click='step(2)'>Continue</span>
                    </div>
                  </div>
                  <div class="tab-pane fade" role="tabpanel" id="step3" aria-labelledby="step3-tab">
                    <div class="mb-4">
                      <h3><strong>Summary</strong></h3>
                    </div>
                    <form class="form3">
                      <div class="row">
                        <div class="col-lg-6" hidden>
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Blood Pressure<span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input"
                              v-model="blood_pressure" disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Glucose Level <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input"
                              v-model="glucose_level" disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Heart Rate <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="heart_rate"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Body Mass Index<span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input"
                              v-model="body_mass_index" disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Height <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="height"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Weight <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="weight"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6" hidden>
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Temperature <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="temperature"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Fever <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="fever"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6" hidden>
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Oxygen <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="oxygen"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Carbon <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="carbon"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Hydrogen <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="hydrogen"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Nitrogen <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="nitrogen"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Calcium <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="calcium"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Lung <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="lung"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Stomach <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="stomach"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Liver <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="liver"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Brain <span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input" v-model="brain"
                              disabled />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="input-block mb-3">
                            <label for="basicpill-namecard-input" class="form-label">Smoking Status<span
                                class="login-danger">*</span></label>
                            <input type="text" class="form-control" id="basicpill-namecard-input"
                              v-model="smoking_status" disabled />
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="d-flex doctor-submit text-end">
                      <a class="btn btn-primary previous me-2" @click='step(-2)'>Previous</a>
                      <a class="btn btn-primary next" data-bs-toggle="modal" data-bs-target="#save_modal">Save
                        Changes</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal custom-modal fade" id="save_modal" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-md">
            <div class="modal-content">
              <div class="modal-body">
                <div class="form-header">
                  <h3>Confirm Save Changes</h3>
                  <p>Are you sure want to Confirm Save Changes?</p>
                </div>
                <div class="modal-btn delete-action">
                  <div class="row">
                    <div class="col-6">
                      <button type="reset" data-bs-dismiss="modal" class="w-100 btn btn-primary paid-continue-btn"
                        @click="add_patient_bio_data()">
                        Save Changes
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="submit" data-bs-dismiss="modal"
                        class="w-100 btn btn-primary paid-cancel-btn bg-danger text-light">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      blood_pressure: null,
      glucose_level: "",
      heart_rate: "",
      body_mass_index: "",
      weight: "",
      height: "",
      temperature: "",
      fever: "",
      calcium: "",
      oxygen: "",
      nitrogen: "",
      hydrogen: "",
      carbon: "",
      lung: "",
      stomach: "",
      liver: "",
      brain: "",
      blood_count: "",
      smoking_status: "",
      slashAdded: false,
      is_step1: true,
      is_step2: false,
      is_step3: false,
      id: localStorage.getItem("bio_id")
    };
  },

  methods: {

    step(k) {
      if (k == 1) {
        this.is_step1 = true;
        this.is_step3 = false;
        this.is_step2 = true;
      }
      if (k == -1) {
        this.is_step1 = true;
        this.is_step3 = false;
        this.is_step2 = false;
      }
      if (k == 2) {
        this.is_step1 = true;
        this.is_step3 = true;
        this.is_step2 = true;
      }
      if (k == -2) {
        this.is_step1 = true;
        this.is_step3 = false;
        this.is_step2 = true;
      }
    },
    ...mapActions({
      _add_bioData: "patient/add_bioData",
    }),

    add_patient_bio_data() {
      const bioDataPayload = {
        blood_pressure: this.blood_pressure,
        glucose_level: this.glucose_level,
        heart_rate: this.heart_rate,
        body_mass_index: this.body_mass_index,
        height: this.height,
        weight: this.weight,
        temperature: this.temperature,
        fever: this.fever,
        calcium: this.calcium,
        oxygen: this.oxygen,
        nitrogen: this.nitrogen,
        hydrogen: this.hydrogen,
        carbon: this.carbon,
        lung: this.lung,
        stomach: this.stomach,
        liver: this.liver,
        brain: this.brain,
        blood_count: this.blood_count,
        smoking_status: this.smoking_status,
      };
      // let id = null;
      // if (this.whoisLoggedin == "Doctor"||this.whoisLoggedin == "Admin") {
      //  id = localStorage.getItem("this_patient_id");
      //  alert(id)
      // }

      // else if (this.whoisLoggedin == "Front Desk"||this.whoisLoggedin == "Admin") {
      //   id = localStorage.getItem("bio_id");
      //   alert(id)

      // }
      let id = (this.id !== null) ? this.id : localStorage.getItem("this_patient_id");


      this._add_bioData({ id: id, payload: bioDataPayload })
        .then((response) => {
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            if (localStorage.getItem("this_patient_id") != null) {
              window.location = "My-Patient-Dashboard";
            }

            else if (localStorage.getItem("bio_id") != null) {
              window.location = "Patients"
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },

    formatDate() {
      if (
        this.blood_pressure !== null &&
        this.blood_pressure.toString().length === 3 &&
        !this.slashAdded
      ) {
        // Add '/' after the first three digits
        this.blood_pressure = this.blood_pressure.toString().replace(/(\d{3})/, "$1/");
        this.slashAdded = true;
      } else if (
        this.blood_pressure !== null &&
        this.blood_pressure.toString().length < 3
      ) {
        // Reset slashAdded if the length is less than 3
        this.slashAdded = false;
      }
    },
    separator() {
      var input = document.getElementById('bp')
      input.value = input.value.replace(/[^\d/]/g, '');
      if (input.value.length > 3 && input.value.length < 5) {
        if (input.value.charAt(3) !== '/') {
          input.value = input.value.slice(0, 3) + '/' + input.value.slice(3);
        }
      }
    }
  },

  computed: {},

  mounted() {



    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
    $(".modal-backdrop").hide();
    window.onload = () => {
      require("../../assets/assets/js/app.js");
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
