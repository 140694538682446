import { api, url } from "@/api";
import { downloadData } from "@/utils/download";

export const specializations = {
  namespaced: true,
  state: {
    serviceRequests: [],
    serviceTypes: [],
    serviceHistory: [],

  },

  actions: {
    fetchRequests({ context, state }) {
      api.get(url("services-specialization/requests")).then((res) => {
        state.serviceRequests = res.data.data;
      });
    },
    fetchHistory({ context, state }) {
      api.get(url("services-specialization/history")).then((res) => {
        state.serviceHistory = res.data.data;
      });
    },
    fetchTypes({ context }) {
      return api.get(url("services-specialization"));
    },
    addSpec({ context }, payload) {
      return api.post(url("services-specialization"), {
        ...payload,
        collect_specimen: payload.collect_specimen ? 1 : 0,
      });
    },
    editServiceSpec({ context }, { id, payload }) {
      return api.post(url(`services-specialization/${id}`), {
        ...payload,
        _method: "patch",
        collect_specimen: payload.collect_specimen ? 1 : 0,
      });
    },
    deleteServiceSpec({ context }, id) {
      return api.delete(url(`services-specialization/${id}`));
    },
    // request mgt

    async collectRequestSpecimen({ context, state }, requestId) {
      await api.post(url(`services-specialization/${requestId}/collect`), {
        _method: "patch",
      });
      // Commit the mutation to update the state
      const updatedServiceIndex = state.serviceRequests.findIndex(
        (serviceRequest) => serviceRequest.id == requestId
      );
      state.serviceRequests[updatedServiceIndex].collected = true;
    },
    async updateRequestReport({ context, state }, { requestId, note }) {
      await api.post(url(`services-specialization/${requestId}/note`), {
        note,
        _method: "patch",
      });
      // update the updated request instead of fetching all data
      const updatedServiceIndex = state.serviceRequests.findIndex(
        (serviceRequest) => serviceRequest.id == requestId
      );
      state.serviceRequests[updatedServiceIndex].note = note;
    },


     completedRequestSpecimen({ context, state }, requestId) {
      return api.post(url(`services-specialization/${requestId}/complete`), {
        _method: "patch",
      });
      
      // Commit the mutation to update the state
      // const updatedServiceIndex = state.serviceRequests.findIndex(
      //   (serviceRequest) => serviceRequest.id == requestId
      // );
      // state.serviceRequests[updatedServiceIndex].collected = true;
    },
  },
};

export const quickService = {
  namespaced: true,
  actions: {
    bookService({ context }, payload) {
      return api.post(url("quick-services"), payload);
    },
    fetchBookedServices({ context }) {
      return api.get(url("quick-services"));
    },
    async downloadBookingReport({ context }, id) {
      const response = await api.get(url(`quick-services/${id}/print-report`), {
        responseType: "blob",
      });
      downloadData(response.data);
    },
  },
};
