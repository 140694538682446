<template>
  <div class="settings-menu-links">
    <ul class="nav nav-tabs menu-tabs">
      <li class="nav-item">
        <router-link to="/Room_Category" class="nav-link n1" id="n1"
          >Room Type</router-link
        >
      </li>
      <!-- <li class="nav-item">
        <router-link to="/Room_Number" class="nav-link n2">Room Number</router-link>
      </li> -->
    </ul>
  </div>
</template>

<script>
// import { store } from "../../store";

export default {
  name: "Settings_menu",
  methods: {},

  mounted() {
    $(".n1").click(function () {
      $(".n1").css("background-color", "#14a79e");
      $(".n2,.n3,.n4,.n5").css("background-color", "#8b90ca");
    });

    $(".n2").click(function () {
      $(".n2").css("background-color", "#14a79e");
      $(".n1,.n3,.n4,.n5").css("background-color", "#8b90ca");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#n1 {
  background-color: #14a79e;
}
</style>
