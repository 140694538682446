<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Appointment</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Accepted Appointment List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Appointment</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden="">
                            <img src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>

                <!-- /Table Header table-bordered-->

                <div class="">
                  <a-table :dataSource="main_data" :columns="columns" :loading="isLoading">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key == 'name'">
                        <span
                          @click="
                            gotoDashboard(
                              record.patient_id,
                              record.full_name,
                              record.id,
                              record.patient_type,
                              record.is_collaboration,
                              record.patient_result_id
                            )
                          "
                          style="cursor: pointer"
                        >
                          {{ record.full_name }}
                        </span>
                      </template>
                      <template v-if="column.key == 'patient_type'">
                        <span>
                          {{ edit_text(record.patient_type) }}
                        </span>
                      </template>
                      <template v-if="column.key == 'type'">
                        <button class="custom-badge badge-primary">
                          {{ record.type }}
                        </button>
                      </template>
                      <template v-if="column.key == 'date'">
                        <span>
                          {{ record.date }}
                        </span>
                      </template>

                      <template v-if="column.key == 'action'">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu">
                            <span class="dropdown-item" title="View Patient" @click="
                              gotoDashboard(
                                record.patient_id,
                                record.full_name,
                                record.id,
                                record.patient_type,
                                record.is_collaboration,
                                record.patient_result_id
                              )
                              ">
                              <i class="fa fa-tachometer m-r-5"></i> View
                              Dashboard</span>
                            <span v-if="
                              record.type == 'Direct' &&
                              $store.getters.canAccess([
                                'view_pending_appointment',
                              ])
                            " class="dropdown-item" title="Transfer appointment" @click="showmodal(record.id)"><i
                                class="fa fa-exchange m-r-5"></i> Transfer
                              Patient</span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster transfer_modal"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Transfer patient</h3>
            <p>You either transfer to a docotor or department</p>
          </div>
          <div class="row invite_row">
            <div class="col-6 ib">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle selectbtn" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  {{ category_cat || "Choose a Category" }}
                </button>
                <div class="dropdown-menu" style="
                    height: 100px;
                    overflow-y: scroll;
                    width: 180px;
                    border-radius: 8px;
                  ">
                  <span class="dropdown-item" href="#" :value="data.id" v-for="data in category" :key="data.id"
                    @click="getdoctors(data)">{{ data.category }}</span>
                </div>
              </div>
            </div>

            <div class="col-6 ib">
              <div class="btn-group">
                <button type="button" class="btn dropdown-toggle selectbtn docbtn" data-bs-toggle="dropdown" id="docbtn"
                  aria-haspopup="true" aria-expanded="false" disabled>
                  {{ doc_doc_name || "Choose a Doctor" }}
                </button>
                <div class="dropdown-menu" style="
                    height: 80px;
                    overflow-y: scroll;
                    width: 180px;
                    border-radius: 8px;
                  ">
                  <span class="dropdown-item" :value="data.id" v-for="(data, _index) in doctors" :key="_index"
                    @click="updateDoc(index, data)">
                    {{ data.first_name }} {{ data.last_name }}</span>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12">
              <div class="input-block local-forms">
                <label>Description <span class="login-danger">*</span></label>
                <textarea class="form-control" rows="3" cols="30" v-model="reason">
                Why are you transfering this patient?</textarea>
              </div>
            </div>
          </div>

          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button type="reset" data-bs-dismiss="modal" class="w-100 btn paid-continue-btn subbtn" id="subbtn"
                  @click="transferPatient()" disabled>
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button type="submit" data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn cancel cancel_transfer bg-danger text-light">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- last modal for comfirmation  -->

  <div class="modal modal-lg animate__animated animate__zoomIn animate__faster confirm_doctor_assign"
    style="margin-top:0px;margin-left:100px;margin-right" id="save_modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Assign to a doctor</h3>
            <p>
              Do You want to assign to a doctor in
              {{ category_cat }} department?
            </p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button type="reset" data-bs-dismiss="modal" class="w-100 btn btn-primary paid-continue-btn"
                  @click="activate_doctor_list()">
                  Yes
                </button>
              </div>
              <div class="col-6">
                <button type="submit" data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn cancel bg-danger text-light"
                  @click="deactivate_doctor_list()">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import { store } from "@/store";

import { mapActions } from "vuex";
// import { radiology } from "@/store/adminStore";

const columns = [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Patient Type",
    key: "patient_type",
  },
  {
    title: "Appointment Type",
    key: "type",
  },
  // {
  //   title: "From",
  //   key: "from",
  // },
  {
    title: "Date",
    key: "date",
  },
  {
    // title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      reponse: "",
      main_data: [],
      main_data1: [],
      no_of_appointments: 0,
      apt_id: 0,
      reason: "",

      doctors: "",
      DoctorinCat: "",
      List: "",
      tempDOC: {},
      reponse: "",

      category_id: null,
      category_cat: "",
      doc_id: null,
      category: "",
      doc_doc_name: "",
      role: localStorage.getItem("role"),
      search: "",
      main_data_reserve: [],
      columns,
    };
  },
  methods: {
    ...mapActions({
      _getDoctorApprovedAppontmentList:
        "doctor/getDoctorApprovedAppontmentList",
      // _patientBoidata: "patient/patientBoidata",
      _cat: "general/category",
      _getdoctors: "general/getdoctors",
      _transferPatient: "doctor/transferPatient",
    }),
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    getDoctorApprovedAppontmentList() {
      this._getDoctorApprovedAppontmentList()
        .then((response) => {
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            // alert(this.response.message);
            this.main_data = this.response.data;
            this.main_data = this.main_data;
            this.main_data_reserve = this.main_data;
            // 

            // this.main_data = this.main_data.slice(0, this.entry);

            this.no_of_patients = this.response.data.length;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },

    gotoDashboard(
      id,
      full_name,
      aptid,
      type,
      collaboration,
      patient_result_id
    ) {
      localStorage.setItem("patient_name", full_name);
      localStorage.setItem("apt_id", aptid);
      localStorage.setItem("this_patient_id", id);
      localStorage.setItem("patient_type", type);
      localStorage.setItem("patient_result_id", patient_result_id);
      localStorage.setItem("collaboration", collaboration);

      window.location = "My-Patient-Dashboard";
    },
    edit_text(text) {
      const formatted_text =
        text.charAt(0).toUpperCase() +
        text.slice(1).replace("_", " ").replace("p", "P");
      return formatted_text;
    },
    edit_text2(text) {
      const formatted_text = text.replace("-", " ");
      return formatted_text;
    },
    showmodal: function (id) {
      $(".transfer_modal").modal("show");
      this.apt_id = id;
    },

    //copied from  to power doctor collaboration
    updateDoc(index, value) {
      this.tempDOC[index] = value;
      this.doc_id = this.tempDOC[index].id;
      this.doc_doc_name =
        this.tempDOC[index].first_name + " " + this.tempDOC[index].last_name;
    },
    cat() {
      this._cat()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.category = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    getdoctors(data) {
      $(".confirm_doctor_assign").modal("show");
      $(".invite_modal").modal("hide");

      this.category_id = data.id;
      this.category_cat = data.category;
      this._getdoctors(data.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.doctors = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching docors", error);
        });
    },

    activate_doctor_list() {
      $(".docbtn").removeAttr("disabled").css("bckground-color", "#00d3c7");
      $(".subbtn").removeAttr("disabled").addClass("btn-primary");
      $(".invite_modal").modal("show");
      this.doc_doc_name = "";
    },
    deactivate_doctor_list() {
      $(".docbtn")
        .attr("disabled", "disabled")
        .css("bckground-color", "transparent");
      //  $('#docbtn').removeClass("btn-primary");
      $(".subbtn").removeAttr("disabled").addClass("btn-primary");
      $(".invite_modal").modal("show");
      this.doc_doc_name = "";
    },

    transferPatient() {
      this.reject = "";
      $("#con-close-modal").modal("hide");
      $(".inloader").show();
      this._transferPatient({
        id: this.apt_id,
        payload: {
          reason: this.reason,
          doctor_category_id: this.category_id,
          doctor_id: this.doc_id,
        },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.getDoctorApprovedAppontmentList();
            $(".inloader").hide();
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var full_name = this.main_data[i].full_name.toLowerCase();
          var type = this.main_data[i].type.toLowerCase();
          var patient_type = this.main_data[i].patient_type.toLowerCase();
          var appointment_type =
            this.main_data[i].appointment_type.toLowerCase();
          var date = this.main_data[i].date;
          // var doctor = this.main_data[i].doctor.toLowerCase();
          if (
            full_name.includes(searchLetter) ||
            type.includes(searchLetter) ||
            patient_type.includes(searchLetter) ||
            appointment_type.includes(searchLetter) ||
            date.includes(searchLetter)
            // ||doctor.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
  },
  mounted() {
    this.getDoctorApprovedAppontmentList();
    this.cat();
    console.log();
    $(".cancel_transfer").click(function () {
      $(".invite_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 70%;
}

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

.invite_row {
  padding-left: 3%;
}

.selectbtn {
  width: 200px;
  background-color: #00d3c7;
}

#save_modal {
  margin-top: 0px;
  margin-left: 100px;
}

@media (max-width: 800px) {
  #save_modal {
    margin-top: -35% !important;
  }
}

@media (max-width: 480px) {
  #save_modal {
    margin-top: -25% !important;
    margin-left: -3px;
  }

  .page-table-header {
    overflow-x: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    width: 100% !important;
  }
}
</style>
