export const flutterEventKeys = Object.freeze({
  logout: { type: "logout" },
});

export function postFlutterEvent(event) {
  // Ensure flutter and flutter.postMessage are available
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler("flutter_inappwebview", event);
  } else {
    console.warn("flutter.postMessage is not available.");
  }
}
