<template>
  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster patient_details-modal"
    style="margin-top:0px;margin-left:100px;margin-right"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Patient Details</h3>
            <!-- <p>How we will be administering this drug?</p> -->
          </div>
          <div class="row">
            <div class="col-12">
              <filter-table
                :columns="columns"
                :data="[patient_details_data_recieved]"
                :noPagination="true"
              >
              </filter-table>
              <!-- <div class="sm_info_table">
                <div class="sm_info_table-header">
                  <div class="sm_info_table-row">
                    <div class="sm_info_table-cell">Patient Name</div>
                    <div class="sm_info_table-cell">Phone number</div>
                    <div class="sm_info_table-cell">Age</div>
                    <div class="sm_info_table-cell">Gender</div>
                  </div>
                </div>
                <div class="sm_info_table-body">
                  <div class="sm_info_table-row">
                    <div class="sm_info_table-cell">
                      {{ patient_details_data_recieved.patient_name }}
                    </div>
                    <div class="sm_info_table-cell">
                      {{ patient_details_data_recieved.mobile_number }}
                    </div>
                    <div class="sm_info_table-cell">
                      {{ patient_details_data_recieved.patient_age }}
                    </div>
                    <div class="sm_info_table-cell">
                      {{ patient_details_data_recieved.patient_sex }}
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->
</template>
<script>
// import Loader from "../plugins/Loader.vue";
// import Inloader from "../plugins/inloader";

import jquery from "jquery";
import { mapActions } from "vuex";
const columns = [
  {
    title: "Patient Name",
    dataIndex: "patient_name",
  },
  {
    title: "Phone number",
    dataIndex: "mobile_number",
  },
  {
    title: "Age",
    dataIndex: "patient_age",
  },
  {
    title: "Gender",
    dataIndex: "patient_sex",
  },
];
export default {
  name: "patient_details_modal",
  props: {
    patient_details_data_recieved: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns,
    };
  },

  methods: {},

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sm_info_table {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.sm_info_table-header {
  background-color: #f4f4f4;
  /* font-weight: light; */
}

.sm_info_table-row {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.sm_info_table-cell {
  flex: 1;
  padding: 10px;
  text-align: left;
}

.sm_info_table-cell:nth-child(even) {
  background-color: #f9f9f9;
}

.sm_info_table-cell:last-child {
  border-bottom: none;
}

.sm_info_table-body .sm_info_table-row:hover {
  background-color: #eaeaea;
}
.prescriptions-modal {
  margin-top: 100px;
  margin-left: 100px;
}
@media (max-width: 1280px) {
  #prescriptions-modal {
    margin-left: 10px !important;
  }
}

@media (max-width: 800px) {
  #prescriptions-modal {
    margin-left: -3px !important;
  }
}

@media (max-width: 480px) {
  #prescriptions-modal {
    margin-left: -3px !important;
  }
}
</style>
