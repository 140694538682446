<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">App </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Chat</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-xl-4" style="justify-content: right">
          <div class="close_chat" @click="clearchat()">Close Chat</div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 d-flex">
          <div class="card chat-box-clinic">
            <div class="chat-widgets">
              <!-- <div class="chat-user-group-head d-flex align-items-center" >
                <div class="img-users call-user">
                  <a href="#"
                    ><img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="img"
                  /></a>
                  <span class="active-users"></span>
                </div>
                <div class="chat-users user-main">
                  <div class="user-titles">
                    <h5>William Stephin</h5>
                    <div class="chat-user-time">
                      <p>Doctor</p>
                    </div>
                  </div>
                  <div class="drop-item chat-menu user-dot-list">
                    <a href="#" data-bs-toggle="dropdown" aria-expanded="false" class="">
                      <i class="feather-more-vertical"></i>
                    </a>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-user me-2 text-primary"></i> Profile</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-plus-circle me-2 text-success"></i> Archive</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-trash-2 me-2 text-danger"></i> Delete</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-slash me-2 text-secondary"></i> Block</a
                      >
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="top-liv-search top-chat-search">
                <form>
                  <div class="chat-search">
                    <div class="input-block me-2 mb-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search here"
                      />
                      <a class="btn"
                        ><img
                          src="../../assets/assets/img/icons/search-normal.svg"
                          alt=""
                      /></a>
                    </div>
                    <div class="add-search">
                      <a href="javascript:;"><i class="feather-plus"></i></a>
                    </div>
                  </div>
                </form>
              </div>

              <Message_loader v-if="data.length == 0" />

              <div
                v-else
                class="chat-user-group d-flex align-items-center"
                v-for="chat in data"
                :key="chat.session_id"
                @click="
                  getSingleChat(
                    chat?.session_id,
                    chat?.user_name,
                    chat.user_avatar,
                    chat.user_id,
                    chat.user_type
                  )
                "
              >
                <div class="img-users call-user">
                  <a href="#"><img :src="chat.user_avatar" /></a>
                  <span
                    class="active-users bg-success"
                    v-if="chat.user_status == 'online'"
                  ></span>
                  <span
                    class="active-users bg-warning"
                    v-if="chat.user_status == 'away'"
                  ></span>
                  <span
                    class="active-users bg-danger"
                    v-if="chat.user_status == 'offline'"
                  ></span>
                </div>
                <div class="chat-users">
                  <div class="user-titles d-flex">
                    <h5>{{ chat?.user_name }}</h5>
                    <div class="chat-user-time">
                      <p>{{ chat.created_at }}</p>
                    </div>
                  </div>
                  <div class="user-text d-flex">
                    <!-- <p v-if="chat?.message == null">An image was sent  <i class="fa fa-image"></i></p> -->
                    <p v-if="chat?.message == null">
                      A file was sent
                      <i class="fa fa-image" style="color: #14a79e"></i>
                    </p>
                    <p v-else>{{ chat?.message }}</p>
                    <div class="chat-user-count" v-if="chat?.unread_count != 0">
                      <span>{{ chat?.unread_count }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card chat-box" v-if="session_id_store != ''">
            <div class="chat-search-group">
              <div
                class="chat-user-group mb-0 d-flex align-items-center"
                @click="gotoDashboard()"
              >
                <div class="avatar flex-shrink-0">
                  <img
                    src="../../assets/assets/img/profiles/avatar-01.jpg"
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                   <!-- <span
                    class="active-users bg-success"
                    v-if="chat.user_status == 'online'"
                  ></span>
                  <span
                    class="active-users bg-warning"
                    v-if="chat.user_status == 'away'"
                  ></span>
                  <span
                    class="active-users bg-danger"
                    v-if="chat.user_status == 'offline'"
                  ></span> -->
                </div>

                <div class="chat-users">
                  <div class="user-titles">
                    <h5>{{ preview_name }}</h5>
                  </div>
                  <div class="user-text">
                    <!-- <p>Lorem ipsum dolor sit amet...</p> -->
                  </div>
                </div>
              </div>
              <div class="chat-search-list">
                <ul>
                  <li>
                    <a href="/Video-call"
                      ><img
                        src="../../assets/assets/img/icons/chat-icon-01.svg"
                        alt="img"
                    /></a>
                  </li>
                  <li>
                    <a href="/Voice-call"
                      ><img
                        src="../../assets/assets/img/icons/chat-icon-02.svg"
                        alt="img"
                    /></a>
                  </li>
                  <li>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/chat-icon-03.svg"
                        alt="img"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Chat -->
          <div class="card chat-message-box">
            <div class="card-body p-0">
              <div class="body_fixedheight">
                <div class="chat-body">
                  <ul class="list-unstyled chat-message">
                    <div v-for="data in getSingleChat_data" :key="data.id">
                      <li
                        class="media d-flex received"
                        v-if="data.mine == false"
                      >
                        <!-- <div class="avatar flex-shrink-0">
                        <img
                          src="../../assets/assets/img/profiles/avatar-01.jpg"
                          alt="User Image"
                          class="avatar-img rounded-circle"
                        />
                      </div> -->
                        <div class="media-body flex-grow-1">
                          <div class="msg-box">
                            <div class="message-sub-box">
                              <!-- <h4>{{ preview_name }}</h4> -->
                              <p>
                                <span
                                  class="message"
                                  v-if="data.message != null"
                                >
                                  {{ data.message }}</span
                                >
                                <span class="image" v-if="data.file != null">
                                  <img
                                    :src="data.file"
                                    id="chat_image"
                                    @click="showimg_preview_modal(data.file)"
                                  />
                                </span>
                              </p>
                              <span>{{ data.created_at }}</span>
                            </div>
                          </div>
                        </div>
                      </li>

                      <li class="media d-flex sent" v-if="data.mine == true">
                        <div class="media-body flex-grow-1">
                          <div class="msg-box">
                            <div class="message-sub-box">
                              <p>
                                <span
                                  class="message text-left"
                                  v-if="data.message != null"
                                >
                                  {{ data.message }}</span
                                >
                                <span
                                  class="image"
                                  v-if="data.file != null"
                                  style="width: 100%"
                                >
                                  <img
                                    :src="data.file"
                                    id="chat_image"
                                    @click="showimg_preview_modal(data.file)"
                                  />
                                </span>
                                <span
                                  class="checker ch "
                                  v-if="data.read == 0"
                                  >
                                  <!-- ✔ -->
                                  <img src="../../assets/assets/img/single_check.png" alt="" style="height: 20px;width:20px">
                                  </span
                                >
                                <span
                                  class="checker ch"
                                  v-else-if="data.read == 1"
                                  >
                                  <!-- <span class="cs">✔</span
                                  ><span
                                    style="margin-left: -3px; margin-top: -7px"
                                    class="cs"
                                    >✔</span
                                  > -->
                                  <img src="../../assets/assets/img/double_check.png" alt="" style="height: 20px;width:20px">
                                  
                                  </span
                                >
                              </p>
                              <span>{{ data.created_at }}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>

                <div
                  style="text-align: center; margin-top: 20%; margin-bottom: 5%"
                  v-if="getSingleChat_data == '' || getSingleChat_data == null"
                >
                  <i
                    class="far fa-comments"
                    style="font-size: 150px; color: #14a79e; opacity: 0.4"
                  ></i>
                  <!-- <div class="font-size:24px">No chat opened</div> -->
                </div>
              </div>

              <div class="chat-footer-box">
                <div class="discussion-sent">
                  <div class="row gx-2">
                    <!-- <div class="col-lg-12" v-if="getSingleChat_data != '' || getSingleChat_data != null"> -->
                    <div class="col-lg-12" v-if="session_id_store != ''">
                      <div class="flex-container" id="flex-container">
                        <!-- <img :src="getUrl(product?.image)" alt="product image" @click="showimg_preview_modal(data.file)" style="cursor: pointer;" /> -->
                        <img
                          style="cursor: pointer"
                          id="preview_img"
                          v-for="(image, index) in imagePreviews"
                          :key="index"
                          :src="image"
                        />
                        <!-- <a 
                          href="javascript:void(0);"
                          @click="deleteAvatar(index)"
                          class="imagedelete"
                          ><i class="fa fa-x"></i
                        ></a> -->
                      </div>
                      <div class="footer-discussion">
                        <div class="inputgroups">
                          <input
                            type="text"
                            :placeholder="
                              images.length > 0
                                ? 'you can not send a message while sending a file'
                                : 'Type your Message here...'
                            "
                            v-model="chatIn"
                            :disabled="images.length > 0"
                          />
                          <div class="micro-text position-icon">
                            <img
                              src="../../assets/assets/img/icons/chat-foot-icon-04.svg"
                              alt=""
                            />
                          </div>
                          <div
                            class="send-chat position-icon comman-flex disabled"
                            @click="send_chat()"
                          >
                            <a href="javascript:;"
                              ><img
                                src="../../assets/assets/img/icons/chat-foot-icon-03.svg"
                                alt=""
                            /></a>
                          </div>
                          <div class="symple-text position-icon">
                            <ul>
                              <li>
                                <a href="javascript:;"
                                  ><img
                                    @click="click_file()"
                                    src="../../assets/assets/img/icons/chat-foot-icon-01.svg"
                                    class="me-2"
                                    alt=""
                                /></a>
                                <input
                                  type="file"
                                  hidden
                                  id="upload_chat_file"
                                  class="upload_chat_file"
                                  @change="loadFileInputs($event.target.files)"
                                  multiple
                                />
                              </li>
                              <li>
                                <a href="javascript:;"
                                  ><img
                                    src="../../assets/assets/img/icons/chat-foot-icon-02.svg"
                                    alt=""
                                /></a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chat -->
        </div>
      </div>
    </div>
  </div>

  <!-- image preveiow modal -->
  <!-- Modal structure -->
  <div id="modal" class="modal">
    <div
      class="modal-backdrop"
      id="modalBackdrop"
      @click="hideimg_preview_modal()"
    ></div>
    <div class="modal-content">
      <!-- <h5 class="head">Product Images</h5> -->
      <span
        class="close-btn"
        id="closeModalBtn"
        @click="hideimg_preview_modal()"
        >X</span
      >
      <div class="prev_img_card">
        <img id="previewImage" src="your-image-url.jpg" alt="Image Preview" />
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import Message_loader from "../plugins/Message_loader";
import { mapActions } from "vuex";
import { socket } from "../../modules/websocket";
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Message_loader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      chatIn: "",
      // file: null,
      data: [],
      getSingleChat_data: [],
      preview_name: "",
      session_id_store: "",
      receiver_id: "",
      chat_read: "",
      imagePreviews: [],
      images: [],
      patient_id: "",
      type: "",
    };
  },

  methods: {
    showimg_preview_modal(src) {
      const modal = document.getElementById("modal");
      const img = document.getElementById("previewImage");
      modal.style.display = "block";
      img.src = src;
    },

    hideimg_preview_modal() {
      const modal = document.getElementById("modal");
      modal.style.display = "none";
    },

    // imageviewer(E) {
    //   alert('KKKK')
    //   var previewer = document.getElementById("previewImage");

    //   var imgg = E.target;
    //   var img = imgg.src;
    //   previewer.src = img;

    //   // imgg.style.border = 'none';
    //   // imgg.style.border = '1px solid #00afef';
    // },

    ...mapActions({
      _send_chat: "chat/send_chat",
      _get_chat: "chat/get_chat",
      _getSingleChat: "chat/getSingleChat",
    }),

    get_chat() {
      //   this.getchartdata();

      this._get_chat()
        .then((response) => {
          this.data = response.data.data;
          this.data = this.data;
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    send_chat() {
      if (this.chatIn == "" && this.images.length == 0) {
        this.$toast.error("Add a message");
      } else {
        this._send_chat({
          receiver_type: "patient",
          file: this.images,
          message: this.chatIn,
          receiver_chat_id: this.receiver_id,
        })
          .then((response) => {
            this.chatIn = "";
            this.getSingleChat(this.session_id_store, this.preview_name);
            this.get_chat();
            this.response = response.data;

            if (this.response["status"] == true) {
              $(".inloader").hide();
              this.$toast.success(this.response["message"]);
              // window.location = "My-Patient-Dashboard";
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            console.log(error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }

            $(".inloader").hide();
          });
      }
    },

    getSingleChat(session_id, user_name, previmg, patient_id, type) {
      this.imagePreviews = [];
      this.images = [];
      // for this i will have to send the id of the chat for accuracy
      this.chat_read = true;

      this.preview_name = user_name;
      this.session_id_store = session_id;

      this.patient_id = patient_id;
      this.type = type;

      // alert('loading...')
      this._getSingleChat(session_id)
        .then((response) => {
          this.getSingleChat_data = response.data.data;

          this.receiver_id = this.getSingleChat_data[0].receiver_chat_id;
          this.get_chat();
          // alert(this.receiver_id)
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    clearchat() {
      this.getSingleChat_data = null;
      this.session_id_store = "";
      this.imagePreviews = [];
      this.images = [];
    },

    gotoDashboard() {
      // alert(this.patient_id);
      localStorage.setItem("patient_name", this.preview_name);
      localStorage.setItem("this_patient_id", this.patient_id);
      localStorage.setItem("patient_type", this.type);

      window.location = "My-Patient-Dashboard";
    },

    click_file() {
      document.getElementById("upload_chat_file").click();
    },
    handleFile(files) {
      var flex_container = document.getElementById("flex-container");
      var preview = document.getElementById("preview_img");
      var file = files[0];
      // this.thumbnail = file

      var reader = new FileReader();

      reader.onload = function (e) {
        preview.src = e.target.result;
        flex_container.style = "display:flex";

        // alert(this.previewer);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },

    // for multiple image upload
    loadFileInputs(filess) {
      var flex_container = document.getElementById("flex-container");

      const vm = this;

      // let files = $(this).prop("files");
      let files = filess;
      // if (files.length <= 5) {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        vm.images.push(file);
        flex_container.style = "display:flex";
      }
      // } else {
      //   toast.error("You can not select more than 5 images.", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }
      console.log(vm.images);
      vm.updateImagePreviews();
    },

    updateImagePreviews() {
      // Images for that type
      for (let index = 0; index < this.images.length; index++) {
        const img = this.images[index];

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          this.imagePreviews.push(imageUrl); // Add The Image Url to the List of that type
        };
        console.log(this.imagePreviews, "pre");
        reader.readAsDataURL(img);
      }
    },

    deleteAvatar(index) {
      this.imagePreviews.splice(index, 1);
      // this.avatars[index].splice(index, 1);
      for (let i = 0; i < this.images.length; i++) {
        if (i === index) {
          this.images.splice(i, 1);
          break;
        }
      }
    },

    clearImageInput() {
      var input = document.getElementById("upload_Thumbnail");
      var file = input.files[0];
      this.thumbnail = file;
      this.thumbnail = null;
      var dk = document.getElementById("uploadd");
      dk.style = "display:none";
    },
  },

  mounted() {
    this.get_chat();
try {
    // Code that may throw an error
    socket.addEventListener("message", (event) => {
      let message = JSON.parse(event.data);
      switch (message.channel) {
        case "newMessageAlert":
          this.get_chat();
          this.getSingleChat(this.session_id_store);
          // alert('New message recieved')
          break;
        default:
          break;
      }
    });
} catch (error) {
    // Code to handle the error
    console.error('An error occurred:', error.message);
} finally {}
   
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-users,
.call-user {
  height: 50px;
  width: 50px;
}

.body_fixedheight {
  height: 500px;
  overflow-y: scroll;
}
/* .chat-footer-box{position:fixed;width: 100%;} */

.close_chat {
  text-align: center;
  width: 120px;
  padding: 5px;
  background: #14a79e;
  margin-bottom: 10px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.checker {
  margin-top: -24px;
}
.cs {
  color: #14a79e;
  font-size: 12px;
}
.ch {
  margin-left: 3px;
}

#flex-container {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* justify-content: left;
  align-items: left; */
  gap: 10px; /* Space between images */
  /* position: bottom; */
  margin-top: 10px;
  margin-bottom: -20px;
}

#flex-container img {
  width: 60px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  object-fit: cover; /* Ensure the image covers the entire area without distortion */
  border-radius: 8px; /* Optional: round the corners of the images */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for a nicer look */
}
/* #chat_image {
  height: 50px;
  width: 50px;
  object-fit: contain;
} */

/* modal rules */

#openModalBtn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
  z-index: 2;
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 20px;
  width: 40%;
  min-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-out;
  height: 600px;
  margin-top: 200px;
  z-index: 2;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 18px;
  font-weight: bold;
  color: red;
  cursor: pointer;
  /* border: 1px solid red; */
  padding-left: 8px;
  /* padding-bottom: 28px; */
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.prev_img_card {
  text-align: center;
  height: 80%;
  width: 100%;
  margin-top: 40px;
}

#previewImage {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .modal-content {
    position: relative;
    margin: auto;
    padding: 20px;
    width: 90%;
    min-width: 90%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-out;
    height: 400px;
    margin-top: 30%;
    z-index: 2;
  }
  #previewImage {
    /* max-width:400px; */
    height: auto;
    border-radius: 10px;
    height: 200px;
  }
}

@media screen and (max-width: 320px) {
  .flex-container img {
    width: 30px; /* Adjust the size as needed */
    height: 30px; /* Adjust the size as needed */
  }

  .modal-content {
    height: 400px;
  }
}
</style>
