<template>
  <div class="d-flex align-items-center justify-content-center body">
    <div class="container col-12 col-md-6" v-if="isAdmin == 'true'">
      <h1>
        <img
          src="../../assets/assets/img/login-logo.png"
          alt=""
          style="padding-bottom: 10px"
        />
        StellaSync Suspension Notice
      </h1>
      <p>
        Your account has been suspended due to non-payment of the outstanding
        balance.
      </p>
      <p>
        If you believe this is an error, or if you would like to resolve the
        issue, please visit our
        <router-link to="/Payment-Checkout">payment page</router-link> to settle
        your dues and reactivate your account.
      </p>
      <p>
        Failure to resolve the outstanding balance may result in the permanent
        termination of your access to StellaSync services.
      </p>
      <p>
        If you have any other questions or concerns, please
        <a href="mailto:hello@stellasync.com">contact support</a>.
      </p>
      <router-link to="/Payment-Checkout" class="button"
        >Go to Payment Page</router-link
      >
    </div>

    <div class="container col-12 col-md-6" v-else>
      <h1>
        <img
          src="../../assets/assets/img/login-logo.png"
          alt=""
          style="padding-bottom: 10px"
        />
        StellaSync Suspension Notice
      </h1>
      <p>
        Your account has been suspended due to non-payment of the outstanding
        balance by your Institution.
      </p>
      <p>
        If you believe this is an error, or if you would like to resolve the
        issue, please <a href="/#">Contact your Healthcare Institution.</a>
      </p>
      <p>
        Failure to resolve the outstanding balance may result in the permanent
        termination of your access to StellaSync services.
      </p>
      <p>
        If you have any other questions or concerns, please
        <a href="mailto:hello@stellasync.com">contact support</a>.
      </p>
      <!-- <a href="#" class="button">Go to Payment Page</a> -->
    </div>
  </div>
</template>
<script>
import { store } from "@/store";
import Loader from "../plugins/Loader.vue";

export default {
  name: "Suspension_Page",
  components: {
    Loader,
  },

  data() {
    return {
      isAdmin: localStorage.getItem("isAdmin"),
    };
  },

  methods: {},

  mounted() {
    if (store.state.has_active_subscription) {
      store.dispatch("take2Dashboard");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
} */
.body {
  min-height: 100vh;
}
.container {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #14a79e;
}

a {
  color: #14a79e;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #14a79e;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
}

.button:hover {
  background-color: black;
}

@media (max-width: 800px) {
  .container {
    margin-top: -30% !important;
  }
}
</style>
