<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div id="con-close-modal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster fluid_chart_modal_out"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Fluid Balance (Output)</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" id="timecy">
                    <label>Time</label>
                    <input class="form-control" type="time" v-model="time" />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Urine</label>
                    <input class="form-control" type="text" v-model="urine" placeholder="" />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Drain</label>
                    <input class="form-control" type="text" v-model="drain" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>NG Tube</label>
                    <input class="form-control" type="text" v-model="ng_tub" placeholder="" />
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Vomit</label>
                    <input class="form-control" type="text" v-model="vomit" placeholder="" />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Feaces</label>
                    <input class="form-control" type="text" v-model="feaces" placeholder="" />
                  </div>
                </div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-12 col-sm-6"></div>
                <div class="col-12 col-sm-6"></div>
                
                <div class="col-6" id="subcanme">
                  <div class="doctor-submit text-end">
                    <span type="submit" class="btn btn-primary submit-form me-2" @click="add_patient_bio_data()">
                      Submit
                    </span>
                    <span class="btn btn-primary cance_fluid_chart_out bg-danger text-light">Cancel</span>
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},
  data() {
    return {
      // blood_pressure: "",
      // glucose_level: "",
      // heart_rate: "",
      // body_mass_index: 0,
      // weight: 0,
      // height: 0,
      // temperature: "",
      // fever: 0,
      // calcium: 0,
      // oxygen: "",
      // nitrogen: 0,
      // hydrogen: 0,
      // carbon: 0,
      // lung: 0,
      // stomach: 0,
      // liver: 0,
      // brain: 0,
      // blood_count: 0,
      // smoking_status: "",
      drain: "",
      urine: "",
      time: "",
      ng_tub: "",
      feaces: "",
      vomit: "",

    };
  },

  methods: {
    ...mapActions({
      _add_bioData: "patient/add_bioData",
    }),

    add_patient_bio_data() {
      $(".inloader").show();
      $(".open_fluid_chart_out").modal("hide");
      const bioDataPayload = {
        fluid_output_drain: this.drain,
        fluid_output_urine: this.urine,
        fluid_output_time: this.time,
        fluid_output_ng_tube: this.ng_tub,
        fluid_output_faeces: this.feaces,
        fluid_output_vomit: this.vomit,
      };
      // if (this.whoisLoggedin == "Doctor" || this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      // if (this.whoisLoggedin == "Front Desk" ||this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      let id = localStorage.getItem("this_patient_id");
      this._add_bioData({ id: id, payload: bioDataPayload })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // this.$router.push({ name: "Patients" });
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    goto(route) {
      window.location = route;
      // this.$router.push({ name: route })
    },
    separator() {
      var input = document.getElementById("bp");
      input.value = input.value.replace(/[^\d/]/g, "");
      if (input.value.length > 3 && input.value.length < 5) {
        if (input.value.charAt(3) !== "/") {
          input.value = input.value.slice(0, 3) + "/" + input.value.slice(2);
        }
      }
    },
  },
  mounted() {
    $(".cance_fluid_chart_out").click(function () {
      $(".fluid_chart_modal_out").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}



@media (max-width: 480px) {
  #timecy{
    margin-top: 10%;
    height: 40px
  }
  /* #hrtrat {
    margin-top: 3%;
  } */

  #con-close-modal {
    margin-left: 0px !important;
    margin-top: 90px !important;
    margin-top:200px;
    margin-left:100px;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 60vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e  #3334480d;
  }

  #subcanme{
    width: 100% !important;
  }
  
}
</style>
