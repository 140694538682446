<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="hospitals">Hospitals </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Offline Hospital</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Hospital Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Device Mac Address
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="mac_address" required minlength="8"
                        placeholder="00:00:00:00:00:00" maxlength="17" @input="setMacformat(mac_address)" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Allowed IP Address <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="ip_address" maxlength="15"
                        placeholder="192.168.0.1" @input="setIPformat(ip_address)" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Institution<span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="hospital_id" showSearch
                        :filterOption="(input, option) => filterObject(option, input, 'key')">
                        <a-select-option disabled value="Select a hospital">
                          Select a hospital
                        </a-select-option>
                        <a-select-option v-for="hospital in hospital_list" v-bind:key="hospital.name"
                          :value="hospital.institution_id">
                          {{ hospital.name }}
                        </a-select-option>

                        <a-select-option v-if="hospital_list.length == 0" disabled="">
                          No hospital yet
                        </a-select-option>
                      </a-select>
                    </div>


                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span @click="Register_Offline_Hospital()" class="btn btn-primary submit-form me-2">Submit</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OTP />
</template>

<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";
import OTP from "./Offline_OTP.vue";
import { filterObject } from "@/utils/helpers";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    OTP,
  },

  data() {
    return {
      filterObject,
      mac_address: null,
      ip_address: null,
      hospital_id: null,
      hospital_list: []
    };
  },

  methods: {
    ...mapActions({
      _Register_Offline_Hospital: "general/Register_Offline_Hospital",
      _get_hospital: "general/get_hospitalList",


    }),
    stringToArray(str) {
      return str.split(",");
    },

    Register_Offline_Hospital() {

      if (this.mac_address == null && this.ip_address == null && this.hospital_id == null) {
        this.$toast.error('Fill all the input fields to proceed.');

      }
      else {
        $(".inloader").show();
        // alert(this.dob)
        this._Register_Offline_Hospital({
          mac_address: this.mac_address,
          device_ip: this.ip_address,
          hospital_id: this.hospital_id
        })
          .then((response) => {
            this.response = response.data;
            $(".inloader").hide();
            $("#offline_otp_modal").modal("show");
            this.startCountdown();

            // this.$router.push({ name: "Offline_Hospitals" });
            // this.$toast.success(this.response["message"]);

            // show otp modal
          })
          .catch((error) => {
            $(".inloader").hide();
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422) {
                this.$toast.error(error.response.data.message);
              }

            }
          });
      }
    },
    gethospital() {
      this._get_hospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.hospital_list = this.response.data;

          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    updateTimerDisplay(timeInSeconds) {
      // Get the timer element
      const timerElement = document.getElementById("count-down");

      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      timerElement.textContent = formattedTime;
    },
    performAction() {
      $("#offline_otp_modal").modal("hide");
      $(".xvx").show();
    },
    startCountdown() {
      const countdownTime = 5 * 60; // 5 minutes * 60 seconds
      let timeRemaining = countdownTime;
      // Update the display initially
      this.updateTimerDisplay(timeRemaining);

      let _vm = this;
      // Set an interval to update the countdown every second
      const countdownInterval = setInterval(function () {
        timeRemaining--;

        // Update the display
        _vm.updateTimerDisplay(timeRemaining);

        // Check if the countdown reached 0
        if (timeRemaining <= 0) {
          // Clear the interval
          clearInterval(countdownInterval);

          // Perform the action when the countdown reaches 0
          _vm.performAction();
        }
      }, 1000); // Update every 1000 milliseconds (1 second)
    },
    setMacformat(mac_address) {
      let value = mac_address.replace(/[^0-9A-Fa-f]/g, '').toUpperCase(); // Remove invalid characters

      // Insert colons at the correct positions
      if (value.length > 2) {
        value = value.match(/.{1,2}/g).join(':'); // Group into pairs
      }

      this.mac_address = value;
    },
    setIPformat(ip_address) {
      let value = ip_address.replace(/[^0-9.]/g, ''); // Remove invalid characters

      // Split the value into parts
      const parts = value.split('.').filter(part => part);

      // Limit each part to 3 digits and take only the first 4 parts
      const formattedParts = parts.map(part => {
        return part.slice(0, 3); // Limit to 3 digits
      }).slice(0, 4); // Limit to 4 parts

      // Join parts with dots and update the input value
      this.ip_address = formattedParts.join('.');

      // Optionally, add a trailing dot for better user experience
      if (formattedParts.length < 4 && this.ip_address[this.ip_address.length - 1] !== '.') {
        this.ip_address += '.';
      }
    }
  },

  mounted() {
    this.gethospital();

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
