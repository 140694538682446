<template>
<Loader id="loadercomp" />
<Inloader class="inloader" id="inloader" />
  
      
        <div class="page-wrapper">
          <div class="content">
			
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="patients.html">Room Allotment </a></li>
              <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
              <li class="breadcrumb-item active">New Allotment</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
        
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>New Allotment</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">  
                    <div class="input-block local-forms">
                      <label >First Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Last Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" >
                    </div>
                  </div>
                 
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Mobile <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Email <span class="login-danger">*</span></label>
                      <input class="form-control" type="email" placeholder="" >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Room No <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Room Type <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" >
                    </div>
                  </div>
                  
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="gender" class="form-check-input">Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="gender" class="form-check-input">Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Admit Date <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" placeholder="" >
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label >Discharge Date <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" placeholder="" >
                    </div>
                  </div>

   <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-top-form">
                      <label class="local-top">Avatar <span class="login-danger">*</span></label>
                      <div class="settings-btn upload-files-avator">
                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)" class="hide-input">
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div>              
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                      <button type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>							
        </div>					
      </div>
          </div> 

           
        </div>
   
    
</template>            
<script>







import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";


export default {
  name: "Index_Page",
  components: {
    
Loader,
Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
     
    };
  },

  methods: {
    
  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>