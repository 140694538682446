<template>
  <div id="sub_cont" class="modal modal-xl animate__animated animate__zoomIn animate__faster sub_cont"
    style="margin-top:100px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Subscription Plans</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <!-- {{ plan }} -->

          <div class="toggle-container ">
            <button class="toggle-btn active" id="monthly-btn" @click="subSwitch('monthly')">
              Monthly
            </button>
            <button class="toggle-btn" id="yearly-btn" @click="subSwitch('yearly')">
              Yearly
            </button>

          </div>
          <br />
          <div class="pricing-container" v-if="state">
            <!-- Clinic & Maternity Plan -->
            <div class="pricing-card" v-for="plan in subscriptions" :key="plan">
              <div class="pricing-header">
                <span class="fa fa-hospital sub_icon"></span>

                <h2>{{ plan.name }}</h2>
              </div>
              <ul class="features-list">
                <li v-for="ft in plan.features" :key="ft">
                  {{ ft }}
                </li>
              </ul>
              <div class="price">
                <p>₦ {{ plan.monthly_price }} / Month</p>
              </div>
              <router-link to="/Payment-Checkout" class="subscribe-btn" @click="close_modal()">
                {{ isLowerThanCurrentPlan(plan) ? 'Downgrade' : 'Upgrade' }}</router-link>
            </div>
            <div class="pricing-card" id="custom" v-if="plan?.custom_plan == true">
              <div class="pricing-header">
                <span class="fa fa-hospital sub_icon"></span>

                <h2>Enterprise</h2>
              </div>
              <ul class="features-list">
                <li v-for="ft in plan?.plan?.features" :key="ft">
                  {{ ft }}
                </li>
              </ul>
              <div class="price">
                <p v-if="plan?.type == 'Monthly' && plan?.custom_plan == false">₦{{ plan?.plan.monthly_price }}/Month
                </p>
                <p v-if="plan?.type == 'Yearly' && plan?.custom_plan == false">₦{{ plan?.plan.yearly_price }}/Yearly</p>
                <p v-if="plan?.custom_plan == true">₦{{ plan?.plan.price }}/{{ plan.type }}</p>
              </div>
              <a href="mailto:hello@stellasync.com" class="subscribe-btn" @click="close_modal()">Contact Support</a>
            </div>
          </div>
          <div class="pricing-container" v-else>
            <div class="pricing-card" v-for="plan in subscriptions" :key="plan">
              <div class="pricing-header">
                <span class="fa fa-hospital sub_icon"></span>
                <h2>{{ plan.name }}</h2>
              </div>
              <ul class="features-list">
                <li v-for="ft in plan.features" :key="ft">
                  {{ ft }}
                </li>
              </ul>
              <div class="price">
                <p>₦ {{ plan.yearly_price }} / Year</p>
              </div>
              <!-- <button class="subscribe-btn">Subscribe Now</button> -->
              <router-link to="/Payment-Checkout" class="subscribe-btn"> {{ isLowerThanCurrentPlan(plan) ? 'Downgrade' :
                'Upgrade' }}</router-link>

            </div>

            <div class="pricing-card" id="custom" v-if="plan?.custom_plan == true">
              <div class="pricing-header">
                <span class="fa fa-hospital sub_icon"></span>

                <h2>Enterprise</h2>
              </div>
              <ul class="features-list">
                <li v-for="ft in plan?.plan?.features" :key="ft">
                  {{ ft }}
                </li>
              </ul>
              <div class="price">
                <p v-if="plan?.type == 'Monthly' && plan?.custom_plan == false">₦{{ plan?.plan.monthly_price }}/Month
                </p>
                <p v-if="plan?.type == 'Yearly' && plan?.custom_plan == false">₦{{ plan?.plan.yearly_price }}/Yearly</p>
                <p v-if="plan?.custom_plan == true">₦{{ plan?.plan?.price }}/{{ plan.type }}</p>
              </div>
              <a href="mailto:hello@stellasync.com" class="subscribe-btn" @click="close_modal()">Contact Support</a>
            </div>
          </div>
          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "subscription",
  data() {
    return {
      planLevels: {
        free: 0,
        basic: 1,
        premium: 2,
        enterprise: 3
      },
      state: true,
      subscriptions: [],
      plan: null,
      subscriptions_reserve: []
    };
  },
  methods: {
    ...mapActions({
      _get_subscription: "subscription/get_subscription",
    }),
    isLowerThanCurrentPlan(plan) {
      return this.plan?.plan?.price > plan?.monthly_price
    },
    subSwitch(action) {
      const monthlyBtn = document.getElementById("monthly-btn");
      const yearlyBtn = document.getElementById("yearly-btn");
      if (action == "monthly") {
        monthlyBtn.classList.add("active");
        yearlyBtn.classList.remove("active");
        this.state = true;
      }

      if (action == "yearly") {
        yearlyBtn.classList.add("active");
        monthlyBtn.classList.remove("active");
        this.state = false;
      }
    },
    get_subscription() {
      this._get_subscription()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.subscriptions = this.response.data;
            this.subscriptions_reserve = this.subscriptions;

            if (this.plan?.custom_plan == true) {
              this.subscriptions = this.subscriptions.filter(plan => plan.name !== "Enterprise")
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    close_modal() {
      $(".sub_cont").modal("hide");
      // get last 
      localStorage.setItem("last_route_from_sub", this.$route.fullPath)
    }
  },

  mounted() {
    var role = localStorage.getItem("role");

    this.get_subscription();
    if (role != "Super Admin") {
      this.plan = JSON.parse(localStorage.getItem("current_plan"))
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */

.pricing-container {
  display: flex;
  gap: 20px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
}

.pricing-card {
  background: linear-gradient(to bottom, #17a2b8, #00696a);
  border-radius: 10px;
  color: white;
  padding: 20px;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  justify-content: center;
}

.pricing-header {
  text-align: center;
}

.pricing-header img {
  width: 50px;
  margin-bottom: 10px;
}

.features-list {
  list-style: none;
  margin: 20px 0;
  text-align: center;
}

.features-list li {
  margin-bottom: 10px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.subscribe-btn {
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.subscribe-btn:hover {
  background-color: black;
  color: white;
}

/* ......... */
.toggle-container {
  display: flex;
  border: 2px solid #17a2b8;
  border-radius: 25px;
  overflow: hidden;
  width: 40%;
  margin-left: 30%;
}

.toggle-btn {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent;
  color: #333;
  font-family: Arial, sans-serif;
  width: 50%;
}

.toggle-btn.active {
  background-color: #17a2b8;
  color: white;
}

#monthly-btn.active {
  border-radius: 25px 0 0 25px;
}

#yearly-btn.active {
  border-radius: 0 25px 25px 0;
}

.toggle-btn:hover {
  background-color: rgba(23, 162, 184, 0.1);
}

.sub_icon {
  font-size: 50px;
}

/* .modal-open .subscription{
  display: block;
} */
@media (max-width:1280px) {}

@media (max-width:800px) {}

@media (max-width:600px) {}

@media (max-width:480px) {
  .toggle-container {
    width: 54%;
  }
}
</style>
