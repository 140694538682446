export function Blood_pressure_chart(Series_data1, Series_data2, xlabels) {
  return {
      options: {
        chart: {
          // id: "vuechart-example",
          zoom: {
           enabled: true,
          //  type: 'x', // Enable zooming on the x-axis
          //  autoScaleYaxis: true,
          //  zoomedArea: {
            //  fill: {
            //    color: '#90CAF9', // Color of the zoomed area
            //    opacity: 0.4
            //  },
            //  stroke: {
            //    color: '#0D47A1', // Color of the border around the zoomed area
            //    opacity: 0.4,
            //    width: 1
            //  }
          //  }
          }
         
        },
   
        xaxis: {
         categories: xlabels,
         tickPlacement: 'on',
        //  tickAmount: 12,
        range: 8,
         labels: {
         //  rotate: -45, // Rotate x-axis labels if necessary
          style: {
            fontSize: '12px',
            color: '#333', 
           marginLeft: '64px'
          }
        },
       
       },
        responsive: [
          {
            breakpoint: 100,
            options: +{
              chart: {
                width: 100,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
      },
    series: [
      {
        name: "High",
        color: "#14a79e",
        data: Series_data1,
      },
      {
        name: "Low",
        color: "#000000",
        data: Series_data2,
      },
    ],
  };}
  
