<template>
  <div class="settings-menu-links">
    <ul class="nav nav-tabs menu-tabs">
      <!-- <li class="nav-item active">
        <router-link to="/Settings" class="nav-link">General Settings</router-link>
      </li> -->
      <li class="nav-item active">
        <router-link to="/Settings" class="nav-link">View Roles</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/View-Category" class="nav-link">View Categories</router-link>
      </li>
      <!-- <li class="nav-item hide_for_now" hidden="">
      <router-link to="/Assign-Permission" class="nav-link">Assign Permission</router-link>
    </li> -->
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Loacalization-Settings" class="nav-link"
          >Localization</router-link
        >
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Payment-Settings" class="nav-link"
          >Payment Settings</router-link
        >
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Email-Settings" class="nav-link">Email Settings</router-link>
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Social-Login-Settings" class="nav-link"
          >Social Media Login</router-link
        >
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Social-Links" class="nav-link">Social Links</router-link>
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/SEO-Settings" class="nav-link">SEO Settings</router-link>
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Theme-Settings" class="nav-link">Theme Settings</router-link>
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Change-Password" class="nav-link">Change Password</router-link>
      </li>
      <li class="nav-item hide_for_now" hidden="">
        <router-link to="/Other-Settings" class="nav-link">Others</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
// import { store } from "../../store";

export default {
  name: "Settings_menu",
  methods: {},

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.none,
.hide_for_now {
  display: none;
  cursor: pointer;
}
</style>
