export function downloadData(data, type = "application/pdf") {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${localStorage.getItem("hospital")} PRINT OUT.pdf`
  );
  link.style.display = "none";
  document.body.appendChild(link);

  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
