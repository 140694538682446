<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="/Settings">Settings </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">View Permission</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="settings-menu-links">
        <ul class="nav nav-tabs menu-tabs">
          <!-- <li class="nav-item active">
            <router-link to="/Settings" class="nav-link">General Settings</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/View-Roles" class="nav-link">View Roles</router-link>
          </li>
          <li class="nav-item  active" v-if="role == 'Super Admin'">
            <router-link to="/View-Permissions" class="nav-link">View Permissions</router-link>
          </li>
          <li class="nav-item" v-if="role == 'Super Admin'">
            <router-link to="/View-Permission-Categories" class="nav-link">Permission Categories</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/View-Category" class="nav-link">View Categories</router-link>
          </li>
          <li class="nav-item not_for_admin">
            <router-link to="/Splash-Screen" class="nav-link"
              >Splash Screen</router-link
            >
          </li>
          <!-- <li class="nav-item hide_for_now" hidden="">
      <router-link to="/Assign-Permission" class="nav-link">Assign Permission</router-link>
    </li> -->
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Loacalization-Settings" class="nav-link">Localization</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Payment-Settings" class="nav-link">Payment Settings</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Email-Settings" class="nav-link">Email Settings</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Login-Settings" class="nav-link">Social Media Login</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Links" class="nav-link">Social Links</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/SEO-Settings" class="nav-link">SEO Settings</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Theme-Settings" class="nav-link">Theme Settings</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Change-Password" class="nav-link">Change Password</router-link>
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Other-Settings" class="nav-link">Others</router-link>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Permisions</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_new_permission" data-bs-toggle="modal"
                            data-bs-target="#add-permission-modal"><img src="../../assets/assets/img/icons/plus.svg"
                              alt="" /></span>
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-end float-end ms-auto" id="docsbutton">
                    <div class="btn-group selectbtn">
                      <button type="button" class="btn dropdown-toggle btn-primary btn-xl" data-bs-toggle="dropdown">
                        {{ permision_selector || "Permissions" }}
                      </button>
                      <div class="dropdown-menu dropdown-menusk">
                        <span class="dropdown-item text-bg-secondary">Select List</span>
                        <span class="dropdown-item" v-for="(name, index) in option_list" v-bind:key="index"
                          @click="selectpermission(name)">{{ name }}</span>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
              <!-- /Table Header -->
              <!-- {{ permission_category_list }} -->
              <div class="table-responsie" style="overflow: visible">
                <a-table :dataSource="_filtered_rolePermission" :columns="columns">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                          aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a class="dropdown-item" href="#" data-bs-toggle="modal"
                            data-bs-target="#edit-permission-modal" @click="handover_details(record)"><i
                              class="fa fa-pen-to-square m-r-5"></i>
                            Edit</a>
                          <a class="dropdown-item" href="#" @click="confirmAction(record.id)"><i
                              class="fa fa-x m-r-5"></i>
                            Delete</a>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <!-- sample modal content -->

    <div id="add-permission-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Permission</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Name <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="" v-model="permission_name" />
                </div>
              </div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Category <span class="login-danger"></span></label>
                  <select class="form-control" type="text" name="cat" v-model="permission_category">
                    <option value="" disabled>Select Category</option>
                    <option :value="d.id" v-for="d in permission_category_list" v-bind:key="d.id">
                      {{ d.category }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>Description </label>
                  <textarea class="form-control" rows="3" cols="30" v-model="description">
              </textarea>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="doctor-submit text-end">
                <span type="submit" class="btn btn-primary submit-form me-2" @click="add_Permission()">
                  Submit
                </span>
                <span class="btn btn-primary cancel-form bg-danger text-light" data-bs-dismiss="modal"
                  aria-label="Close">
                  Cancel
                </span>
              </div>
            </div>
            <!-- </div> -->

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>

    <!-- edit permission -->
    <div id="edit-permission-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
      tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Edit Permission</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <div class="row">
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Name <span class="login-danger">*</span></label>
                  <input class="form-control" type="text" placeholder="" v-model="edit_permission_name" />
                </div>
              </div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Category <span class="login-danger"></span></label>
                  <select class="form-control" type="text" name="cat" v-model="edit_permission_category">
                    <option value="" disabled>Select Category</option>
                    <option :value="d.id" v-for="d in permission_category_list" v-bind:key="d.id">
                      {{ d.category }}
                    </option>

                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>Description </label>
                  <textarea class="form-control" rows="3" cols="30" v-model="edit_description">
              </textarea>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="doctor-submit text-end">
                <span type="submit" class="btn btn-primary submit-form me-2" @click="editPermission()">
                  Submit
                </span>
                <span class="btn btn-primary cancel-form bg-danger text-light" data-bs-dismiss="modal"
                  aria-label="Close">
                  Cancel
                </span>
              </div>
            </div>
            <!-- </div> -->

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Settings_menu";
import Swal from "sweetalert2";

import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Slug",
    dataIndex: "code",
    key: "code",
  },
  {
    // title: "Action",
    key: "action",
  },
  // {
  //   key: "action",
  // },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      _permissions: [],
      rolePermission: JSON.parse(localStorage.getItem("edit_permissions")),
      _filtered_rolePermission: [],
      permision_selector: "Doctor",
      option_list: [],
      role: localStorage.getItem("role"),
      role_id: localStorage.getItem("role_id"),
      roles: [],
      selectedRole: JSON.parse(localStorage?.getItem("roles")),
      columns,
      permission_category_list: null,
      permission_name: null,
      permission_category: null,
      description: null,
      edit_permission_name: null,
      edit_permission_category: null,
      edit_description: null,
      permission_id: null,

    };
  },

  methods: {
    ...mapActions({
      _getPermissions: "general/getPermissions",
      _editPermission: "general/edit_main_permission",
      _add_Permission: "general/add_Permission",
      _delete_permission: "general/delete_permission",
      _get_category_permissions: "general/get_Permission_category",

    }),
    getPermissions() {
      this._getPermissions()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this._permissions = this.response.data;
            this._permissions = this._permissions;

            for (const obj of this._permissions) {
              this.option_list.push(obj.category);
            }
            this._filtered_rolePermission = this._permissions.filter(
              (obj) => obj.category == this.permision_selector
            );

            this._filtered_rolePermission =
              this._filtered_rolePermission[0].permission;
          }

          // getting category with id
          // this.permission_category_list = Array.from(
          //   new Map(this._permissions.map(item => [item.category, { name: item.category, id: item.id }])).values()
          // );
          // console.log(this.permission_category_list,'uio');

        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    get_category_permissions() {
      this._get_category_permissions({ all: 1 })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.permission_category_list = this.response.data;
            this.permission_category_list = this.permission_category_list;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    selectpermission(permission_name) {
      this.permision_selector = permission_name;
      this._filtered_rolePermission = this._permissions.filter(
        (obj) => obj.category == this.permision_selector
      );
      this._filtered_rolePermission =
        this._filtered_rolePermission[0].permission;
    },


    add_Permission() {
      $(".inloader").show();
      this._add_Permission({
        name: this.permission_name,
        slug: this.description,
        permission_category_id: this.permission_category,
      })
        .then((response) => {
          $(".inloader").hide();
          $("#add-permission-modal").modal("hide");

          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);

          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },


    editPermission() {
      $(".inloader").show();
      this._editPermission({
        id: this.permission_id,
        payload: {
          name: this.edit_permission_name,
          slug: this.edit_description,
          permission_category_id: this.edit_permission_category,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          this.getPermissions();
          $("#edit-permission-modal").modal("hide");
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // window.location = "View-Roles";
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

    handover_details(record) {
      console.log(record)
      this.edit_permission_name = record.name
      this.edit_permission_category = record.permission_category_id
      this.edit_description = record.code
      this.permission_id = record.id
    },

    delete_permission(permission_id) {
      $(".inloader").show();
      this._delete_permission(permission_id)
        .then((response) => {
          $(".inloader").hide();

          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

    confirmAction(permission_id) {
      Swal.fire({
        title: "Are you sure you want delete this permission?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete_permission(permission_id);
        }
      });
    },
  },

  mounted() {
    this.getPermissions();
    this.get_category_permissions()

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n2 {
  background-color: #14a79e;
}

.checkb {
  height: 25px;
  width: 25px;
}

.dropdown-item {
  font-size: 15px;
}

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.dropdown-menusk {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  overflow-x: scroll;
}

.role_seclect {
  float: right;
  border: 1px solid #00d3c7;
}

@media (max-width: 1280px) {

  #updacance {
    padding: 20px !important;
  }
}

@media (max-width: 800px) {

  #updacance {
    padding: 20px !important;
  }
}

@media (max-width: 480px) {
  .page-table-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  #docsbutton {
    width: 100%;
    margin-top: 5%;
    margin-bottom: -10%;
  }

  #newrolehere {
    width: 100%;
    margin-top: 20%;
  }

  .top-nav-search form {
    width: 370px;
  }

  .doctor-table-blk {
    margin-bottom: 3%;
  }

  #updacance {
    padding: 10px !important;
  }

  .dropdown-menu {
    position: absolute !important;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(0px, 52px, 0px);
    overflow-y: scroll;
    max-height: 60vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    z-index: 999;
  }

  /* #docsbutton{height: 500px;} */
}
</style>
