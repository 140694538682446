<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Specialization</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Service Specializations</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- <SettingsMenu /> -->

      <div class="row">
        <div class="col-sm-12">
          <filter-table :search-keys="['name']" :columns="columns" :data="specializationServices"
            title="List of Service Specializations" :loading="isLoading">
            <template #addGroup>
              <span class="bntn btn-primary add-pluss ms-2 add_room"><img src="../../assets/assets/img/icons/plus.svg"
                  alt="" /></span>
            </template>

            <template #bodyCell="{ column, record, index }">
              <template v-if="column.key == 'price'">
                <span> ₦ {{ formatAmount(record.price) }} </span>
              </template>
              <template v-if="column.key == 'collect_specimen'">
                <span> {{ record.collect_specimen ? 'Yes' : 'No' }} </span>
              </template>
              <template v-if="column.key == 'date'">
                <span> {{ formatDate(record.created_at) }} </span>
              </template>
              <template v-if="column.key == 'action'">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="fa fa-ellipsis-v"></i></a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#edit-close-modal"
                      @click="setSelectedLabTestType(record)"><i class="fa fa-pen-to-square m-r-5"></i>
                      Edit</a>
                    <a class="dropdown-item" href="#" @click="deleteServiceSpec(record.id, index)">
                      <i class="fa fa-trash m-r-5"></i>Delete</a>
                  </div>
                </div>
              </template>
            </template>
          </filter-table>

        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Specialization Type</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="row">
            <div class="col-12"></div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="input-block local-forms">
                <label>Name<span class="login-danger">*</span></label>
                <input class="form-control" type="text" placeholder="e.g Gastro test" v-model="newSpec.name" />
              </div>
            </div>

            <div class="col-12 col-md-12 col-xl-12">
              <div class="input-block local-forms">
                <label>Price <span class="login-danger">*</span></label>
                <input class="form-control" type="text" placeholder="e.g 5000" v-model="newSpec.price" />
              </div>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="d-flex gap-2">
                <a-switch id="collectSpec" v-model:checked="newSpec.collect_specimen"></a-switch>
                <label for="#collectSpec">Collect Specimen</label>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="doctor-submit text-end">
              <span type="submit" class="btn btn-primary submit-form me-2" @click="addSpec()">
                Submit
              </span>
              <span class="btn btn-primary cancel-form bg-danger text-light">
                Cancel
              </span>
            </div>
          </div>
          <!-- </div> -->

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <!-- Edit Modal -->
  <div id="edit-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Service Specialization</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="row">
            <div class="col-12"></div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="input-block local-forms">
                <label>Name<span class="login-danger">*</span></label>
                <input class="form-control" type="text" placeholder="e.g Gastro test"
                  v-model="selectedServiceSpec.name" />
              </div>
            </div>

            <div class="col-12 col-md-12 col-xl-12">
              <div class="input-block local-forms">
                <label>Price <span class="login-danger">*</span></label>
                <input class="form-control" type="text" placeholder="e.g 5000" v-model="selectedServiceSpec.price" />
              </div>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="d-flex gap-2">
                <a-switch id="collectSpec" v-model:checked="selectedServiceSpec.collect_specimen"></a-switch>
                <label for="#collectSpec">Collect Specimen</label>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="doctor-submit text-end">
              <span type="submit" class="btn btn-primary submit-form me-2" @click="saveSpec()">
                Submit
              </span>
              <span class="btn btn-primary cancel-form bg-danger text-light">
                Cancel
              </span>
            </div>
          </div>
          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>


</template>
<script>
import { formatAmount, formatDate } from "@/utils/formatter";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Lab_Setting_Menu";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Collect Specimen",
    key: "collect_specimen",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Date Added",
    key: "date",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },

  data() {
    return {
      isLoading: true,
      formatAmount,
      formatDate,
      test_types: [],
      test: "",
      cost: "",
      lab_category_id: 0,
      type: "lab",
      depts: [],
      reserve_test_types: [],
      department: "",
      search: "",
      selectedServiceSpec: {},
      columns,
      department_name: "",
      about_department: "",
      specializationServices: [],
      newSpec: {
        name: "",
        price: "",
        collect_specimen: ""
      }
    };
  },

  methods: {
    ...mapActions({
      _fetchSpecializationServices: "specializations/fetchTypes",
      _addSpec: "specializations/addSpec",
      _editServiceSpec: "specializations/editServiceSpec",
      _deleteServiceSpec: "specializations/deleteServiceSpec",
      _addCategory: "general/add_global_category",
    }),

    fetchSpecializationServices() {
      this._fetchSpecializationServices()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.specializationServices = this.response.data;
            // console.log(this.test_types);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    addSpec() {
      if (this.newSpec.name != "" && this.newSpec.price != "") {
        $(".inloader").show();
        this._addSpec(this.newSpec)
          .then((response) => {
            $(".inloader").hide();
            $("#con-close-modal").modal("hide");
            this.department = "";
            this.description = "";
            this.fetchSpecializationServices();
            this.response = response.data;
            // console.log(response);
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
            }
          })
          .catch((error) => {
            $(".inloader").hide();
            console.log(error);
            this.$toast.error(error);
          });
      } else {
        this.$toast.error("Fill all the inputs to proceed");
      }
    },
    deleteServiceSpec(id, index) {
      $(".inloader").show();
      this._deleteServiceSpec(id)
        .then((response) => {
          $(".inloader").hide();
          this.specializationServices.splice(index, 1);
          if (response.data.status == true) {
            this.$toast.success(response.data.message);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          this.$toast.error(error);
        });
    },
    setSelectedLabTestType(selectedSpec) {
      this.selectedServiceSpec = selectedSpec;
    },
    saveSpec() {
      $(".inloader").show();
      this._editServiceSpec({
        id: this.selectedServiceSpec.id,
        payload: this.selectedServiceSpec,
      })
        .then((response) => {
          $(".inloader").hide();
          $("#edit-close-modal").modal("hide");
          this.fetchSpecializationServices();
          if (response.data.status == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          this.$toast.error(error);
        });
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    $(".edit-cancel-form").click(function () {
      $("#edit-close-modal").modal("hide");
    });

    this.fetchSpecializationServices();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n1 {
  background-color: #14a79e;
}

.btn_r {
  height: 50px;
  padding-top: 15px;
  width: 80%;
}
</style>
