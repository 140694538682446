<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Invoices</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Invoice List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="#l" class="btn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></a>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              <div class="staff-search-table">
                <form>
                  <div class="row">
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>From </label>
                        <input
                          class="form-control datetimepicker"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>To </label>
                        <input
                          class="form-control datetimepicker"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Payment Status </label>
                        <a-select class="w-100">
                          <a-select-option>Select Payment Status</a-select-option>
                          <a-select-option value="Paid">Paid</a-select-option>
                          <a-select-option>Un Paid</a-select-option>
                          <a-select-option>Patially Paid</a-select-option>
                        </a-select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4 ms-auto">
                      <div class="doctor-submit">
                        <button
                          type="submit"
                          class="btn btn-primary submit-list-form me-2"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="table-responsive"
                style="overflow-x: scroll; overflow-y: visible"
              >
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Invoice Number</th>
                      <th>Patient</th>
                      <th>Created Date</th>
                      <th>Due Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0004</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Andrea Lalema</a
                        >
                      </td>
                      <td>01.10.2024</td>
                      <td>01.10.2024</td>
                      <td>$20</td>
                      <td>
                        <button class="custom-badge status-green">Paid</button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0001</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Smith Bruklin</a
                        >
                      </td>
                      <td>02.10.2024</td>
                      <td>02.10.2024</td>
                      <td>$15</td>
                      <td>
                        <button class="custom-badge status-pink">
                          Un Paid
                        </button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0002</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-03.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Bernardo James</a
                        >
                      </td>
                      <td>01.10.2024</td>
                      <td>01.10.2024</td>
                      <td>$20</td>
                      <td>
                        <button class="custom-badge status-green">Paid</button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0003</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-04.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Mark Hay Smith</a
                        >
                      </td>
                      <td>05.10.2024</td>
                      <td>05.10.2024</td>
                      <td>$10</td>
                      <td>
                        <button class="custom-badge status-orange">
                          Patially Paid
                        </button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0004</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-05.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Galaviz Lalema</a
                        >
                      </td>
                      <td>01.10.2024</td>
                      <td>01.10.2024</td>
                      <td>$20</td>
                      <td>
                        <button class="custom-badge status-green">Paid</button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0003</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-06.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Andrea Lalema</a
                        >
                      </td>
                      <td>05.10.2024</td>
                      <td>05.10.2024</td>
                      <td>$10</td>
                      <td>
                        <button class="custom-badge status-orange">
                          Patially Paid
                        </button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td><a href="#">#INV-0005</a></td>
                      <td class="profile-image">
                        <a href="#"
                          ><img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-07.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          William Stephin</a
                        >
                      </td>
                      <td>10.10.2024</td>
                      <td>10.10.2024</td>
                      <td>$30</td>
                      <td>
                        <button class="custom-badge status-pink">
                          Un Paid
                        </button>
                      </td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</a
                            >
                            <a class="dropdown-item" href="#"
                              ><i class="feather-eye m-r-5"></i> View</a
                            >
                            <a class="dropdown-item" href="javascript:;"
                              ><i class="feather-download m-r-5"></i>
                              Download</a
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
