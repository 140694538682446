<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div id="con-close-modal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster edit_medical_info_modal"
      style="margin-top:100px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add Clinical Info</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label v-if="edit_data.target == 'Allergies'">Allergies</label>
                    <label v-else-if="edit_data.target == 'current_medical_conditions'">Current Medical Conditions</label>
                    <label v-else-if="edit_data.target == 'past_medical_conditions'">Past Medical Conditions</label>
                    <textarea class="form-control" rows="3" cols="30" v-model="value"></textarea>
                  </div>
                </div>

                <div class="col-12 col-sm-6"></div>
                <div class="col-6">
                  <div class="doctor-submit text-end">
                    <span type="submit" class="btn btn-primary submit-form me-2" @click="edit_medical_info()" v-if="value">
                      Submit
                    </span>
                    <span class="btn btn-primary cancel_medical_info bg-danger text-light">Cancel</span>
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
  },
  props: {
    edit_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null
    };
  },
  watch: {
    edit_data: {
      deep: true,
      handler(newValue) {
        // Safely assign the name to value
        this.value = newValue?.obj?.name || '';
      },
    }},
  methods: {
    ...mapActions({
      _edit_medical_info: "patient/edit_medical_info",
    }),

    edit_medical_info() {
      $(".inloader").show();
      $(".edit_medical_info_modal").modal("hide");
      var Medical_infoPayload = {}
      if (this.edit_data.target == 'Allergies') {
        Medical_infoPayload = {
          name: this.value,
          allergy:1,
        };
      }
      else if (this.edit_data.target == 'past_medical_conditions') {
        Medical_infoPayload = {
          name: this.value,
          current: 0,
          allergy:0,

        };
      }
      else if (this.edit_data.target == 'current_medical_conditions') {
        Medical_infoPayload = {
          name: this.value,
          current:1,
          allergy:0,

        };
      }


      this._edit_medical_info({ id: this.edit_data?.obj?.id, payload: Medical_infoPayload })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    goto(route) {
      window.location = route;
      // this.$router.push({ name: route })
    },

  },
  mounted() {
    $(".cancel_medical_info").click(function () {
      $(".edit_medical_info_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 480px) {
  #hrtrat {
    margin-top: 3%;
  }

  #con-close-modal {
    margin-left: 0px !important;
    margin-top: 90px !important;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 68vh;
    scrollbar-width: thin;
    scrollbar-color: #ffffff #14a79e;
  }

  #sunandcanc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .text-end {
    gap: 20px;
    text-align: center !important;
  }

  .modal-title {
    margin-bottom: 3%;
  }

  #tempor {
    margin-top: 10%;
  }
}
</style>
