<template>
  <div
    id="set_password"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster set_password"
    style="margin-top:150px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
              <h4 class="modal-title">Set Password</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> -->
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="form-header">
            <h2>Change your password</h2>
            <p style="font-weight: lighter">
              We noticed you just signed up, Change your password then procced.
            </p>
          </div>
          <form>
            <div class="row">
              <div class="col-1 col-sm-1"></div>

              <div class="col-12 col-sm-10">
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 20px"
                >
                  <label>Password<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password1"
                    @input="validatepassword"
                  />
                  <div class="profile-views">
                    <i
                      class="fa fa-eye toggle"
                      v-if="showPassword"
                      @click="toggle_input_type"
                    ></i>
                    <i
                      class="fa fa-eye-slash toggle"
                      v-else
                      @click="toggle_input_type"
                    ></i>
                  </div>
                </div>
                <div class="errorcont">
                  <!-- <span class="small_error" v-if="is_not_password_len && password1!==''"> <i class="fa fa-x"></i>  Your password length can not be less or more than six.</span><br> -->
                  <!-- <span class="small_error" v-if="containsAlphabets">
                    <i class="fa fa-x"></i> Your password cannot have an alphabet.
                  </span> -->
                </div>
              </div>
              <!-- <div class="col-1 col-sm-1" ><i class="fa fa-check" v-if="is_not_password_len===false && containsAlphabets===false && is_not_matched===false"></i></div> -->
              <div class="col-1 col-sm-1">
                <i
                  class="fa fa-check"
                  v-if="is_not_matched === false && password1 !== ''"
                ></i>
              </div>

              <!-- newrow starts here -->
              <div class="col-1 col-sm-1"></div>
              <div class="col-12 col-sm-10">
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 50px"
                >
                  <label>Re-Type<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="password2"
                    @input="validatepassword"
                    :style="{ borderColor: is_not_matched ? 'red' : '' }"
                  />
                  <div class="errorcont">
                    <span v-if="is_not_matched" class="small_error">
                      <i class="fa fa-x"></i> Passwords do not match.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-1 col-sm-1">
                <i
                  class="fa fa-check"
                  v-if="is_not_matched === false && password1 !== ''"
                ></i>
              </div>

              <br /><br />

              <div class="text-center">
                <span
                  class="btn btn-primary submit-btn mb-4"
                  @click="password()"
                  >Save</span
                >
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { store } from "../../store";
import { mapActions } from "vuex";

export default {
  name: "Create_password",
  data() {
    return {
      password1: "",
      password2: "",
      is_not_matched: 0,
      old_password: localStorage.getItem("password"),
      // is_not_password_len: 0,
      // containsAlphabets: 0,
      showPassword: false,
      type: "Password",
    };
  },
  methods: {
    ...mapActions({
      _password: "general/update_credential",
    }),
    toggle_input_type() {
      this.showPassword = !this.showPassword;
    },
    validatepassword() {
      this.is_not_matched = this.password1 !== this.password2;
      // this.is_not_password_len = this.password1.length > 6 || this.password1.length < 6;
      // this.containsAlphabets = /[a-zA-Z]/.test(this.password1);
    },
    password() {
      if (this.is_not_matched === false) {
        // localStorage.setItem("password", "true");

        $(".inloader").show();
        $("#set_password").modal("hide");
        const thismodal = document.querySelector(`[data-bs-backdrop="static"]`);
        thismodal.removeAttribute("data-bs-backdrop");

        this._password({
          type: this.type,
          //   new_pin: this.new_pin,
          //   current_pin: this.inputed_old_pin,
          current_password: this.old_password,
          new_password: this.password1,
        })
          .then((response) => {
            $(".inloader").hide();
              $("#set_password").modal("hide");
            localStorage.setItem("password_change", "true");

            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
              $("#set_password").modal("hide");

              if (localStorage.getItem("pin") == "false") {
                $("#set_pin").modal("show");
              }
              localStorage.setItem("password", this.password1);
              // location.reload();
              //   if (type == "Pin") {
              //     localStorage.setItem("decrypted_pin", this.new_pin);
              //   } else if (type == "Password") {
              //     localStorage.setItem("password", this.new_password);
              //   }
            }
          })
          .catch((error) => {
            $(".inloader").hide();
            localStorage.setItem("pin", "false");

            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }
          });
      }
    },
  },

  mounted() {},
};

// $('#set_password').modal({
//     backdrop: 'static',
//     keyboard: false
// })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.errorcont {
  font-size: 12px;
  color: red;
  margin-bottom: 40px;
  margin-top: 10px;
  padding-left: 5px;
  /* text-align: center; */
}

.small_error {
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
}

.fa-check {
  font-size: 20px;
  margin-top: 10px;
  display: inline-block;
  color: green;
}
.toggle {
  font-size: 18px;
  color: black;
  padding-top: 30px;
}
</style>
