<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />


  <div class="page-wrapper">
    <div class="content">

      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="voice-call.html">App </a></li>
              <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
              <li class="breadcrumb-item active">Voice Call</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-xl-4 d-flex">
          <div class="card chat-box ">
            <div class="chat-widgets">
              <div class="call-all comman-space-flex">
                <h4>Calls<span>32</span></h4>
                <h5><a href="javascript:;">Show All</a></h5>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-05.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> William Stephin</h5>
                    <p>5 min Ago</p>
                  </div>
                  <div class="user-text ">
                    <p>10:35</p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-02.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> William Stephin</h5>
                    <p>5 min Ago</p>
                  </div>
                  <div class="user-text ">
                    <p>11:35</p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-outgoing.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-03.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> William Stephin</h5>
                    <p>5 min Ago</p>
                  </div>
                  <div class="user-text ">
                    <p>10:35 </p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-04.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Bernardo James </h5>
                    <p>06:32 PM</p>
                  </div>
                  <div class="user-text ">
                    <p>11:35</p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-05.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Harald Kowalski </h5>
                    <p>06:32 PM Yesterday</p>
                  </div>
                  <div class="user-text ">
                    <p>10:15 </p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-06.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Alexandr Donnelly </h5>
                    <p>05:32 PM Yesterday</p>
                  </div>
                  <div class="user-text ">
                    <p>09:20 </p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-07.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Regina Dickerson </h5>
                    <p>06:00 PM, 30 Sep 2024</p>
                  </div>
                  <div class="user-text ">
                    <p>11:35</p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-outgoing.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-user-group mb-0 d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-08.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Forest Kroch</h5>
                    <p>05:32 PM Yesterday</p>
                  </div>
                  <div class="user-text ">
                    <p>10:35</p>
                    <div class="chat-user-icon">
                      <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card chat-box ">
            <div class=" chat-search-group ">
              <div class="chat-user-group mb-0 d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-09.jpg" alt="img"></a>
                </div>
                <div class="chat-users">
                  <div class="user-titles">
                    <h5> Forest Kroch</h5>
                  </div>
                  <div class="user-text">
                    <p>Doctor</p>
                  </div>
                </div>
              </div>
              <div class="chat-search-list">
                <ul>
                  <li><a href="video-call.html"><img src="../../assets/assets/img/icons/chat-icon-01.svg" alt="img"></a>
                  </li>
                  <li><a href="voice-call.html"><img src="../../assets/assets/img/icons/chat-icon-02.svg" alt="img"></a>
                  </li>
                  <li><a href="javascript:;"><img src="../../assets/assets/img/icons/chat-icon-03.svg" alt="img"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Chat -->
          <div class="card chat-box  mb-0">
            <div class="voice-call-blk text-center">
              <div class="voice-call-user">
                <img src="../../assets/assets/img/profiles/avatar-03.jpg" alt="img">
                <h3>Bernardo James</h3>
                <p>Doctor</p>
              </div>
              <div class="voice-time">
                <h3><img src="../../assets/assets/img/icons/clock.svg" class="me-2" alt="img"> 00:10:10</h3>
              </div>
              <div class="voice-menu">
                <ul>
                  <li><a href="javascript:;"><img src="../../assets/assets/img/icons/voice-icon-01.svg" alt="img"></a>
                  </li>
                  <li class="active"><a href="javascript:;"><img src="../../assets/assets/img/icons/voice-icon-02.svg"
                        alt="img"></a></li>
                  <li><a href="javascript:;"><img src="../../assets/assets/img/icons/voice-icon-03.svg" alt="img"></a>
                  </li>
                </ul>
              </div>
              <div class="end-call-voice">
                <a href="javascript:;" class="btn btn-primary">End Call</a>
              </div>
            </div>
          </div>
          <!-- /Chat -->
        </div>
      </div>

    </div>


  </div>

</template>
<script>







import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";


export default {
  name: "Index_Page",
  components: {

    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {

    };
  },

  methods: {

  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>