<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Add Note</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card card5" id="Laboratory_v">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Note</h4>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <!-- ote -->
                    <div class="input-block local-forms">
                      <label>Note</label>
                      <summernote
                        :initial="requestNote?.toString()"
                        v-model="requestNote"
                      />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span
                        type="submit"
                        class="btn btn-primary submit-form me-2"
                        @click="updateReport()"
                        >Submit</span
                      >
                      <span
                        type="submit"
                        class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)"
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { decode } from "@/utils/helpers";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },

  data() {
    return {
      decode,
      requestNote: "",
      requestId: null,
    };
  },
  watch: {
    "$store.state.specializations.serviceRequests"() {
      const currentReq = this.$store.state.specializations.serviceRequests.find(
        (serviceRequest) => serviceRequest.id == this.requestId
      );
      this.requestNote = currentReq?.note;
      this.initialNote = currentReq?.note;
    },
  },
  methods: {
    ...mapActions({
      _fetchRequests: "specializations/fetchRequests",
      _updateRequestReport: "specializations/updateRequestReport",
      _send_Laboratory_note: "lab/send_Laboratory_note",
    }),
    async updateReport() {
      await this._updateRequestReport({
        requestId: this.requestId,
        note: this.requestNote,
      });
      this.$toast.success("Note updated successfully");
      this.$router.back();
    },
  },

  async mounted() {
    this.requestId = decode(this.$route.params.id);
    if (!this.currentRequest) {
      await this._fetchRequests();
    }
    //
    jQuery(document).ready(function ($) {
      //Use this inside your document ready jQuery
      $(window).on("popstate", function () {
        location.reload(true);
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .addbtn{position:fixed;left: 90%;top:50%;z-index: 999;} */
.addbtn {
  position: absolute;
  left: 96%;
  top: 70px;
  z-index: 999;
}

.navhide {
  display: none;
  cursor: pointer;
}

.xl_edit {
  margin-right: -1%;
  width: 13%;
}

.xxl_edit {
  width: 15%;
}

.xl_edit_big {
  width: 34%;
}

.xl_edit_big_with_date {
  width: 21%;
}

.xxll {
  width: 100%;
  height: 60px;
  margin-bottom: 40px;
}

.add-pluss {
  padding: 0px;
}

.none {
  display: none;
}

.nav-link {
  cursor: pointer;
}

#file {
  margin-top: -38px;
  margin-left: -10px;
}

.blink {
  display: block;
  /* position: absolute;
    top: 24px; */
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  /* background: #00d3c7; */
}

.blink:before {
  content: "";
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: rgb(0 211 199 / 50%);
  cursor: pointer;
  box-shadow: 0 0 0 rgb(0 211 199);
  animation: blink 1s linear infinite;
  z-index: 1;
}

@keyframes blink {
  100% {
    transform: scale(2.5);
  }
}
</style>
