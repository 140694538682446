<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Add Note</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <!-- <div class="settings-menu-links">
                <ul class="nav nav-tabs menu-tabs">
                  <li class="nav-item for_lab">
                    <div class="nav-link n5" @click="lab_link()">
                      Laboratory
                      <span class="blink" v-if="lab_note"></span>
                    </div>
                  </li>

                  <li class="nav-item for_radiology">
                    <div class="nav-link n6 none" @click="rad_link()">
                      Radiology
                      <span class="blink" v-if="radiology_note"></span>
                    </div>
                  </li>
                </ul>
              </div> -->

          <div class="card card5" id="radiology_v">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Radiology Report</h4>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <!-- ote -->
                    <div class="input-block local-forms">
                      <label>Radiology Note
                        <span class="login-danger">*</span></label>


                      <summernote :initial="previousNote" v-model="radiology_note"></summernote>
                      <!-- <summernote :initial="radiology_note?.toString()" v-model="radiology_note"></summernote> -->
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2"
                        @click="send_radiology_note()">Submit</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Items Modal -->
      <!-- <div class="modal custom-modal fade" id="save_modal" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Doctor's Note Summery</h3>
                <p>Confirm and Save</p>
              </div>

              <div class="row">
                <div class="col-12 col-md-6 col-xl-6" v-if="note != ''">
                  <div
                    class="preview card"
                    style="background-color: #f1efff; padding: 15px"
                  >
                    <div style="margin-bottom: 10px">
                      <div
                        class=""
                        style="text-align: right; display: inline-block; color: #14a79e"
                      >
                        Note
                      </div>
                      <div
                        class=""
                        style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        "
                      >
                        X
                      </div>
                    </div>
                    <span class="main_cont">{{ note }}</span>
                    <div class="preview" style="margin-top: 20px">
                      <div
                        class=""
                        style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        "
                      >
                        <img
                          v-for="(image, index) in imagePreviews.note"
                          :src="image"
                          :key="index"
                          class=""
                          alt="Uploaded Image"
                          style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6" v-if="prescription != ''">
                  <div
                    class="preview card"
                    style="background-color: #fed5e0; padding: 15px"
                  >
                    <div style="margin-bottom: 10px">
                      <div
                        class=""
                        style="text-align: right; display: inline-block; color: #14a79e"
                      >
                        Prescription
                      </div>
                      <div
                        class=""
                        style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        "
                      >
                        X
                      </div>
                    </div>
                    <span class="main_cont">{{ prescription }}</span>
                    <div class="preview" style="margin-top: 20px">
                      <div
                        class=""
                        style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        "
                      >
                        <img
                          v-for="(image, index) in imagePreviews.prescription"
                          :src="image"
                          :key="index"
                          class=""
                          alt="Uploaded Image"
                          style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-12 col-md-6 col-xl-12"
                  style="
                    font-size: 24px;
                    padding: 10px;
                    text-align: center;
                    margin-bottom: 20px;
                  "
                  v-if="
                    lab == '' &&
                    examination == '' &&
                    note == '' &&
                    prescription == '' &&
                    diagnosis == ''
                  "
                >
                  No note(s) yet
                </div>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <button
                      type="reset"
                      data-bs-dismiss="modal"
                      class="w-100 btn btn-primary paid-continue-btn"
                      @click="sendnote()"
                    >
                      Save Changes
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      class="w-100 btn btn-primary paid-cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--Items Modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,

    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      radiology_id: localStorage.getItem("radiology_id"),
      // lab_id: localStorage.getItem("lab_id"),
      // lab_note: null,
      radiology_note: null,
      previousNote:null
    };
  },
  methods: {
    ...mapActions({
      _send_radiology_note: "radiology/send_radiology_note",
    }),

    send_radiology_note() {
      $(".inloader").show();

      this._send_radiology_note({
        id: this.radiology_id,
        payload: {
          note: this.radiology_note,
        },
      })
        .then((response) => {
          this.response = response.data;

          if (this.response["status"] == true) {
            this.radiology_note = "";
            // alert(this.response)
            $(".inloader").hide();
            this.$toast.success(this.response["message"]);
            if (localStorage.getItem("k_value") == 0) {
              window.location = "Radiology-Department-list";
            }
            if (localStorage.getItem("k_value") == 1) {
              window.location = "Radiologist-List";
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status == 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },

    // rad_link() {
    //   $("#radiology_v").show(1000);
    //   $("#note,#examination,#diagnosis,#prescription,#radiology,#lab").hide(0);

    //   $(".n6").css("background-color", "#14a79e");
    //   $(".n1,.n3,.n4,.n2,.n5").css("background-color", "#8b90ca");
    // },
  },

  mounted() {
    setTimeout(() => {
      if (this.radiology_id) {
        this.previousNote = localStorage.getItem("previousNote")
        this.radiology_note = localStorage.getItem("previousNote")
      }
    }, 1500);

    $(".view-modal").click(function () {
      $("#save_modal").modal("show");
    });

    jQuery(document).ready(function ($) {
      //Use this inside your document ready jQuery
      $(window).on("popstate", function () {
        location.reload(true);
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .addbtn{position:fixed;left: 90%;top:50%;z-index: 999;} */
.addbtn {
  position: absolute;
  left: 96%;
  top: 70px;
  z-index: 999;
}

.navhide {
  display: none;
  cursor: pointer;
}

.xl_edit {
  margin-right: -1%;
  width: 13%;
}

.xxl_edit {
  width: 15%;
}

.xl_edit_big {
  width: 34%;
}

.xl_edit_big_with_date {
  width: 21%;
}

.xxll {
  width: 100%;
  height: 60px;
  margin-bottom: 40px;
}

.add-pluss {
  padding: 0px;
}

.none {
  display: none;
}

.nav-link {
  cursor: pointer;
}

#file {
  margin-top: -38px;
  margin-left: -10px;
}

.blink {
  display: block;
  /* position: absolute;
    top: 24px; */
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  /* background: #00d3c7; */
}

.blink:before {
  content: "";
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: rgb(0 211 199 / 50%);
  cursor: pointer;
  box-shadow: 0 0 0 rgb(0 211 199);
  animation: blink 1s linear infinite;
  z-index: 1;
}

@keyframes blink {
  100% {
    transform: scale(2.5);
  }
}
</style>
