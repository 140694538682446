<template>
  <div class="small_loader_cont">
  <div class="small_lloader" id="small_lloader"></div>
  </div>
  </template>
  
  <script>
  
  
  
  export default {
    name: 'Inloader',
  //  mounted(){}
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .small_loader_cont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: fixed;
    width: 88%;
    margin-left: 12%;
    height: 50vh;
    z-index: 999999;
    overflow: visible;
    background: #ffffff9c;
  display: none;
  padding-top: 10%;
  padding-left: 45%;
    text-align: center;
    bottom: 0px;
  }
  
  .small_lloader {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #14a79e;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }



  /* .small_loader_cont{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: fixed;
    width: 88%;
    margin-left: 12%;
    height: 100vh;
    z-index: 999999;
    overflow: visible;
    background: #ffffff9c;
  display: none;
  padding-top: 25%;
  padding-left: 50%;
    text-align: center;
  }
  
  .small_lloader {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #14a79e;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
   */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  </style>
  