<template>
  <div class="cont">
    <div class="main-wrapper">
      <!-- third modal for confirmation -->

      <div
        class="modal modal-lg animate__animated animate__zoomIn animate__faster invite_modal"
        style="margin-top:0px;margin-left:100px;margin-right"
        id="save_modal"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Request for collaboration</h3>
                <p>Invite another doctor to join this case</p>
              </div>
              <div class="row invite_row">
                <div class="col-6 ib">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn dropdown-toggle selectbtn"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{ category_cat || "Choose a Category" }}
                    </button>
                    <div
                      class="dropdown-menu"
                      style="
                        height: 100px;
                        overflow-y: scroll;
                        width: 180px;
                        border-radius: 8px;
                      "
                    >
                      <span
                        class="dropdown-item"
                        href="#"
                        :value="data.id"
                        v-for="data in category"
                        :key="data.id"
                        @click="getdoctors(data)"
                        >{{ data.category }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-6 ib">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn dropdown-toggle selectbtn docbtn"
                      data-bs-toggle="dropdown"
                      id="docbtn"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled
                    >
                      {{ doc_doc_name || "Choose a Doctor" }}
                    </button>
                    <div
                      class="dropdown-menu"
                      style="
                        height: 80px;
                        overflow-y: scroll;
                        width: 180px;
                        border-radius: 8px;
                      "
                    >
                      <span
                        class="dropdown-item"
                        :value="data.id"
                        v-for="(data, _index) in doctors"
                        :key="_index"
                        @click="updateDoc(index, data)"
                      >
                        {{ data.first_name }} {{ data.last_name }}</span
                      >

                      <span class="dropdown-item" v-if="doctors.length == 0"
                        >No doctor yet</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Description</label>
                    <textarea class="form-control" rows="3" cols="30" v-model="message">
Why are you transfering this patient?</textarea
                    >
                  </div>
                </div>
              </div>

              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <button
                      type="reset"
                      data-bs-dismiss="modal"
                      class="w-100 btn paid-continue-btn subbtn"
                      id="subbtn"
                      @click="invite()"
                      disabled
                    >
                      Proceed
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      class="w-100 btn btn-primary paid-cancel-btn cancel cancel_invite bg-danger text-light"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- last modal for comfirmation  -->

      <div
        class="modal modal-lg animate__animated animate__zoomIn animate__faster confirm_doctor_assign"
        style="margin-top:0px;margin-left:100px;margin-right"
        id="save_modal"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Assign to a doctor</h3>
                <p>Do You want to assign to a doctor in {{ category_cat }} department?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <button
                      type="reset"
                      data-bs-dismiss="modal"
                      class="w-100 btn btn-primary paid-continue-btn"
                      @click="activate_doctor_list()"
                    >
                      Yes
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      class="w-100 btn btn-primary paid-cancel-btn cancel bg-danger text-light"
                      @click="deactivate_doctor_list()"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},

  data() {
    return {
      id: localStorage.getItem("this_patient_id"),
      type: localStorage.getItem("type"),
      stat: "Out Patient",
      message: "",
      reponse: "",
      specialty: "",
      doctors: "",
      DoctorinCat: "",
      List: "",
      tempDOC: {},
      reponse: "",
      main_data1: [],
      category_id: 0,
      category_cat: "",
      doc_id: 0,
      category: "",
      doc_doc_name: "",
      appointmentId: localStorage.getItem("apt_id"),
      collab: localStorage.getItem("collaboration"),

      // biodata:JSON.parse(localStorage.getItem("BioData"))
    };
  },

  methods: {
    ...mapActions({
      _cat: "general/category",
      _getdoctors: "general/getdoctors",
      _doctortransferPatient: "doctor/doctortransferPatient",
    }),

    //copied from  to power doctor collaboration
    updateDoc(index, value) {
      this.tempDOC[index] = value;
      this.doc_id = this.tempDOC[index].id;
      this.doc_doc_name =
        this.tempDOC[index].first_name + " " + this.tempDOC[index].last_name;
    },
    cat() {
      this._cat()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.category = this.response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },

    getdoctors(data) {
      $(".confirm_doctor_assign").modal("show");
      $(".invite_modal").modal("hide");

      this.category_id = data.id;
      this.category_cat = data.category;
      this._getdoctors(data.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.doctors = this.response.data;
            var doc_id = localStorage.getItem("doc_id");
            this.doctors = this.doctors.filter((item) => item.id != doc_id);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching docors", error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422) {
              this.$toast.error(error.response.data.message);
            } else if (status == 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
        });
    },

    activate_doctor_list() {
      $(".docbtn").removeAttr("disabled").css("bckground-color", "#00d3c7");
      $(".subbtn").removeAttr("disabled").addClass("btn-primary");
      $(".invite_modal").modal("show");
    },
    deactivate_doctor_list() {
      $(".docbtn").attr("disabled", "disabled").css("bckground-color", "transparent");
      //  $('#docbtn').removeClass("btn-primary");
      $(".subbtn").removeAttr("disabled").addClass("btn-primary");
      $(".invite_modal").modal("show");
      this.doc_id = 0;
      this.doc_doc_name = "";
    },

    invite() {
      $(".inloader").show();

      if (this.doc_id == 0) {
        this._doctortransferPatient({
          id: this.appointmentId,
          payload: { doctor_category_id: this.category_id, message: this.message },
        })
          .then((response) => {
            $(".inloader").hide();
            location.reload();

            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(`Depertment of ${this.category_cat} was invited.`);
              this.cat();
            }
            // ---important
            this.doc_id = 0;
            this.doc_doc_name = "";
          })
          .catch((error) => {
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              // alert(this.$toast.error(error.response.data.message))
              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status == 500) {
                this.$toast.error("Network Error, Check your internet conectivity.");
              }
            }
            $(".inloader").hide();
          });
      } else {
        this._doctortransferPatient({
          id: this.appointmentId,
          payload: {
            doctor_id: this.doc_id,
            doctor_category_id: this.category_id,
            message: this.message,
          },
        })
          .then((response) => {
            $(".inloader").hide();
            location.reload();
            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(`Dr. ${this.doc_doc_name} was invited.`);
              this.cat();
            }
          })
          .catch((error) => {
            $(".inloader").hide();

            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;
              // alert(this.$toast.error(error.response.data.message))
              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status == 500) {
                this.$toast.error("Network Error, Check your internet conectivity.");
              }
            }
          });
      }
    },
  },

  mounted() {
    this.cat();

    $(".specialist_add").click(function () {
      $(".invite_modal").modal("show");
    });
    $(".cancel_invite").click(function () {
      $(".invite_modal").modal("hide");
      this.doc_id = 0;
      this.doc_doc_name = "";
    });

    // alert(this.type)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 70%;
}

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

.invite_row {
  padding-left: 3%;
}

.selectbtn {
  width: 200px;
  background-color: #00d3c7;
}

.topbadge {
  height: 35px;
  width: 100%;
  font-size: 16px;
  padding-top: 10px;
  float: right;
}
</style>
