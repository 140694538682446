import { api, url } from "@/api";

export const template = {
  namespaced: true,
  state: {
    templates: [],
  },
  actions: {
    fetchTemplates({ context, state }) {
      api.get(url("templates")).then((response) => {
        state.templates = response.data.data;
      });
    },
    requestNewTemplate({ context }, payload) {
      return api.post(url("templates/request-new"), payload, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    addTemplate({ context }, payload) {
      return api.post(url("templates"), {
        ...payload,
      });
    },
    editTemplate({ context }, { id, payload }) {
      return api.post(url(`templates/${id}`), {
        ...payload,
        _method: "patch",
        collect_specimen: payload.collect_specimen ? 1 : 0,
      });
    },
    deleteTemplate({ context }, id) {
      return api.delete(url(`templates/${id}`));
    },
  },
};
