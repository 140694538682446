<template>
  <div
    id="set_pin"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster set_pin"
    style="margin-top:150px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
              <h4 class="modal-title">Set Pin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div> -->
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div class="form-header">
            <h2>Create your pin</h2>
            <p style="font-weight: lighter">
              We noticed you just signed up, set up your pin then procced.
            </p>
          </div>
          <form>
            <div class="row">
              <div class="col-1 col-sm-1"></div>

              <div class="col-12 col-sm-10">
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 20px"
                >
                  <label>Pin<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="pin1"
                    @input="validatepassword"
                    maxlength="6"
                  />
                  <div class="profile-views">
                    <i
                      class="fa fa-eye toggle"
                      v-if="showPassword"
                      @click="toggle_input_type"
                    ></i>
                    <i
                      class="fa fa-eye-slash toggle"
                      v-else
                      @click="toggle_input_type"
                    ></i>
                  </div>
                </div>
                <div class="errorcont">
                  <!-- <span class="small_error" v-if="is_not_pin_len && pin1!==''"> <i class="fa fa-x"></i>  Your pin length can not be less or more than six.</span><br> -->
                  <span class="small_error" v-if="containsAlphabets">
                    <i class="fa fa-x"></i> Your pin cannot have an alphabet.
                  </span>
                </div>
              </div>
              <!-- <div class="col-1 col-sm-1" ><i class="fa fa-check" v-if="is_not_pin_len===false && containsAlphabets===false && is_not_matched===false"></i></div> -->
              <div class="col-1 col-sm-1">
                <i
                  class="fa fa-check"
                  v-if="
                    containsAlphabets === false && is_not_matched === false && pin1 !== ''
                  "
                ></i>
              </div>

              <!-- newrow starts here -->
              <div class="col-1 col-sm-1"></div>
              <div class="col-12 col-sm-10">
                <div
                  class="input-block local-forms"
                  style="height: 40px; margin-bottom: 50px"
                >
                  <label>Re-Type<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    :type="showPassword ? 'text' : 'password'"
                    v-model="pin2"
                    @input="validatepassword"
                    :style="{ borderColor: is_not_matched ? 'red' : '' }"
                    maxlength="6"
                  />
                  <div class="errorcont">
                    <span v-if="is_not_matched" class="small_error">
                      <i class="fa fa-x"></i> Passwords do not match.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-1 col-sm-1">
                <i class="fa fa-check" v-if="is_not_matched === false && pin1 !== ''"></i>
              </div>

              <br /><br />

              <div class="text-center">
                <span class="btn btn-primary submit-btn mb-4" @click="pin()">Save</span>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { store } from "../../store";
import { mapActions } from "vuex";

export default {
  name: "Create_pin",
  data() {
    return {
      pin1: "",
      pin2: "",
      is_not_matched: 0,
      // is_not_pin_len: 0,
      containsAlphabets: 0,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      _pin: "general/set_pin",
    }),
    toggle_input_type() {
      this.showPassword = !this.showPassword;
    },
    validatepassword() {
      this.is_not_matched = this.pin1 !== this.pin2;
      // this.is_not_pin_len = this.pin1.length > 6 || this.pin1.length < 6;
      this.containsAlphabets = /[a-zA-Z]/.test(this.pin1);
    },
    pin() {
      if (this.is_not_matched === false && this.containsAlphabets === false) {
        localStorage.setItem("pin", "true");

        $(".inloader").show();
        $("#set_pin").modal("hide");
        const thismodal = document.querySelector(`[data-bs-backdrop="static"]`);
        thismodal.removeAttribute("data-bs-backdrop");
        this._pin({ pin: this.pin1 })
          .then((response) => {
            $("#set_pin").modal("hide");
            $(".inloader").hide();
            // this.$router.push({ name: "Password" });

            this.response = response.data;

            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
              localStorage.setItem("decrypted_pin", this.pin1);
            }
          })
          .catch((error) => {
            localStorage.setItem("pin", "false");

            $(".inloader").hide();
            $("#set_pin").modal("hide");

            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error("Network Error, Check your internet conectivity.");
              }
            }
            $("#con-close-modal").modal("hide");
            this.issue = "";
          });
      }
    },
  },

  mounted() {},
};

// $('#set_pin').modal({
//     backdrop: 'static',
//     keyboard: false
// })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.errorcont {
  font-size: 12px;
  color: red;
  margin-bottom: 40px;
  margin-top: 10px;
  padding-left: 5px;
  /* text-align: center; */
}

.small_error {
  margin-bottom: 2px;
  margin-top: 2px;
  display: inline-block;
}

.fa-check {
  font-size: 20px;
  margin-top: 10px;
  display: inline-block;
  color: green;
}
.toggle {
  font-size: 18px;
  color: black;
  padding-top: 30px;
}
</style>
