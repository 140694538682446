<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Quick Services</a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Booking</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Service Booking</h4>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Patient <span class="login-danger">*</span></label>
                      <a-select class="w-100 required-input" v-model:value="patient_id" showSearch
                        optionFilterProp="filterBy">
                        <a-select-option disabled>Select Patient</a-select-option>
                        <a-select-option v-for="(x, index) in patients" :key="index" :value="x.id"
                          :filterBy="`${x.first_name} ${x.last_name}`">
                          {{ x.first_name }} {{ x.last_name }}
                        </a-select-option>
                        <a-select-option disabled value="loading" key="loading" v-if="patients?.length == 0">
                          <a-spin size="small" />
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Service {{ service }} <span class="login-danger">*</span></label>
                      <a-select class="w-100 required-input" v-model:value="service_id" showSearch
                        optionFilterProp="filterBy">
                        <a-select-option disabled key="a">Select Service</a-select-option>
                        <a-select-option v-for="(x, index) in mergedData_holder" :key="index" :filterBy="x.service_name"
                          :value="x.id">{{
                            x.service_name }} - {{ formatAmount(x.price, true) }} -
                          {{ x.label || capitalize(x.service_type) }}</a-select-option>

                        <a-select-option disabled value="loading" key="loading" v-if="mergedData_holder?.length == 0">
                          <a-spin size="small" />
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Refering Doctor</label>
                      <input autocomplete="name" class="form-control required-input" type="text" v-model="ref_doctor" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Refering Doctor's Phone</label>
                      <input autocomplete="name" class="form-control required-input" type="text"
                        v-model="ref_doctor_phone" @input="limitInput(this)" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Referral Hospital</label>
                      <input autocomplete="name" class="form-control required-input" type="text"
                        v-model="ref_hospital" />
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Other Information </label>
                      <textarea class="form-control" rows="3" cols="30" v-model="other_info"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end" id="hereupcanc">
                      <span type="submit" class="btn btn-primary submit-form me-2" @click="book_service()">Book</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { formatAmount } from "@/utils/formatter";
import { filterObject } from "@/utils/helpers";
import { capitalize } from "vue";

import { mapActions } from "vuex";
// import { createToaster } from "@meforma/vue-toaster";
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,

  },
  data() {
    return {
      response: "",
      current_date: new Date().toISOString().split("T")[0],
      gender: "Select Gender",
      service_id: "Select Service",
      mergedData_holder: [],
      patients: [],
      formatAmount,
      filterObject,
      capitalize,
      other_info: "",
      ref_doctor_phone: "",
      other_info: "",
      ref_hospital: "",
      ref_doctor: "",
      patient_id: "",
    };
  },
  methods: {
    ...mapActions({
      _fetchSpecializationServices: "specializations/fetchTypes",
      _fetch_lap_testTypes: "lab/getTestType",
      _fetch_radiology_testTypes: "radiology/getTestType",
      _getServiceList: "finance/getServiceList",
      _getPatientList: "patient/patientList",
      _book_service: "quickService/bookService",
    }),

    preventNonNumeric(event) {
      // Allow only number keys (0-9) and special keys (Backspace, Delete, Arrow keys)
      const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

      if ((event.key < "0" || event.key > "9") && !allowedKeys.includes(event.key)) {
        event.preventDefault(); // Block non-numeric keys
      }
    },

    getPatientList() {
      this._getPatientList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.patients = this.response.data;
            this.patients = this.patients;
            console.log(this.patients);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    fetchData() {

      Promise.all([
        this._fetch_lap_testTypes(),
        this._fetch_radiology_testTypes(),
        this._getServiceList(),
        this._fetchSpecializationServices(),
      ])
        .then(([lap_testTypes, radiology_testTypes, services, serviceSpecs]) => {


          this.mergedData_holder = this.mergeData(
            lap_testTypes.data.data,
            radiology_testTypes.data.data,
            services.data.data,
            serviceSpecs.data.data
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);


        });
    },

    mergeData(labTest, radTest, hospitalServices, serviceSpecializations) {
      const mergedArray = [];

      // Extracting from service Specializations
      const serviceSpecializationsOptions = serviceSpecializations.map((item) => ({
        service_name: item.name,
        price: parseFloat(item.price), // Convert cost to float
        service_type: "service_specialization",
        label: "Service Specialization",
        id: item.id,
      }));
      const labServices = labTest.map((item) => ({
        service_name: item.test,
        price: parseFloat(item.cost), // Convert cost to float
        service_type: "lab",
        id: item.id,
      }));

      // Extracting from rad data
      const radServices = radTest.map((item) => ({
        service_name: item.test,
        price: parseFloat(item.cost), // Convert cost to float
        service_type: "radiology",
        id: item.id,
      }));

      // Extracting from hospital data
      const hospitalServicesOptions = hospitalServices.map((item) => ({
        service_name: item.service,
        price: item.price,
        service_type: "service",
        id: item.id,
      }));

      // Combine both arrays
      mergedArray.push(
        ...serviceSpecializationsOptions,
        ...labServices,
        ...radServices,
        ...hospitalServicesOptions
      );

      return mergedArray;
    },
    book_service() {
      if (this.areInputsFilled()) {
        const service = this.mergedData_holder.find(
          (_service) => _service.id == this.service_id
        );
        this._book_service({
          patient_id: this.patient_id,
          ref_doctor: this.ref_doctor,
          ref_hospital: this.ref_hospital,
          ref_doctor_phone: this.ref_doctor_phone,
          other_info: this.other_info,
          service_type: service.service_type,
          service_id: service.id,
        })
          .then((response) => {
            this.issue = "";
            this.response = response.data;
            if (this.response["status"] == true) {
              this.$toast.success(this.response["message"]);
              this.$router.push({ name: "Booking-List" });
            }
          })
          .catch((error) => {
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422 || status == 401) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error("Network Error, Check your internet conectivity.");
              }
            }
          });
      } else {
        this.$toast.error("Fill all the inputs to proceed.");

      }
    },
    limitInput() {
      var input = document.getElementById("phone");
      // input.value = input.value.replace(/[^0-9]/g, ''); // Allow only numbers
      input.value = input.value.replace(/\D/g, "");
      if (input.value.length > input.maxLength) {
        input.value = input.value.slice(0, input.maxLength);
      }
    },
    areInputsFilled() {
      // Select all inputs with the specified class
      const inputs = document.querySelectorAll(`.required-input`);

      // Check if all inputs are filled
      for (const input of inputs) {
        if (
          (input.tagName === "INPUT" || input.tagName === "TEXTAREA" || input.tagName === "SELECT") &&
          input.value.trim() === ""
        ) {
          return false; // Return false if any input is empty
        }
      }

      return true; // Return true if all inputs are filled
    }
  },
  mounted() {
    this.fetchData();
    this.getPatientList();

  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.noformat {
  display: inline-block;
  padding-top: 10px;
}

#samall_loader {
  display: none;
  border: 6px solid #f3f3f3;
  border-top: 4px solid #14a79e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 20%;
  left: 95%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  #hereupcanc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
