<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="/Settings">Settings </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Assign Permission</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- <SettingsMenu /> -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Permisions</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <!-- <span
                                class="bntn btn-primary add-pluss ms-2 add_room"
                                ><img
                                  src="../../assets/assets/img/icons/plus.svg"
                                  alt=""
                              /></span> -->
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-end float-end ms-auto">
                    <div class="btn-group selectbtn">
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-primary btn-xl"
                        data-bs-toggle="dropdown"
                      >
                        {{ permision_selector || "Permissions" }}
                      </button>
                      <div class="dropdown-menu">
                        <span class="dropdown-item text-bg-secondary"
                          >Select List</span
                        >
                        <span
                          class="dropdown-item"
                          v-for="(name, index) in option_list"
                          v-bind:key="index"
                          @click="selectpermission(name)"
                          >{{ name }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-end float-end ms-auto">
                    <input
                      class="form-control"
                      type="text"
                      v-model="role"
                      placeholder="Add a new role"
                    />
                  </div>

                  <div class="col-2 text-end float-end ms-auto">
                    <select
                      class="form-control select"
                      v-model="own_healthcare"
                    >
                      <option value="Select Institution Type" disabled>
                        Create as an Institution
                      </option>
                      <option :value="1">True</option>
                      <option :value="0">False</option>
                    </select>
                  </div>

                  <div class="col-2 text-end float-end ms-auto">
                    <div class="btn-group selectbtn">
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-primary btn-xl"
                        data-bs-toggle="dropdown"
                      >
                        Select roles for this institution
                      </button>
                      <div class="dropdown-menu">
                        <span class="dropdown-item text-bg-secondary"
                          >Select Roles</span
                        >
                        <span
                          class="dropdown-item"
                          v-for="(r, index) in roles"
                          v-bind:key="index"
                          >{{ r.role }}
                          <input
                            class="form-check-input checkb role_seclect"
                            type="checkbox"
                            v-model="selectedRole"
                            :value="r.id"
                        /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th></th>
                      <th>Description</th>
                      <th>Select</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="Permission in _filtered_rolePermission"
                      :key="Permission.id"
                    >
                      <!-- <tr> -->
                      <td>
                        <div class="form-check check-tables" hidden="">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td>{{ Permission.name }}</td>
                      <td></td>
                      <td>{{ Permission.code }}</td>
                      <td>
                        <input
                          class="form-check-input checkb"
                          type="checkbox"
                          v-model="rolePermission"
                          :value="Permission.id"
                        />
                      </td>

                      <td class="text-end"></td>
                    </tr>

                    <tr>
                      <td colspan="6">
                        <div class="col-12">
                          <div class="doctor-submit text-end">
                            <span
                              type="submit"
                              class="btn btn-primary submit-form me-2"
                              @click="addPermission()"
                              >Create</span
                            >
                            <button
                              type="submit"
                              class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                              @click="$router.go(-1)"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <LabRequest />
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Settings_menu";

import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    SettingsMenu,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      _permissions: [],
      rolePermission: [],
      _filtered_rolePermission: [],
      role: "",
      permision_selector: "Doctor",
      option_list: [],
      own_healthcare: "Select Institution Type",
      roles: [],
      selectedRole: [],
    };
  },

  methods: {
    ...mapActions({
      _getPermissions: "general/getPermissions",
      _addPermission: "general/addPermission",
      _getrole: "general/getrole",
    }),
    getPermissions() {
      this._getPermissions()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this._permissions = this.response.data;
            this._permissions = this._permissions;

            for (const obj of this._permissions) {
              this.option_list.push(obj.category);
            }
            console.log(this._permissions);
            console.log(this.option_list);
            this._filtered_rolePermission = this._permissions.filter(
              (obj) => obj.category == this.permision_selector
            );

            this._filtered_rolePermission =
              this._filtered_rolePermission[0].permission;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    selectpermission(role_name) {
      this.permision_selector = role_name;
      this._filtered_rolePermission = this._permissions.filter(
        (obj) => obj.category == this.permision_selector
      );
      this._filtered_rolePermission =
        this._filtered_rolePermission[0].permission;
    },
    addPermission() {
      this._addPermission({
        role: this.role,
        permission_id: this.rolePermission,
        own_healthcare: this.own_healthcare,
        roles_id: this.selectedRole,
      })
        .then((response) => {
          // this.Permission=''
          // this.description=''
          this.getPermissions();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            window.location = "View-Roles";
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    getrole() {
      this._getrole()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.roles = this.response.data;

            this.roles = this.roles.filter(
              (item) => item.own_healthcare == false
            );
            console.log(this.roles);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching categories", error);
        });
    },
  },

  mounted() {
    this.getPermissions();
    this.getrole();
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    // this.getPermissions();
    // this.getNonHodDoctor();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.n2 {
  background-color: #14a79e;
}

.checkb {
  height: 25px;
  width: 25px;
}

.dropdown-item {
  font-size: 15px;
}

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.dropdown-menu {
  height: 300px;
  width: 100%;
  border-radius: 8px;
  overflow-x: scroll;
}
.role_seclect {
  float: right;
  border: 1px solid #00d3c7;
}
</style>
