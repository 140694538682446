<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Service Specialization </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">History</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <filter-table :search-keys="['patient_name', 'service_name']" :columns="columns" :data="serviceHistory"
          title="Service Specialization History" :loading="isLoading">
          <template #rightContent="data"> </template>
          <template #addGroup> </template>

          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'sample_number'">
              <div class="tooltip-container">
                <span @click="view_patient_details(record)" class="tb_name">{{ record.sample_number }}</span>

                <div>
                  <span class="tooltip-text">
                    <span>Patient Name - {{ record.patient_name }}</span>
                    <span>Moblie Number -
                      {{ record.mobile_number }}</span>

                    <span>Patient Age - {{ record.patient_age }}</span>
                    <span>Patient Gender - {{ record.patient_sex }}</span>
                  </span>
                </div>
              </div>
            </template>
            <template v-if="column.key == 'amount'">
              <span> ₦ {{ formatAmount(record.amount) }} </span>
            </template>
            <template v-if="column.key == 'result'">
              <span class="btn btn-primary" @click="view_result(record)">View result
              </span>
            </template>
            <template v-if="column.key == 'date'">
              <span>
                {{ getDatetime(record.created_at) }}
              </span>
            </template>
            <template v-if="column.key == 'action'">

              <div class="dropdown dropdown-action">
                <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                    class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu">
                  <span class="dropdown-item" title="Collect Sample"
                    @click="downloadBookingReport(record.quick_service.id)" v-if="record.note">
                    <i class="fa fa-print m-r-5"></i>Print Report</span>
                </div>
              </div>
            </template>
          </template>
        </filter-table>
      </div>
    </div>
  </div>
  <div class="modal modal-lg animate__animated animate__zoomIn animate__faster result_modal" style="" id="save_modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Laboratory scientist note</h3>
            <!-- <p>You either transfer to a docotor or department</p> -->
          </div>
          <pre v-html="result_note"></pre>
        </div>
      </div>
    </div>
  </div>
  <PatientDetails :patient_details_data_recieved="send_record" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
import { formatAmount } from "@/utils/formatter";
import { encode } from "@/utils/helpers";
import PatientDetails from "./plugin/patient_details.vue";

const columns = [


  {
    title: "Sample Number",
    dataIndex: "sample_number",
    key: "sample_number",
  },
  {
    title: "Patient",
    dataIndex: "patient_name",
  },
  {
    title: "Service",
    dataIndex: "service_name",
  },
  {
    title: "Result",
    dataIndex: "result",
    key: "result",
  },
  {
    title: "Date",
    key: "date",
  },
  {
    title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    PatientDetails

  },
  data() {
    return {
      isLoading: true,
      serviceHistory: [],
      columns,
      formatAmount,
      encode,
      filterTableBy: [
        { title: "Paid Bookings", key: "status", exclude: "Unpaid" },
        { title: "Unpaid Bookings", key: "status", match: "Unpaid" },
      ],
      send_record: {},
      result_note: null,


    };
  },
  computed: {
    serviceHistory() {
      return this.$store.state.specializations.serviceHistory.map((serviceReq) => ({
        ...serviceReq,
        patient_name: serviceReq.patient.name,
        service_name: serviceReq.service.name,
        patient_age: `${serviceReq.patient.age} Yrs.`,
        patient_sex: serviceReq.patient.sex,
        mobile_number: serviceReq.patient.mobile_number,
      }));
    },
  },
  methods: {
    ...mapActions({
      _fetchHistory: "specializations/fetchHistory",
      _collectRequestSpecimen: "specializations/collectRequestSpecimen",
      _editRequestReport: "specializations/editRequestReport",
      _downloadBookingReport: "quickService/downloadBookingReport",
      _who_viewed_report: "lab/who_viewed_report",

    }),
    view_patient_details(record) {
      this.send_record = record;
      $(".patient_details-modal").modal("show");
    },
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    async fetchHistory() {
      await this._fetchHistory();
      this.isLoading = false;
    },
    collectSpecimen(serviceId) {
      this._collectRequestSpecimen(serviceId);
    },

    editReport(serviceId) {
      this._editRequestReport(serviceId);
    },
    downloadBookingReport(quickServiceId) {
      this._downloadBookingReport(quickServiceId);
    },
    getTypeClass(type) {
      return {
        "text-red": type === "debt",
        "text-green": type === "credit",
        // Add more classes for other types if needed
      };
    },
    view_result(n) {
      $(".result_modal").modal("show");
      if (n != null) {
        this.result_note = n.note;
        this.who_viewed_report(n.id)
      }
    },
    who_viewed_report(id) {
      this._who_viewed_report({ target: "service_specialization", target_id: id })
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);

        });
    },
  },
  mounted() {
    this.fetchHistory();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  inline-size: 100%;
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.dropdown-menu {
  inline-size: 100%;
}

.dropdown-item {
  cursor: pointer;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}
</style>
