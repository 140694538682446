<template>
  <!-- <Inloader class="inloader" id="inloader" /> -->

  <div class="table-responsive" style="overflow: scroll">
    <a-table :dataSource="prescription_lists" :columns="columns">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'name'">
          <span class="tb_name" @click="getAdministerHistory(record.id)"
            style="font-size: 20px; text-transform: capitalize">
            {{ record.drug_name }}
          </span>
        </template>

        <template v-if="column.key == 'action'">
          <span class="btn me-2 restuck-btn"
            v-if="record.countdown == 'Not yet' || record.countdown == 'Countdown finished!'"
            :style="`cursor:${cannotAdminister(record) ? 'not-allowed' : 'pointer'}; background: ${cannotAdminister(record) ? '#aaa' : '#14a79e'};color:#fff; padding: 10px`"
            @click="confirmAction(record)">
            {{ record.administration_type != "nurse_administer" ? 'Self-Administered' : 'Administer' }}
          </span>
          <span class="btn me-2 restuck-btn" v-else
            :style="`background: #aaa;color:#fff; padding: 10px;font-weight:bolder;`">
            {{ record.countdown }}
          </span>
        </template>
        <template v-if="column.key == 'type'">
          <span class="rounded" style="border: #14a79e 1px solid; padding: 10px; font-size: 15px">
            {{
              record.frequency
                ? `${record.frequency} Time${record.frequency > 1 ? 's' : ''} Daily`
                : `Every ${record.interval} Hour(s)`
            }}
          </span>
        </template>
        <template v-if="column.key == 'duration'">
          <span>
            {{ record.duration }} Day{{ record.duration > 1 ? 's' : '' }}
          </span>
        </template>
        <template v-if="column.key == 'last_administer'">
          <span>
            {{ record.last_administer ? formatDate(record.last_administer, true) : "-" }}
          </span>
        </template>
        <!-- <template v-if="column.key == 'action2'">
          <div class="dropdown dropdown-action">
            <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="fa fa-ellipsis-v"></i></a>
            <div class="dropdown-menu dropdown-menu-end">
              <span class="dropdown-item" title="Discontinue Medication" @click="confirmAction2(record)"
                v-if="status != 'Paid'">
             
                Discontinue Medication</span>
            </div>
          </div>
        </template> -->

        <template v-if="column.key == 'status'">
          <div v-if="record.isDiscontinued == 1" :id="`toggleContainer${record.id}`" class="toggle-container on"
            title="Continue Medication" @click="confirmAction2(record)">
            <div class="toggle-circle" title="Continue Medication"></div>
          </div>

          <div v-if="record.isDiscontinued == 0" :id="`toggleContainer${record.id}`" class="toggle-container off"
            title="Discontinue Medication" @click="confirmAction2(record)">
            <div class="toggle-circle" title="Discontinue Medication"></div>
          </div>
        </template>
      </template>
    </a-table>
  </div>

  <History :history_data_recieved="history_data" />
  <!-- /.modal -->
</template>
<script>
import Inloader from "../../plugins/inloader.vue";
import History from "./view_history.vue";
import jquery from "jquery";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import { formatDate } from "@/utils/formatter";
import { title } from "@/assets/assets/js/Chart.bundle";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Dosage",
    dataIndex: "dosage",
  },
  {
    title: "Type",
    key: "type",
  },
  {
    title: "Duration",
    key: "duration",
  },
  // {
  //   title: "Interval",
  //   dataIndex: "interval",
  // },
  {
    title: "Total Qty",
    dataIndex: "quantity",
  },
  // {
  //   title: "Quantity Taken",
  //   dataIndex: "quantity_taken",
  // },
  {
    title: "Administered Qty Remaining",
    dataIndex: "quantity_remaining",
  },
  {
    title: "Last Date Administered",
    key: "last_administer",
  },

  {
    title: "Date Prescribed",
    dataIndex: "created_at",
  },

  {
    key: "action",
  },
  {
    title: "Discontinue",
    key: "status",
  },
];

export default {
  name: "Index_Page",
  components: {
    Inloader,
    History,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      formatDate,
      prescription_lists: [],
      search: "",
      prescription_lists_reserve: [],
      med_data: {},
      edit_data: {},
      history_data: [],
      columns,
      patient_id: localStorage.getItem("this_patient_id"),

      // for timer

      intervalIds: [],
    };
  },

  methods: {
    ...mapActions({
      _getPatient_Prescriptions: "pharmacy/getPatient_Prescriptions",
      _getAdministerHistory: "pharmacy/getAdministerHistory",
      _administerDrug: "pharmacy/administerDrug",
      _discontinueDrug: "pharmacy/discontinueDrug",
    }),
    cannotAdminister(record) {

      return (
        record.administration_type != "nurse_administer" ||
        record.quantity_remaining == 0 ||
        record.isDiscontinued
      );
    },
    getPatient_Prescriptions() {
      this._getPatient_Prescriptions(this.patient_id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.prescription_lists = this.response.data;
            this.prescription_lists = this.prescription_lists;
            this.prescription_lists_reserve = this.prescription_lists;

            this.prescription_lists.forEach((item, index) => {
              const intervalId = setInterval(() => {
                item.countdown = this.calculateCountdown(item.next_administer);
              }, 1000);
              this.intervalIds.push(intervalId);
              // console.log(this.intervalIds,'lll')
              // console.log(this.prescription_lists,'kl')
            });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    onEditMedModalMounted(selectedMedicine) {
      this.edit_data = selectedMedicine;
    },

    getAdministerHistory(_id) {
      $(".inloader").show();

      this._getAdministerHistory(_id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const drug_history = this.response.data;
            $(".inloader").hide();
            this.history_data = drug_history;
            // localStorage.setItem('drug_history', JSON.stringify(drug_history))
            $("#history-modal").modal("show");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          $(".inloader").hide();
        });
    },
    administerDrug(prescription) {


      $(".inloader").show();
      this._administerDrug(prescription.id)
        .then((response) => {
          $(".inloader").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            Swal.fire({
              title: "Drug Administered!",
              text: "Drug has been Administered.",
              icon: "success",
            });

            this.getPatient_Prescriptions();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
          $("#con-close-modal").modal("hide");
          this.issue = "";
        });
    },
    confirmAction(prescription) {
      if (this.cannotAdminister(prescription)) {
        return;
      }
      
      if (prescription.daily_limit_exceeded) {
        Swal.fire({
          title: "Daily Dosage Reached!",
          text: "Please Wait till Next Day",
          icon: "warning",
        });
        return;
      }
      Swal.fire({
        title: "Are you sure you want administer this drug?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Proceed",
      }).then((result) => {
        if (result.isConfirmed) {

          this.administerDrug(prescription);
        }
      });
    },
    confirmAction2(record) {
      // const { quantity_remaining, id } = record;
      if (record.isDiscontinued) {
        title = "continue";
      } else {
        title = "Discontinue";
      }
      Swal.fire({
        title: "Are you sure you want " + title + " this medication?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.discontinueDrug(record);
        }
      });
    },

    discontinueDrug(record) {
      $(".inloader").show();

      this._discontinueDrug(record.id)
        .then((response) => {
          $(".inloader").hide();
          this.toggle(record.id);
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            if (record.isDiscontinued) {
              Swal.fire({
                title: "Drug Continued!",
                text: "Drug has been continued.",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Drug discontinued!",
                text: "Drug has been discontinued.",
                icon: "success",
              });
            }

            this.getPatient_Prescriptions();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
          $("#con-close-modal").modal("hide");
          this.issue = "";
        });
    },

    toggle(id) {
      this.state = 1 - this.state; // Toggle this.state between 0 and 1
      const container = document.getElementById(`toggleContainer${id}`);

      if (this.state === 1) {
        container.classList.remove("off");
        container.classList.add("on");
      } else {
        container.classList.remove("on");
        container.classList.add("off");
      }
    },
    // timer
    calculateCountdown(countDownDate) {
      if (countDownDate) {
        const countDownDatek = new Date(countDownDate).getTime();
        const now = new Date().getTime();
        const distance = countDownDatek - now;

        // Time calculations for hours, minutes, and seconds
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
          // clearInterval(this.intervalIds[index]); // Clear the interval
          return 'Countdown finished!';
        } else {
          return `${hours}h ${minutes}m ${seconds}s`;
        }
      }

      else {
        return 'Not yet'
      }
    }
  },
  beforeDestroy() {
    this.intervalIds.forEach((id) => clearInterval(id)); // Clear all intervals on component destroy
  },
  mounted() {

    // this.setNextAdministerTime();

    // //////////////
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });

    //  $(".restuck-btn").click(function () {
    //   $("#restuck-modal").modal("show");
    // });
    this.getPatient_Prescriptions();

    //   if(this.prescription_lists){
    //   this.prescription_lists.forEach((item, index) => {
    //     const intervalId = setInterval(() => {
    //       item.countdown = this.calculateCountdown(item.next_administer);
    //     alert(this.prescription_lists,'kl')

    //     }, 1000);
    //     this.intervalIds.push(intervalId);
    //     // console.log(this.intervalIds,'lll')
    //     // console.log(this.prescription_lists,'kl')
    //   });
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */
.tb_name:hover {
  color: #14a79e;
  cursor: pointer;
}

.tooltip-container {
  position: relative;
  /* display: block; */
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  display: none;

  width: 400px;
  height: auto;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  /* Position the tooltip above the text */
  left: 150%;
  margin-left: -100px;
  /* Use negative margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  /* Arrow pointing down */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  display: inline-block;
}

.tooltip-text span {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

.timer {
  font-size: 24px;
  font-weight: bold;
}
</style>
