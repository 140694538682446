<template>
<Loader id="loadercomp" />
<Inloader class="inloader" id="inloader" />
  
      
        <div class="page-wrapper">
     <!-- Page Content -->
     <div class="content container-fluid">

<!-- Page Header -->
<div class="page-header">
<div class="row">
<div class="col-sm-12">
<ul class="breadcrumb">
<li class="breadcrumb-item"><a href="#">Dashboard </a></li>
<li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
<li class="breadcrumb-item active">Settings</li>
</ul>
</div>
</div>
</div>
<!-- /Page Header -->

<SettingsMenu />


<div class="row">
<div class="col-md-6">
<div class="card">
<div class="card-header">
<h5 class="card-title">Localization Details</h5>
</div>
<div class="card-body pt-0">
<form>
  <div class="settings-form">
    <div class="input-block">
      <label>Time Zone</label>
      <select class="select form-control">
        <option selected="selected">(UTC +5:30) Antarctica/Palmer</option>
        <option>(UTC+05:30) India</option> 
      </select>
    </div>
    <div class="input-block">
      <label>Date Format</label>
      <select class="select form-control">
        <option selected="selected">15 May 2016</option>
        <option>15/05/2016</option>
        <option>15.05.2016</option>
        <option>15-05-2016</option>
        <option>05/15/2016</option>
        <option>2016/05/15</option>
        <option>2016-05-15</option>
      </select>
    </div>
    <div class="input-block">
      <label>Time Format</label>
      <select class="select form-control">
        <option selected="selected">12 Hours</option>
        <option>24 Hours</option>
        <option>36 Hours</option>  
        <option>48 Hours</option>
        <option>60 Hours</option> 
      </select>
    </div>
    <div class="input-block">
      <label>Currency Symbol</label>
      <select class="select form-control">
        <option selected="selected">$</option>
        <option>₹</option>  
        <option>£</option>
        <option>€</option> 
      </select>
    </div>
    <div class="input-block mb-0">
      <div class="settings-btns">
        <button type="submit" class="border-0 btn btn-primary btn-gradient-primary btn-rounded">Update</button>
      </div>
    </div>
  </div>
</form>
</div>
</div>
</div>
</div>
</div>
<!-- /Page Content -->        

          
  </div>
    
</template>            
<script>







import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Settings_menu";


export default {
  name: "Index_Page",
  components: {
    
Loader,
Inloader,
  SettingsMenu
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
     
    };
  },

  methods: {
    
  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>