<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-6">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Pharmacy </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Draft Medicine List</li>
            </ul>
          </div>

          <div class="col-sm-4"></div>

          <div class="col-sm-2">
            <button
              class="w-100 btn btn-primary paid-continue-btn"
              @click="route_to('Medicine-List')"
            >
              <!-- <span class="fa fa-pills"></span> -->
              Go to Medicine List
            </button>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center" id="mediclister">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Draft Medicine List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                                @input="click()"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <!-- <div class="add-group">
                          <span class="btn btn-primary add-pluss ms-2 add_medicine" @click="route_to('Add-Medicine')">
                            <img src="../../assets/assets/img/icons/plus.svg" alt="" /></span>
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Table Header -->

          <div class="table-responsive" style="overflow: scroll">
            <a-table
              :dataSource="drug_lists"
              :columns="columns"
              :loading="isLoading"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'name'">
                  <span
                    class="tb_name"
                    @click="getDrugHistory(record)"
                    style="font-size: 20px; text-transform: capitalize"
                  >
                    {{ record.drug_name }}
                  </span>
                  <div class="tooltip-containerm">
                    {{ record.drugType }}
                    <div>
                      <span
                        class="tooltip-text"
                        v-if="
                          record.drugType == 'Tablets' &&
                          record.tablet_type == 'pack'
                        "
                      >
                        <span
                          >{{ record.drug_name }} {{ record.drugType }}</span
                        >
                        {{ record?.card_per_pack ?? 0 }} - Cards per
                        {{ record.tablet_type }}
                        <br />
                        {{ record?.tablet_per_card ?? 0 }} - Tablets per card
                      </span>
                      <span
                        class="tooltip-text"
                        v-else-if="
                          record.drugType == 'Tablets' &&
                          record.tablet_type == 'bottle'
                        "
                      >
                        <span>{{ record.drug_name }}</span>
                        {{ record?.tablet_per_card ?? 0 }} - Tablets per
                        {{ record.tablet_type }}
                      </span>

                      <span
                        class="tooltip-text"
                        v-else-if="record.drugType != 'Tablets'"
                      >
                        <span>{{ record.drug_name }}</span>
                        Quantity - {{ record?.quantity ?? 0 }}
                      </span>
                    </div>
                  </div>
                </template>
                <template v-if="column.key == 'price'">
                  <span>
                    ₦ {{ formatNumberWithCommas(record.price) || 0 }}
                  </span>
                </template>
                <template v-if="column.key == 'excess_card'">
                  <span>
                    {{ record?.excess_card || 0 }}
                  </span>
                </template>
                <template v-if="column.key == 'excess_tablet'">
                  <span>
                    {{ record?.excess_tablet || 0 }}
                  </span>
                </template>
                <template v-if="column.key == 'total_tab_quantity'">
                  <span>
                    {{ record.total_tab_quantity }}
                  </span>
                </template>
                <!-- <template v-if="column.key == 'restuck'">
                  <button type="submit" class="btn me-2 restock-btn" style="border: #14a79e 1px solid; padding: 10px"
                    @click="save_restock_data(record)">
                    Restock
                  </button>
                </template> -->
                <template v-if="column.key == 'action'">
                  <div class="dropdown dropdown-action">
                    <a
                      href="#"
                      class="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fa fa-ellipsis-v"></i
                    ></a>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#edit-close-modal"
                        @click="onEditMedModalMounted(record)"
                        ><i class="fa fa-pen-to-square m-r-5"></i> Edit</a
                      >
                      <a class="dropdown-item" @click="confirmAction(record.id)"
                        ><i class="fa fa-x m-r-5"></i> Delete</a
                      >
                      <!-- <a
                              class="dropdown-item"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            > -->
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- fixed -->
  <!-- add medicine modal -->
  <!-- <div class="modal modal-lg animate__animated animate__zoomIn animate__faster medicine_addition" id="medicine_addition"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Choose Action</h3>
            <p>How do you want to add medicines?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row" id="chrtsearch">
              <div class="col-6" id="crtnew">
                <span @click="route_to('Add-Medicine')" class="w-100 btn btn-primary paid-continue-btn">
                  Create Medicine
                </span>
              </div>
              <div class="col-6" id="crtnew">
                <button class="w-100 btn btn-primary paid-continue-btn show_import_modal">
                  <span class="fa fa-upload"></span> Import Medicine
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- upload modal -->
  <div
    class="modal animate__animated animate__zoomIn animate__faster import_medication_modal"
    id="import_medication_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="form-header">
          <br />
          <h3>Upload CSV File</h3>
          <!-- <p>How do you want to add medicines?</p> -->
        </div>
        <div class="modal-body">
          <div class="input-blocks image-upload-down">
            <!-- <label> Upload CSV File</label> -->
            <div class="image-upload download">
              <input
                type="file"
                id="file_to_upload"
                @change="handleFileUpload"
                accept=".xlsx"
              />
              <div class="image-uploads">
                <!-- <img src="" alt="img" /> -->
                <span
                  class="fa fa-upload text-primary"
                  style="font-size: 40px; margin-bottom: 20px"
                ></span>
                <!-- File Uploaded:   -->
                <h4 v-if="file?.name">{{ file?.name }}</h4>
                <h4 v-else>Drag and drop a <span>file to upload</span></h4>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12" hidden>
            <div class="input-block local-forms">
              <label>Description</label>
              <textarea
                class="form-control"
                rows="3"
                cols="30"
                v-model="message"
              ></textarea>
            </div>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-3"></div>

              <div class="col-6" v-if="file?.name">
                <button class="w-100 btn paid-continue-btn" @click="view">
                  View uploaded file
                </button>
              </div>
              <!-- <div class="col-6">
                <button type="submit" data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn bg-danger text-light">
                  Cancel
                </button>
              </div> -->
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- preview modal -->
  <div
    class="modal animate__animated animate__zoomIn animate__faster view_imported_medication_modal"
    id="view_imported_medication_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <br />
        <div class="form-header">
          <h3>Uploaded List</h3>
        </div>
        <div class="modal-body p-4">
          <div class="row">
            <div class="col-12">
              <div
                class="table-responsive"
                style="height: 500px; overflow: scroll"
              >
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>Drug Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Drug Type</th>
                      <th>Tablet Type</th>
                      <th>Tablet Per Card</th>
                      <th>Card Per Pack</th>
                      <th>Card Price</th>
                      <th>Tablet Price</th>
                      <th>Available Card</th>
                      <th>Available Tablet</th>
                      <th>Expiry Date</th>
                      <!-- <th></th>/ -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(upload, index) in upload_preview" :key="index">
                      <!-- {{ upload }} -->
                      <td>{{ upload.drug_name || "-" }}</td>
                      <td>{{ upload.price || "-" }}</td>
                      <td>{{ upload.quantity || "-" }}</td>
                      <td>{{ upload.drug_type || "-" }}</td>
                      <td>{{ upload.tablet_type || "-" }}</td>
                      <td>{{ upload.tablet_per_card || "-" }}</td>
                      <td>{{ upload.card_per_pack || "-" }}</td>
                      <td>{{ upload.card_price || "-" }}</td>
                      <td>{{ upload.tablet_price || "-" }}</td>
                      <td>{{ upload.available_card || "-" }}</td>
                      <td>{{ upload.available_tablet || "-" }}</td>
                      <td>{{ upload.expiry_date || "-" }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- <div
            style="text-align: center; margin-top: 10%; padding-bottom: 40px"
            v-if="history_data_recieved == '' || history_data_recieved == null"
          >
            <i
              class="fas fa-box-open"
              style="font-size: 120px; color: #14a79e; opacity: 0.4"
            ></i>
            <div class="font-size:24px">No data yet</div>
          </div> -->

          <br />
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-continue-btn"
                  @click="import_medicines()"
                  :disabled="file ? false : true"
                >
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn bg-danger text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
  <!-- sample modal content -->
  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:50px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Medicine</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <Add_MedModalVue />
          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>

  <div
    id="edit-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:50px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Edit Draft Medicine ({{ edit_data.drug_name }})
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <!-- <Edit_Imported_Drugs_Modal /> -->

          <Edit_Imported_Drugs_Modal
            :formData="edit_data"
          ></Edit_Imported_Drugs_Modal>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>

  <!-- second modal for confirmation -->
  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster restock_modal1"
    role="dialog"
    id="meandu"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Choose action</h3>
            <p>What do you want to Restock?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row_ali">
              <div class="item">
                <span
                  @click="retuck_show('Pack')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Packs
                </span>
              </div>
              <div
                class="item"
                v-if="
                  this.restock_data?.drugType == 'Tablets' &&
                  this.restock_data?.tablet_type == 'pack'
                "
              >
                <span
                  @click="retuck_show('Card')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Cards
                </span>
              </div>
              <div class="item" v-if="this.restock_data?.drugType == 'Tablets'">
                <span
                  @click="retuck_show('Tablets')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Tablets
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->

  <Restuck
    :med_data_recieved="{
      data: restock_data,
      type: restock_type,
      quantity: 0,
      answer: 0,
    }"
  />
  <History :history_data_recieved="history_data" />
  <!-- /.modal -->
  <!-- </div> -->
  <!-- </div> -->
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import Add_MedModalVue from "./Add_MedModal";
import Edit_Imported_Drugs_Modal from "./Edit_Imported_Drugs_Modal.vue";
import Restuck from "./plugin/Restuck.vue";
import History from "./plugin/view_history.vue";
import jquery from "jquery";
import { mapActions } from "vuex";
import readXlsxFile from "read-excel-file";
import Swal from "sweetalert2";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Price",
    key: "price",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiry_date",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
  {
    title: "Excess Card",
    dataIndex: "excess_card",
  },
  {
    title: "Excess Tablet",
    dataIndex: "excess_tablet",
  },
  {
    title: "Total Tablets",
    key: "total_tab_quantity",
  },
  {
    key: "restuck",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Edit_Imported_Drugs_Modal,
    Add_MedModalVue,
    Loader,
    Inloader,
    Restuck,
    History,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      drug_lists: [],
      search: "",
      drug_lists_reserve: [],
      edit_data: {},
      history_data: [],
      columns,
      restock_data: {},
      restock_type: "",
      file: null,
      upload_preview: [],
    };
  },

  methods: {
    route_to(route) {
      window.location = route;
    },
    ...mapActions({
      _getImportedDrugs: "pharmacy/getImportedDrugs",
      _restock: "pharmacy/getDrugHistory",
      _getDrugHistory: "pharmacy/getDrugHistory",
      _import_medicines: "pharmacy/import_medicines",
      _delete_draft_medicine: "pharmacy/delete_draft_medicine",
    }),
    save_restock_data(data) {
      this.restock_data = data;
      $(".restock_modal1").modal("show");
    },
    getImportedDrugs() {
      this._getImportedDrugs()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_lists = this.response.data;
            this.drug_lists = this.drug_lists;
            this.drug_lists_reserve = this.drug_lists;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onEditMedModalMounted(selectedMedicine) {
      this.edit_data = selectedMedicine;
    },
    search_the_list() {
      if (this.search == "") {
        this.drug_lists = this.drug_lists_reserve;
      } else {
        this.drug_lists = this.drug_lists_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.drug_lists.length; i++) {
          var drug_name = this.drug_lists[i].drug_name.toLowerCase();
          // var category = this.drug_lists[i].category.toLowerCase();
          // var company_name = this.drug_lists[i].company_name.toLowerCase();
          // var price = this.drug_lists[i]?.price;
          var expiry_date = this.drug_lists[i].expiry_date;
          if (
            drug_name.includes(searchLetter) ||
            // price.includes(searchLetter) ||
            expiry_date.includes(searchLetter)
          ) {
            searchResult.push(this.drug_lists[i]);
          }
          // if (searchResult == []) {
          //   this.drug_lists = this.drug_lists;
          // }
        }
      }
      if (this.search == "") {
        this.drug_lists = this.drug_lists_reserve;
      } else {
        this.drug_lists = searchResult;
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number != null) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    retuck_show(restock_type) {
      $(".restock_modal1").modal("hide");
      $("#restuck-modal").modal("show");
      this.restock_type = restock_type;
    },
    getDrugHistory(Q) {
      $(".inloader").show();

      this._getDrugHistory(Q.id)
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            const drug_history = this.response.data;
            $(".inloader").hide();
            this.history_data = drug_history;
            // localStorage.setItem('drug_history', JSON.stringify(drug_history))
            $("#history-modal").modal("show");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          $(".inloader").hide();
        });
    },

    filterlist(target) {
      if (target == "low_stock") {
        const data_here = this.drug_lists_reserve;
        this.drug_lists = data_here.filter((item) => item.quantity <= 5);
      } else if (target == "all") {
        this.drug_lists = this.drug_lists_reserve;
      } else if (target == "out_of_stock") {
        const data_here = this.drug_lists_reserve;
        this.drug_lists = data_here.filter((item) => item.quantity == 0);
      } else if (target == "expiry_date") {
        const data_here = this.drug_lists_reserve;
        this.drug_lists = data_here.filter(
          (item) => this.getDaysDifference(item.expiry_date) <= 30
        );
      }
    },
    getDaysDifference(expiryDateStr) {
      const currentDate = new Date();
      const expiryDate = new Date(expiryDateStr);
      const timeDifference = expiryDate - currentDate; // Time difference in milliseconds
      const dayDifference = timeDifference / (1000 * 3600 * 24); // Convert ms to days
      return Math.floor(dayDifference); // Round down to the nearest whole number
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file; // Set file name to indicate something was uploaded
        readXlsxFile(file).then((rows) => {
          // Extract keys from the first row
          const keys = rows[0]
            .slice(1)
            .map((key) => key.toLocaleLowerCase().replace(" ", "_")); // Ignore the 0 index in the first row
          console.log(keys);
          // Map the rest of the data into objects
          const jsonData = rows.slice(1).map((row) => {
            return keys.reduce((obj, key, index) => {
              obj[key] = row[index + 1]; // Map values to corresponding keys
              return obj;
            }, {});
          });
          this.upload_preview = jsonData;
          console.log(this.upload_preview);
          $(".view_imported_medication_modal").modal("show");
          $(".import_medication_modal").modal("hide");

          // `rows` is an array of rows
          // each row being an array of cells.
        });
      }
    },
    import_medicines() {
      this._import_medicines({ drug: this.upload_preview })
        .then((response) => {
          // this.response = response.data;
          if (response["status"] == true) {
            this.file = document.getElementById();
            file.files[0] = null;
            $(".view_imported_medication_modal").modal("hide");
            $(".import_medication_modal").modal("hide");
            this.$toast.success(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
          this.$toast.error(error.response.data.message);
        });
    },
    view() {
      $(".view_imported_medication_modal").modal("show");
    },
    // returned_preview() {
    //   this._returned_preview()
    //     .then((response) => {
    //       this.response = response.data;
    //       if (this.response["status"] == true) {
    //         this.upload_preview = this.response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       console.log("Error fetching data", error);
    //     });
    // },

    delete_draft_medicine(drug_id) {
      $(".inloader").show();
      this._delete_draft_medicine(drug_id)
        .then((response) => {
          $(".inloader").hide();

          this.getImportedDrugs();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    confirmAction(drug_id) {
      Swal.fire({
        title: "Are you sure you want delete this drug?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete_draft_medicine(drug_id);
        }
      });
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    // $(".add_medicine").click(function () {
    //   $(".medicine_addition").modal("show");
    // });
    $(".show_import_modal").click(function () {
      $(".import_medication_modal").modal("show");
      // $(".medicine_addition").modal("hide");
    });
    // $(".show_view_imported_medication_modal").click(function () {
    //   $(".view_imported_medication_modal").modal("show");
    // });
    this.getImportedDrugs();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fa {
  color: #131313;
}

#meandu {
  margin-top: 0px;
  margin-left: 100px;
}

/* #con-close-modal{width:60%} */
.tb_name:hover {
  color: #14a79e;
  cursor: pointer;
}

.tooltip-containerm {
  position: relative;
  /* display: block; */
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.tooltip-containerm .tooltip-text {
  visibility: hidden;
  display: none;

  width: 400px;
  height: auto;
  background-color: #14a79e;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  /* Position the tooltip above the text */
  left: 150%;
  margin-left: -100px;
  /* Use negative margin to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-containerm .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  /* Arrow pointing down */
  left: 0%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltip-containerm:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  display: inline-block;
}

.tooltip-text span {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.row_ali {
  align-items: center;
  text-align: center;
  display: flex;
}

.item {
  flex: 1;
  /* Each item will take an equal share of the available space */
  min-width: 0;
  /* Prevent overflow issues */
  margin-left: 5px;
  margin-right: 5px;
}

.selectbtn {
  inline-size: 100%;
  /* margin-left: 20%; */
  background-color: #00d3c7;
  block-size: 35px;
  color: white;
}

.ant-table-container {
  width: 100%;
}

.ant-table-thead th,
.ant-table-tbody td {
  width: auto;
  white-space: nowrap;
}

#mediclister {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}

#buttonmediclister {
  width: 10%;
}

/* coppied code */

.input-blocks.image-upload-down {
  margin-bottom: 15px;
}

.custom-modal-body label {
  font-size: 15px;
  font-weight: 400;
}

.image-upload.download {
  border-radius: 5px;
  border: 1px dashed #14a79e;
  margin-bottom: 0;
}

.image-upload {
  border: 1px solid rgba(145, 158, 171, 0.32);
  position: relative;
  margin-bottom: 35px;
  border-radius: 5px;
}

.image-upload.download input[type="file"] {
  height: 150px;
}

.image-upload input[type="file"] {
  width: 100%;
  opacity: 0;
  height: 100px;
  position: relative;
  z-index: 9;
  cursor: pointer;
}

.image-upload .image-uploads {
  text-align: center;
  width: 100%;
  padding: 20px 0;
  border-radius: 5px;
  position: absolute;
  top: 0;
}

/* coppied code end*/

@media (max-width: 1380px) {
  #mediclister {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 5px;
  }

  #buttonmediclister {
    width: 15%;
    margin-top: 20px;
  }
}

@media (max-width: 1280px) {
  #meandu {
    margin-top: -30%;
  }

  #mediclister {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 5px;
  }

  #buttonmediclister {
    width: 15%;
    margin-top: 20px;
  }

  .ant-table-container {
    width: 100%;
  }

  .ant-table-thead th,
  .ant-table-tbody td {
    width: auto;
    white-space: nowrap;
  }
}

@media (max-width: 800px) {
  #meandu {
    margin-top: -30%;
  }

  #mediclister {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  #buttonmediclister {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #meandu {
    margin-left: 0px;
    margin-top: -20%;
  }

  #mediclister {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  #buttonmediclister {
    width: 100%;
  }
}
</style>
