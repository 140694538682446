<template>
  <!-- <Inloader class="inloader" id="inloader" /> -->

  <div
    id="restuck-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster restuck-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Restock {{ med_data_recieved.type }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>
                    <span v-if="med_data_recieved.type == 'Pack'"
                      >Pack Quantity</span
                    >
                    <span v-else-if="med_data_recieved.type == 'Card'"
                      >Exccess Card</span
                    >
                    <span v-else-if="med_data_recieved.type == 'Tablets'"
                      >Exccess Tablet</span
                    >
                    <span class="login-danger">*</span></label
                  >

                  <input
                    id="edate"
                    class="form-control"
                    type="number"
                    v-model="quantity"
                  />
                </div>
              </div>

              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>Description </label>
                  <textarea
                    class="form-control"
                    rows="3"
                    cols="30"
                    v-model="description"
                  ></textarea>
                </div>
              </div>
              <div class="col-6 col-sm-5">
                <div class="toggle_ct">
                  <div class="tc tc1">Quantities</div>
                  <span class="tc">
                    <div
                      v-if="add == true"
                      id="toggleContainer1"
                      class="toggle-container on"
                      @click="toggle(1)"
                    >
                      <div class="toggle-circle"></div>
                    </div>
                    <div
                      v-if="add == false"
                      id="toggleContainer0"
                      class="toggle-container off"
                      @click="toggle(0)"
                    >
                      <div class="toggle-circle"></div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-6 col-sm-7">
                <div class="preview">
                  <span
                    class="returned cal_conts"
                    v-if="med_data_recieved.type == 'Pack'"
                    >{{ med_data_recieved.data.quantity || 0 }}</span
                  >
                  <span
                    class="returned cal_conts"
                    v-else-if="med_data_recieved.type == 'Card'"
                    >{{ med_data_recieved.data.excess_card || 0 }}</span
                  >
                  <span
                    class="returned cal_conts"
                    v-else-if="med_data_recieved.type == 'Tablets'"
                    >{{ med_data_recieved.data.excess_tablet || 0 }}</span
                  >
                  <span class="sign cal_conts">{{ sign }}</span>
                  <span class="inputed cal_conts">({{ quantity }})</span>
                  <span class="equal_sign cal_conts">=</span>
                  <span class="answer cal_conts">{{ answer }}</span>
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2 restock_btn"
                    @click="restock()"
                  >
                    Submit
                  </span>
                  <span
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    >Cancel</span
                  >
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
// import Inloader from "../plugins/inloader";

import jquery from "jquery";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",

  data() {
    return {
      sign: "+",
      add: true,
      reduce: 0,
      quantity: 0,
      description: "",
      // old_quantity: localStorage.getItem("remaining_quantity"),
      answer: 0,
      state: 0,
      // excess_tablet: 0,
      // excess_card: 0,
      payload: {},
      // id: localStorage.getItem("drug_id"),
    };
  },
  props: {
    med_data_recieved: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions({
      _restock: "pharmacy/restock",
    }),
    toggle(id) {
      this.state = 1 - this.state; // Toggle this.state between 0 and 1
      const container = document.getElementById(`toggleContainer${id}`);

      if (this.state === 1) {
        container.classList.remove("off");
        container.classList.add("on");
        this.sign = "+";
        this.add = true;
        this.reduce = 0;
      } else {
        container.classList.remove("on");
        container.classList.add("off");
        this.sign = "-";
        this.add = false;
        this.reduce = 1;
      }
    },
    restock() {
      $(".inloader").show();

      // if(this.add==true){this.add=1}
      // else if(this.add==false){this.add=0}
      if (this.med_data_recieved.type == "Pack") {
        this.payload = { quantity: this.quantity, reduce: this.reduce };
      } else if (this.med_data_recieved.type == "Card") {
        this.payload = { card_quantity: this.quantity, reduce: this.reduce };
      } else if (this.med_data_recieved.type == "Tablets") {
        this.payload = { tablet_quantity: this.quantity, reduce: this.reduce };
      }

      this._restock({
        id: this.med_data_recieved.data.id,
        payload: this.payload,
      })
        .then((response) => {
          $(".inloader").hide();

          window.location.reload();
          this.response = response.data;
          this.$toast.success(this.response["message"]);
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "An error occured, try again."
              );
            }
          }
        });
    },
  },
  watch: {
    quantity: {
      handler(newValue, oldValue) {
        const newQuantity = Number(newValue);
        var receivedQuantity = 0;
        if (this.med_data_recieved.type == "Pack") {
          receivedQuantity = Number(this.med_data_recieved.data.quantity);
        } else if (this.med_data_recieved.type == "Card") {
          receivedQuantity = Number(this.med_data_recieved.data.excess_card);
        } else if (this.med_data_recieved.type == "Tablets") {
          receivedQuantity = Number(this.med_data_recieved.data.excess_tablet);
        }

        if (isNaN(newQuantity) || isNaN(receivedQuantity)) {
          this.answer = 0; // Default value if NaN is encountered
        } else {
          if (this.add) {
            this.answer = receivedQuantity + newQuantity;
            $(".restock_btn").show();
          } else {
            if (receivedQuantity < newQuantity) {
              this.$toast.error("You cannot reduce more than current stock.");

              $(".restock_btn").hide();
              this.answer = receivedQuantity - newQuantity;
            } else {
              this.answer = receivedQuantity - newQuantity;
              $(".restock_btn").show();
            }
          }
        }
      },
      immediate: true,
    },
    add: {
      handler(newValue, oldValue) {
        const currentQuantity = Number(this.quantity);
        var receivedQuantity = 0;
        if (this.med_data_recieved.type == "Pack") {
          receivedQuantity = Number(this.med_data_recieved.data.quantity);
        } else if (this.med_data_recieved.type == "Card") {
          receivedQuantity = Number(this.med_data_recieved.data.excess_card);
        } else if (this.med_data_recieved.type == "Tablets") {
          receivedQuantity = Number(this.med_data_recieved.data.excess_tablet);
        }

        if (isNaN(currentQuantity) || isNaN(receivedQuantity)) {
          this.answer = 0; // Default value if NaN is encountered
        } else {
          if (newValue) {
            // alert(newValue)
            this.answer = receivedQuantity + currentQuantity;
            $(".restock_btn").show();
          } else {
            if (receivedQuantity < currentQuantity) {
              this.$toast.error("You cannot reduce more than current stock.");
              this.answer = receivedQuantity - currentQuantity;

              $(".restock_btn").hide();
            } else {
              this.answer = receivedQuantity - currentQuantity;
              $(".restock_btn").show();
            }
          }
        }
      },
      immediate: true,
    },
  },

  mounted() {
    // this.quantity=0
    // this.answer=0
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */

.preview {
  padding: 20px;
  font-size: 35px;
  background-color: rgba(236, 236, 236, 0.493);
  margin-bottom: 20px;
}

.cal_conts {
  margin: 5px;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.toggle-circle {
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  transition: transform 0.3s ease;
}

.toggle-container.on {
  background-color: #14a79e;
}

.toggle-container.on .toggle-circle {
  transform: translateX(30px);
}

.toggle-container.off {
  background-color: #16161649;
}

.toggle_ct {
  padding: 40px;
}

.tc {
  display: inline-block;
  margin: 10px;
  margin-top: 0px;
}

.tc1 {
  float: left;
}

.restock_btn {
  display: none;
}

#restuck-modal {
  margin-top: 200px;
  margin-left: 100px;
}

@media (max-width: 800px) {
  #restuck-modal {
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  #restuck-modal {
    margin-left: 0px;
    margin-top: 20%;
  }

  .preview {
    font-size: 15px;
    margin-top: 30%;
  }
}
</style>
