<template>
  <!-- <Inloader class="inloader" id="inloader" /> -->

  <div
    id="prescriptions-modal"
    class="modal modal-xl animate__animated animate__zoomIn animate__faster prescriptions-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Dispensed Prescriptions for
            <b>{{ prescription_data_recieved.patient_name }}</b>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4" style="height: 500px; overflow: scroll">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive" style="overflow: visible">
                <a-table
                  :dataSource="prescription_data_recieved.prescriptions"
                  :columns="columns"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'total_price'">
                      <span>
                        ₦ {{ formatNumberWithCommas(record.total_price) }}
                      </span>
                    </template>
                    <template v-if="column.key == 'frequency'">
                      <span>
                        {{ record.frequency || "-" }}
                      </span>
                    </template>
                    <template v-if="column.key == 'interval'">
                      <span>
                        {{ record.interval || "-" }}
                      </span>
                    </template>
                    <!-- <template v-if="column.key == 'dispense'">
                   <span class="btn btn-primary me-2" @click="question_modal(record.id)">Dispense</span>
                </template> -->
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <!-- second modal for confirmation -->
  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster who_to_administer"
    style="margin-top:0px;margin-left:100px;margin-right"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Choose action</h3>
            <p>How we will be administering this drug?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <span
                  @click="select_type('nurse_administer')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Nurse Administered
                </span>
              </div>
              <div class="col-6">
                <span
                  @click="select_type('self_administer')"
                  class="w-100 btn btn-primary paid-continue-btn"
                >
                  Self Administered
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->
</template>
<script>
// import Loader from "../plugins/Loader.vue";
// import Inloader from "../plugins/inloader";

import jquery from "jquery";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Drug Name",
    dataIndex: "drug_name",
    key: "drug_name",
  },

  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Frequency",
    dataIndex: "frequency",
    key: "frequency",
  },
  {
    title: "Interval",
    dataIndex: "interval",
    key: "interval",
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Total Quantity",
    dataIndex: "total_quantity",
    key: "total_quantity",
  },
  {
    title: "Total Price",
    dataIndex: "total_price",
    key: "total_price",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    key: "dispense",
  },
];

export default {
  name: "prescription_modal",

  props: {
    prescription_data_recieved: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns,
      id_holder: null,
    };
  },

  methods: {
    ...mapActions({
      _dispense_drugs: "pharmacy/dispense_drugs",
    }),

    question_modal(data_id) {
      $(".who_to_administer").modal("show");
      $("#prescriptions-modal").modal("hide");

      this.id_holder = data_id;
    },
    select_type(choice) {
      this.dispense_drugs(choice);
      $(".who_to_administer").modal("hide");
      $("#prescriptions-modal").modal("show");
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    dispense_drugs(choice) {
      // alert(this.result_id)
      $(".inloader").show();

      this._dispense_drugs({
        id: this.id_holder,
        payload: { administration_type: choice },
      })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(response.data.message);
            this.prescription_data_recieved.prescriptions.filter(
              (prescription) => prescription.id !== this.id_holder
            );
            // this.getDispensory();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prescriptions-modal {
  margin-top: 100px;
  margin-left: 100px;
}
@media (max-width: 1280px) {
  #prescriptions-modal {
    margin-left: 10px !important;
  }
}

@media (max-width: 800px) {
  #prescriptions-modal {
    margin-left: -3px !important;
  }
}

@media (max-width: 480px) {
  #prescriptions-modal {
    margin-left: -3px !important;
  }
}
</style>
