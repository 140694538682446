<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="patients.html">Patients </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Transfer Patient</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Transfer Patient</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-5">
                    <div class="input-block local-forms">
                      <label>Account number
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="account_number" @keyup="checkAccount" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-2 text center">
                    <!-- <span class="fa fa-exchange"></span> -->
                  </div>
                  <div class="col-12 col-md-6 col-xl-5">
                    <div class="input-block local-forms">
                      <label>Patient Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="patient_name" disabled />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-5"></div>
                  <div class="col-12 col-md-6 col-xl-2 text-center">
                    <span class="fa fa-exchange" id="faz"></span>
                  </div>
                  <div class="col-12 col-md-6 col-xl-5"></div>

                  <div class="col-12 col-md-6 col-xl-5">
                    <div class="input-block local-forms">
                      <label>From <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="from" disabled />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-2 text center">
                    <!-- <span class="fa fa-exchange"></span> -->
                  </div>
                  <div class="col-12 col-md-6 col-xl-5">
                    <div class="input-block local-forms">
                      <label>To <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="to" showSearch
                        :filterOption="(input, option) => filterObject(option, input, 'key')">
                        <a-select-option disabled value="Select a hospital">
                          Select a hospital
                        </a-select-option>
                        <a-select-option v-for="hospital in hospital_list"  :value="hospital.institution_id">
                          {{ hospital.name }}
                        </a-select-option>

                        <a-select-option v-if="hospital_list.length == 0" disabled>
                          No hospital yet
                        </a-select-option>
                      </a-select>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Description </label>
                      <textarea class="form-control text-area" rows="3" cols="30" v-model="description"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span class="btn btn-primary submit-form me-2 open_otp_modal" @click="OTPcall()">Transfer</span>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">Cancel</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <OTP />
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import OTP from "../plugins/OTP.vue";
import { mapActions } from "vuex";
import { filterObject } from "@/utils/helpers";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    OTP,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      filterObject,
      patient_name: "",
      account_number: null,
      to: "Select a hospital",
      hospitalId: JSON.parse(localStorage.getItem("ADMIN_DETAILS")).hospital_id,
      from: JSON.parse(localStorage.getItem("ADMIN_DETAILS")).hospital_name,
      hospital_list: "",
      patient_id: null,
      // hospital_id: localStorage.getItem("hospital_id"),
    };
  },

  methods: {
    ...mapActions({
      _checkAccount: "finance/checkAccount",
      _get_hospital: "general/get_hospitalList",
      _OTP_call: "general/Transfer_OTP_call",
    }),

    checkAccount() {
      // if (this.account.length === 10) {
      // this.removeSpaces(this.account)
      this._checkAccount({ account: this.account_number })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            // this.patient_detail = this.response.data
            this.patient_name = `${this.response.data.first_name}  ${this.response.data.last_name}`;
            this.returned_account = this.response.data.account_number;
            this.patient_id = this.response.data.id;
            // this.$toast.success(this.id);
          } else if (this.response["status"] == false) {
          }
        })
        .catch((error) => {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    gethospital() {
      this._get_hospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.hospital_list = this.response.data;
            this.hospital_list = this.hospital_list.filter(
              (hospital) => hospital.institution_id != this.hospitalId
            );
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    updateTimerDisplay(timeInSeconds) {
      // Get the timer element
      const timerElement = document.getElementById("count-down");

      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const formattedTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
      timerElement.textContent = formattedTime;
    },
    performAction() {
      $("#otp_modal").modal("hide");
      $(".xvx").show();
    },
    startCountdown() {
      const countdownTime = 5 * 60; // 5 minutes * 60 seconds
      let timeRemaining = countdownTime;
      // Update the display initially
      this.updateTimerDisplay(timeRemaining);

      let _vm = this;
      // Set an interval to update the countdown every second
      const countdownInterval = setInterval(function () {
        timeRemaining--;

        // Update the display
        _vm.updateTimerDisplay(timeRemaining);

        // Check if the countdown reached 0
        if (timeRemaining <= 0) {
          // Clear the interval
          clearInterval(countdownInterval);

          // Perform the action when the countdown reaches 0
          _vm.performAction();
        }
      }, 3000); // Update every 1000 milliseconds (1 second)
    },

    OTPcall() {
      // alert(this.to)
      localStorage.setItem("v_patient_id", this.patient_id);
      localStorage.setItem("v_hospital_id", this.to);

      // alert(localStorage.getItem("v_patient_id"));

      $(".inloader").show();
      this._OTP_call({
        id: this.patient_id,
        payload: {
          hospital_id: this.to,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          this.test_types = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            $("#otp_modal").modal("show");
            this.startCountdown();
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          $(".inloader").hide();
        });
    },
  },
  mounted() {
    this.gethospital();

    //   $(".open_otp_modal").click(function () {
    //     $("#otp_modal").modal("show");
    //     startCountdown()
    //   });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#faz {
  font-size: 30px;
  display: inline-block;
  background-color: #14a79e;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
}

.form-control {
  height: 60px;
}

.text-area {
  height: 100px;
}

@media (max-width:800px) {
  .col-md-6 {
    width: 100% !important;
  }
}
</style>
