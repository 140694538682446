<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                Add Note for <b style="font-size: 16px"> {{ patient_name }}</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="settings-menu-links">
            <ul class="nav nav-tabs menu-tabs">
              <li class="nav-item for_doc">
                <div class="nav-link n1" @click="note_link()" style="background-color: #14a79e">
                  Note <span class="blink nb" v-if="note != ''"></span>
                </div>
              </li>
              <li class="nav-item for_doc" v-if="
                whoisLoggedin == 'Admin' ||
                whoisLoggedin == 'Doctor' ||
                whoisLoggedin == 'Nurse'
              ">
                <div class="nav-link n5" @click="administer_link()">
                  Administer Drug
                  <!-- <span class="blink eb" v-if="examination != ''"></span> -->
                </div>
              </li>
              <li class="nav-item for_doc" v-if="whoisLoggedin == 'Doctor' || whoisLoggedin == 'Admin'">
                <div class="nav-link n4" @click="examination_link()">
                  Examination
                  <span class="blink eb" v-if="examination != ''"></span>
                </div>
              </li>
              <li class="nav-item for_doc" v-if="whoisLoggedin == 'Doctor' || whoisLoggedin == 'Admin'">
                <div class="nav-link n3" @click="diagnosis_link()">
                  Diagnosis
                  <span class="blink dp" v-if="diagnosis != ''"></span>
                </div>
              </li>
              <li class="nav-item for_doc" v-if="whoisLoggedin == 'Doctor' || whoisLoggedin == 'Admin'">
                <div class="nav-link n2" @click="prescription_link()">
                  Prescription
                  <span class="blink pb" v-if="
                    this.prescriptions[0]?.note != null &&
                    this.prescriptions[0]?.note != ''
                  "></span>
                </div>
              </li>
            </ul>
          </div>

          <div class="card card1" id="note">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Note</h4>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <!-- ote -->
                    <div class="input-block local-forms">
                      <label>Note <span class="login-danger">*</span></label>
                      <textarea class="form-control" rows="3" cols="30" v-model="note"></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-md-12 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top">Upload Image (Allow Multiple)<span class="login-danger">*</span></label>
                      <div class="settings-btn upload-files-avator upload" for="file" style="background-color: #f1f2f3">
                        <!-- <span v-if="imagePreviews.note.length == 0"
                          >Choose File...</span
                        > -->
                        <span v-if="imagePreviews.note.length > 0">{{ imagePreviews.note.length }} image(s) was
                          seleced</span>
                        <input type="file" accept="image/*" name="note" id="file" multiple hidden />
                        <label for="file" class="upload" style="background-color: #14a79e; color: white">Browse</label>
                      </div>
                    </div>

                    <!-- Sample preview -->
                    <div class="preview" style="
                        padding-left: 50px;
                        margin-bottom: 50px;
                        margin-top: 50px;
                      ">
                      <div style="text-align: center" v-if="imagePreviews.note == ''">
                        <img src="../../assets/assets/img/imgpreview.png" width="400px" height="200px" />
                      </div>

                      <div class="" style="
                          display: inline-block;
                          margin-bottom: 50px;
                          margin-right: 30px;
                        " v-for="(image, index) in imagePreviews.note" :key="index">
                        <span class="remove_img custom-file-container__image-multi-preview__single-image-clear__icon"
                          style="
                            border-radius: 50%;
                            box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
                            margin-top: 5px;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            color: #14a79e;
                            cursor: pointer;
                          " @click="deleteAvatar(index, 'note')">×</span>
                        <img :src="image" class="" alt="Uploaded Image" style="
                            height: 100px;
                            width: 260px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 20px;
                            border-radius: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2 view-modal"
                        @click="showsubmodal()">Submit</span>
                      <button type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="card card2 none" id="prescription">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4 style="display: inline-block">Add Prescription</h4>
                      <div class="addbtn">
                        <div class="add-group" @click="openIntervalModal()">
                          <span class="btn btn-primary fa fa-plus ms-1" style="font-size: 18px">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="for1" v-if="
                    Ptype == 'out_patient' ||
                    Ptype == 'unattended' ||
                    Ptype == ''
                  ">
                    <!-- {{  prescriptions }}  the one that shows for clinics -->
                    <div class="row" v-for="(prescription, index) in prescriptions" :key="prescription" id="rackone">
                      <div class="col-12 col-md-2 col-xl-2 xl_edit xxl_edit" id="lax">

                        <!-- send top external pharmacy -->
                        <div class="input-block local-forms">
                          <label>Pharmacy List
                            <span class="login-danger">*</span></label>
                          <!-- grouped select -->
                          <a-tree-select tree-node-filter-prop="label" showSearch
                            v-model:value="prescription.institution_id" tree-data-simple-mode style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="pharmacies"
                            placeholder="Please select" @change="load_needed_data(prescription.institution_id, index)">
                            <template #title="{ value: val, label, title }">
                              <b v-if="title === 'External Pharmacies'" class="dropdown-title">{{ title }}</b>
                              <template v-else-if="val == 'loading'">
                                <a-spin size="small" />
                              </template>
                              <template v-else>{{ title || label }}</template>
                            </template>
                          </a-tree-select>
                          <i class="very-small text-danger"
                            v-if="prescription.institution_id && prescription.institution_id != 'Internal Pharmacy'">Note:
                            You Selected an External
                            Pharmacy</i>


                        </div>
                      </div>

                      <div class="col-12 col-md-2 col-xl-2 xl_edit xxl_edit" id="lax">

                        <div class="input-block local-forms">
                          <label>Drug List
                            <span class="login-danger">*</span></label>
                          <a-select class="w-100" v-model:value="prescription.drug_id" showSearch :filterOption="(input, option) =>
                            filterObject(option, input, 'key')" :loading="drug_list.length == 0" @change="get_drug_name(prescription)">
                            <a-select-option disabled>Select a Drug</a-select-option>
                            <a-select-option v-for="drug in drug_list" :key="drug.drug_name" :value="drug.id">
                              {{ drug.drug_name }} ({{ drug.drug_type }})
                            </a-select-option>
                            <a-select-option disabled v-if="drug_list.length == 0">
                              <a-spin size="small" />
                            </a-select-option>

                            <!-- {{ drug.tablet_type }} -->
                          </a-select>
                        </div>

                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit xxl_edit" v-if="isLiquid(prescription.drug_id)"
                        id="lax">
                        <div class="input-block local-forms">
                          <label>Single Dose
                            <span class="login-danger">*</span></label>
                          <a-select class="" v-model:value="prescription.single_dose">

                            <a-select-option value="1">True</a-select-option>
                            <a-select-option value="0">False</a-select-option>
                          </a-select>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>Quantity <span class="login-danger">*</span></label>
                          <input class="form-control" type="number" v-model="prescription.quantity" min="0"
                            oninput="javascript: if (this.value < 0) this.value = 0;" />
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <span v-if="prescription.interval_frequncy == 'frequency'">
                            <label>Frequency
                              <span class="login-danger">*</span></label>
                            <input class="form-control" type="number" v-model="prescription.frequency" min="0" />
                          </span>

                          <span v-else-if="
                            prescription.interval_frequncy == 'interval'
                          ">
                            <label>Interval
                              <span class="login-danger">*</span></label>
                            <a-select id="timeSelect" v-model:value="prescription.interval" class="">
                              <a-select-option value="1">1 hour</a-select-option>
                              <a-select-option value="2">2 hours</a-select-option>
                              <a-select-option value="3">3 hours</a-select-option>
                              <a-select-option value="4">4 hours</a-select-option>
                              <a-select-option value="5">5 hours</a-select-option>
                              <a-select-option value="6">6 hours</a-select-option>
                              <a-select-option value="7">7 hours</a-select-option>
                              <a-select-option value="8">8 hours</a-select-option>
                              <a-select-option value="9">9 hours</a-select-option>
                              <a-select-option value="10">10 hours</a-select-option>
                              <a-select-option value="11">11 hours</a-select-option>
                              <a-select-option value="12">12 hours</a-select-option>
                              <a-select-option value="13">13 hours</a-select-option>
                              <a-select-option value="14">14 hours</a-select-option>
                              <a-select-option value="15">15 hours</a-select-option>
                              <a-select-option value="16">16 hours</a-select-option>
                              <a-select-option value="17">17 hours</a-select-option>
                              <a-select-option value="18">18 hours</a-select-option>
                              <a-select-option value="19">19 hours</a-select-option>
                              <a-select-option value="20">20 hours</a-select-option>
                              <a-select-option value="21">21 hours</a-select-option>
                              <a-select-option value="22">22 hours</a-select-option>
                              <a-select-option value="23">23 hours</a-select-option>
                              <a-select-option value="24">24 hours</a-select-option>
                            </a-select>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>Duration <span class="login-danger">*</span></label>
                          <input class="form-control" type="number" v-model="prescription.duration" min="0" />
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>When <span class="login-danger">*</span></label>
                          <a-select class="w-100" v-model:value="prescription.intake_time">
                            <a-select-option value="Before Meal">Before Meal</a-select-option>
                            <a-select-option value="After Meal">After Meal</a-select-option>
                          </a-select>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>Total Qty<span class="login-danger">*</span></label>
                          <input class="form-control" v-if="prescription.interval_frequncy == 'frequency'" type="number"
                            :value="multiplier(
                              prescription.quantity,
                              prescription.frequency,
                              prescription.duration
                            )
                              " disabled />
                          <input class="form-control" v-else-if="
                            prescription.interval_frequncy == 'interval'
                          " type="number" :value="multiplier(
                            prescription.quantity,
                            prescription.interval,
                            prescription.duration
                          )
                            " disabled />
                        </div>
                      </div>
                      <!-- <div class="col-12 col-md-3 col-xl-3 xl_edit xl_edit_big">
                        <div class="input-block local-forms">
                          <label>Dosage<span class="login-danger">*</span></label>
                          <input class="form-control" type="text" :value="dosage(
                            prescription,
                            prescription.quantity,
                            prescription.frequency,
                            prescription.duration
                          )
                            " disabled />
                        </div>
                      </div> -->
                      <div class="col-md-1 col-xl-1 xl_edit_bt">
                        <!-- <div class="removebtn" v-if="prescription.id != null"> -->
                        <div class="removebtn">
                          <div class="add-group" @click="removePrescription(prescription.id)">
                            <span class="btn btn-primary fa fa-minus ms-1" style="font-size: 18px">
                            </span>
                          </div>
                        </div>
                      </div>
                      <p class="display_dosage" style="font-size: 20px; margin-bottom: 20px"
                        v-if="prescription.interval_frequncy == 'frequency'">
                        <b> Dosage:</b>
                        {{
                          dosage(
                            prescription,
                            prescription.quantity,
                            prescription.frequency,
                            prescription.duration
                          )
                        }}, {{ prescription.intake_time }}.
                      </p>
                      <p class="display_dosage" style="font-size: 20px; margin-bottom: 20px"
                        v-if="prescription.interval_frequncy == 'interval'">
                        <b> Dosage:</b>
                        {{
                          dosage(
                            prescription,
                            prescription.quantity,
                            prescription.interval,
                            prescription.duration
                          )
                        }}, {{ prescription.intake_time }}.
                      </p>
                      <br />
                      <div class="col-12 col-md-3 col-xl-3 xl_edit xl_edit_big xxll">
                        <div class="input-block local-forms">
                          <label>Note</label>
                          <textarea class="form-control" type="text" v-model="prescription.note" required></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="for2" v-if="Ptype == 'in_patient'">
                    <!-- {{  prescriptions }} -->
                    <div class="row" v-for="(prescription, index) in prescriptions" :key="prescription">
                      <div class="col-12 col-md-2 col-xl-2 xl_edit xxl_edit" id="lax">
                        <!-- send top external pharmacy -->
                        <div class="input-block local-forms">
                          <label>Pharmacy List
                            <span class="login-danger">*</span></label>
                          <!-- grouped select -->
                          <a-tree-select tree-node-filter-prop="label" showSearch
                            v-model:value="prescription.institution_id" tree-data-simple-mode style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="pharmacies"
                            placeholder="Select a Pharmacy"
                            @change="load_needed_data(prescription.institution_id, index)">

                            <template #title="{ value: val, label, title }">
                              <b v-if="title === 'External Pharmacies'" class="dropdown-title">{{ title }}</b>
                              <template v-else-if="val == 'loading'">
                                <a-spin size="small" />
                              </template>
                              <template v-else>{{ title || label }}</template>
                            </template>
                          </a-tree-select>
                          <i class="very-small text-danger"
                            v-if="prescription.institution_id && prescription.institution_id != 'Internal Pharmacy'">Note:
                            You Selected an External
                            Pharmacy</i>
                        </div>
                      </div>

                      <div class="col-12 col-md-2 col-xl-2 xl_edit xxl_edit" id="lax">

                        <div class="input-block local-forms">
                          <label>Drug List
                            <span class="login-danger">*</span></label>
                          <a-select class="w-100" v-model:value="prescription.drug_id" showSearch :filterOption="(input, option) =>
                            filterObject(option, input, 'key')" :loading="drug_list.length == 0" @change="get_drug_name(prescription)">
                            <a-select-option disabled>Select a Drug</a-select-option>
                            <a-select-option v-for="drug in drug_list" :key="drug.drug_name" :value="drug.id">{{
                              drug.drug_name }} ({{ drug.drug_type }})
                            </a-select-option>

                            <a-select-option disabled v-if="drug_list.length == 0">
                              <a-spin size="small" />
                            </a-select-option>
                          </a-select>
                        </div>

                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>Quantity <span class="login-danger">*</span></label>
                          <input class="form-control" type="number" v-model="prescription.quantity" min="0" />
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <span v-if="prescription.interval_frequncy == 'frequency'">
                            <label>Frequency
                              <span class="login-danger">*</span></label>
                            <input class="form-control" type="number" v-model="prescription.frequency" min="0" />
                          </span>

                          <span v-else-if="
                            prescription.interval_frequncy == 'interval'
                          ">
                            <label>Interval
                              <span class="login-danger">*</span></label>
                            <a-select id="timeSelect" v-model:value="prescription.interval" class="">
                              <a-select-option value="1">1 hour</a-select-option>
                              <a-select-option value="2">2 hours</a-select-option>
                              <a-select-option value="3">3 hours</a-select-option>
                              <a-select-option value="4">4 hours</a-select-option>
                              <a-select-option value="5">5 hours</a-select-option>
                              <a-select-option value="6">6 hours</a-select-option>
                              <a-select-option value="7">7 hours</a-select-option>
                              <a-select-option value="8">8 hours</a-select-option>
                              <a-select-option value="9">9 hours</a-select-option>
                              <a-select-option value="10">10 hours</a-select-option>
                              <a-select-option value="11">11 hours</a-select-option>
                              <a-select-option value="12">12 hours</a-select-option>
                              <a-select-option value="13">13 hours</a-select-option>
                              <a-select-option value="14">14 hours</a-select-option>
                              <a-select-option value="15">15 hours</a-select-option>
                              <a-select-option value="16">16 hours</a-select-option>
                              <a-select-option value="17">17 hours</a-select-option>
                              <a-select-option value="18">18 hours</a-select-option>
                              <a-select-option value="19">19 hours</a-select-option>
                              <a-select-option value="20">20 hours</a-select-option>
                              <a-select-option value="21">21 hours</a-select-option>
                              <a-select-option value="22">22 hours</a-select-option>
                              <a-select-option value="23">23 hours</a-select-option>
                              <a-select-option value="24">24 hours</a-select-option>
                            </a-select>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>From <span class="login-danger">*</span></label>
                          <input class="form-control" v-model="prescription.start_date" type="date" id="edate"
                            :min="current_date" />
                        </div>
                      </div>
                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>To <span class="login-danger">*</span></label>
                          <input class="form-control" v-model="prescription.end_date" type="date" id="edate_end"
                            :min="prescription.start_date" />
                        </div>
                      </div>

                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>When <span class="login-danger">*</span></label>
                          <a-select v-model:value="prescription.intake_time" class="w-100">
                            <a-select-option value="Before Meal">Before Meal</a-select-option>
                            <a-select-option value="After Meal">After Meal</a-select-option>
                          </a-select>
                        </div>
                      </div>

                      <div class="col-12 col-md-2 col-xl-2 xl_edit" id="lax">
                        <div class="input-block local-forms">
                          <label>Total Qty<span class="login-danger">*</span></label>
                          <input class="form-control" v-if="prescription.interval_frequncy == 'frequency'" type="number"
                            :value="multiplier(
                              prescription.quantity,
                              prescription.frequency,
                              calculated_duration(
                                prescription.start_date,
                                prescription.end_date
                              )
                            )
                              " disabled />
                          <input class="form-control" v-else-if="
                            prescription.interval_frequncy == 'interval'
                          " type="number" :value="multiplier(
                            prescription.quantity,
                            prescription.interval,
                            calculated_duration(
                              prescription.start_date,
                              prescription.end_date
                            )
                          )
                            " disabled />
                        </div>
                      </div>
                      <!-- <div class="col-12 col-md-3 col-xl-3 xl_edit xl_edit_big_with_date">
                        <div class="input-block local-forms">
                          <label>Dosage<span class="login-danger">*</span></label>
                          <input class="form-control" type="text" :value="dosage(
                            prescription,
                            prescription.quantity,
                            prescription.frequency,
                            calculated_duration(
                              prescription.start_date,
                              prescription.end_date
                            )
                          )
                            " disabled />
                        </div>
                      </div> -->

                      <!-- added here but not tested -->
                      <div class="col-md-1 col-xl-1 xl_edit_bt">
                        <div class="removebtn">
                          <div class="add-group" @click="removePrescription(prescription.id)">
                            <span class="btn btn-primary fa fa-minus ms-1" style="font-size: 18px">
                            </span>
                          </div>
                        </div>
                      </div>
                      <p class="display_dosage" style="font-size: 20px; margin-bottom: 20px"
                        v-if="prescription.interval_frequncy == 'frequency'">
                        <b> Dosage:</b>
                        {{
                          dosage(
                            prescription,
                            prescription.quantity,
                            prescription.frequency,
                            calculated_duration(
                              prescription.start_date,
                              prescription.end_date
                            )
                          )
                        }}, {{ prescription.intake_time }}.
                      </p>
                      <p class="display_dosage" style="font-size: 20px; margin-bottom: 20px"
                        v-if="prescription.interval_frequncy == 'interval'">
                        <b> Dosage:</b>
                        {{
                          dosage(
                            prescription,
                            prescription.quantity,
                            prescription.interval,
                            calculated_duration(
                              prescription.start_date,
                              prescription.end_date
                            )
                          )
                        }}, {{ prescription.intake_time }}.
                      </p>
                      <br />
                      <div class="col-12 col-md-3 col-xl-3 xl_edit xl_edit_big xxll">
                        <div class="input-block local-forms">
                          <label>Note</label>
                          <textarea class="form-control" type="text" v-model="prescription.note" required></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2 view-modal"
                        @click="showsubmodal()">Submit</span>
                      <button type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="card card3 none" id="examination">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Examination</h4>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <!-- ote -->
                    <div class="input-block local-forms">
                      <label>Examination <span class="login-danger">*</span></label>
                      <textarea class="form-control" rows="3" cols="30" v-model="examination"></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-md-12 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top">Upload Image (Allow Multiple)<span class="login-danger">*</span></label>
                      <div class="settings-btn upload-files-avator upload" for="file" style="background-color: #f1f2f3">
                        <!-- <span v-if="imagePreviews.examination.length == 0"
                          >Choose File...</span
                        > -->
                        <span v-if="imagePreviews.examination.length > 0">{{ imagePreviews.examination.length }}
                          image(s) was
                          seleced</span>
                        <input type="file" accept="image/*" name="examination" id="examinationFile" multiple hidden />
                        <label for="examinationFile" class="upload"
                          style="background-color: #14a79e; color: white">Browse</label>
                      </div>
                    </div>

                    <!-- Sample preview -->
                    <div class="preview" style="
                        padding-left: 50px;
                        margin-bottom: 50px;
                        margin-top: 50px;
                      ">
                      <div style="text-align: center" v-if="imagePreviews.examination == ''">
                        <img src="../../assets/assets/img/imgpreview.png" width="400px" height="200px" />
                      </div>

                      <div class="" style="
                          display: inline-block;
                          margin-bottom: 50px;
                          margin-right: 30px;
                        " v-for="(image, index) in imagePreviews.examination" :key="index">
                        <span class="remove_img custom-file-container__image-multi-preview__single-image-clear__icon"
                          style="
                            border-radius: 50%;
                            box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
                            margin-top: 5px;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            color: #14a79e;
                            cursor: pointer;
                          " @click="deleteAvatar(index, 'examination')">×</span>
                        <img :src="image" class="" alt="Uploaded Image" style="
                            height: 100px;
                            width: 260px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 20px;
                            border-radius: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2 view-modal"
                        @click="showsubmodal()">Submit</span>
                      <button type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="card card4 none" id="diagnosis">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Diagnosis</h4>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <!-- ote -->
                    <div class="input-block local-forms">
                      <label>Diagnosis <span class="login-danger">*</span></label>
                      <textarea class="form-control" rows="3" cols="30" v-model="diagnosis"></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-md-12 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top">Upload Image (Allow Multiple)<span class="login-danger">*</span></label>
                      <div class="settings-btn upload-files-avator upload" for="file" style="background-color: #f1f2f3">
                        <!-- <span v-if="imagePreviews.diagnosis.length == 0"
                          >Choose File...</span
                        > -->
                        <span v-if="imagePreviews.diagnosis.length > 0">{{ imagePreviews.diagnosis.length }} image(s)
                          was
                          seleced</span>
                        <input type="file" accept="image/*" name="diagnosis" id="diagnosisFile" multiple hidden />
                        <label for="diagnosisFile" class="upload"
                          style="background-color: #14a79e; color: white">Browse</label>
                      </div>
                    </div>

                    <!-- Sample preview -->
                    <div class="preview" style="
                        padding-left: 50px;
                        margin-bottom: 50px;
                        margin-top: 50px;
                      ">
                      <div style="text-align: center" v-if="imagePreviews.diagnosis == ''">
                        <img src="../../assets/assets/img/imgpreview.png" width="400px" height="200px" />
                      </div>

                      <div class="" style="
                          display: inline-block;
                          margin-bottom: 50px;
                          margin-right: 30px;
                        " v-for="(image, index) in imagePreviews.diagnosis" :key="index">
                        <span class="remove_img custom-file-container__image-multi-preview__single-image-clear__icon"
                          style="
                            border-radius: 50%;
                            box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
                            margin-top: 5px;
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            color: #14a79e;
                            cursor: pointer;
                          " @click="deleteAvatar(index, 'diagnosis')">×</span>
                        <img :src="image" class="" alt="Uploaded Image" style="
                            height: 100px;
                            width: 260px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 20px;
                            border-radius: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <span type="submit" class="btn btn-primary submit-form me-2 view-modal"
                        @click="showsubmodal()">Submit</span>
                      <button type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                        @click="$router.go(-1)">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card card5 none" id="administer">
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>Administer Drugs</h4>
                </div>
              </div>
            </div>
            <Prescription_Table />
          </form>
        </div>
      </div>

      <!-- Items Modal -->
      <div class="modal custom-modal fade" id="save_modal" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3 v-if="whoisLoggedin == 'Doctor'">Doctor's Note Summary</h3>
                <h3 v-if="whoisLoggedin == 'Nurse'">Nurse's Note Summary</h3>
                <p>Confirm and Save</p>
              </div>

              <div class="row">
                <div class="col-12 col-md-6 col-xl-6" v-if="note != ''">
                  <div class="preview card" style="background-color: #f1efff; padding: 15px">
                    <div style="margin-bottom: 10px">
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          color: #14a79e;
                        ">
                        Note
                      </div>
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        ">
                        X
                      </div>
                    </div>
                    <span class="main_cont">{{ note }}</span>
                    <div class="preview" style="margin-top: 20px">
                      <div class="" style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        ">
                        <img v-for="(image, index) in imagePreviews.note" :src="image" :key="index" class=""
                          alt="Uploaded Image" style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-6" v-if="prescriptions.length > 0">
                  <div class="preview card" style="background-color: #fed5e0; padding: 15px">
                    <div style="margin-bottom: 10px">
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          color: #14a79e;
                        ">
                        Prescription
                      </div>
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        ">
                        X
                      </div>
                    </div>
                    <span class="main_cont">
                      <ul v-for="p in prescriptions" v-bind:key="p.index">
                        <li><span>Note:</span> {{ p.note }}</li>
                        <li><span>Drug Name :</span> {{ p.drug_name }}</li>
                        <li v-if="p.interval_frequncy == 'frequency'">
                          <span>Duration: </span>{{ p.duration }} days |
                          <span>Quantity: </span>{{ p.quantity }} |
                          <span>Frequency: </span>{{ p.frequency }} time(s)
                          daily <span>When to take: </span>{{ p.intake_time }}
                        </li>
                        <li v-else-if="p.interval_frequncy == 'interval'">
                          <span>Duration: </span>{{ p.duration }} days |
                          <span>Quantity: </span>{{ p.quantity }} |
                          <span>Interval: </span>every {{ p.interval }} hours.
                          <span>When to take: </span>{{ p.intake_time }}
                        </li>
                        <!-- <li></li> -->
                      </ul>
                      <br />
                    </span>
                    <div class="preview" style="margin-top: 20px">
                      <div class="" style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        ">
                        <img v-for="(image, index) in imagePreviews.prescription" :src="image" :key="index" class=""
                          alt="Uploaded Image" style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-6" v-if="diagnosis != ''">
                  <div class="preview card" style="background-color: #dcf1d3; padding: 15px">
                    <div style="margin-bottom: 10px">
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          color: #14a79e;
                        ">
                        Diagnosis
                      </div>
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        ">
                        X
                      </div>
                    </div>
                    <span class="main_cont">{{ diagnosis }}</span>
                    <div class="preview" style="margin-top: 20px">
                      <div class="" style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        ">
                        <img v-for="(image, index) in imagePreviews.diagnosis" :src="image" :key="index" class=""
                          alt="Uploaded Image" style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-6" v-if="examination != ''">
                  <div class="preview card" style="background-color: #f6e9ff; padding: 15px">
                    <div style="margin-bottom: 10px">
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          color: #14a79e;
                        ">
                        Examination
                      </div>
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        ">
                        X
                      </div>
                    </div>
                    <span class="main_cont">{{ examination }}</span>
                    <div class="preview" style="margin-top: 20px">
                      <div class="" style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        ">
                        <img v-for="(image, index) in imagePreviews.examination" :src="image" :key="index" class=""
                          alt="Uploaded Image" style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-6" v-if="lab != ''">
                  <div class="preview card" style="background-color: #fef7d4; padding: 15px">
                    <div style="margin-bottom: 10px">
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          color: #14a79e;
                        ">
                        lab
                      </div>
                      <div class="" style="
                          text-align: right;
                          display: inline-block;
                          float: right;
                          color: red;
                        ">
                        X
                      </div>
                    </div>
                    <span class="main_cont">{{ lab }}</span>
                    <div class="preview" style="margin-top: 20px">
                      <div class="" style="
                          display: inline-block;
                          margin-left: 0px;
                          margin-right: 30px;
                        ">
                        <img v-for="(image, index) in imagePreviews.lab" :src="image" :key="index" class=""
                          alt="Uploaded Image" style="
                            height: 60px;
                            width: 140px;
                            display: inline-block;
                            margin-left: 0px;
                            margin-right: 10px;
                            border-radius: 20px;
                            margin-bottom: 20px;
                            object-fit: cover;
                          " />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-12 col-md-6 col-xl-12" style="
                    font-size: 24px;
                    padding: 10px;
                    text-align: center;
                    margin-bottom: 20px;
                  " v-if="
                    lab == '' &&
                    examination == '' &&
                    note == '' &&
                    prescriptions[0]?.note == null &&
                    diagnosis == ''
                  ">
                  No note(s) yet
                </div> -->
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <button type="reset" data-bs-dismiss="modal" class="w-100 btn btn-primary paid-continue-btn"
                      @click="sendnote()">
                      Save Changes
                    </button>
                  </div>
                  <div class="col-6">
                    <button type="submit" data-bs-dismiss="modal"
                      class="w-100 btn btn-primary paid-cancel-btn bg-danger text-light">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Items Modal -->

      <!-- second modal for confirmation -->
      <div class="modal modal-lg animate__animated animate__zoomIn animate__faster add_interval_type" role="dialog"
        id="grandhere">
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Choose action</h3>
                <p>How do you want this medication to be taken?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <span @click="select_type('frequency')" class="w-100 btn btn-primary paid-continue-btn">
                      Frequency
                    </span>
                  </div>
                  <div class="col-6">
                    <span @click="select_type('interval')" class="w-100 btn btn-primary paid-continue-btn">
                      Interval
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>lab
      </div>
      <!-- /.modal -->

      <!-- second modal for confirmation -->
      <div class="modal modal-lg animate__animated animate__zoomIn animate__faster where_to_send" role="dialog"
        id="where_to_send">
        <div class="modal-dialog modal-dialog-centered modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Choose action</h3>
                <p>Which pharmacy do you want to send this prescription?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <span @click="openIntervalModal('internal')" class="w-100 btn btn-primary paid-continue-btn">
                      Internal
                    </span>
                  </div>
                  <div class="col-6">
                    <span @click="openIntervalModal('external')" class="w-100 btn btn-primary paid-continue-btn">
                      External
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import Prescription_Table from "./plugin/prescription_table.vue";
import { mapActions } from "vuex";
import { filterObject } from "@/utils/helpers";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Prescription_Table,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      hospital: localStorage.getItem('hospital'),
      pharmacies: [
        { id: 1, value: 'Internal Pharmacy', title: `Internal Pharmacy - ${localStorage.getItem('hospital')}`, isLeaf: true },
        {
          id: 2, disabled: true, value: 'External Pharmacies', title: 'External Pharmacies', children: [
            {
              value: 'loading',
              disabled: true,
              isLeaf: true
            }
          ],
        },
      ],
      internal_drug_list: [],
      patient_name: localStorage.getItem("patient_name"),
      response: "",
      drug: "",
      frequency: "",
      duration: "",
      drug_list: [],
      drug_selected: [],
      Ptype: localStorage.getItem("patient_type"),
      doctor_name:
        localStorage.getItem("Fname") + " " + localStorage.getItem("Lname"),
      type: "Add Note",
      label: "Note",
      status: true,
      note: "",
      prescription: "",
      examination: "",
      diagnosis: "",
      // lab: "",
      lab: "",
      id: localStorage.getItem("apt_id"),
      avatars: [],
      imagePreviews: {
        note: [],
        prescription: [],
        examination: [],
        diagnosis: [],
        lab: [],
      },
      prescriptions: [
        /**  {
             id: null,
             drug_id: null,
             frequency: null,
             start_date: null,
             end_date: null,
             quantity: null,
             duration: null,
             note: null,
             single_dose: 1,
           }, */
      ],
      lab_note: null,
      radiology_note: null,

      whoisLoggedin: localStorage.getItem("role"),
      increaser: 0,
      // val_holder:null
      current_date: new Date().toISOString().split("T")[0],
      filterObject,
      location_choice: null,
      institution_list: null,
      // institution_id: null
      drug_list_reserve: null
    };
  },
  computed: {
    unselectedDrugList() {
      const selectedDrugIds = this.prescriptions.map(
        (prescription) => prescription.drug_id
      );
      console.log(
        this.drug_list,
        this.drug_list.filter((drug) => !selectedDrugIds.includes(drug.id))
      );

      return this.drug_list.filter(
        (drug) => !selectedDrugIds.includes(drug.id)
      );
    },
  },
  methods: {

    get_drug_name(prescription){
      var drug = this.drug_list.find(
        (drug) => drug.id ==  prescription.drug_id
      );
      prescription.drug_name=`${drug.drug_name} (${drug.drug_type})`
      // alert(prescription.drug_name)
    },
    // loadExternalPharmacies() { },
    load_needed_data(id, prescriptionIndex) {
      this.prescriptions[prescriptionIndex].drug_id = null;//reset the drug id
      if (id.toString().toLocaleLowerCase() == "internal pharmacy") {
        this.drug_list = this.internal_drug_list;
      } else {
        this.drug_list = [];//empty to avoid leaving old data
        this.getDrugList(id)
      }
    },
    openIntervalModal() {
      $(".add_interval_type").modal("show");
    },
    select_type(choice) {
      this.addPrescription(choice);
      $(".add_interval_type").modal("hide");
    },

    isLiquid(id) {
      if (this.drug_list) {
        const drug = this.drug_list.find((i) => i.id == id);
        // alert(drug);
        return drug?.drugType == "Liquids";
      }
    },
    removePrescription(id) {
      const index = this.prescriptions.findIndex(
        (prescription) => prescription.id === id
      );
      if (index !== -1) {
        this.prescriptions.splice(index, 1);
      } else {
        console.log(`Prescription with id ${id} not found.`);
      }
    },
    calculated_duration(date1, date2) {
      var date1 = new Date(date1);
      var date2 = new Date(date2);
      if (date2 > date1) {
        // Calculate the difference in time
        var timeDiff = date2 - date1;

        // Convert the time difference from milliseconds to days
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return diffDays;
      } else {
        return 0;
      }
    },
    // mainduration(func){
    // return func
    // },
    multiplier(quantity, frequency, duration, is_single_doze) {
      return quantity * duration * frequency;

      // if (is_single_doze == 0) {
      //   return quantity;
      // } else if (is_single_doze == 1) {
      // }
    },

    dosage(data, quantity, frequency, duration) {
      if (quantity == null && frequency == null && duration == null) {
        return `no data`;
      } else {
        var freq_str = "";
        if (data.interval_frequncy == "frequency") {
          freq_str = `${quantity == null || quantity < 0 ? 0 : quantity} dose,${frequency == null || frequency < 0 ? 0 : frequency
            } times daily, for ${duration == null || duration < 0 ? 0 : duration
            } days`;
        } else {
          freq_str = `${quantity == null || quantity < 0 ? 0 : quantity
            } dose, every ${frequency == null || frequency < 0 ? 0 : frequency
            } hours, for ${duration == null || duration < 0 ? 0 : duration} days`;
        }
        return freq_str;
      }
    },

    addPrescription(choice) {
      this.increaser++;
      this.prescriptions.push({
        id: this.increaser,
        interval_frequncy: choice,
        drug_id: null,
        drug_name:null,
        frequency: null,
        start_date: null,
        end_date: null,
        quantity: null,
        duration: null,
        note: null,
        single_dose: 0,
        intake_time: null,
        interval: null,
        institution_id: 'Internal Pharmacy'
      });
    },
    ...mapActions({
      _add_schedule: "general/add_schedule",
      _sendnote: "doctor/sendnote",
      _getDrugList: "pharmacy/getDrugList",
      _get_hospital: "general/get_hospitalList",

      // _send_radiology_note: "radiology/send_radiology_note",
    }),
    sendnote() {
      $(".inloader").show();
      const prescriptions = this.prescriptions.map((prescription) => {
        const formattedData = { ...prescription }; // spread to avoid modification of original data
        formattedData.single_dose == "1" ? true : false;

        if (formattedData.institution_id == 'Internal Pharmacy') {
          formattedData.institution_id = null;
        }
        return formattedData;
      });
      // this.drug_selected = this.drug_list.find((item) => item.single_dose != id)
      this._sendnote({
        id: this.id,
        payload: {
          note: this.note,
          // prescription: this.prescription,
          examination: this.examination,
          // lab: this.lab,
          diagnosis: this.diagnosis,
          // lab_note: this.lab_note,
          // radiology_note: this.radiology_note,
          avatars: this.avatars,
          prescriptions: prescriptions,
        },
      })
        .then((response) => {
          this.response = response.data;

          if (this.response["status"] == true) {
            this.lab_note = "";
            this.radiology_note = "";
            // alert(this.response)
            $(".inloader").hide();
            this.$toast.success(this.response["message"]);
            window.location = "My-Patient-Dashboard";
            // location.reload();

            // if (this.whoisLoggedin == "Doctor") {
            //   this.$toast.success(this.response["message"]);
            //   window.location = "My-Patient-Dashboard";
            // }
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },

    note_link() {
      $("#note").show(1000);
      $(
        "#prescription,#examination,#diagnosis,#lab,#radiology_v,#administer"
      ).hide(0);

      $(".n1").css("background-color", "#14a79e");
      $(".n2,.n3,.n4,.n5,.n6").css("background-color", "black");

      $(".nb").addClass("blink").removeClass("blink2");
      $(".eb,.dp,.pb").removeClass("blink").addClass("blink2");
    },
    prescription_link() {
      $("#prescription").show(1000);
      $("#note,#examination,#diagnosis,#lab,#radiology_v,#administer").hide(0);
      $(".n2").css("background-color", "#14a79e");
      $(".n1,.n3,.n4,.n5,.n6").css("background-color", "black");

      $(".pb").addClass("blink").removeClass("blink2");
      $(".eb,.dp,.nb").removeClass("blink").addClass("blink2");
    },
    diagnosis_link() {
      $("#diagnosis").show(1000);
      $("#note,#examination,#prescription,#lab,#radiology_v,#administer").hide(
        0
      );
      $(".n3").css("background-color", "#14a79e");
      $(".n1,.n2,.n4,.n5,.n6").css("background-color", "black");

      $(".dp").addClass("blink").removeClass("blink2");
      $(".eb,.pb,.nb").removeClass("blink").addClass("blink2");
    },
    examination_link() {
      $("#examination").show(1000);
      $("#note,#prescription,#diagnosis,#lab,#radiology_v,#administer").hide(0);

      $(".n4").css("background-color", "#14a79e");
      $(".n1,.n3,.n2,.n5,.n6").css("background-color", "black");

      $(".eb").addClass("blink").removeClass("blink2");
      $(".dp,.pb,.nb").removeClass("blink").addClass("blink2");
    },
    administer_link() {
      $("#administer").show(1000);
      $("#note,#examination,#diagnosis,#prescription,#radiology_v").hide(0);

      $(".n5").css("background-color", "#14a79e");
      $(".n1,.n3,.n4,.n2,.n6").css("background-color", "black");
    },
    lab_link() {
      $("#lab").show(1000);
      $("#note,#examination,#diagnosis,#prescription,#radiology_v").hide(0);

      $(".n5").css("background-color", "#14a79e");
      $(".n1,.n3,.n4,.n2,.n6").css("background-color", "black");
    },

    rad_link() {
      $("#radiology_v").show(1000);
      $("#note,#examination,#diagnosis,#prescription,#radiology,#lab").hide(0);

      $(".n6").css("background-color", "#14a79e");
      $(".n1,.n3,.n4,.n2,.n5").css("background-color", "black");
    },
    deleteAvatar(index, type) {
      this.imagePreviews[type].splice(index, 1);
      // this.avatars[index].splice(index, 1);
      for (let i = 0; i < this.avatars.length; i++) {
        if (i === index && this.avatars[i].type === type) {
          this.avatars.splice(i, 1);
          break;
        }
      }
    },
    updateImagePreviews(type) {
      // Images for that type
      let avatars = this.avatars.filter((avatar) => avatar.type == type);
      this.imagePreviews[type] = [];
      for (let index = 0; index < avatars.length; index++) {
        const avatar = avatars[index].avatar;

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          this.imagePreviews[type].push(imageUrl); // Add The Image Url to the List of that type
        };

        reader.readAsDataURL(avatar);
      }
    },
    loadFileInputs() {
      const vm = this;
      $("input[type=file]").change(function () {
        let files = $(this).prop("files");
        for (let index = 0; index < files.length; index++) {
          const file = files[index];

          let avatar = {
            type: $(this).attr("name"),
            avatar: file,
          };
          vm.avatars.push(avatar);
          console.log($(this).attr("name"));
          // console.log(vm.avatars,'smdmdi')
        }

        vm.updateImagePreviews($(this).attr("name"));
      });
    },
    getDrugList(institution_id) {
      // is there's no institution_id, that means it's internal
      this._getDrugList({ institution_id: institution_id })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.drug_list = this.response.data;
            this.drug_list.sort((a, b) =>
              a.drug_name.localeCompare(b.drug_name)
            );

            this.drug_list_reserve = this.drug_list
            if (!institution_id) {
              this.internal_drug_list = this.response.data;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    showsubmodal() {
      // if (
      //   this.examination != "" ||
      //   this.note != "" ||
      //   this.prescriptions[0]?.note != null ||
      //   this.diagnosis != ""
      // ) {
      //   $("#save_modal").modal("show");
      // } else {
      //   this.$toast.error("Fill up at least one note, before submiting");
      // }

      $("#save_modal").modal("show");
    },
    getdrugname_and_id() { },
    navigate_to_tab(tabname) {
      console.log(tabname);

      switch (tabname) {
        case "note":
          this.note_link();
          break;
        case "administer":
          this.administer_link();
          break;
        case "examination":
          this.examination_link();
          break;
        case "diagnosis":
          this.diagnosis_link();
          break;
        case "prescription":
          this.prescription_link();
          break;
      }
    },
    gethospital() {
      this._get_hospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.institution_list = this.response.data;
            this.institution_list = this.institution_list.filter(
              (institution) => institution.role_name == 'Pharmaceutical'
            );

            const formattedPharmacies = response.data.data.filter(
              (institution) => institution.role_name == 'Pharmaceutical'
            ).map((pharmacy, index) => ({
              value: pharmacy.institution_id,
              label: pharmacy.name,
              isLeaf: true,
            }));


            if (formattedPharmacies.length == 0) {
              this.pharmacies[1].children = [{
                value: "value",
                label: "No Data Available",
                isLeaf: true,
                disabled: true,
              }]
            } else {
              this.pharmacies[1].children = formattedPharmacies
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
  },

  watch: {
    end_date: {
      handler() { },
    },

  },
  mounted() {
    this.gethospital()
    // if(this.institutution_choice == 'internal'){
    this.getDrugList();
    // }

    const tabname = this.$route.hash.replace("#", "");
    if (tabname) {
      this.navigate_to_tab(tabname);
    }

    const dateEle = document.getElementById("edate");
    if (dateEle) {
      const currentDate = new Date().toISOString().split("T")[0];
      dateEle.min = currentDate;
    }
    let d = new Date();
    let currDate = d.getDate();
    let currMonth = d.getMonth() + 1;
    let currYear = d.getFullYear();
    $(".date").val(
      currYear +
      "-" +
      (currMonth < 10 ? "0" + currMonth : currMonth) +
      "-" +
      (currDate < 10 ? "0" + currDate : currDate)
    );

    let tmp = new Date(Date.now());
    let dateInputFormatted = tmp.toISOString().split("T")[0];
    $(".time").val(dateInputFormatted);

    // $(".view-modal").click(function () {
    //   if (this.examination == '' ||this.note == '' ||this.prescriptions[0].note == null ||
    //   this.diagnosis == '') { this.$toast.error('Fill up at least one note, before submiting'); }
    //   else{
    //     $("#save_modal").modal("show");
    //   }
    // });

    this.loadFileInputs();

    jQuery(document).ready(function ($) {
      //Use this inside your document ready jQuery
      $(window).on("popstate", function () {
        location.reload(true);
      });
    });

    // if (this.whoisLoggedin == "Doctor") {
    //   $(".for_doc").show();
    // }
    // if (this.whoisLoggedin == "Admin") {
    //   $(".for_doc,.for_lab,.for_radiology").show();
    // }
    // if (this.whoisLoggedin == "Lab") {
    //   $(".for_lab").show();
    // }
    // if (this.whoisLoggedin == "Radiology") {
    //   $(".for_radiology").show();
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#grandhere {
  margin-top: 0px;
  margin-left: 100px;
}

/* .addbtn{position:fixed;left: 90%;top:50%;z-index: 999;} */
.addbtn {
  position: absolute;
  right: 1%;
  top: 10px;
  z-index: 999;
}

.navhide {
  display: none;
  cursor: pointer;
}

.xl_edit {
  margin-right: -1%;
  width: 14%;
}

.xl_edit_bt {
  width: 3%;
}

.xxl_edit {
  width: 15%;
}

.xl_edit_big {
  width: 20%;
}

.xl_edit_big_with_date {
  width: 17%;
}

.xxll {
  width: 100%;
  height: 60px;
  margin-bottom: 40px;
}

.add-pluss {
  padding: 0px;
}

.none {
  display: none;
}

.nav-link {
  cursor: pointer;
}

#file {
  margin-top: -38px;
  margin-left: -10px;
}

.blink {
  display: block;
  /* position: absolute;
    top: 24px; */
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  /* background: #00d3c7; */
}

.blink2 {
  display: block;
  /* position: absolute;
    top: 24px; */
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  /* background: #00d3c7; */
}

.blink:before {
  content: "";
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.832);
  /* background: rgba(0, 0, 0, 0.5); */
  cursor: pointer;
  box-shadow: 0 0 0 rgb(26, 29, 29);
  /* box-shadow: 0 0 0 rgb(0 211 199); */
  animation: blink 1s linear infinite;
  z-index: 1;
}

.blink2:before {
  content: "";
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: #14a79e;
  /* background: rgba(0, 0, 0, 0.5); */
  cursor: pointer;
  box-shadow: 0 0 0 #14a79e;
  /* box-shadow: 0 0 0 rgb(0 211 199); */
  animation: blink 1s linear infinite;
  z-index: 1;
}

@keyframes blink {
  100% {
    transform: scale(2.5);
  }
}

.fa-minus:hover {
  background-color: red;
  color: white;
}

/* .nav-link .n1 {
  background-color: #14a79e;
} */
@media (max-width: 1280px) {
  #grandhere {
    margin-left: 0px !important;
  }

  #rackone {
    padding-top: 20px;
    display: block;
    margin-bottom: 20px;
  }

  #lax {
    width: 100%;
  }
}

@media (max-width: 800px) {
  #grandhere {
    margin-left: 15% !important;
    margin-top: -35%;
  }

  #rackone {
    padding-top: 20px;
    display: block;
    margin-bottom: 20px;
  }

  #lax {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #grandhere {
    margin-left: 0px !important;
  }

  #rackone {
    padding-top: 20px;
    display: block;
    margin-bottom: 20px;
  }

  #lax {
    width: 100%;
  }
}
</style>
