<template>
  <div
    id="Onboard_settings"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster Onboard_settings"
    style="margin-top:0px;margin-left:0px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Hospital Settings</h3>
            <p>Perform basic setuups and customization.</p>
          </div>
          <!-- /Table Header -->
          <div class="table-responsive">
            <a-table :dataSource="data" :columns="columns">
              <template #bodyCell="{ column }">
                <template v-if="column.key == 'action'">
                  <div class="row text-center">
                    <div class="col-4">
                      <input
                        class="form-check-input checkb"
                        type="radio"
                        v-model="method"
                        value="bill_by_tablet"
                      />
                      <label for="option1"> Bill by Tablets</label>
                    </div>
                    <div class="col-4">
                      <input
                        class="form-check-input checkb"
                        type="radio"
                        v-model="method"
                        value="bill_by_card"
                      />
                      <label for="option1"> Bill by Card</label>
                    </div>
                    <div class="col-4">
                      <span
                        @click="onboard_settings()"
                        class="w-100 btn btn-primary"
                      >
                        Confirm
                      </span>
                    </div>
                  </div>
                </template>
              </template>
            </a-table>
          </div>

          <div class="row" hidden>
            <div class="col-10"></div>
            <div class="col-2">
              <span
                @click="route_to('Add-Patient')"
                class="w-100 btn btn-primary"
              >
                Confirm
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { store } from "../../store";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Setting",
    dataIndex: "setting",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Action",
    // dataIndex: "action",
    key: "action",
  },
];

const data = [
  {
    setting: "Pharmacy Settings",
    description: "How do you want to bill your Prescriptions?",
  },
];

export default {
  name: "Onboard_settings",
  data() {
    return {
      method: "",
      columns,
      data,
    };
  },
  methods: {
    ...mapActions({
      _onboard_settings: "general/onboard_settings",
    }),

    onboard_settings() {
      $(".inloader").show();

      this._onboard_settings({ pharmacy_billing: this.method })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          if (this.response["status"] == true) {
            localStorage.setItem("settings", true);
            localStorage.setItem("settings_info",JSON.stringify(this.response.data));

            $("#Onboard_settings").modal("hide");
            this.$toast.success("Settings updated successfully");
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
        });
    },
  },

  mounted() {},
};

// $('#set_pin').modal({
//     backdrop: 'static',
//     keyboard: false
// })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkb {
  height: 15px;
  width: 15px;
  border: 2px solid black;
}
label {
  margin-left: 10px;
}
</style>
