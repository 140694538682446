<template>
<Loader id="loadercomp" />
<Inloader class="inloader" id="inloader" />
  
      
        <div class="page-wrapper">
 <!-- Page Content -->
 <div class="content container-fluid">

<!-- Page Header -->
<div class="page-header">
<div class="row">
<div class="col-sm-12">
<ul class="breadcrumb">
<li class="breadcrumb-item"><a href="#">Settings </a></li>
<li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
<li class="breadcrumb-item active">Change Password</li>
</ul>
</div>
</div>
</div>
<!-- /Page Header -->

<SettingsMenu />

<div class="row">
<div class="col-lg-8">
<div class="card">
<div class="card-body">
<form>
  <div class="row">
    <h4 class="page-title">Change Password</h4>
    <div class="col-12 col-md-6 col-xl-12">  
      <div class="input-block local-forms">
        <label>Old password <span class="login-danger">*</span></label>
        <input class="form-control" type="text" placeholder="">
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-6">  
      <div class="input-block local-forms">
        <label>New password <span class="login-danger">*</span></label>
        <input class="form-control" type="text" placeholder="">
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-6">  
      <div class="input-block local-forms">
        <label>Confirm password <span class="login-danger">*</span></label>
        <input class="form-control" type="text" placeholder="">
      </div>
    </div>
    <div class="col-12">
      <div class="doctor-submit text-end">
        <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
      </div>
    </div>
  </div>
</form>
</div>
</div>
</div>
</div>

</div>
<!-- /Page Content -->         

          
  </div>
    
</template>            
<script>







import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Settings_menu";


export default {
  name: "Index_Page",
  components: {
    
Loader,
Inloader,
SettingsMenu
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
     
    };
  },

  methods: {
    
  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>