<template>
<Loader id="loadercomp" />
<Inloader class="inloader" id="inloader" />
  
      
        <div class="page-wrapper">
     <!-- Page Content -->
     <div class="content container-fluid">

<!-- Page Header -->
<div class="page-header">
<div class="row">
<div class="col-sm-12">
<ul class="breadcrumb">
<li class="breadcrumb-item"><a href="#">Dashboard </a></li>
<li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
<li class="breadcrumb-item active">Settings</li>
</ul>
</div>
</div>
</div>
<!-- /Page Header -->

<SettingsMenu />


<div class="row">
<div class="col-md-6">
<div class="card">
<div class="card-header">
<h5 class="card-title">SEO Settings</h5>
</div>
<div class="card-body pt-0">
<form>
  <div class="settings-form">
    <div class="input-block form-placeholder">
      <label>Meta Title <span class="star-red">*</span></label>
      <input type="text" class="form-control">
    </div>
    <div class="input-block">
      <label>Meta Keywords <span class="star-red">*</span></label>
      <input type="text" data-role="tagsinput" class="input-tags form-control" placeholder="Meta Keywords" name="services" value="Lorem,Ipsum" id="services">
    </div>
    <div class="input-block">
      <label>Meta Description <span class="star-red">*</span></label>
      <textarea class="form-control"></textarea>
    </div>
    <div class="input-block mb-0">
      <div class="settings-btns">
        <button type="submit" class="border-0 btn btn-primary btn-gradient-primary btn-rounded">Submit</button>
        <button type="submit" class="btn btn-secondary btn-rounded">Cancel</button>
      </div>
    </div>
  </div>
</form>
</div>
</div>
</div>
</div>

</div>
<!-- /Page Content -->     

          
  </div>
    
</template>            
<script>







import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import SettingsMenu from "../plugins/Settings_menu";


export default {
  name: "Index_Page",
  components: {
    
Loader,
Inloader,
  SettingsMenu
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
     
    };
  },

  methods: {
    
  },

  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>