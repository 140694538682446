<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Settings</a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Category</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="settings-menu-links">
        <ul class="nav nav-tabs menu-tabs">
          <li class="nav-item">
            <router-link to="/Settings" class="nav-link"
            v-if="role != 'Super Admin'"
              >General Settings</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/View-Roles" class="nav-link"
              >View Roles</router-link
            >
          </li>
          <li class="nav-item" v-if="role == 'Super Admin'">
            <router-link to="/View-Permissions" class="nav-link"
              >View Permissions</router-link
            >
          </li>
          <li class="nav-item" v-if="role == 'Super Admin'">
            <router-link to="/View-Permission-Categories" class="nav-link"
              >Permission Categories</router-link
            >
          </li>
          <li class="nav-item not_for_admin active">
            <router-link to="/View-Category" class="nav-link"
              >View Categories</router-link
            >
          </li>
          <li class="nav-item not_for_admin">
            <router-link to="/Splash-Screen" class="nav-link"
              >Splash Screen</router-link
            >
          </li>
          <!-- <li class="nav-item hide_for_now" hidden="">
      <router-link to="/Assign-Permission" class="nav-link">Assign Permission</router-link>
    </li> -->
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Loacalization-Settings" class="nav-link"
              >Localization</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Payment-Settings" class="nav-link"
              >Payment Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Email-Settings" class="nav-link"
              >Email Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Login-Settings" class="nav-link"
              >Social Media Login</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Social-Links" class="nav-link"
              >Social Links</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/SEO-Settings" class="nav-link"
              >SEO Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Theme-Settings" class="nav-link"
              >Theme Settings</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Change-Password" class="nav-link"
              >Change Password</router-link
            >
          </li>
          <li class="nav-item hide_for_now" hidden="">
            <router-link to="/Other-Settings" class="nav-link"
              >Others</router-link
            >
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Category List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></span>
                          <!-- <span class="btn btn-primary doctor-refresh ms-2" hidden=''
                                ><img
                                  src="../../assets/assets/img/icons/re-fresh.svg"
                                  alt=""
                              /></span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 text-end float-end ms-auto">
                    <div class="btn-group selectbtn">
                      <button
                        type="button"
                        class="btn dropdown-toggle btn-primary btn-xl"
                        data-bs-toggle="dropdown"
                      >
                        {{ category_selector || "Select Category" }}
                      </button>
                      <div class="dropdown-menu">
                        <span class="dropdown-item text-bg-secondary"
                          >Select List</span
                        >
                        <span
                          class="dropdown-item"
                          @click="select_category('Doctor')"
                          >Doctor</span
                        >

                        <span
                          class="dropdown-item"
                          @click="select_category('Lab')"
                          >Laboratory</span
                        >
                        <span
                          class="dropdown-item"
                          @click="select_category('Radiology')"
                          >Radiology</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              <div class="table-responsive" style="overflow: visible">
                <a-table
                  :dataSource="categories"
                  :columns="columns"
                  v-if="approved"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'status'">
                      <span
                        class="badge badge-success"
                        v-if="record?.is_active == true"
                        >Active
                      </span>
                      <span
                        class="badge badge-danger"
                        v-if="record?.is_active == false"
                        >Inactive</span
                      >
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="dropdown dropdown-action">
                        <a
                          href="#"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="fa fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#edit-modal"
                            @click="setEditCategory(record)"
                            ><i class="fa fa-pen-to-square m-r-5"></i>
                            Edit</a
                          >
                          <a
                            v-if="record?.is_active == false"
                            class="dropdown-item"
                            @click="confirmAction(record.id)"
                            ><i class="fa fa-check m-r-5"></i> Activate</a
                          >
                          <a
                            v-if="record?.is_active == true"
                            class="dropdown-item"
                            @click="confirmAction(record.id)"
                            ><i class="fa fa-x m-r-5"></i> Deactivate</a
                          >
                          <a class="dropdown-item" href="#"
                            ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>

  <!-- fixed -->

  <!-- sample modal content -->

  <div
    id="edit-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Category</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <div>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label>Category <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    v-model="editCategory.category"
                  />
                </div>

                <div class="input-block local-forms">
                  <label>Description <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    v-model="editCategory.description"
                  />
                </div>
                <div
                  class="input-block local-forms"
                  v-if="category_selector == 'Doctor'"
                >
                  <label>Avatar<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="file"
                    placeholder=""
                    v-on="category_avatar"
                    accept="*"
                    @change="handleImageChange($event, true)"
                    required
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="updateCategory()"
                  >
                    Submit
                  </span>
                  <button
                    data-bs-dismiss="modal"
                    type="button"
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>

  <div
    id="con-close-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster"
    style="margin-top:100px;margin-left:100px;margin-right"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Category</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12"></div>
              <div class="col-12 col-md-12 col-xl-12">
                <div class="input-block local-forms">
                  <label
                    >Category Name <span class="login-danger">*</span></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    v-model="formData.category"
                  />
                </div>
                <div class="input-block local-forms">
                  <label>Type <span class="login-danger"></span></label>
                  <select
                    class="form-control"
                    type="text"
                    name="cat"
                    v-model="formData.type"
                  >
                    <option value="">Select Type</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Lab">Laboratory</option>
                    <option value="Radiology">Radiology</option>
                  </select>
                </div>
                <div class="input-block local-forms">
                  <label>Description <span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder=""
                    v-model="formData.description"
                  />
                </div>
                <div
                  class="input-block local-forms"
                  v-if="formData.type == 'Doctor'"
                >
                  <label>Avatar<span class="login-danger">*</span></label>
                  <input
                    class="form-control"
                    type="file"
                    placeholder=""
                    v-on="category_avatar"
                    accept="*"
                    @change="handleImageChange"
                    required
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span
                    type="submit"
                    class="btn btn-primary submit-form me-2"
                    @click="addCategory()"
                  >
                    Submit
                  </span>
                  <button
                    type="submit"
                    class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
// import SettingsMenu from "../plugins/Staff_Setting_Menu";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
// import { type } from "@/assets/assets/plugins/raphael/raphael-min";

const columns = [
  {
    title: "Category Name",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },

  {
    title: "Date Added",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Status",
    key: "status",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // SettingsMenu,
  },

  data() {
    return {
      categories: [],
      reserve_categories: [],
      editCategory: {},
      category_selector: "Doctor",
      cat_id: null,
      formData: {
        category: "",
        description: "",
        hod_id: "",
        category_avatar: null,
        type: "",
      },
      columns,
      approved: true,
      role: localStorage.getItem("role"),

    };
  },

  methods: {
    ...mapActions({
      _getcategory: "general/global_category",
      _addCategory: "general/add_global_category",
      _updateCategory: "general/update_global_category",
      _accept_category: "general/accept_category",
    }),
    select_category(category_name) {
      this.category_selector = category_name;
      this.getCategory();
    },
    setEditCategory(category) {
      this.editCategory = category;
      this.cat_id = category.id;
      this.editCategory.type = this.category_selector;
    },
    getCategory() {
      if (
        this.category_selector == "Radiology" ||
        this.category_selector == "Lab"
      ) {
        $(".inloader").show();
      }
      this._getcategory({ type: this.category_selector })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.categories = this.response.data;
            this.categories = this.categories;
            this.reserve_categories = this.categories;
            console.log(this.categories);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    // Function to handle image change when selecting a file
    // Parameters:
    // - event: The event object representing the file input change
    // - isEdit: A boolean indicating whether it's an edit operation or not (default is false)
    handleImageChange(event, isEdit = false) {
      // If it's an edit operation
      if (isEdit) {
        // Update the category avatar for editing
        this.editCategory.avatar = event.target.files[0];
      } else {
        // If it's not an edit operation, update the category avatar for a new entry
        this.formData.category_avatar = event.target.files[0];
      }
    },

    addCategory() {
      let formData = new FormData();
      formData.append("category", this.formData.category);
      formData.append("description", this.formData.description);
      formData.append("type", this.formData.type);

      if (this.formData.type == "Doctor") {
        formData.append("avatar", this.formData.category_avatar);
      }

      $(".inloader").show();
      this._addCategory(formData)
        .then((response) => {
          $("#con-close-modal").modal("hide");
          this.getCategory();
          this.response = response.data;
          // console.log(response);
          $(".inloader").hide();

          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            this.formData.category = "";
            this.formData.category_avatar = "";
            fthis.formData.description = "";
            this.formData.type = "";
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

    updateCategory() {
      $(".inloader").show();
      this._updateCategory(this.editCategory)
        .then((response) => {
          $(".inloader").hide();

          $("#edit-modal").modal("hide");
          this.getCategory();
          this.response = response.data;
          // console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    search_the_list() {
      if (this.search == "") {
        this.categories = this.reserve_categories;
      } else {
        this.categories = this.reserve_categories;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.categories.length; i++) {
          var category = this.categories[i].category.toLowerCase();
          if (category.includes(searchLetter)) {
            searchResult.push(this.categories[i]);
          }
          // if (searchResult == []) {
          //   this.categories = this.categories;
          // }
        }
      }
      if (this.search == "") {
        this.categories = this.reserve_categories;
      } else {
        this.categories = searchResult;
      }
    },
    confirmAction(id) {
      Swal.fire({
        title: "Are you sure you want add this category?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.accept_category(id);
        }

      });
    },

    accept_category(id) {
      $(".inloader").show();
      this._accept_category({id:id,payload:{type:this.category_selector}})
        .then((response) => {
          $(".inloader").hide();
          this.getCategory();
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
          }
        })
        .catch((error) => {
          $(".inloader").hide();
          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });

    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });

    this.getCategory();

    // controlling with role
    // var role = localStorage.getItem("role");
    // if (role == "Super Admin") {
    //   $(".only_for_super_admin").show();
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selectbtn {
  width: 200px;
  padding: 0px;
}
.dropdown-menu {
  width: 200px;
}
td {
  width: 200px;
}
/* .not_for_admin{display: none;} */
</style>
