<template>
  <!-- <Inloader class="inloader" id="inloader" /> -->

  <div
    id="history-modal"
    class="modal modal-lg animate__animated animate__zoomIn animate__faster history-modal"
    style="margin-top: 200px; margin-left: 100px"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Appointment History for {{ history_data_recieved.first_name }} {{ history_data_recieved.last_name }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body p-4" style="height: 500px; overflow: scroll">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive" style="overflow: visible">
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                     

                      <th>Appointment Type</th>
                      <th>Status</th>
                      <th>doctor</th>
                      <th>Date</th>
                      <!-- <th>Time</th> -->
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="H in history_data_recieved" :key="H.id">
                      
                      <td>{{ H.appointment_type }}</td>
                      <td>{{ H.status }}</td>
                      <td>-</td>
                      <td>{{ formatDate(H.time, true) }}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin-top: 10%; padding-bottom: 40px"
            v-if="history_data_recieved == '' || history_data_recieved == null"
          >
            <i
              class="fas fa-box-open"
              style="font-size: 120px; color: #14a79e; opacity: 0.4"
            ></i>
            <div class="font-size:24px">No data yet</div>
          </div>
        </div>
      </div>
      <!-- /.modal -->
    </div>
  </div>
</template>
<script>
// import Inloader from "../plugins/inloader";

import jquery from "jquery";
import { mapActions } from "vuex";
import { formatDate } from "@/utils/formatter";


export default {
  name: "Index_Page",

  data() {
    return {
      formatDate
      // history_data_recieved: JSON.parse(localStorage.getItem('history_data_recieved')),
    };
  },
  props: {
    history_data_recieved: {
      type: Array,
      required: true,
    },
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
