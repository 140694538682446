<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Radiology</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Radiology Reports</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Radiology Reports</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden="">
                            <img src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header table-bordered-->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="main_data" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'sample_number'">
                      <div class="tooltip-container">
                        <span @click="view_patient_details(record)" class="tb_name">{{ record.sample_number }}</span>

                        <div>
                          <span class="tooltip-text">
                            <span>Patient Name - {{ record.patient_name }}</span>
                            <span>Moblie Number -
                              {{ record.mobile_number }}</span>

                            <span>Patient Age - {{ record.patient_age }}</span>
                            <span>Patient Gender - {{ record.patient_sex }}</span>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-if="column.key == 'priority'">
                      <span>
                        <span class="badge badge-danger w-100" v-if="record?.priority == 'High'">{{ record?.priority
                          }}</span>
                        <span class="badge badge-warning w-100" v-if="record?.priority == 'Moderate'">{{
                          record?.priority
                        }}</span>
                        <span class="badge badge-primary w-100" v-if="record?.priority == 'Low'">{{ record?.priority
                          }}</span>
                        <span class="badge badge-dark w-100" v-if="record?.priority == 'QS'">Quick Service</span>

                      </span>
                    </template>
                    <template v-if="column.key == 'cost'">
                      <span> ₦ {{ formatNumberWithCommas(record.cost) }} </span>
                    </template>
                    <template v-if="column.key == 'status'">
                      <span :class="`badge p-2 ${record?.payment_status == 'Paid' ? 'badge-success' : 'badge-danger'}`">
                        {{ record.payment_status }}
                      </span>
                    </template>
                    <template v-if="column.key == 'date'">
                      <span> {{ formatDate(record.created_at, true) }} </span>
                    </template>
                    <template v-if="column.key == 'result'">
                      <div class="d-flex align-items-center gap-2">
                        <span class="btn btn-primary btn-sm" @click="view_result(record)">View
                        </span>

                        <!-- <button class="btn btn-outline-primary btn-sm d-flex align-items-center" title="Print Report"
                          @click="downloadRadiologyReport(record.id)" :disabled="!record.note">
                          <i class="fa fa-print m-r-5"></i>Print</button> -->
                      </div>
                    </template>
                    <template v-if="column.key == 'action'">
                      <label class="toggle-container">
                        <input type="checkbox" class="toggle-input" />
                        <div class="toggle-slider" @click="sign(record.id)"></div>
                      </label>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-lg animate__animated animate__zoomIn animate__faster result_modal"
    style="margin-top:0px;margin-left:100px;margin-right" id="save_modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Radiologist Note</h3>
            <!-- <p>You either transfer to a docotor or department</p> -->
          </div>
          <pre v-html="result_note"></pre>

        </div>
      </div>
    </div>
  </div>
  <PatientDetails :patient_details_data_recieved="send_record" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";
import { formatDate } from "@/utils/formatter";
import { patientNoteTargets } from "@/utils/constants";
import PatientDetails from "./plugin/patient_details.vue";

const columns = [

  {
    title: "Sample Number",
    dataIndex: "sample_number",
    key: "sample_number",
  },
  {
    title: "Test",
    dataIndex: "test",
    key: "test",
  },
  {
    title: "Department",
    dataIndex: "radiology",
    key: "department",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Handling",
    dataIndex: "handler",
    key: "handler",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "date",
  },
  {
    title: "Result",
    dataIndex: "result",
    key: "result",
  },
  {
    title: "Signed",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    PatientDetails
  },

  data() {
    return {
      isLoading: true,
      reponse: "",
      main_data: [],
      result_note: "No note yet",
      main_data_reserve: [],
      columns,
      formatDate,
      send_record: {}

    };
  },
  methods: {
    ...mapActions({
      _get_lab_requests: "radiology/get_radiology_requests",
      _sign: "radiology/sign",
      _who_viewed_report: "lab/who_viewed_report",

    }),
    view_patient_details(record) {
      this.send_record = record;
      $(".patient_details-modal").modal("show");
    },
    downloadRadiologyReport(radiologyTestId) {
      this.$store
        .dispatch("general/downloadReport", { targetId: radiologyTestId, target: patientNoteTargets.radiology });
    },
    // showmodal: function (id) {
    //       $(".transfer_modal").modal("show");
    //       this.apt_id = id;
    //     },
    get_lab_requests() {
      this._get_lab_requests({ type: "supervisor" })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data.map((data) => ({
              ...data,
              patient_sex: data?.patient?.sex,
              patient_name: data?.patient?.name,
              patient_age: `${data?.patient?.age} Yrs.`,
              mobile_number: data?.patient?.mobile_number,
            }));
            this.main_data_reserve = this.main_data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },
    sign(id) {
      this._sign({ id: id, payload: { sign: "yes" } })
        .then((response) => {
          var msg = response.data.message;
          // this.main_data = this.response.data
          this.$toast.success(msg);
          this.get_lab_requests();

          // if (this.response['status'] == true) {}
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    view_result(n) {
      $(".result_modal").modal("show");
      if (n != null) {
        this.result_note = n.note;
        this.who_viewed_report(n.id)
      }
    },
    who_viewed_report(id) {
      this._who_viewed_report({ target: "lab", target_id: id })
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);

        });
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var test = this.main_data[i].test.toLowerCase();
          var lab = this.main_data[i].radiology.toLowerCase();
          var priority = this.main_data[i].priority.toLowerCase();

          if (
            test.includes(searchLetter) ||
            lab.includes(searchLetter) ||
            priority.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
  },
  mounted() {
    this.get_lab_requests();
    // $(".cancel_transfer").click(function () {});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 70%;
}

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

.invite_row {
  padding-left: 3%;
}

.selectbtn {
  width: 200px;
  background-color: #00d3c7;
}

td {
  padding: 50px;
}

.toggle-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-input {
  display: none;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 5px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 5%;
}

/* Style when switch is on */
.toggle-input:checked+.toggle-slider {
  background-color: #14a79e;
}

.toggle-input:checked+.toggle-slider:before {
  transform: translateX(26px);
}
</style>
