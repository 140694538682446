<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Ambulance</a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Ambulance Call List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Ambulance Call List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn text-end"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></span>
                          <span
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div
                class="table-responsive"
                style="overflow-x: scroll; overflow-y: visible"
              >
                <table
                  class="table border-0 custom-table comman-table table-striped table-hover mb-0"
                >
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </th>
                      <th>Case No</th>
                      <th>Patient Name</th>
                      <th>Gender</th>
                      <th>Date</th>
                      <th>Vehichle No</th>
                      <th>Driver Name</th>
                      <th>Driver No</th>
                      <th>Patient Address</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td>103</td>
                      <td class="profile-image">
                        <a href="profile.html">
                          <img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Emmanuel Uka</a
                        >
                      </td>
                      <td><span class="badge bg-success-light">Male</span></td>
                      <td>01.10.2024</td>
                      <td>GJ54XS2345</td>
                      <td>Ben ifeanyi</td>
                      <td>0903634459</td>
                      <td>St. Joseph Avenue</td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <span class="dropdown-item edit_btn"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</span
                            >
                            <span
                              class="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                            >
                              <i class="fa fa-trash-alt m-r-5"></i> Delete</span
                            >
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div class="form-check check-tables">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="something"
                          />
                        </div>
                      </td>
                      <td>109</td>
                      <td class="profile-image">
                        <a href="#">
                          <img
                            width="28"
                            height="28"
                            src="../../assets/assets/img/profiles/avatar-02.jpg"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          Esther orji</a
                        >
                      </td>
                      <td>
                        <span class="badge bg-primary-light">Female</span>
                      </td>
                      <td>01.10.2024</td>
                      <td>GJ54XS2345</td>
                      <td>Chris Donald</td>
                      <td>0903000567</td>
                      <td>OUK Avenue</td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <span class="dropdown-item edit_btn"
                              ><i class="fa fa-pen-to-square m-r-5"></i>
                              Edit</span
                            >
                            <span
                              class="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                            >
                              <i class="fa fa-trash-alt m-r-5"></i> Delete</span
                            >
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- fixed -->

      <!-- sample modal content model1 -->

      <div
        id="con-close-modal"
        class="modal modal-lg mm1 animate__animated animate__zoomIn animate__faster"
        style="margin-top:100px;margin-left:100px;margin-right"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">New Ambulance Call</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Case No<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Patient Name <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block select-gender">
                      <label class="gen-label"
                        >Gender<span class="login-danger">*</span></label
                      >
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="male"
                            class="form-check-input"
                          />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="Female"
                            class="form-check-input"
                          />Female
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Date<span class="login-danger">*</span></label>
                      <input class="form-control" type="Date" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Vehichle No<span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Diver's Name <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Diver's No <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-forms">
                      <label
                        >Patient Address
                        <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top"
                        >Avatar <span class="login-danger">*</span></label
                      >
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button
                        type="submit"
                        class="btn btn-primary submit-form me-2"
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal -->

      <!-- sample modal content model2 -->

      <div
        id="con-close-modal"
        class="modal fade mm2"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Birth Record</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Case No<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Patient Name <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block select-gender">
                      <label class="gen-label"
                        >Gender<span class="login-danger">*</span></label
                      >
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="male"
                            class="form-check-input"
                          />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="Female"
                            class="form-check-input"
                          />Female
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Date<span class="login-danger">*</span></label>
                      <input class="form-control" type="Date" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Vehichle No<span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Diver's Name <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label
                        >Diver's No <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-forms">
                      <label
                        >Patient Address
                        <span class="login-danger">*</span></label
                      >
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top"
                        >Avatar <span class="login-danger">*</span></label
                      >
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button
                        type="submit"
                        class="btn btn-primary submit-form me-2"
                      >
                        Submit
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import $ from "jquery";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {};
  },

  methods: {},

  mounted() {
    $(".add_room").click(function () {
      $(".mm1").modal("show");
    });
    $(".edit_btn").click(function () {
      $(".mm2").modal("show");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */
</style>
