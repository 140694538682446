<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Appointment </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Pending Appointment List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Appointment</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"
                                @click="search_the_list()"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>

                <!-- /Table Header -->

                <div class="table-responsiv" style="overflow: visible">
                  <a-table :dataSource="main_data" :columns="columns" :loading="isLoading">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key == 'name'">
                        <a href="#"><img width="28" height="28" src="../../assets/assets/img/profiles/avatar-01.jpg"
                            class="rounded-circle m-r-5" alt="" />
                          {{ record.full_name }}</a>
                      </template>
                      <template v-if="column.key == 'type'">
                        <button class="custom-badge badge-primary" v-if="record.type">
                          {{ record.type }}
                        </button>
                      </template>
                      <template v-if="column.key == 'date'">

                        {{ formattedDate(record.date) }}

                      </template>

                      <template v-if="column.key == 'action'">
                        <div class="dropdown dropdown-action">
                          <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <span class="dropdown-item" title="Accept appointment"
                              @click="acceptPatient(record.id, record.type)"><i
                                class="fa fa-check m-r-5 text-success"></i>
                              Accept Patient</span>
                            <span v-if="record.type == 'Direct'" class="dropdown-item" title="Reject appointment"
                              @click="showmodal(record.id, record.type)"><i class="fa fa-x m-r-5 text-danger"></i>
                              Reject
                              Patient</span>
                            <!-- <span class="dropdown-item" title="Transfer appointment"><i class="fa fa-exchange m-r-5 text-primary"></i> Transfer Patient</span> -->
                          </div>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- sample modal content ;overflow-y:scroll-->

  <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster reject_modal"
    style="margin-top:160px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Reject patient</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-4">
          <!-- body starts here -->
          <form>
            <div class="row">
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>Reason for Reject
                    <span class="login-danger">*</span></label>
                  <textarea class="form-control" rows="3" cols="30" v-model="reject"></textarea>
                </div>
              </div>

              <div class="col-12">
                <div class="doctor-submit text-end">
                  <span type="submit" class="btn btn-primary submit-form me-2" @click="rejectPatient()">
                    Submit
                  </span>
                  <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                    @click="hidemodal()">
                    Cancel
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- body ends here -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";

const columns = [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Patient Type",
    key: "type",
  },
  {
    title: "Appointment Type",
    dataIndex: "appointment_type",
  },
  {
    title: "Mobile",
    dataIndex: "phone_number",
  },
  {
    title: "From",
    dataIndex: "doctor",
  },
  // {
  //   title: "Time",
  //   dataIndex: "time",
  // },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      reponse: "",
      main_data: [],
      no_of_appointments: 0,
      apt_id: 0,
      reject: "",
      type: "",
      Ttype: "",
      role: localStorage.getItem("role"),
      search: "",
      main_data_reserve: [],
      columns,
    };
  },
  methods: {
    showmodal: function (id, type) {
      $("#con-close-modal").modal("show");
      this.apt_id = id;
      this.Ttype = type;
    },
    hidemodal: function (id) {
      $("#con-close-modal").modal("hide");
    },
    ...mapActions({
      _getDoctorPendigAppontmentList: "doctor/getDoctorPendigAppontmentList",
      _acceptPatient: "doctor/acceptPatient",
      _rejectPatient: "doctor/rejectPatient",
    }),
    getDoctorPendigAppontmentList() {
      this._getDoctorPendigAppontmentList()
        .then((response) => {
          this.response = response.data;
          //   this.response
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            this.main_data_reserve = this.main_data;
            // this.main_data = this.response.data.slice(0, 23);
            this.no_of_appointments = this.response.data.length;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    acceptPatient(id, type) {
      var type = type.toLowerCase();
      $(".inloader").show();
      this._acceptPatient({
        id: id,
        payload: { type: type },
      })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.getDoctorPendigAppontmentList();
            $(".inloader").hide();
            this.$toast.success("Appointment Accepted");
          }
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    rejectPatient() {
      $("#con-close-modal").modal("hide");
      $(".inloader").show();
      this._rejectPatient({
        id: this.apt_id,
        payload: {
          reason: this.reject,
          type: this.Ttype.toLowerCase(),
        },
      })
        .then((response) => {
          this.response = response.data;
          this.reject = "";

          if (this.response["status"] == true) {
            this.getDoctorPendigAppontmentList();
            $(".inloader").hide();
            this.$toast.success("Appointment Rejected");
          }
        })
        .catch((error) => {
          console.log(error);
          $(".inloader").hide();
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var full_name = this.main_data[i].full_name.toLowerCase();
          var type = this.main_data[i].type.toLowerCase();
          var patient_type = this.main_data[i].patient_type.toLowerCase();
          var appointment_type =
            this.main_data[i].appointment_type.toLowerCase();
          var date = this.main_data[i].date;
          // var doctor = this.main_data[i].doctor.toLowerCase();
          if (
            full_name.includes(searchLetter) ||
            type.includes(searchLetter) ||
            patient_type.includes(searchLetter) ||
            appointment_type.includes(searchLetter) ||
            date.includes(searchLetter)
            // ||doctor.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
    formattedDate(created_at) {
      const dateObject = new Date(created_at);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    this.getDoctorPendigAppontmentList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
