<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />


  <div class="page-wrapper">
    <div class="content">

      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="voice-call.html">App </a></li>
              <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
              <li class="breadcrumb-item active">Video Call</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-xl-4 d-flex">
          <div class="card chat-box">
            <div class="chat-widgets">
              <div class="call-all comman-space-flex">
                <h4>Participants<span>32</span></h4>
                <h5><a href="javascript:;">Show All</a></h5>
              </div>
              <div class="chat-user-group video-call-blk d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Me</h5>
                  </div>
                  <div class="chat-user-icon">
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/video-icon-01.svg" class="me-2"
                        alt="img"></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/video-icon-02.svg" alt="img"></a>
                  </div>
                </div>
              </div>
              <div class="chat-user-group video-call-blk d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-02.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Laura Williams</h5>
                  </div>
                  <div class="chat-user-icon">
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/video-icon-01.svg" class="me-2"
                        alt="img"></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/video-icon-02.svg" alt="img"></a>
                  </div>
                </div>
              </div>
              <div class="chat-user-group video-call-blk d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-03.jpg" alt="img"></a>
                </div>
                <div class="chat-users chat-user-blk">
                  <div class="user-titles ">
                    <h5> Smith Bruklin</h5>
                  </div>
                  <div class="chat-user-icon">
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/video-icon-01.svg" class="me-2"
                        alt="img"></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/video-icon-02.svg" alt="img"></a>
                  </div>
                </div>
              </div>
              <div class="call-all comman-space-flex">
                <h4>Chats<span>32</span></h4>
                <h5><a href="javascript:;">Show All</a></h5>
              </div>
              <div class="video-chat-show">
                <ul>
                  <li class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="User Image"
                        class="avatar-img rounded-circle">
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <h5>Galaviz Lalema</h5>
                          <p>Hi Guys, How are you?</p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img src="../../assets/assets/img/profiles/avatar-02.jpg" alt="User Image"
                        class="avatar-img rounded-circle">
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <h5>Williams Bruk</h5>
                          <p>non tellus dignissim </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img src="../../assets/assets/img/profiles/avatar-03.jpg" alt="User Image"
                        class="avatar-img rounded-circle">
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <h5>Laura Williams</h5>
                          <p>Vivamus sed dictum dictum ligula, cursus blandit risus</p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img src="../../assets/assets/img/profiles/avatar-04.jpg" alt="User Image"
                        class="avatar-img rounded-circle">
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <h5>Galaviz Lalema</h5>
                          <p>Lorem ipsum dolor sit amet aliquam ut a ex aliquam ut a ex</p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img src="../../assets/assets/img/profiles/avatar-05.jpg" alt="User Image"
                        class="avatar-img rounded-circle">
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <h5>Smith Bruklin</h5>
                          <p>Vivamus sed dictum dictum ligula, cursus blandit risus
                            Lorem ipsum dolor sit amet aliquam ut a ex aliquam ut a ex</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="card chat-box ">
            <div class=" chat-search-group ">
              <div class="chat-user-group clinic-user mb-0 d-flex align-items-center">
                <div class="img-users call-user">
                  <a href="profile.html"><img src="../../assets/assets/img/profiles/avatar-01.jpg" alt="img"></a>
                  <span class="active-users bg-info"></span>
                </div>
                <div class="chat-users">
                  <div class="user-titles">
                    <h5> Forest Kroch</h5>
                  </div>
                  <div class="user-text">
                    <p>Lorem ipsum dolor sit amet...</p>
                  </div>
                </div>
                <ul class="call-list-add">
                  <li><a href="javascript:;"><img src="../../assets/assets/img/icons/profile-user.svg" alt="img">32</a>
                  </li>
                  <li><a href="javascript:;"><img src="../../assets/assets/img/icons/plus.svg" alt="img"></a></li>
                </ul>
              </div>
              <div class="chat-search-list">
                <ul>
                  <li @click="startCall()"><img src="../../assets/assets/img/icons/chat-icon-01.svg" alt="img">
                  </li>
                  <li><img src="../../assets/assets/img/icons/chat-icon-02.svg" alt="img">
                  </li>
                  <li><a href="javascript:;"><img src="../../assets/assets/img/icons/chat-icon-03.svg" alt="img"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Chat -->
          <div class="card chat-box ">

            <!-- Join Call -->
            <div class="page-content">
              <div class="meeting">
                <div class="meeting-wrapper">
                  <div class="meeting-list">

                    <!-- Horizontal View -->
                    <div class="join-contents horizontal-view fade-whiteboard">
                      <div class="join-video user-active">
                        <video ref="remoteVideo" autoplay=""></video>
                        <!-- <img src="../../assets/assets/img/call.jpg" class="img-fluid call-imgs" alt="Logo"> -->
                        <div class="video-avatar">
                          <div class="text-avatar">
                            <div class="text-box">
                              S
                            </div>
                          </div>
                        </div>
                        <div class="part-name">
                          <h4>
                            <img src="../../assets/assets/img/icons/video-icon-03.svg" class="me-2" alt="img">Laura
                            Strattenberg
                          </h4>
                        </div>
                        <div class="more-icon">
                          <a href="#" class="handraise-on me-2">
                            <i class="fas fa-thumbtack"></i>
                          </a>
                          <a href="#" class="mic-off">
                            <i class="fe fe-mic-off"></i>
                          </a>
                        </div>
                      </div>
                      <div class="join-video single-user">
                        <!-- <img src="../../assets/assets/img/call-01.jpg" class="img-fluid call-imgs" alt="Logo"> -->
                        <video ref="localVideo" autoplay="" muted=""></video>
                        <div class="part-name">
                          <h4><img src="../../assets/assets/img/icons/video-icon-03.svg" class="me-2" alt="img">You({{
                            connectionId }})</h4>
                        </div>
                        <div class="more-icon">
                          <a href="#">
                            <i class="fe fe-mic-off"></i>
                          </a>
                        </div>
                      </div>
                      <div class="join-video single-user">
                        <img src="../../assets/assets/img/call-02.jpg" class="img-fluid call-imgs" alt="Logo">
                        <div class="part-name">
                          <h4><img src="../../assets/assets/img/icons/video-icon-03.svg" class="me-2" alt="img">Galaviz
                            Lalema</h4>
                        </div>
                        <div class="more-icon">
                          <a href="#">
                            <i class="fe fe-mic-off"></i>
                          </a>
                        </div>
                      </div>
                      <div class="join-video single-user">
                        <img src="../../assets/assets/img/call-03.jpg" class="img-fluid call-imgs" alt="Logo">
                        <div class="part-name">
                          <h4><img src="../../assets/assets/img/icons/video-icon-03.svg" class="me-2" alt="img">Smith
                            Bruklin</h4>
                        </div>
                        <div class="more-icon">
                          <a href="#">
                            <i class="fe fe-mic-off"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <!-- /Horizontal View -->

                  </div>
                  <!-- Meet Footer -->
                  <div class="meet-footer">
                    <div class="meet-icons">
                      <div class="met-icons">
                        <ul class="meet-items">
                          <li class="meet-item">
                            <a href="javascript:;" class="mute-bt">
                              <img src="../../assets/assets/img/icons/call-icon-03.svg" alt="img">
                            </a>
                          </li>
                          <li class="meet-item">
                            <a href="javascript:;" class="mute-video">
                              <i class="fe fe-video"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="end-call-chat">
                        <a href="javascript:;" class="btn btn-primary">End Call</a>
                      </div>
                      <div class="end-calls">
                        <ul class="meet-items">
                          <li class="meet-item">
                            <a href="javascript:;" class="hand-raise">
                              <img src="../../assets/assets/img/icons/call-icon-01.svg" alt="img">
                            </a>
                          </li>
                          <li class="meet-item">
                            <a @click="answerCall()" href="javascript:;"
                              :class="`showInviteList ${hasACall && 'vibrate'}`">
                              <img src="../../assets/assets/img/icons/call-incoming.svg" alt="img">
                              <!-- <img src="../../assets/assets/img/icons/call-icon-02.svg" alt="img"> -->
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- /Meet Footer -->
                </div>
              </div>
            </div>
            <!-- /Join Call -->

          </div>
          <!-- /Chat -->
        </div>
      </div>

    </div>
  </div>

</template>
<script setup>
import { onMounted } from "vue";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { ref } from "vue";
import { videoCall } from "@/assets/assets/plugins/video-call/sdk";


const connectionId = ref();
const localVideo = ref(null);
const remoteVideo = ref(null);


const videoCallInstance = ref();
const hasACall = ref(false)
const caller = ref({});

function startCall() {
  videoCallInstance.value.callUser(prompt("Enter the User Id:"));
}
function answerCall() {
  videoCallInstance.value.acceptCall();
  hasACall.value = false;
}
onMounted(() => {

  videoCallInstance.value = videoCall({
    isDev: false,
    audioOnly: false,
    appId: "1234AB",
    localVideo: localVideo.value,
    remoteVideo: remoteVideo.value,
    events: {
      onConnect: ({ _connectionId }) => {
        connectionId.value = _connectionId;
      },
      onCallReceived: ({ name, id }) => {
        caller.value = { name, id };
        hasACall.value = true;
      },
      onCallAccepted: ({ name, id }) => {
        hasACall.value = true;
        console.log(`Call Accepted ${name},id: ${id}`);
      }
    },
  })
  videoCallInstance.value.init()
  videoCallInstance.value.startBroadcasting()
})

</script>

<style>
video {
  width: 100%;
  object-fit: fill;
  user-select: none;
}

.vibrate {
  animation: vibrate 0.5s infinite;
}

@keyframes vibrate {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, -2px);
  }

  80% {
    transform: translate(-2px, 2px);
  }

  100% {
    transform: translate(0, 0);
  }
}
</style>