<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">Radiology</li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Radiology History List</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Radiology History</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2" hidden="">
                            <img src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header table-bordered-->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="main_data" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'sample_number'">
                      <div class="tooltip-container">
                        <span @click="view_patient_details(record)" class="tb_name">{{ record.sample_number }}</span>

                        <div>
                          <span class="tooltip-text">
                            <span>Patient Name - {{ record.patient_name }}</span>
                            <span>Moblie Number -
                              {{ record.mobile_number }}</span>

                            <span>Patient Age - {{ record.patient_age }}</span>
                            <span>Patient Gender - {{ record.patient_sex }}</span>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-if="column.key == 'priority'">
                      <span class="badge badge-danger w-100" v-if="record?.priority == 'High'">{{ record?.priority
                        }}</span>
                      <span class="badge badge-warning w-100" v-if="record?.priority == 'Moderate'">{{ record?.priority
                        }}</span>
                      <span class="badge badge-primary w-100" v-if="record?.priority == 'Low'">{{ record?.priority
                        }}</span>
                      <span class="badge badge-dark w-100" v-if="record?.priority == 'QS'">Quick Service</span>

                    </template>
                    <template v-if="column.key == 'cost'">
                      <span> ₦ {{ formatNumberWithCommas(record.cost) }} </span>
                    </template>
                    <template v-if="column.key == 'date'">
                      <span> {{ formatDate(record.created_at, true) }} </span>
                    </template>
                    <template v-if="column.key == 'result'">
                      <span class="btn btn-primary" @click="view_result(record)">View result
                      </span>
                    </template>
                    <template v-if="column.key == 'status'">
                      <span class="badge badge-success" v-if="record?.payment_status == 'Paid'">{{ record.payment_status
                        }}</span>
                      <span class="badge badge-danger" v-if="record?.payment_status == 'Unpaid'">{{
                        record.payment_status }}</span>
                    </template>
                    <template v-if="column.key == 'action'">
                      <div class="d-flex align-items-center gap-2">
                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center" title="Print Report"
                          @click="downloadRadiologyReport(record.id)" :disabled="!record.note">
                          <i class="fa fa-print m-r-5"></i>Print</button>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal modal-lg animate__animated animate__zoomIn animate__faster result_modal" style="" id="save_modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Radiologist note</h3>
            <!-- <p>You either transfer to a docotor or department</p> -->
          </div>
          <pre v-html="result_note"></pre>
        </div>
      </div>
    </div>
  </div>
  <PatientDetails :patient_details_data_recieved="send_record" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";
import { formatDate } from "@/utils/formatter";
import { patientNoteTargets } from "@/utils/constants";
import PatientDetails from "./plugin/patient_details.vue";

const columns = [

  {
    title: "Sample Number",
    dataIndex: "sample_number",
    key: "sample_number",
  },
  {
    title: "Test",
    dataIndex: "test",
    key: "test",
  },
  {
    title: "Department",
    dataIndex: "radiology",
    key: "department",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Handler",
    dataIndex: "handler",
    key: "handler",
  },
  {
    title: "Result",
    dataIndex: "result",
    key: "result",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "date",
  },
  {
    title: "Action",
    key: "action",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    PatientDetails


  },

  data() {
    return {
      isLoading: true,
      reponse: "",
      main_data: [],
      main_data1: [],
      apt_id: 0,
      reason: "",
      main_data_reserve: [],
      columns,
      formatDate,
      send_record: {},
      result_note: null
    };
  },
  methods: {
    ...mapActions({
      _get_radiology_requests: "radiology/get_radiology_requests",
      _patientBoidata: "patient/patientBoidata",
      _completed: "radiology/completed",
      _who_viewed_report: "lab/who_viewed_report",

    }),
    view_patient_details(record) {
      this.send_record = record;
      $(".patient_details-modal").modal("show");
    },
    downloadRadiologyReport(radiologyTestId) {
      this.$store
        .dispatch("general/downloadReport", { targetId: radiologyTestId, target: patientNoteTargets.radiology });
    },
    get_radiology_requests() {
      this._get_radiology_requests({ type: "history" })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data.map((data) => ({
              ...data,
              patient_sex: data?.patient?.sex,
              patient_name: data?.patient?.name,
              patient_age: `${data?.patient?.age} Yrs.`,
              mobile_number: data?.patient?.mobile_number,
            }));
            this.main_data_reserve = this.main_data;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },

    completed(id) {
      // alert(id)
      this._completed({
        id: id,
        payload: { analyze: "sample" },
      })
        .then((response) => {
          // this.response = response.data;
          if (this.response["status"] == true) {
            // this.response = this.response.data
            // console.log(this.response)
            this.$toast.success(response.data.message);
            this.get_radiology_requests();
          }
        })
        .catch((error) => {
          console.log(error);
          // console.log('Error fetching data', error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },

    report(id) {
      localStorage.setItem("radiology_id", id);
      localStorage.setItem("k_value", 0);
      window.location = "Add-Radiology-Note";
    },

    edit_text(text) {
      const formatted_text =
        text.charAt(0).toUpperCase() +
        text.slice(1).replace("_", " ").replace("p", "P");
      return formatted_text;
    },
    edit_text2(text) {
      const formatted_text = text.replace("-", " ");
      return formatted_text;
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var test = this.main_data[i].test.toLowerCase();
          var lab = this.main_data[i].category.toLowerCase();
          var priority = this.main_data[i].priority.toLowerCase();

          if (
            test.includes(searchLetter) ||
            lab.includes(searchLetter) ||
            priority.includes(searchLetter)
          ) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
    view_result(n) {
      $(".result_modal").modal("show");
      if (n != null) {
        this.result_note = n.note;
        this.who_viewed_report(n.id)
      }
    },

    who_viewed_report(id) {
      this._who_viewed_report({ target: "radiology", target_id: id })
        .then((response) => {
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);

        });
    },

  },
  mounted() {
    this.get_radiology_requests();
    // this.cat();

    $(".cancel_transfer").click(function () {
      $(".invite_modal").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 70%;
}

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

.invite_row {
  padding-left: 3%;
}

.selectbtn {
  width: 200px;
  background-color: #00d3c7;
}
</style>
