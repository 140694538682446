<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="Subscription">Subscriptions </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Custom Plan</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add plan - {{ name }}</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Name
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="name" placeholder="" maxlength="17" />
                    </div>
                  </div>

                  <div class="col-12 col-md-4 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Price (₦) <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="price" maxlength="15" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-4 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Duration <span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="type" title="Select Type">
                        <a-select-option disabled>Select type</a-select-option>
                        <a-select-option  value="Monthly">Monthly</a-select-option>
                        <a-select-option  value="Yearly">Yearly</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Discount (%)<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="discount" maxlength="15" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Apply To<span class="login-danger">*</span></label>
                      <a-select class="w-100" v-model:value="hospital_id" showSearch
                        :filterOption="(input, option) => filterObject(option, input, 'key')">
                        <a-select-option disabled value="Select a hospital">
                          Select a hospital
                        </a-select-option>
                        <a-select-option v-for="hospital in hospital_list" :key="hospital.name"
                          :value="hospital.institution_id">
                          {{ hospital.name }}
                        </a-select-option>

                        <a-select-option v-if="hospital_list.length == 0">
                          No hospital yet
                        </a-select-option>
                      </a-select>
                    </div>


                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Custom Plan Features</h4>
                      <div class="addbtn">
                        <div class="add-group" @click="addMonthlySubcriptions()">
                          <span class="btn btn-primary fa fa-plus ms-1" style="font-size: 18px">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <label>Features
                    <span class="login-danger">*</span></label> -->
                  <div class="col-12 col-md-12 col-xl-12" v-for="subscription in subscriptions_monthly"
                    :key="subscription">
                    <div class="feature_block_cont">
                      <div class="input-block local-forms">
                        <input class="form-control" type="text" v-model="subscription.name" placeholder=""
                          maxlength="17" />
                      </div>

                    </div>

                    <div class="remove_cont" v-if="subscriptions_monthly.length > 1">
                      <div class="add-group" @click="removeMonthlySubcriptions(subscription.id)">
                        <span class="btn btn-primary fa fa-minus ms-1" style="font-size: 18px">
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- yearly sub -->
        <div class="col-sm-6" hidden>
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Yearly Plan</h4>
                      <div class="addbtn">
                        <div class="add-group" @click="addYearlySubcriptions()">
                          <span class="btn btn-primary fa fa-plus ms-1" style="font-size: 18px">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label>Features
                    <span class="login-danger">*</span></label>
                  <div class="col-12 col-md-12 col-xl-12" v-for="subscription in subscriptions_yearly"
                    :key="subscription">
                    <div class="feature_block_cont">
                      <div class="input-block local-forms">
                        <input class="form-control" type="text" v-model="subscription.name" placeholder=""
                          maxlength="17" />
                      </div>

                    </div>

                    <div class="remove_cont" v-if="subscriptions_yearly.length > 1">
                      <div class="add-group" @click="removeYearlySubcriptions(subscription.id)">
                        <span class="btn btn-primary fa fa-minus ms-1" style="font-size: 18px">
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        <br>
        <div class="col-12" style="text-align: center;">
          <div class="doctor-submit">
            <span @click="Add_Custom_Subscription()" class="btn btn-primary submit-form me-2">Submit</span>
            <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
              @click="$router.go(-1)">
              Cancel
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { filterObject } from "@/utils/helpers";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },

  data() {
    return {
      filterObject,
      name: null,
      price: null,
      discount: null,
      hospital_list: [],
      hospital_id: null,
      type:null,
      subscriptions_monthly: [
        {
          id: null,
          name: null,

        },
        {
          id: null,
          name: null,

        },
      ],
      // subscriptions_yearly: [
      //   {
      //     id: null,
      //     name: null,

      //   },
      // ],
    };
  },

  methods: {
    ...mapActions({
      _Add_Custom_Subscription: "general/Add_Custom_Subscription",
      _get_hospital: "general/get_hospitalList",

    }),
    stringToArray(str) {
      return str.split(",");
    },

    Add_Custom_Subscription() {

      if (this.name == null && this.discount == null && this.price == null && this.subscriptions_monthly.length > 0) {
        this.$toast.error('Fill all the input fields to proceed.');

      }
      else {
        $(".inloader").show();
        // alert(this.dob)
        const features = this.subscriptions_monthly.map(subscription => subscription.name);
        console.log(features)

        this._Add_Custom_Subscription({
          plan_name: this.name,
          price: this.price,
          institution_id: this.hospital_id,
          features: features,
          discount: this.discount,
          type:this.type
        })
          .then((response) => {
            this.response = response.data;
            $(".inloader").hide();
            this.$router.push({ name: "Subscriber-List" });
            this.$toast.success(this.response["message"]);
          })
          .catch((error) => {
            $(".inloader").hide();
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422) {
                this.$toast.error(error.response.data.message);
              }

            }
          });
      }
    },
    gethospital() {
      this._get_hospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.hospital_list = this.response.data;

          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    removeMonthlySubcriptions(id) {
      const index = this.subscriptions_monthly.findIndex(
        (subcriptions) => subcriptions.id === id
      );
      if (index !== -1) {
        this.subscriptions_monthly.splice(index, 1);
      } else {
        console.log(`Subcriptions with id ${id} not found.`);
      }
    },
    addMonthlySubcriptions() {
      if (this.subscriptions_monthly.length < 7) {
        this.increaser++;
        this.subscriptions_monthly.push({
          id: null,
          name: null,

        },);
      }
      else {
        this.$toast.error('You can`t add more features.');

      }
    },
    removeYearlySubcriptions(id) {
      const index = this.subscriptions_yearly.findIndex(
        (subcriptions) => subcriptions.id === id
      );
      if (index !== -1) {
        this.subscriptions_yearly.splice(index, 1);
      } else {
        console.log(`Subcriptions with id ${id} not found.`);
      }
    },
    addYearlySubcriptions() {
      if (this.subscriptions_yearly.length < 7) {
        this.increaser++;
        this.subscriptions_yearly.push({
          id: null,
          name: null,

        },);
      }
      else {
        this.$toast.error('You can`t add more features.');

      }
    },

  },

  mounted() {
    this.gethospital();

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feature_block_cont {
  width: 93%;
  margin-right: 2%;
  display: inline-block;
}

.remove_cont {
  width: 5%;
  display: inline-block;
}

.addbtn {
  position: absolute;
  right: 1%;
  top: 10px;
  z-index: 999;
}
</style>
