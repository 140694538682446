<template>
  <!-- make_a_request -->

  <!-- third modal for confirmation -->

  <div
    class="modal animate__animated animate__zoomIn animate__faster lab_request lab_request"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Laboratory Request</h3>
            <p>Request a Laboratory Result</p>
          </div>

          <div class="row request_row">
            <div class="col-3 ib">

              <a-tree-select
                tree-node-filter-prop="label"
                showSearch
                v-model:value="institution_id"
                tree-data-simple-mode
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="laboratories"
                placeholder="Please select"
                @change="load_needed_data(institution_id)"
              >
                <template #title="{ value: val, label, title }">
                  <b v-if="val === 'external'" class="dropdown-title">{{ title }}</b>
                  <template v-else-if="val == 'loading'">
                    <a-spin size="small" />
                  </template>
                  <template v-else>{{ title || label }}</template>
                </template>
              </a-tree-select>
            </div>
            <div class="col-3 ib">
              <a-select
                class="w-100 selectba"
                v-model:value="rdata_holder_id"
                @change="testtype_get_dept_and_specialist()"
              >
                <a-select-option disabled>Select Test Type</a-select-option>
                <a-select-option v-for="(rdata_holder, index) in test_types" v-bind:key="index"
                  :value="rdata_holder.id">{{
                    rdata_holder.test }}</a-select-option>

                <a-select-option disabled value="loading" v-if="test_types.length == 0">
                  <a-spin size="small"/>
                </a-select-option>
              </a-select>
            </div>
            <!-- v-if="institution_choice == 'internal'" -->
            <div class="col-2 ib">
              <a-select class="w-100 selectba" v-model:value="dept_name">
                <a-select-option disabled value="Department"
                  >Department</a-select-option
                >
              </a-select>
            </div>

            <div class="col-2 ib">
              <!-- @change="specialistname()" -->
              <a-select class="w-100 selectba lab_sceintist_btn" v-model:value="lab_tech_id"
                :disabled="disable_input ? true : false">
                <a-select-option disabled>Select Scientist</a-select-option>
                <a-select-option v-for="(data, index) in lab_techs" :key="index" :value="data.id">{{
                  capitalize(data.first_name) +
                  " " +
                  capitalize(data.last_name)
                }}</a-select-option>
              </a-select>
            </div>

            <div class="col-2 ib">
              <a-select
                class="w-100"
                v-model:value="priority"
                @change="Change_priority(priority)"
              >
                <a-select-option disabled>Choose Priority</a-select-option>
                <a-select-option value="High">High</a-select-option>
                <a-select-option value="Moderate">Moderate</a-select-option>
                <a-select-option value="Low">Low</a-select-option>
              </a-select>
            </div>
            <div class="col-12 col-sm-12">
              <div class="input-block local-forms">
                <label>Description </label>
                <textarea class="form-control" rows="3" cols="30" v-model="description">
      Why are you requesting these tests and how critical is it?</textarea>
              </div>
            </div>
          </div>

          <div class="modal-btn delete-action">
            <div class="row" id="cancproceed">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-continue-btn subbtn btn-primary"
                  v-if="institution_choice == 'external'"
                  id="subbtn"
                  @click="make_external_request()"
                >
                  Proceed
                </button>
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-continue-btn subbtn btn-primary"
                  v-else
                  id="subbtn"
                  @click="make_request()"
                >
                  Proceed
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn paid-cancel-btn cancel cancel_request bg-danger text-light"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- last modal for comfirmation  -->

  <div
    class="modal modal-lg animate__animated animate__zoomIn animate__faster confirm_tech_assign"
    style="margin-top:0px;margin-left:100px;margin-right"
    id="save_modal"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Assign to a laboratory scientist</h3>
            <p>Do You want to assign to a specific laboratory scientist?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <button
                  type="reset"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-continue-btn"
                  @click="activate_tech_list()"
                >
                  Yes
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  class="w-100 btn btn-primary paid-cancel-btn cancel bg-danger text-light"
                  @click="deactivate_tech_list()"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},

  data() {
    return {
      reponse: "",
      dept_name: "Department",
      lab_tech_id: "Choose a Lab Scientist",
      test_type: "",
      test_types: [],
      lab_techs: "",
      lab_techs_holder: '',
      lab_techs_holder_reserve:'',
      filtered_lab_techs: "",
      result_id: localStorage.getItem("result_id"),
      institution_choice: 'internal',
      lab_sci_id: null,
      test_type_id: null,
      lab_tech_id: null,
      description: "",
      priority: "Low",
      appointmentId: localStorage.getItem("apt_id"),
      rdata_holder_id: null,
      institution_list: null,
      institution_id: "Internal laboratory",
      id: localStorage.getItem("this_patient_id"),
      test_types_reserve: null,
      laboratories: [
        {
          key: "internal",
          id: 1,
          value: "Internal laboratory",
          title: `Internal laboratory - ${localStorage.getItem("hospital")}`,
          isLeaf: true,
        },
        {
          id: 2, disabled: true, value: 'external', title: 'External Laboratories', children: [
            {
              value: 'loading',
              disabled: true,
              isLeaf: true
            }
          ],
        },
      ],
      disable_input: false
    };
  },
  props: {},

  methods: {
    load_needed_data(id) {
      if (id.toString().toLocaleLowerCase() == "internal laboratory") {
        this.test_types = this.test_types_reserve
        this.institution_choice = 'internal'
        this.filtered_lab_techs = this.lab_techs_holder_reserve 
      } else {
        this.get_test_type(id)
        this.get_lab_techs(id)
        this.institution_choice = 'external'
        this.lab_techs = []
        this.lab_tech_id = null
        this.rdata_holder_id = null
        this.dept_name = null
      }
    },
    ...mapActions({
      _get_test_type: "lab/getTestType",
      _get_lab_techs: "lab/get_lab_techs",
      _make_request: "lab/make_request",
      _get_hospital: "general/get_hospitalList",
      _make_external_request: "lab/make_external_request",
      // external apis
    }),

    // specialistname(name1, name2, L_id) {
    //   this.lab_tech_id =
    //     this.capitalize(name1) + " " + this.capitalize(name2);
    //   this.lab_tech_id = L_id;
    // },

    // specialistname() {
    //   const lablist = this.filtered_lab_techs.find(test => test.id == this.lab_tech_id)
    //   this.lab_tech_id =
    //     this.capitalize(lablist.first_name) + " " + this.capitalize(lablist.last_name);
    //   this.lab_tech_id = lablist.id;
    // },

    Change_priority(priority) {
      this.priority = priority;
    },

    // testtype_get_dept_and_specialist(L_id, D_name, T_name, T_id) {
    //   this.dept_name = D_name;
    //   this.lab_sci_id = L_id;
    //   this.test_type = T_name;
    //   this.test_type_id = T_id;

    //   this.filtered_lab_techs = this.lab_techs.filter(
    //     (lab_tech) => lab_tech.lab_category_id == this.lab_sci_id
    //   );

    //   console.log(this.filtered_lab_techs);
    //   $(".confirm_tech_assign").modal("show");
    //   $(".lab_request").modal("hide");
    // },
    testtype_get_dept_and_specialist() {
      const rdata_holder = this.test_types.find(
        (test) => test.id == this.rdata_holder_id
      );
      this.dept_name = rdata_holder.lab_category;
      this.lab_sci_id = rdata_holder.lab_category_id;
      this.test_type = rdata_holder.test;
      this.test_type_id = rdata_holder.id;

      this.filtered_lab_techs = this.lab_techs_holder.filter(
        (lab_tech) => lab_tech.lab_category_id == this.lab_sci_id
      );
      this.lab_techs = this.filtered_lab_techs
      // if(this.institution_choice == 'internal'){
      $(".confirm_tech_assign").modal("show");
      $(".lab_request").modal("hide");
      // }
    },

    get_test_type(institution_id) {
      this._get_test_type({ institution_id: institution_id })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.test_types = this.response.data;
            this.test_types = this.test_types;

            if (!institution_id) {
              this.test_types_reserve = this.test_types;
            }
            console.log(this.test_types);
          }
        })
        .catch((error) => {
          console.log("Error fetching data", error);
        });
    },

    get_lab_techs(institution_id) {
      this._get_lab_techs({ institution_id: institution_id })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.lab_techs_holder = this.response.data;
            this.lab_techs_holder = this.lab_techs_holder;
            console.log(this.lab_techs_holder);

            if (!institution_id) {
              this.lab_techs_holder_reserve = this.lab_techs_holder;
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching data", error);
        });
    },
    make_request() {
      // alert(this.result_id)
      $(".inloader").show();

      this._make_request({
        id: this.appointmentId,
        payload: {
          patient_result_id: this.result_id,
          lab_test_id: this.test_type_id,
          priority: this.priority,
          lab_category_id: this.lab_sci_id,
          labTech_id: this.lab_tech_id,
          description: this.description,
        },
      })
        .then((response) => {
          $(".inloader").hide();
          location.reload();

          this.test_types = "";
          this.lab_tech_id = "";
          this.description = "";
          this.priority = "";
          this.dept_name = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(response.data.message);
            // this.get_test_type();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },

    //copied from  to power doctor collaboration
    activate_tech_list() {
      this.disable_input = false
      $(".lab_request").modal("show");
    },

    deactivate_tech_list() {
      this.disable_input = true
      $(".lab_request").modal("show");
      this.lab_techs = []
      this.lab_tech_id = null
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    gethospital() {
      this._get_hospital()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.institution_list = this.response.data;
            this.institution_list = this.institution_list.filter(
              (institution) => institution.role_name == "Laboratory"
            );

            const formattedLaboratories = response.data.data
              .filter((institution) => institution.role_name == "Laboratory")
              .map((lab, index) => ({
                value: lab.institution_id,
                label: lab.name,
                isLeaf: true,
                key: index + 2,
              }));
            if (formattedLaboratories.length == 0) {
              this.laboratories[1].children = [
                {
                  value: "value",
                  label: "No Data Available",
                  isLeaf: true,
                  disabled: true,
                  key: "no-data-available",
                },
              ];
            } else {
              this.laboratories[1].children = formattedLaboratories;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    make_external_request() {
      $(".inloader").show();

      this._make_external_request({
        institution_id: this.institution_id,
        patient_id: this.id,
        lab_test_id: this.test_type_id,
        priority: this.priority,
        description: this.description,
      })
        .then((response) => {
          $(".inloader").hide();
          location.reload();
          this.test_types = "";
          this.description = "";
          this.priority = "";
          this.institution_id = "";
          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(response.data.message);
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }

          $(".inloader").hide();
        });
    },
  },
  watch: {
    // institution_choice: {
    //     handler() {
    //       if(this.institution_choice == 'external'){
    //         this.test_types=[]
    //         this.rdata_holder_id=null
    //       }
    //       else{
    //         this.test_types=this.test_types_reserve
    //       }
    //     },
    //     immediate: true,
    //   },
  },
  mounted() {
    // $('.test_type_btn').click(function(){})
    // if(this.institution_choice == 'internal'){
    this.get_test_type();
    this.get_lab_techs();
    // }
    this.gethospital();
    // alert(this.type)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .comman-activitys {
	background: rgba(51, 52, 72, 0.05);
	border-radius: 0px 16px 16px 16px;
	padding: 20px;
	width: 70%;
} */

.ib {
  display: inline-block;
  margin-bottom: 40px;
}

/* .request_row {
	padding-left: 3%
} */

.selectbtn {
  width: 100%;
  background-color: #00d3c7;
  height: 50px;
  color: white;
}

.so {
  background-color: transparent;
}

.dropdown-menu {
  height: 90px;
  overflow-y: scroll;
  width: 100%;
  border-radius: 8px;
}

.topbadge {
  height: 35px;
  width: 100%;
  font-size: 16px;
  padding-top: 10px;
  float: right;
}
@media (max-width: 1366px) {
}

@media (max-width: 1280px) {
}
@media (max-width: 800px) {
  .row {
    flex-direction: column !important;
  }

  .col-3 {
    width: 100%;
  }

  .col-2 {
    width: 100%;
  }

  #cancproceed {
    flex-direction: row !important;
  }
}

@media (max-width: 480px) {
  .row {
    flex-direction: column !important;
  }

  .col-3 {
    width: 100%;
  }

  .col-2 {
    width: 100%;
  }

  #cancproceed {
    flex-direction: row !important;
  }
}
</style>
