/** Video Call Web SDK **/
function _0x1f6b() {
  const _0x590697 = [
    "setRemoteDescription",
    "receiverConnectionId",
    "6940283RfUPWu",
    "stun:stun.l.google.com:19302",
    "9837AtiiUC",
    "icecandidate",
    "?appId=",
    "peerConnection",
    "_onCallAccepted",
    "1245464VuZGYf",
    "_onCallReceived",
    "setLocalDescription",
    "Waiting\x20for\x20remote\x20description...",
    "getConnectionId",
    "316qBhJXT",
    "1715752npLYJm",
    "socketUrl",
    "sender",
    "answer",
    "isBroadcasting",
    "data",
    "&&mode=",
    "log",
    "getUserMedia",
    "offer",
    "isCalling",
    "send",
    "localStream",
    "d3NzOi8vc3FtMXQxbjcyaS5leGVjdXRlLWFwaS5ldS13ZXN0LTIuYW1hem9uYXdzLmNvbS9kZXZlbG9wbWVudA==",
    "socket",
    "joinCall",
    "Error\x20adding\x20received\x20ice\x20candidate",
    "parse",
    "You\x20Called",
    "iceCandidate",
    "configuration",
    "init",
    "mediaDevices",
    "development",
    "webrtc",
    "handlerCallReceived",
    "error",
    "candidate",
    "appId",
    "srcObject",
    "createOffer",
    "startBroadcasting",
    "2994366CQeySK",
    "freeze",
    "caller",
    "5wncTfu",
    "localVideo",
    "createAnswer",
    "callerData",
    "message",
    "sendSocketMessage",
    "connectionId",
    "connect2Socket",
    "production",
    "name",
    "17950njCZGb",
    "onopen",
    "socketActions",
    "5581NihStr",
    "track",
    "1204917DxFIJE",
    "streams",
    "startCall",
    "forEach",
  ];
  _0x1f6b = function () {
    return _0x590697;
  };
  return _0x1f6b();
}
function _0x3295(_0x2d3f71, _0xe3f900) {
  const _0x1f6bd0 = _0x1f6b();
  return (
    (_0x3295 = function (_0x3295a0, _0xfdea93) {
      _0x3295a0 = _0x3295a0 - 0x109;
      let _0x668f5 = _0x1f6bd0[_0x3295a0];
      return _0x668f5;
    }),
    _0x3295(_0x2d3f71, _0xe3f900)
  );
}
(function (_0x1cffcc, _0x4c5613) {
  const _0x486ccc = _0x3295,
    _0x3ee1a9 = _0x1cffcc();
  while (!![]) {
    try {
      const _0x5c5cfe =
        (-parseInt(_0x486ccc(0x112)) / 0x1) *
          (parseInt(_0x486ccc(0x126)) / 0x2) +
        parseInt(_0x486ccc(0x114)) / 0x3 +
        (-parseInt(_0x486ccc(0x127)) / 0x4) *
          (-parseInt(_0x486ccc(0x14a)) / 0x5) +
        -parseInt(_0x486ccc(0x147)) / 0x6 +
        -parseInt(_0x486ccc(0x11a)) / 0x7 +
        parseInt(_0x486ccc(0x121)) / 0x8 +
        (parseInt(_0x486ccc(0x11c)) / 0x9) * (parseInt(_0x486ccc(0x10f)) / 0xa);
      if (_0x5c5cfe === _0x4c5613) break;
      else _0x3ee1a9["push"](_0x3ee1a9["shift"]());
    } catch (_0x467ffa) {
      _0x3ee1a9["push"](_0x3ee1a9["shift"]());
    }
  }
})(_0x1f6b, 0x8c97b);
export const videoCall = ({
  appId: _0x36a49a,
  localVideo: _0x50c6ff,
  remoteVideo: _0x4334bf,
  audioOnly: audioOnly = ![],
  isDev: isDev = ![],
  events: {
    onConnect: onConnect = ({ _connectionId: _0x5b9606 }) => {},
    onCallReceived: onCallReceived = ({ name: _0x3c7333, id: _0x5315d6 }) => {},
    onCallAccepted: onCallAccepted = ({ name: _0x24444b, id: _0xba2983 }) => {},
  } = {},
}) => {
  const _0x23b2e2 = _0x3295;
  return {
    appId: _0x36a49a,
    localVideo: _0x50c6ff,
    remoteVideo: _0x4334bf,
    localStream: null,
    remoteStream: null,
    peerConnection: null,
    receiverConnectionId: null,
    socket: null,
    isCalling: ![],
    callerData: { offer: null, caller: null },
    connectionId: null,
    isBroadcasting: ![],
    socketUrl: isDev ? "d3M6Ly9sb2NhbGhvc3Q6ODAwMA==" : _0x23b2e2(0x134),
    _onConnect: ({ _connectionId: _0x46e5e4 }) =>
      onConnect({ _connectionId: _0x46e5e4 }),
    _onCallReceived: ({ name: _0x5ecd7f, id: _0x220bc7 }) =>
      onCallReceived({ name: _0x5ecd7f, id: _0x220bc7 }),
    _onCallAccepted: ({ name: _0x5326a0, id: _0x2dcc1b }) =>
      onCallAccepted({ name: _0x5326a0, id: _0x2dcc1b }),
    configuration: { iceServers: [{ urls: _0x23b2e2(0x11b) }] },
    socketActions: Object[_0x23b2e2(0x148)]({
      getConnectionId: _0x23b2e2(0x125),
      message: _0x23b2e2(0x109),
    }),
    sendSocketMessage({
      action: action = _0x23b2e2(0x109),
      data: data = { message: "", type: "", action: _0x23b2e2(0x13f) },
    }) {
      const _0x4bfe35 = _0x23b2e2;
      this["socket"][_0x4bfe35(0x132)](
        JSON["stringify"]({
          action: action,
          data: data,
          connectionId: this["receiverConnectionId"],
        })
      );
    },
    reconnect() {
      const _0x5ef495 = _0x23b2e2;
      this[_0x5ef495(0x13c)]();
    },
    async startBroadcasting() {
      const _0x4ef270 = _0x23b2e2;
      (this[_0x4ef270(0x12b)] = !![]),
        (this["localStream"] = await navigator[_0x4ef270(0x13d)][
          _0x4ef270(0x12f)
        ]({ video: !audioOnly, audio: !![] })),
        (this[_0x4ef270(0x14b)]["srcObject"] = this["localStream"]),
        (this[_0x4ef270(0x11f)] = new RTCPeerConnection(
          this[_0x4ef270(0x13b)]
        )),
        this[_0x4ef270(0x11f)]["addEventListener"](
          _0x4ef270(0x11d),
          (_0xe8703f) => {
            const _0x113f58 = _0x4ef270;
            _0xe8703f[_0x113f58(0x142)] &&
              this[_0x113f58(0x10a)]({
                data: {
                  iceCandidate: _0xe8703f["candidate"],
                  type: "iceCandidate",
                },
              });
          }
        ),
        this["peerConnection"]["addEventListener"](
          _0x4ef270(0x113),
          (_0x4f271d) => {
            const _0x395b18 = _0x4ef270;
            _0x4334bf[_0x395b18(0x144)] !== _0x4f271d[_0x395b18(0x115)][0x0] &&
              (_0x4334bf["srcObject"] = _0x4f271d["streams"][0x0]);
          }
        ),
        this[_0x4ef270(0x133)]
          ["getTracks"]()
          [_0x4ef270(0x117)]((_0x204df3) =>
            this["peerConnection"]["addTrack"](
              _0x204df3,
              this[_0x4ef270(0x133)]
            )
          );
    },
    async startCall(_0x485812) {
      const _0x8a9055 = _0x23b2e2;
      if (!this["peerConnection"]) return;
      await this["peerConnection"][_0x8a9055(0x118)](
        new RTCSessionDescription(_0x485812)
      );
      const _0x4ab359 = await this[_0x8a9055(0x11f)][_0x8a9055(0x14c)]();
      await this[_0x8a9055(0x11f)][_0x8a9055(0x123)](_0x4ab359),
        this[_0x8a9055(0x10a)]({
          data: { answer: _0x4ab359, type: _0x8a9055(0x12a) },
        });
    },
    async joinCall({ callerId: _0x568a15, name: _0x4ddf3e }) {
      const _0x189889 = _0x23b2e2;
      this[_0x189889(0x119)] = _0x568a15;
      try {
        !this[_0x189889(0x12b)] && (await this[_0x189889(0x146)]()),
          setTimeout(async () => {
            const _0x3c2fe7 = _0x189889;
            try {
              const _0xe6bd50 = await this["peerConnection"][
                _0x3c2fe7(0x145)
              ]();
              await this[_0x3c2fe7(0x11f)][_0x3c2fe7(0x123)](_0xe6bd50);
              const _0x283179 = {
                offer: _0xe6bd50,
                type: "offer",
                sender: {
                  name: this[_0x3c2fe7(0x10b)],
                  connectionId: this["connectionId"],
                },
              };
              this[_0x3c2fe7(0x10a)]({ data: _0x283179 });
            } catch (_0x33f554) {
              console[_0x3c2fe7(0x141)](
                "Error\x20setting\x20local\x20description:",
                _0x33f554
              );
            }
          }, 0x7d0);
      } catch (_0x409a26) {
        console[_0x189889(0x141)](
          "Error\x20accessing\x20media\x20devices.",
          _0x409a26
        );
      }
    },
    acceptCall() {
      const _0xd7d00f = _0x23b2e2;
      this[_0xd7d00f(0x116)](this["callerData"][_0xd7d00f(0x130)]),
        this[_0xd7d00f(0x136)]({
          callerId: this[_0xd7d00f(0x14d)][_0xd7d00f(0x149)][_0xd7d00f(0x10b)],
          name: this[_0xd7d00f(0x14d)][_0xd7d00f(0x149)][_0xd7d00f(0x10e)],
        });
    },
    handlerCallReceived(_0x229537, _0x105c6c) {
      const _0x1b4b31 = _0x23b2e2;
      (this[_0x1b4b31(0x14d)] = { caller: _0x229537, offer: _0x105c6c }),
        this[_0x1b4b31(0x122)]({
          name: _0x229537["name"],
          id: _0x229537[_0x1b4b31(0x10b)],
        }),
        (this[_0x1b4b31(0x119)] =
          this["callerData"]["caller"][_0x1b4b31(0x10b)]);
    },
    async init() {
      const _0x549d28 = _0x23b2e2;
      this[_0x549d28(0x10c)](),
        (this[_0x549d28(0x135)][_0x549d28(0x110)] = () => {
          const _0x654172 = _0x549d28;
          this[_0x654172(0x10a)]({
            action: this[_0x654172(0x111)][_0x654172(0x125)],
          });
        }),
        (this[_0x549d28(0x135)]["onmessage"] = async (_0x55dd48) => {
          const _0xe515f1 = _0x549d28,
            { data: _0x103c19, type: _0x39a772 } = JSON[_0xe515f1(0x138)](
              _0x55dd48[_0xe515f1(0x12c)]
            );
          switch (_0x39a772) {
            case _0xe515f1(0x10b):
              (this["connectionId"] = _0x103c19),
                this["_onConnect"]({ _connectionId: _0x103c19 });
              break;
            case "message":
              switch (_0x103c19["type"]) {
                case "new-peer":
                  break;
                case _0xe515f1(0x130):
                  !this["isCalling"]
                    ? this[_0xe515f1(0x140)](
                        _0x103c19[_0xe515f1(0x129)],
                        _0x103c19[_0xe515f1(0x130)]
                      )
                    : this[_0xe515f1(0x116)](_0x103c19[_0xe515f1(0x130)]);
                  break;
                case _0xe515f1(0x12a):
                  this["isCalling"] &&
                    this[_0xe515f1(0x120)]({
                      name: _0xe515f1(0x10e),
                      id: "id",
                    });
                  await this["peerConnection"][_0xe515f1(0x118)](
                    new RTCSessionDescription(_0x103c19["answer"])
                  );
                  break;
                case _0xe515f1(0x13a):
                  if (!this["peerConnection"]) return;
                  if (!this["peerConnection"]["remoteDescription"]) {
                    console[_0xe515f1(0x12e)](_0xe515f1(0x124));
                    return;
                  }
                  try {
                    await this[_0xe515f1(0x11f)]["addIceCandidate"](
                      _0x103c19["iceCandidate"]
                    );
                  } catch (_0x1de0d1) {
                    console["error"](_0xe515f1(0x137), _0x1de0d1);
                  }
                  break;
              }
              break;
          }
        });
    },
    callUser(_0x513368) {
      const _0x12bd98 = _0x23b2e2;
      (this[_0x12bd98(0x119)] = _0x513368),
        this["receiverConnectionId"] &&
          ((this[_0x12bd98(0x131)] = !![]),
          this[_0x12bd98(0x136)]({
            callerId: this[_0x12bd98(0x119)],
            name: _0x12bd98(0x139),
          }));
    },
    connect2Socket() {
      const _0x1611c8 = _0x23b2e2;
      this[_0x1611c8(0x135)] = new WebSocket(
        atob(this[_0x1611c8(0x128)]) +
          _0x1611c8(0x11e) +
          this[_0x1611c8(0x143)] +
          _0x1611c8(0x12d) +
          (isDev ? _0x1611c8(0x13e) : _0x1611c8(0x10d))
      );
    },
  };
};
