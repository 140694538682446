<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Records </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Birth Records</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Birth Records</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search_text"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></span>
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <!-- /Table Header -->
                <a-table :dataSource="newBirthRecords" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'name'">
                      <span>
                        <img width="28" height="28" src="../../assets/assets/img/profiles/avatar-02.jpg"
                          class="rounded-circle m-r-5" alt="" />
                        {{ record.child_name }}
                      </span>
                    </template>
                    <template v-if="column.key == 'sex'">
                      <span class="badge bg-success-light">{{
                        record.sex
                      }}</span>
                    </template>
                    <template v-if="column.key == 'birth_date'">
                      {{ formatDate(record.birth_date, !!record.birth_time) }}
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
          <div style="text-align: center; margin-block-start: 15%"
            v-if="newBirthRecords == '' || newBirthRecords == null">
            <i class="fas fa-box-open" style="font-size: 150px; color: #14a79e; opacity: 0.4"></i>
            <div class="font-size:24px">No data yet</div>
          </div>
        </div>
      </div>

      <!-- fixed -->

      <!-- sample modal content model1 -->
      <div id="con-close-modal" class="modal modal-lg mm1 animate__animated animate__zoomIn animate__faster"
        style="margin-block-start:100px;margin-inline-start:100px;margin-right" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">New Birth Record</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <div action="javascript:void()">
                <div class="row">
                  <div class="col-12"></div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Case No<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div> -->
                  <div class="col-12 col-md-6 col-xl-6" id="chidsnme">
                    <div class="input-block local-forms">
                      <label>Child Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="child_name" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mother's Account Number<span class="login-danger">*</span></label>
                      <input class="form-control" type="number" placeholder="" v-model="account_number"
                        @change="search()" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mother's Name<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="patient_name" disabled />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Father's Name<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="father_name" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input class="form-control" type="number" placeholder="" v-model="phone_number" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Birth Date <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" placeholder="" v-model="birth_date" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Birth Time <span class="login-danger">*</span></label>
                      <input class="form-control" type="time" placeholder="" v-model="birth_time" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="address" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <a-select class="" type="text" name="cat" v-model:value="sex">
                        <a-select-option value="" disabled>Select Gender</a-select-option>
                        <a-select-option value="Male">Male</a-select-option>
                        <a-select-option value="Female">Female</a-select-option>
                      </a-select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Child's Weight<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="child_weight" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Head Circumference<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="head_circumference" />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end" id="hereupcanc">
                      <button type="submit" class="btn btn-primary submit-form me-2" @click="new_birth()">
                        Submit
                      </button>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal -->

      <!-- sample modal content model2 -->

      <div id="con-close-modal" class="modal fade mm2" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Birth Record</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Case No<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Child Name <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="male" class="form-check-input" />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="Female" class="form-check-input" />Female
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mother's Name<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Father's Name<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Birth Date <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-top-form">
                      <label class="local-top">Avatar <span class="login-danger">*</span></label>
                      <div class="settings-btn upload-files-avator">
                        <input type="file" accept="image/*" name="image" id="file" onchange="loadFile(event)"
                          class="hide-input" />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">
                        Submit
                      </button>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>

              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import $ from "jquery";
import { mapActions } from "vuex";
import { patient } from "@/store/adminStore";
import { formatDate } from "@/utils/formatter";

const columns = [
  {
    title: "Child Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Gender",
    dataIndex: "sex",
    key: "sex",
  },
  {
    title: "Birth Date",

    key: "birth_date",
  },

  {
    title: "Mother's Name",
    dataIndex: "mother_name",
    key: "mother_name",
  },
  {
    title: "Father's Name",
    dataIndex: "father_name",
    key: "father_name",
  },
  {
    title: "Mobile",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      formatDate,
      femalePatients: [],
      newBirthRecords: [],
      reserve_newBirthRecords: [],
      child_name: "",
      sex: "",
      birth_date: "",
      birth_time: "",
      mother_id: "",
      father_name: "",
      phone_number: "",
      address: "",
      account_number: "",
      filtered_patient_list: "",
      patient_name: "",
      search_text: "",
      head_circumference: "",
      child_weight: "",
      columns,
    };
  },

  methods: {
    ...mapActions({
      _femalePatientList: "patient/femalePatientList",
      _new_birth: "general/new_birth",
      _newBirthRecords: "general/getNewBirthRecords",
    }),

    new_birth() {
      $(".inloader").show();

      this._new_birth({
        child_name: this.child_name,
        birth_date: this.birth_date,
        birth_time: this.birth_time,
        mother_id: this.filtered_patient_list[0].id,
        father_name: this.father_name,
        phone_number: this.phone_number,
        address: this.address,
        sex: this.sex,
        head_circumference: this.head_circumference,
        child_weight: this.child_weight,
      })
        .then((response) => {
          $(".inloader").hide();

          this.response = response.data;
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            location.reload(true);

            this.getNewBirthRecords();
            $(".mm1").modal("hide");
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          $(".mm1").modal("hide");

          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          // } else {
          //  this.$toast.error("An unexpected error occurred.");
          // }
        });
    },

    getNewBirthRecords() {
      this._newBirthRecords({})
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.newBirthRecords = this.response.data;
            this.newBirthRecords = this.newBirthRecords;
            this.reserve_newBirthRecords = this.newBirthRecords;
          }
        })
        .catch((error) => {
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              // this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              // this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
          // } else {
          //  this.$toast.error("An unexpected error occurred.");
          // }
        }).finally(() => {
          this.isLoading = false;
        });
    },

    getFemalePatients() {
      this._femalePatientList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.femalePatients = this.response.data;
            console.log(this.femalePatients);
          }
        })
        .catch((error) => {
          // console.log(error);
          console.log("Error fetching data", error);
        });
    },

    search() {
      this.filtered_patient_list = this.femalePatients.filter(
        (acct) => acct.account_number == this.account_number
      );

      if (this.filtered_patient_list.length > 0) {
        this.patient_name =
          this.filtered_patient_list[0].first_name +
          " " +
          this.filtered_patient_list[0].last_name;
      } else {
        this.patient_name = "No Matching Record";
      }
      console.log(this.patient_name);
    },
    search_the_list() {
      if (this.search_text == "") {
        this.newBirthRecords = this.reserve_newBirthRecords;
      } else {
        this.newBirthRecords = this.reserve_newBirthRecords;
        var searchLetter = this.search_text.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.newBirthRecords.length; i++) {
          var birth_date = this.newBirthRecords[i].birth_date.toLowerCase();
          var mother_name = this.newBirthRecords[i].mother_name.toLowerCase();
          var father_name = this.newBirthRecords[i].father_name.toLowerCase();
          var phone_number = this.newBirthRecords[i].phone_number;
          if (
            birth_date.includes(searchLetter) ||
            mother_name.includes(searchLetter) ||
            father_name.includes(searchLetter) ||
            phone_number.includes(searchLetter) ||
            father_name.includes(searchLetter) ||
            mother_name.includes(searchLetter)
          ) {
            searchResult.push(this.newBirthRecords[i]);
          }
          // if (searchResult == []) {
          //   this.newBirthRecords = this.newBirthRecords;
          // }
        }
      }
      if (this.search_text == "") {
        this.newBirthRecords = this.reserve_newBirthRecords;
      } else {
        this.newBirthRecords = searchResult;
      }
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $(".mm1").modal("show");
    });
    $(".edit_btn").click(function () {
      $(".mm2").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    this.getFemalePatients();
    this.getNewBirthRecords();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{inline-size:60%} */
@media (max-width: 800px) {
  #hereupcanc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width:480px) {
  #con-close-modal {
    margin-inline-start: 0px !important;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 60vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  #chidsnme {
    margin-top: 10%;
  }
}
</style>
