<template>
  <div class="cont">
    <!----------last modal to be added------------->
    <div
      id="con-close-modal"
      class="modal modal-lg animate__animated animate__zoomIn animate__faster some_vitals"
      style="margin-top:200px;margin-left:100px;margin-right"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Add basic patient vitals</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-4">
            <!-- body starts here -->
            <form>
              <div class="row">
                <div class="col-12 col-sm-6" hidden>
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Temperature</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="temperature"
                      placeholder="°C"
                    />
                  </div>
                </div>

                <div class="col-12 col-sm-6" id="hrtrat">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Heart Rate</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="heart_rate"
                      placeholder="BPM"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6" hidden>
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Blood Pressure</label>
                    <input
                      class="form-control"
                      type="text"
                      id="bp"
                      placeholder="mmHg"
                      v-model="blood_pressure"
                      maxlength="7"
                      @input="separator()"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Glucose Level</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="glucose_level"
                      placeholder="mg/dL"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6" hidden>
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Oxygen</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="oxygen"
                      placeholder="%"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Weight</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="weight"
                      placeholder="cm"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="input-block local-forms" style="height: 40px">
                    <label>Height</label>
                    <input
                      class="form-control"
                      type="text"
                      v-model="height"
                      placeholder="cm"
                    />
                  </div>
                </div>

                <div class="col-12 col-sm-12"></div>
                <div class="col-12 col-sm-6">
                  <span
                    class="advnced_vital_link"
                    @click="goto('/Patient-Biodata')"
                    >Add advanced patient vitals</span
                  >
                </div>
                <div class="col-6" id="sunandcanc">
                  <div class="doctor-submit text-end">
                    <span
                      type="submit"
                      class="btn btn-primary submit-form me-2"
                      @click="add_patient_bio_data()"
                    >
                      Submit
                    </span>
                    <span
                      class="btn btn-primary cancel-vital_form bg-danger text-light"
                      >Cancel</span
                    >
                  </div>
                </div>
              </div>
            </form>

            <!-- body ends here -->
          </div>
        </div>
        <!-- /.modal -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {},
  data() {
    return {
      blood_pressure: "",
      glucose_level: "",
      heart_rate: "",
      body_mass_index: 0,
      weight: 0,
      height: 0,
      temperature: "",
      fever: 0,
      calcium: 0,
      oxygen: "",
      nitrogen: 0,
      hydrogen: 0,
      carbon: 0,
      lung: 0,
      stomach: 0,
      liver: 0,
      brain: 0,
      blood_count: 0,
      smoking_status: "",
    };
  },

  methods: {
    ...mapActions({
      _add_bioData: "patient/add_bioData",
    }),

    add_patient_bio_data() {
      $(".inloader").show();
      $(".some_vitals").modal("hide");
      const bioDataPayload = {
        blood_pressure: this.blood_pressure,
        glucose_level: this.glucose_level,
        heart_rate: this.heart_rate,
        body_mass_index: this.body_mass_index,
        height: this.height,
        weight: this.weight,
        temperature: this.temperature,
        fever: this.fever,
        calcium: this.calcium,
        oxygen: this.oxygen,
        nitrogen: this.nitrogen,
        hydrogen: this.hydrogen,
        carbon: this.carbon,
        lung: this.lung,
        stomach: this.stomach,
        liver: this.liver,
        brain: this.brain,
        blood_count: this.blood_count,
        smoking_status: this.smoking_status,
      };
      // if (this.whoisLoggedin == "Doctor" || this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      // if (this.whoisLoggedin == "Front Desk" ||this.whoisLoggedin == "Admin") {
      //   let id = localStorage.getItem("this_patient_id");
      // }

      let id = localStorage.getItem("this_patient_id");
      this._add_bioData({ id: id, payload: bioDataPayload })
        .then((response) => {
          $(".inloader").hide();
          this.response = response.data;
          console.log(response);
          if (this.response["status"] == true) {
            this.$toast.success(this.response["message"]);
            // this.$router.push({ name: "Patients" });
            location.reload();
          }
        })
        .catch((error) => {
          $(".inloader").hide();

          console.log(error);
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
        });
    },
    goto(route) {
      window.location = route;
      // this.$router.push({ name: route })
    },
    separator() {
      var input = document.getElementById("bp");
      input.value = input.value.replace(/[^\d/]/g, "");
      if (input.value.length > 3 && input.value.length < 5) {
        if (input.value.charAt(3) !== "/") {
          input.value = input.value.slice(0, 3) + "/" + input.value.slice(2);
        }
      }
    },
  },
  mounted() {
    $(".cancel-vital_form").click(function () {
      $(".some_vitals").modal("hide");
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advnced_vital_link {
  cursor: pointer;
  color: #14a79e;
  display: inline-block;
  margin-top: 20px;
}

@media (max-width: 480px) {
  #hrtrat {
    margin-top: 3%;
  }

  #con-close-modal {
    margin-left: 0px !important;
    margin-top: 90px !important;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 60vh;
    scrollbar-width: thin;
    scrollbar-color: #14a79e  #3334480d;
  }

  #sunandcanc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
  }

  .text-end {
    gap: 20px;
    text-align: center !important;
    width: 100% !important;
  }

  .modal-title {
    margin-bottom: 3%;
  }

  .modal-content {
    height: 500px;
  }

  .advnced_vital_link {
    margin-bottom: 20px;
    margin-top: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 800px){
  #con-close-modal{
 margin-left: 0px !important;
}
}

@media (max-width: 480px){
  #con-close-modal{
 margin-left: 0px !important;
}
}
</style>
