import { downloadData } from "@/utils/download";
import { api, url } from "../api";

export const general = {
  namespaced: true,
  actions: {
    login({ context }, payload) {
      localStorage.clear();
      return api.post(url("login"), payload);
    },
    async downloadReport({ context }, { targetId, target }) {
      const response = await api.get(url(`download-report`), {
        params: {
          target,
          target_id: targetId,
        },
        responseType: "blob",
      });
      downloadData(response.data);
    },
    // set_satus({ context },payload) {
    //   return api.post(url("view/specialists"), payload );
    // },
    onboard_settings({ context }, payload) {
      return api.post(url(`update/settings`), payload);
    },
    set_pin({ context }, payload) {
      return api.post(url("set/pin"), payload);
    },
    forget_password({ context }, payload) {
      return api.post(url("forgot-password"), payload);
    },
    reset_password({ context }, payload) {
      return api.post(url("password/reset"), payload);
    },
    update_credential({ context }, payload) {
      return api.post(url("update/security/credentials"), payload);
    },
    timeout({ context }, payload) {
      return api.post(url("time/out"), payload);
    },
    getAppontmentList({ context }) {
      return api.get(url("appointment/view"));
    },
    getSheduleList({ context }) {
      return api.get(url("view/doctors/schedule_lists"));
    },
    add_schedule({ context }, payload) {
      return api.post(url(`set/avail_time`), payload);
    },
    category({ context }) {
      return api.get(url("view/specialists"));
    },

    // ------------------------------------------//
    global_category({ context }, payload) {
      return api.get(url("global/category"), { params: payload });
    },

    add_global_category({ context }, payload) {
      return api.post(url("global/category/add"), payload);
    },
    update_global_category({ context }, payload) {
      return api.post(url(`global/category/update/${payload.id}`), payload);
    },
    // ------------------------------------------//

    getdoctors({ context }, id) {
      return api.get(url(`view/doctors/${id}`));
    },
    Bookappointment({ context }, { patientId, payload }) {
      return api.post(url(`book/patient/appointment/${patientId}`), payload);
    },
    Reschedule_appointment({ context }, { patientId, payload }) {
      return api.post(url(`reschedule/appointment/${patientId}`), payload);
    },
    Delete_appointment({ context }, aptId) {
      return api.post(url(`cancel/appointment/${aptId}`));
    },
    Register_staff({ context }, payload) {
      return api.post(url("staff/register"), payload);
    },
    Edit_staff({ context }, { id, payload }) {
      return api.post(url(`staff/update/${id}`), payload);
    },
    getrole({ context }) {
      return api.get(url("view/role"));
    },
    gethospital({ context }) {
      return api.get(url("view/hospital"));
    },
    registerpatient({ context }, payload) {
      return api.post(url("register/patient"), payload);
    },
    new_birth({ context }, payload) {
      return api.post(url("register/birth"), payload);
    },
    getNewBirthRecords({ context }) {
      return api.get(url("view/birth/records"));
    },
    new_death({ context }, payload) {
      return api.post(url("register/death"), payload);
    },
    getNewDeathRecords({ context }) {
      return api.get(url("view/death/records"));
    },
    new_room_allotment({ context }, { id, payload }) {
      return api.post(url(`allocate/room/${id}`), payload);
    },
    getRoomAllotmentRecords({ context }) {
      return api.get(url("view/allocated/room"));
    },
    getStateList({ context }) {
      return api.get(url("stateAndLga"));
    },
    getPermissions({ context }) {
      return api.get(url("view/permission"));
    },
    addPermission({ context }, payload) {
      return api.post(url("add/role"), payload);
    },
    editPermission({ context }, { id, payload }) {
      return api.post(url(`update/role_permission/${id}`), payload);
    },
    editRole({ context }, { id, payload }) {
      return api.post(url(`update/role/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    editPermissionAndRole({ context }, { id, payload }) {
      return api.post(url(`update/institution/settings/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    get_hospitalList({ context }) {
      return api.get(url("hospitals"));
    },
    Transfer_OTP_call({ context }, { id, payload }) {
      return api.post(
        url(`send/transferOut/patient/hospital/otp/${id}`),
        payload
      );
    },
    Transfer_OTP_call_in({ context }, id) {
      return api.post(url(`send/transferIn/patient/hospital/otp/${id}`));
    },
    verifyTransfer({ context }, { id, payload }) {
      return api.post(
        url(`verify/transfer/patient/hospital/otp/${id}`),
        payload
      );
    },
    update_profile({ context }, payload) {
      return api.post(url("profile/update"), payload);
    },

    Register_hospital({ context }, payload) {
      return api.post(url(`hospital/register`), payload);
    },

    Edit_hospital({ context }, { id, payload }) {
      return api.post(url(`hospital/update/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    change_acount_status({ context }, { id, payload }) {
      return api.post(url(`deactivate/account/${id}`), payload);
    },

    Unpaid_appointments({ context }) {
      return api.get(url("unpaid/appointments"));
    },

    // offline hospitals
    get_offline_hospitalList({ context }) {
      return api.get(url("sync-device/view"));
    },
    Register_Offline_Hospital({ context }, payload) {
      return api.post(url(`sync-device/register`), payload);
    },
    // adding subscription
    Add_Subscription({ context }, payload) {
      return api.post(url(`subscription/create`), payload);
    },
    Add_Custom_Subscription({ context }, payload) {
      return api.post(url(`subscription/custom`), payload);
    },

    // editing subscription
    Edit_Subscription({ context }, { plan_id, payload }) {
      return api.post(url(`subscription/update/${plan_id}`), {
        ...payload,
        _method: "PATCH",
      });
    },

    // editting Permission and permission category
    add_Permission({ context }, payload) {
      return api.post(url(`permission`), payload);
    },
    edit_main_permission({ context }, { id, payload }) {
      return api.post(url(`permission/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    delete_permission({ context }, id) {
      return api.post(url(`permission/${id}`), { _method: "DELETE" });
    },

    // permission Category
    get_Permission_category({ context }, payload) {
      return api.get(url("view/permission"), { params: payload });
    },

    add_Permission_category({ context }, payload) {
      return api.post(url(`permission/category`), payload);
    },
    edit_permission_category({ context }, { id, payload }) {
      return api.post(url(`permission/category/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
    delete_permission_category({ context }, id) {
      return api.post(url(`permission/category/${id}`), { _method: "DELETE" });
    },

    get_subscribersList({ context }) {
      return api.get(url("subscription/subscribers"));
    },

    accept_category({ context }, { id, payload }) {
      return api.post(url(`approve/global/category/${id}`), {
        ...payload,
        _method: "PATCH",
      });
    },
  },
};

export const finance = {
  namespaced: true,
  actions: {
    checkAccount({ context }, payload) {
      return api.get(url("confirm/account"), { params: payload });
    },
    pay({ context }, { id, payload }) {
      return api.post(url(`update/bal/${id}`), payload);
    },

    fin_list({ context }) {
      return api.get(url("transaction/history"));
    },
    addService({ context }, payload) {
      return api.post(url("service"), payload);
    },
    editService({ context }, { id, payload }) {
      return api.post(url(`service/${id}`), { ...payload, _method: "PATCH" });
    },
    getServiceList({ context }) {
      return api.get(url("service"));
    },
    deleteService({ context }, id) {
      return api.post(url(`service/${id}`), { _method: "DELETE" });
    },
    changeServiceStatus({ context }, id) {
      return api.post(url(`service/status/${id}`), { _method: "PATCH" });
    },
    add_billing({ context }, { id, payload }) {
      return api.post(url(`service/charge/${id}`), payload);
    },
    getServiceHistory({ context }, id) {
      return api.get(url(`service/history/${id}`));
    },
    pending_payments({ context }) {
      return api.get(url("pending/payments"));
    },
  },
};

export const doctor = {
  namespaced: true,
  actions: {
    getDoctorPendigAppontmentList({ context }) {
      return api.get(url("pending/appointments"));
    },

    getDoctorRejectedAppointmentList({ context }) {
      return api.get(url("rejected/appointments"));
    },
    acceptPatient({ context }, { id, payload }) {
      return api.post(url(`appointment/accept/${id}`), payload);
    },
    rejectPatient({ context }, { id, payload }) {
      return api.post(url(`appointment/reject/${id}`), payload);
    },
    transferPatient({ context }, { id, payload }) {
      console.log(id);
      return api.post(url(`doctor/transfer/appointments/${id}`), payload);
    },
    getDoctorApprovedAppontmentList({ context }) {
      return api.get(url("accepted/appointments"));
    },
    getAdmittedPatientList({ content }) {
      return api.get(url("admitted/patients"));
    },
    sendnote({ context }, { id, payload }) {
      return api.post(url(`appointment/see/patient/${id}`), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    getCategoryGOPDList({ context }) {
      return api.get(url("category/appointments"));
    },
    doctortransferPatient({ context }, { id, payload }) {
      console.log(id, payload);
      return api.post(url(`appointment/collaborate_request/${id}`), payload);
    },
    getNonHodDoctors({ context }) {
      return api.get(url("view/non_hod_doctors"));
    },
    getDoctorDashboardData({ context }, id) {
      return api.get(url(`view/doctor/dashboard/${id}`));
    },
    get_doctors_apt({ context }) {
      return api.get(url("doctor/dashboard/appointments"));
    },

    start_appt({ context }, id) {
      return api.post(url(`start/appointment/${id}`));
    },
    end_appt({ context }, id) {
      return api.post(url(`end/appointment/${id}`));
    },
  },
};

export const patient = {
  namespaced: true,
  actions: {
    patientList({ context }) {
      return api.get(url("patients"));
    },
    femalePatientList({ context }) {
      return api.get(url("patients/female"));
    },
    patientBoidata({ context }, id) {
      console.log(id);
      return api.get(url(`view/patient/timeline/${id}`));
    },

    add_bioData({ context }, { id, payload }) {
      return api.post(url(`add/patient/bio_data/${id}`), payload);
    },
    admit_patient({ context }, { appointmentId, payload }) {
      return api.post(url(`change/patient/status/${appointmentId}`), payload);
    },
    getPatientDashboardData({ context }, id) {
      return api.get(url(`patient/dashboard/${id}`));
    },

    get_bioData({ context }, { id, params }) {
      return api.get(url(`patients/report/${id}`), { params: params });
    },
    get_patientNote({ context }, { patientId, target, target_id }) {
      return api.get(url(`patients/${patientId}/notes`), {
        params: { target, target_id },
      });
    },
    // same end-point duplication fix when chanced
    add_patientSummary({ context }, { appointmentId, payload }) {
      return api.post(url(`appointment/see/patient/${appointmentId}`), payload);
    },
    add_patientoperation_note({ context }, { appointmentId, payload }) {
      return api.post(url(`appointment/see/patient/${appointmentId}`), payload);
    },
    add_medical_info({ context }, { id, payload }) {
      return api.post(url(`medical-condition/${id}`), payload);
    },
    edit_medical_info({ context }, { id, payload }) {
      return api.post(url(`medical-condition/${id}`), {
        _method: "PATCH",
        ...payload,
      });
    },
    patientCheckin({ context }, id) {
      return api.post(url(`check-in-out/${id}`));
    },
    blood_groups({ context }) {
      return api.get(url("blood-groups"));
    },

    getAppointmentHistory({ context }, id) {
      return api.get(url(`patient-appointments/${id}`));
    },
  },
};

export const pharmacy = {
  namespaced: true,
  actions: {
    getDrugCategories({ context }) {
      return api.get(url("view/drug/category_list"));
    },
    addDrugCategory({ context }, payload) {
      return api.post(url("add/drug/category"), payload);
    },
    editDrugCategory({ context }, { drugCategoryId, payload }) {
      return api.post(url(`add/drug/category/${drugCategoryId}`), payload);
    },
    addDrug({ context }, payload) {
      return api.post(url("add/drug"), payload);
    },
    editDrug({ context }, { drugId, payload }) {
      return api.post(url(`edit/drug/${drugId}`), payload);
    },
    getDrugList({ context }, params) {
      return api.get(url("view/drug_list"), { params: params });
    },
    getDrugHistory({ context }, id) {
      return api.get(url(`restock/drug/history/${id}`));
    },
    getDispensory({ context }, params) {
      return api.get(url("view/prescription"), { params: params });
    },
    adddrug_type({ context }, payload) {
      return api.post(url("add/drug/type"), payload);
    },
    getdrug_types({ context }) {
      return api.get(url("view/drug/type_list"));
    },
    dispense_drugs({ context }, { id, payload }) {
      return api.post(url(`dispense/drug/${id}`), {
        _method: "PATCH",
        ...payload,
      });
    },
    restock({ context }, { id, payload }) {
      return api.post(url(`restock/drug/${id}`), payload);
    },

    // drug import upload
    import_medicines({ context }, payload) {
      return api.post(url(`import/drug`), payload);
    },
    // drug import upload
    fetch_medicine_names({ context }) {
      return api.get(url(`drug-names`));
    },
    getImportedDrugs({ context }) {
      return api.get(url("drug-imports"));
    },
    edit_imported_Drug({ context }, { drugId, payload }) {
      return api.post(url(`edit-drug-import/${drugId}`), {
        _method: "PATCH",
        ...payload,
      });
    },
    delete_draft_medicine({ context }, id) {
      return api.post(url(`delete-drug-draft/${id}`), { _method: "DELETE" });
    },
    // drug import upload end

    //administering api
    getPatient_Prescriptions({ context }, patient) {
      return api.get(url(`patient/${patient}/prescriptions`));
    },

    getAdministerHistory({ context }, prescriptionId) {
      return api.get(
        url(`prescription/administration/history/${prescriptionId}`)
      );
    },
    administerDrug({ context }, prescriptionId) {
      return api.post(url(`administer/drug/${prescriptionId}`));
    },
    discontinueDrug({ context }, prescriptionId) {
      return api.post(url(`toggle-prescription-status/${prescriptionId}`));
    },

    change_acount_status({ context },id) {
      return api.post(url(`update-drug-status/${id}`), { _method: "PATCH" });
    },
  },
};

export const gopd = {
  namespaced: true,
  actions: {
    getGOPDList({ context }, payload) {
      return api.get(url("gopd/view/appointments"), { params: payload });
    },
    transferPatient({ context }, { id, payload }) {
      console.log(id, payload);
      return api.post(url(`transfer/appointment/${id}`), payload);
    },
  },
};
// staff APIs needs to be reviewed
export const staff = {
  namespaced: true,
  actions: {
    addrole({ context }, payload) {
      return api.post(url(`add/role`), payload);
    },
    addCategory({ context }, payload) {
      return api.post(url("dept/category/add"), payload);
    },
    updateCategory({ context }, payload) {
      return api.post(url(`doctor/category/update/${payload.id}`), payload);
    },
    category({ context }) {
      return api.get(url("view/doctor/category"));
    },
    getStaffList({ context }) {
      return api.get(url("view/staff"));
    },
    change_doctor_category({ context }, { id, payload }) {
      return api.post(url(`staff/edit/category/${id}`), payload);
    },
  },
};

export const lab = {
  namespaced: true,
  actions: {
    getDepartment({ context }) {
      return api.get(url("lab/view/categories"));
    },
    addDepartment({ context }, payload) {
      return api.post(url(`dept/category/add`), payload);
    },

    add_lab_type({ context }, payload) {
      return api.post(url(`lab/create/test/type`), payload);
    },
    edit_lab_type({ context }, { id, payload }) {
      return api.post(url(`lab/edit/test/type/${id}`), payload);
    },
    getTestType({ context }, params) {
      return api.get(url("lab/tests"), { params: params });
    },
    get_lab_techs({ context }, params) {
      return api.get(url("lab/techs"), { params: params });
    },
    make_request({ context }, { id, payload }) {
      return api.post(url(`send/appointment_to/lab/${id}`), payload);
    },
    get_lab_requests({ context }, params) {
      return api.get(url(`lab/appointments`), { params: params });
    },

    collect_sample({ context }, { id, payload }) {
      return api.post(url(`lab/collect/sample/${id}`), payload);
    },
    start_processing({ context }, id) {
      return api.post(url(`lab/process/sample/${id}`));
    },
    completed({ context }, { id, payload }) {
      return api.post(url(`lab/analyze/sample/${id}`), payload);
    },
    sign({ context }, { id, payload }) {
      return api.post(url(`lab/sign/${id}`), payload);
    },
    send_Laboratory_note({ context }, { id, payload }) {
      return api.post(url(`lab/add/note/${id}`), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    who_viewed_report({ context }, payload) {
      return api.post(url(`view-report`), payload);
    },
    // external
    make_external_request({ context }, payload) {
      return api.post(url(`external-lab-request`), payload);
    },
  },
};

export const radiology = {
  namespaced: true,
  actions: {
    getDepartment({ context }) {
      return api.get(url("radiology/view/categories"));
    },
    addDepartment({ context }, payload) {
      return api.post(url(`dept/category/add`), payload);
    },

    add_radiology_type({ context }, payload) {
      return api.post(url(`radiology/create/test/type`), payload);
    },
    edit_radiology_type({ context }, { id, payload }) {
      return api.post(url(`radiology/edit/test/type/${id}`), payload);
    },

    getTestType({ context }, params) {
      return api.get(url("radiology/tests"), { params: params });
    },
    get_radiologist({ context }, params) {
      return api.get(url("radiology/radiologist"), { params: params });
    },
    make_request({ context }, { id, payload }) {
      return api.post(url(`send/appointment_to/radiology/${id}`), payload);
    },
    get_radiology_requests({ context }, params) {
      return api.get(url(`radiology/appointments`), { params: params });
    },
    collect_sample({ context }, { id, payload }) {
      return api.post(url(`radiology/collect/sample/${id}`), payload);
    },
    start_processing({ context }, id) {
      return api.post(url(`radiology/process/sample/${id}`));
    },
    completed({ context }, { id, payload }) {
      return api.post(url(`radiology/analyze/sample/${id}`), payload);
    },
    sign({ context }, { id, payload }) {
      return api.post(url(`radiology/sign/${id}`), payload);
    },
    send_radiology_note({ context }, { id, payload }) {
      return api.post(url(`radiology/add/note/${id}`), payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    // external
    make_external_request({ context }, payload) {
      return api.post(url(`external-radiology-request`), payload);
    },
  },
};

export const roomAllotment = {
  namespaced: true,
  actions: {
    getRoomTypes({ context }) {
      return api.get(url("view/room/types"));
    },
    addRoomType({ context }, payload) {
      return api.post(url("add/room/type"), payload);
    },
    getRoomNumbers({ context }) {
      return api.get(url("view/room/numbers"));
    },
    addRoomNumber({ context }, payload) {
      return api.post(url("add/room/number"), payload);
    },
    new_room_allotment({ context }, { id, payload }) {
      return api.post(url(`allocate/room/${id}`), payload);
    },
    getRoomAllotmentRecords({ context }) {
      return api.get(url("view/allocated/room"));
    },
  },
};

export const dashboards = {
  namespaced: true,
  actions: {
    getAdminDashboard({ context }) {
      return api.get(url("admin/dashboard"));
    },
    getFrontdeskDashboard({ context }) {
      return api.get(url("frontdesk/dashboard"));
    },
    getAdminChartData({ context }, params) {
      return api.get(url("admin/dashboard/patient/chart"), { params: params });
    },
    getdashappointments({ context }) {
      return api.get(url("admin/dashboard/appointments"));
    },

    getGOPDDashboard({ context }) {
      return api.get(url("gopd/dashboard"));
    },
    getGOPDchart({ context }, params) {
      return api.get(url("gopd/dashboard/appointment/chart"), {
        params: params,
      });
    },

    getfinanceDashboard({ context }) {
      return api.get(url("finance/dashboard"));
    },
    getfinancechart({ context }, params) {
      return api.get(url("finance/dashboard/transaction/chart"), {
        params: params,
      });
    },

    getpharmacyDashboard({ context }) {
      return api.get(url("pharmacy/dashboard"));
    },
    getpharmacychart({ context }) {
      return api.get(url("pharmacy/dashboard/transfers/chart"));
    },

    getlaboratoryDashboard({ context }) {
      return api.get(url("lab/dashboard"));
    },

    getradiologyDashboard({ context }) {
      return api.get(url("radiology/dashboard"));
    },
    getNurseDashboard({ context }) {
      return api.get(url("nurse_dashboard"));
    },
  },
};

export const patient_chart = {
  namespaced: true,
  actions: {
    temp_chart({ context }, { id, params }) {
      return api.get(url(`patient/temperature/chart/${id}`), {
        params: params,
      });
    },
    blood_pressure({ context }, { id, params }) {
      return api.get(url(`patient/blood_pressure/chart/${id}`), {
        params: params,
      });
    },
    heart_rate_chart({ context }, { id, params }) {
      console.log(params, "pa");
      return api.get(url(`patient/heart_rate/chart/${id}`), {
        params: params,
      });
    },
  },
};

export const chat = {
  namespaced: true,
  actions: {
    get_chat({ context }) {
      return api.get(url(`chats`));
    },
    send_chat({ context }, payload) {
      return api.post(url("send/message"), payload);
    },
    getSingleChat({ context }, sessionId) {
      return api.get(url(`chats/${sessionId}`));
    },
  },
};

export const subscription = {
  namespaced: true,
  actions: {
    get_subscription({ context }) {
      return api.get(url("subscription/plans"));
    },
    // transferPatient({ context }, { id, payload }) {
    //   console.log(id, payload);
    //   return api.post(url(`transfer/appointment/${id}`), payload);
    // },
  },
};


export const splash_screen = {
  namespaced: true,
  actions: {
    get_splash_screen({ context }) {
      return api.get(url("splash-screen"));
    },

    add_splash_screen({ context }, payload) {
      return api.post(url("splash-screen"), payload);
    },
  },
};

