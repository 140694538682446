<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Records </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Death Records</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Death Records</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" v-model="search_text"
                              @input="search_the_list()" />
                            <a class="btn text-end"><i class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"></i></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <span class="bntn btn-primary add-pluss ms-2 add_room"><img
                              src="../../assets/assets/img/icons/plus.svg" alt="" /></span>
                          <span class="btn btn-primary doctor-refresh ms-2" hidden=""><img
                              src="../../assets/assets/img/icons/re-fresh.svg" alt="" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt="" /></a>
                    <a href="javascript:;"><img src="../../assets/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive" style="overflow: visible">
                <a-table :dataSource="DeathRecords" :columns="columns" :loading="isLoading">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key == 'name'">
                      <span>
                        <img width="28" height="28" src="../../assets/assets/img/profiles/avatar-02.jpg"
                          class="rounded-circle m-r-5" alt="" />
                        {{ record.patient_name }}
                      </span>
                    </template>
                    <template v-if="column.key == 'sex'">
                      <span class="badge bg-success-light">{{
                        record.sex
                      }}</span>
                    </template>

                    <template v-if="column.key == 'death_date'">
                      {{ formatDate(record.death_date, !!record.death_time) }}
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- fixed -->

      <!-- sample modal content model1 -->

      <div id="con-close-modal" class="modal modal-lg mm1 animate__animated animate__zoomIn animate__faster mm2"
        style="margin-top:100px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">New Death Record</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <div>
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Account Number<span class="login-danger">*</span></label>
                      <input class="form-control search" type="number" v-model="account_number" @change="search()" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Patient's Name<span class="login-danger">*</span></label>
                      <input class="form-control search" type="text" v-model="patient_name" disabled />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Cause Of Death
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="cause_of_death" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Next of Kin<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="nok" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>NOK Relationship<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" v-model="nok_relationship" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>NOK Mobile Number<span class="login-danger">*</span></label>
                      <input class="form-control" type="number" placeholder="" v-model="phone_number" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Death Date <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" placeholder="" v-model="death_date" :max="current_date" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Time Of Death
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="time" placeholder="" v-model="death_time" />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2" @click="new_death()">
                        Submit
                      </button>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal -->

      <!-- sample modal content model2 -->

      <div id="con-close-modal" class="modal fade mm2" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Death Record</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <form>
                <div class="row">
                  <div class="col-12"></div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Patient Name<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Cause Of Death<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="male" class="form-check-input" />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="Female" class="form-check-input" />Female
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Guardian Name<span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Time Of Death
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Death Date <span class="login-danger">*</span></label>
                      <input class="form-control" type="date" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">
                        Submit
                      </button>
                      <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form">
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <!-- body ends here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/formatter";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import $ from "jquery";
import { mapActions } from "vuex";


const columns = [
  {
    title: "Patient Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Gender",
    dataIndex: "sex",
    key: "sex",
  },
  {
    title: "Death Date",
    key: "death_date",
  },

  {
    title: "Next Of Kin",
    dataIndex: "nok",
    key: "nok",
  },

  {
    title: "NOK Mobile",
    dataIndex: "phone_number",
    key: "phone_number",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isLoading: true,
      formatDate,
      patients: [],
      DeathRecords: [],
      reserve_DeathRecords: [],
      patient_id: "",
      sex: "",
      death_date: "",
      death_time: "",
      cause_of_death: "",
      phone_number: "",
      address: "",
      account_number: "",
      filtered_patient_list: "",
      patient_name: "",
      search_text: "",
      nok: "",
      nok_relationship: "",
      columns,
      current_date: new Date().toISOString().split("T")[0],

    };
  },

  methods: {
    ...mapActions({
      PatientList: "patient/patientList",
      _new_death: "general/new_death",
      _newDeathRecords: "general/getNewDeathRecords",
    }),

    new_death() {
      $(".inloader").show();

      this._new_death({
        patient_id: this.filtered_patient_list[0].id,
        sex: this.sex,
        death_time: this.death_time,
        death_date: this.death_date,
        phone_number: this.phone_number,
        nok: this.nok,
        cause_of_death: this.cause_of_death,
        nok_relationship: this.nok_relationship,
      })
        .then((response) => {
          $(".inloader").hide();

          $(".mm2").modal("hide");
          this.getDeathRecords();
          this.response = response.data;
          if (this.response["status"] === true) {
            this.$toast.success(this.response["message"]);
            location.reload(true);

            // this.$router.push("Me");
          }
        })
        .catch((error) => {
          $(".mm1").modal("hide");
          $(".inloader").hide();

          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              this.$toast.error(
                "Network Error, Check your internet conectivity."
              );
            }
          }
          // } else {
          //  this.$toast.error("An unexpected error occurred.");
          // }
        });
    },

    getDeathRecords() {
      this._newDeathRecords({})
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.DeathRecords = this.response.data;
            this.DeathRecords = this.DeathRecords;
            this.reserve_DeathRecords = this.DeathRecords;
          }
        })
        .catch((error) => {
          console.log(error);
          // if (error.response && error.response.data) {
          if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            if (status == 400 || status == 422 || status == 401) {
              // this.$toast.error(error.response.data.message);
            } else if (status === 500) {
              // this.$toast.error("Network Error, Check your internet conectivity.");
            }
          }
          // } else {
          //  this.$toast.error("An unexpected error occurred.");
          // }
        }).finally(() => {
          this.isLoading = false;
        });;
    },

    getPatientData() {
      this.PatientList()
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] === true) {
            this.patients = this.response["data"];
            this.patients = this.patients;
          }
        })
        .catch((error) => {
          // console.log(error);
          console.log("Error fetching data", error);
        });
    },

    search() {
      this.filtered_patient_list = this.patients.filter(
        (acct) => acct.account_number == this.account_number
      );

      if (this.filtered_patient_list.length > 0) {
        this.patient_name =
          this.filtered_patient_list[0].first_name +
          " " +
          this.filtered_patient_list[0].last_name;
      } else {
        this.patient_name = "No Matching Record";
      }
      console.log(this.patient_name);
    },
    search_the_list() {
      if (this.search_text == "") {
        this.DeathRecords = this.reserve_DeathRecords;
      } else {
        this.DeathRecords = this.reserve_DeathRecords;
        var searchLetter = this.search_text.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.DeathRecords.length; i++) {
          var patient_name = this.DeathRecords[i].patient_name.toLowerCase();
          var nok = this.DeathRecords[i].nok.toLowerCase();
          var sex = this.DeathRecords[i].sex.toLowerCase();
          var death_date = this.DeathRecords[i].death_date.toLowerCase();
          var phone_number = this.DeathRecords[i].phone_number;
          var address = this.DeathRecords[i].address.toLowerCase();
          if (
            sex.includes(searchLetter) ||
            death_date.includes(searchLetter) ||
            phone_number.includes(searchLetter) ||
            address.includes(searchLetter) ||
            patient_name.includes(searchLetter) ||
            nok.includes(searchLetter)
          ) {
            searchResult.push(this.DeathRecords[i]);
          }
          // if (searchResult == []) {
          //   this.DeathRecords = this.DeathRecords;
          // }
        }
      }
      if (this.search_text == "") {
        this.DeathRecords = this.reserve_DeathRecords;
      } else {
        this.DeathRecords = searchResult;
      }
    },
  },

  mounted() {
    $(".add_room").click(function () {
      $(".mm1").modal("show");
    });
    $(".edit_btn").click(function () {
      $(".mm2").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    this.getPatientData();
    this.getDeathRecords();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */
</style>
