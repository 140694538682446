<template>
  <div class="chat-container">
        <div class="chat-message skeleton">
            <div class="avatar skeleton"></div>
            <div class="text">
                <div class="name skeleton"></div>
                <div class="message skeleton"></div>
            </div>
        </div>
        <div class="chat-message skeleton">
            <div class="avatar skeleton"></div>
            <div class="text">
                <div class="name skeleton"></div>
                <div class="message skeleton"></div>
            </div>
        </div>
        <div class="chat-message skeleton">
            <div class="avatar skeleton"></div>
            <div class="text">
                <div class="name skeleton"></div>
                <div class="message skeleton"></div>
            </div>
        </div>
        <div class="chat-message skeleton">
            <div class="avatar skeleton"></div>
            <div class="text">
                <div class="name skeleton"></div>
                <div class="message skeleton"></div>
            </div>
        </div>
        <div class="chat-message skeleton">
            <div class="avatar skeleton"></div>
            <div class="text">
                <div class="name skeleton"></div>
                <div class="message skeleton"></div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  
  
  export default {
    name: 'Message_loader',
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.chat-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width:90%;
    margin-left: 5%;
}

.chat-message {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    overflow: hidden;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e0e0e0;
    flex-shrink: 0;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
}

.name {
    width: 30%;
    height: 15px;
    background-color: #e0e0e0;
    border-radius: 5px;
}

.message {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 5px;
}

.skeleton {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

  </style>
  