<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />
  <div id="app">
    <SetPassword />
    <SetPIn />
    <OnboardSettings />

    <div class="cont">
      <div class="main-wrapper">
        <Header
          v-if="
            $route.name != 'Login' &&
            $route.name != 'Lock_Screen' &&
            $route.name != 'Forgot_Password' &&
            $route.name != 'Reset_Password' &&
            $route.name != 'Welcome' &&
            $route.name != 'Suspension_Page' &&
            $route.name != 'Payment_Checkout'
          "
        />
        <Sidebar
          v-if="
            $route.name != 'Login' &&
            $route.name != 'Lock_Screen' &&
            $route.name != 'Forgot_Password' &&
            $route.name != 'Reset_Password' &&
            $route.name != 'Welcome' &&
            $route.name != 'Suspension_Page' &&
            $route.name != 'Payment_Checkout'
          "
        />
        <router-view />
      </div>
      <Subscription />
    </div>
  </div>
</template>
<script>
import { store } from "./store";
import SetPIn from "./components/plugins/Set_Pin";
import SetPassword from "./components/plugins/Set_password";
import OnboardSettings from "./components/plugins/Onboard_settings";
import Sidebar from "./components/plugins/Side_bar";
import Header from "./components/plugins/Header";

import { mapActions } from "vuex";
import Loader from "./components/plugins/Loader.vue";
import Inloader from "./components/plugins/inloader.vue";
import Subscription from "./components/plugins/Subscription.vue";

export default {
  name: "App",
  components: {
    SetPIn,
    Sidebar,
    Header,
    SetPassword,
    OnboardSettings,
    Loader,
    Inloader,
    Subscription,
  },
  data() {
    return {
      inactivityTimeout: null,
    };
  },
  // watch: {
  //   "$route": {
  //     handler() {
  //       if (localStorage.getItem("isLocked")) {
  //         if (this.$route.name != "Lock_Screen" && this.$route.name != "Login") {
  //           localStorage.setItem("lastRoute", this.$route.fullPath);
  //           window.location = "/Lock-Screen";
  //         }
  //       }
  //     },
  //   },
  //   "$store.state.pendingRequests": {
  //     handler() {
  //       if (this.$store.state.pendingRequests > 0) {
  //         $("#inloader").show();
  //       } else {
  //         $("#inloader").hide();
  //       }
  //     },
  //   },
  // },
  async mounted() {
    if (store.state.isAuth) {
      await store.dispatch("fetchAdminProfile");
    }
    window.toast = this.$toast;
    // // add select and form-control class to all select to enable color change
    // document.addEventListener('DOMContentLoaded', function () {
    //   var selects = document.querySelectorAll('select');
    //   selects.forEach(function (select) {
    //     select.classList.add("select", "form-control")
    //   });
    // });
    if (!this.$store.state.isOnFlutter) {
      this.startInactivityTimer();
      // Set up event listeners for user activity
      window.addEventListener("mousemove", this.resetInactivityTimer);
      window.addEventListener("keydown", this.resetInactivityTimer);
      window.addEventListener("touchstart",this.resetInactivityTimer); 

      // if (localStorage.getItem("checker") == 1) {
      // alert('k')
      // store.dispatch("getAdminDetails");

      setInterval(() => {
        // if (this.$route.name != "Lock_Screen" && this.$route.name != "Login") {
        //   if (localStorage.getItem("pin") == 'false') {
        //     $("#set_pin").modal("show");
        //   }
        // }

        if (
          this.$route.name != "Lock_Screen" &&
          this.$route.name != "Login" &&
          this.$route.name != "Welcome" &&
          this.$route.name != "Forgot_Password" &&
          this.$route.name != "Reset_Password" &&
          this.$route.name != "Suspension_Page" &&
          this.$route.name != "Payment_Checkout"
        ) {
          if (
            localStorage.getItem("password_change") == "false" ||
            localStorage.getItem("password_change") == null
          ) {
            $("#set_password").modal("show");
          } else if (
            localStorage.getItem("pin") == "false" ||
            localStorage.getItem("pin") == null
          ) {
            $("#set_pin").modal("show");
          } else if (
            localStorage.getItem("settings") == "false" ||
            localStorage.getItem("settings") == null
          ) {
            $("#Onboard_settings").modal("show");
          }
        }
      }, 0); //5000
    } else {
    }
  },
  methods: {
    ...mapActions({
      // _set_satus: "general/et_satus",
    }),
    //  set_satus() {
    //   this._set_satus({
    //     status: "away",
    //   })
    //     .then((response) => {
    //       this.response = response.data;
    //       if (this.response["status"] == true) {
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       if (error.response) {
    //         const status = error.response.status;
    //         const data = error.response.data;

    //         if (status == 400 || status == 422 || status == 401) {
    //           this.$toast.error(error.response.data.message);
    //         } else if (status === 500) {
    //           this.$toast.error(
    //             "Network Error, Check your internet conectivity."
    //           );
    //         }
    //       }
    //     });
    // },
    resetInactivityTimer() {
      // Reset the inactivity timer
      clearInterval(this.inactivityTimeout);
      this.startInactivityTimer();
    },
    startInactivityTimer() {
      if (localStorage.getItem("pin") == "true") {
        this.inactivityTimeout = setInterval(() => {
          if (
            this.$route.name != "Lock_Screen" &&
            this.$route.name != "Login"
          ) {
            localStorage.setItem("lastRoute", this.$route.fullPath);
            localStorage.setItem("isLocked", true);
            window.location = "/Lock-Screen";
          }
        }, 300000); // 300000 = 5 minutes (adjust as needed)
      }
    },
  },
};

// $('#myModal').modal({
//     backdrop: 'static',
//     keyboard: false
// })
</script>

<style>
/* .router-link,a{color:red} */
body {
  scrollbar-width: 10px;
  /* zoom: 90%; */
  /* height: 100%; */
}

.table,
table {
  overflow: visible;
}

.animated {
  -webkit-animation-duration: 0.0000001s;
  animation-duration: 0.0000001s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

::-webkit-scrollbar-thumb {
  /* background-color: rgb(20, 167, 158);  */
  background-color: rgb(9, 146, 137);
  /* Set the color of the scrollbar thumb */
}

.dropdown-action:hover,
.list-unstyled,
.chat-users,
.chat-user-group,
.send-chat {
  cursor: pointer;
}

/* button:where(.swal2-styled):where(.swal2-confirm){border:0;border-radius:.25em;background:initial;background-color:#14a79e;color:#fff;} */
</style>
