<template>
  <div class="loadercontw">
  <div class="lloaderw" id="lloaderw"></div>
  </div>
  </template>
  
  <script>
  
  
  
  export default {
    name: 'Inloader',
  //  mounted(){}
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  .loadercontw{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    overflow: visible;
    background:rgba(0, 0, 0, 0.309);
  display: none;
  padding-top: 20%;
  padding-left: 50%;
    text-align: center;
  }
  
  .lloaderw {
    border: 6px solid white;
    border-top: 4px solid #14a79e;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  </style>
  