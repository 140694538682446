<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Pharmacy </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Dispensory</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">

          <filter-table :loading="isLoading" :data="dispensory" :columns="columns" title="Dispensory"
            :search-keys="['patient_name']">

            <template #bodyCell="{ column, record }">
              <template v-if="column.key == 'patient_type'">
                  <span v-if="record.patient_type=='in_patient'">In Patient</span>
                  <span v-if="record.patient_type=='out_patient'">Out Patient</span>
                </template>
              <template v-if="column.key == 'action'">
                <span class="btn btn-primary me-2" @click="view_prescriptions(record)">View
                  prescriptions</span>
              </template>
            </template>
          </filter-table>
        </div>
      </div>
    </div>
  </div>
  <Prescription :prescription_data_recieved="prescriptions" />

</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import Prescription from "./plugin/prescriptions.vue";

import jquery from "jquery";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Patient Name",
    dataIndex: "patient_name",
    key: "patient_name",
  },
  {
    title: "Patient Type",
    dataIndex: "patient_type",
    key: "patient_type",
  },

  {
    key: "action",
  },

];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    Prescription
  },

  data() {
    return {
      isLoading: true,
      dispensory: [],
      search: "",
      dispensory_reserve: [],
      columns,
      prescriptions: []
    };
  },

  methods: {
    ...mapActions({
      _getDispensory: "pharmacy/getDispensory",
      _dispense_drugs: "pharmacy/dispense_drugs",
    }),

    getDispensory() {
      this._getDispensory({ pending: 1 })
        .then((response) => {
          this.response = response.data;
          if (this.response["status"] == true) {
            this.dispensory = this.response.data;
            this.dispensory_reserve = this.dispensory;
            // this.$toast.success(this.response["message"]);
            // console.log(this.drug_categories);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("Error fetching data", error);
        }).finally(() => {
          this.isLoading = false;
        });;
    },


    search_the_list() {
      if (this.search == "") {
        this.dispensory = this.dispensory_reserve;
      } else {
        this.dispensory = this.dispensory_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.dispensory.length; i++) {
          var drug_name = this.dispensory[i].drug_name.toLowerCase();
          var price = this.dispensory[i].price;
          var date = this.dispensory[i].date;
          if (
            drug_name.includes(searchLetter) ||
            price.includes(searchLetter) ||
            date.includes(searchLetter)
          ) {
            searchResult.push(this.dispensory[i]);
          }
          // if (searchResult == []) {
          //   this.dispensory = this.dispensory;
          // }
        }
      }
      if (this.search == "") {
        this.dispensory = this.dispensory_reserve;
      } else {
        this.dispensory = searchResult;
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    view_prescriptions(prescription_data) {
      this.prescriptions = prescription_data;
      $("#prescriptions-modal").modal("show");

    },
  },

  mounted() {
    $(".add_room").click(function () {
      $("#con-close-modal").modal("show");
    });
    $(".cancel-form").click(function () {
      $("#con-close-modal").modal("hide");
    });
    this.getDispensory();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* #con-close-modal{width:60%} */
</style>
