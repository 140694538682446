<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="#">Timeline </router-link>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">User Timeline</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- timeline goes here -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <!-- added -->
            <div class="card-body">
              <div class="doctor-search-blk" id="highx">
                <div class="top-nav-search table-search-blk" style="width: 95%">
                  <div v-if="patient_info?.first_name">

                    <p> {{ patient_info.first_name }} {{ patient_info.last_name }} ({{ patient_info.sex }}, {{ patient_info.age }})</p> 
                  </div>
                  <div class="tabs">
                    
                    <button class="tab-button active" @click="showTab('all', 1)">
                      All
                    </button>
                    <button class="tab-button" @click="showTab('note', 1)">
                      Note
                    </button>
                    <button class="tab-button" @click="showTab('prescriptions', 1)">
                      Prescription
                    </button>
                    <button class="tab-button" @click="showTab('diagnosis', 1)">
                      Diagnosis
                    </button>
                    <button class="tab-button" @click="showTab('examination', 1)">
                      Examination
                    </button>
                    <button class="tab-button" @click="showTab('operation', 1)">
                      Operation
                    </button>
                    <button class="tab-button" @click="showTab('lab_cont', 0)">
                      Laboratory
                    </button>
                    <button class="tab-button" @click="showTab('rad_cont', 0)">
                      Radiology
                    </button>
                    <button class="tab-button" @click="showTab('summary', 1)">
                      Summary
                    </button>
                    <button class="tab-button" @click="showTab('administer_cont', 0)">
                      Administer
                    </button>
                    <button class="tab-button" @click="showTab('mis_cont', 0)">
                      Miscellaneous
                    </button>
                  </div>
                </div>
                <div class="add-group">
                  <router-link to="Patient-Note" class="btn btn-primary add-pluss ms-2" id="plusbutt">
                    <img src="../../assets/assets/img/icons/plus.svg" alt="" /></router-link>
                </div>
              </div>
              <!-- added end-->

              <div class="activity">
                <div class="activity-box">
                  <ul class="activity-list all_cont" id="doctor_main_note">
                    <li v-for="(data, index) in timelineData[currentTab]" :key="index"
                      id="sections_in_doctor_main_note">
                      <!-- v-if="
                        data?.note ||
                        data?.prescriptions ||
                        data?.examination ||
                        data?.diagnosis ||
                        data?.lab
                      " -->
                      <div>
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <h4>{{ formatDate(data.created_at) }}</h4>
                            <span class="time">{{ formattedTime(created_at) }}</span>
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <h3>
                              {{ data?.doctor_name }}
                              <!-- && data?.note=='' -->
                              <!-- <span v-if="data?.patient_result_image">  Uploaded some photo(s)</span> -->
                              <span v-if="
                                data?.length !== 0
                              ">
                                Added
                                {{ getTitle(data) }}
                              </span>
                            </h3>
                            <p>
                              <i> Added On {{ formatDate(data.created_at, true) }}.</i>
                            </p>
                            <br />


                            <div v-if="data?.note?.note_text" class="note_cont all_cont in_doctor_main_note">
                              <span style="color: #14a79e; display: block">Note</span>
                              <span class="mcont"> {{ data?.note?.note_text }}</span>

                              <div class="imgcont" v-if="data?.note?.note_images?.length > 0">
                                <div class="imgcontsm" v-for="(img, index) in data?.note?.note_images" :key="index">
                                  <img class="image" :src="img.avatar" alt="" @click="imageviewer($event)" />
                                </div>
                              </div>
                            </div>

                            <div class="prescription_cont all_cont in_doctor_main_note">
                              <div v-if="data?.prescriptions?.length > 0">
                                <span style="color: #14a79e; display: block">Prescription</span>
                              </div>
                              <div class="prescription">
                                <ul v-for="p in data?.prescriptions" v-bind:key="p.index" style="margin-bottom: 20px"
                                  class="mcont">
                                  <li v-if="p.is_external"><i class="badge badge-sm badge-primary">External</i> | {{ p.external_institution	 }}</li>
                                  <li><span>Note:</span> {{ p.note }}</li>
                                  <li><span>Drug Name :</span> {{ p.drug }}</li>
                                  <li v-if="p.interval == null">
                                    <span>Duration: </span>{{ p.duration }} days |
                                    <span>Quantity: </span>{{ p.quantity }} |
                                    <span>Frequency: </span>{{ p.frequency }} times daily |
                                    <span>When to take: </span>{{ p.intake_time }}
                                  </li>
                                  <li v-else-if="p.frequncy == null">
                                    <span>Duration: </span>{{ p.duration }} days |
                                    <span>Quantity: </span>{{ p.quantity }} |
                                    <span>Interval: </span>every {{ p.interval }} hours |
                                    <span>When to take: </span>{{ p.intake_time }}
                                  </li>

                                  <!-- <br />{{p?.status}} -->
                                  <br />
                                  <div v-if="p?.status">
                                    <p v-for="(prescriptionUpdate, index) in p?.status" :key="index">
                                      <span class="d-flex flex-wrap gap-2 align-items-center">
                                        <span class="badge badge-primary">Status</span>
                                        <span class="fs-6">This medication was {{ prescriptionUpdate.type }}d on
                                          <b>{{ formatDate(prescriptionUpdate.created, true) }}</b> by {{
                                            prescriptionUpdate.admin }}</span>
                                      </span>
                                      <hr />
                                    </p>
                                  </div>
                                </ul>
                              </div>
                            </div>


                            <div v-if="data?.summary?.summary_text" class="summary_cont all_cont in_doctor_main_note">
                              <span style="color: #14a79e; display: block">Summary</span>
                              <pre v-html="data?.summary.summary_text" id="tyrabanks" class="mcont"></pre>
                            </div>

                            <div v-if="data?.operation?.operation_text" style="background-color: #fed5e0"
                              class="operation_cont all_cont in_doctor_main_note">
                              <span style="display: block">Operation Note</span>
                              <pre v-html="data?.operation.operation_text" class="mcont"></pre>
                            </div>

                            <div v-if="data?.diagnosis?.diagnosis_text"
                              class="diagnosis_cont all_cont in_doctor_main_note">
                              <span style="color: #14a79e; display: block">Diagnosis</span>
                              <span class="mcont">{{ data?.diagnosis.diagnosis_text }}</span>

                              <div class="imgcont" v-if="data?.diagnosis.diagnosis_images.length !== 0">
                                <div class="imgcontsm" v-for="(img, index) in data?.diagnosis.diagnosis_images"
                                  :key="index">
                                  <img class="image" :src="img.avatar" alt="" @click="imageviewer($event)" />
                                </div>
                              </div>
                            </div>
                            <div v-if="data?.examination?.examination_text"
                              class="examination_cont all_cont in_doctor_main_note">
                              <span style="color: #14a79e; display: block">Examination</span>
                              <span class="mcont">{{ data?.examination.examination_text }}</span>

                              <div class="imgcont" v-if="data?.examination.examination_images.length !== 0">
                                <div class="imgcontsm" v-for="(img, index) in data?.examination
                                  .examination_images" :key="index">
                                  <img class="image" :src="img.avatar" alt="" @click="imageviewer($event)" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- <hr /> -->
                  <ul class="activity-list administer_cont all">
                    <li v-for="(data, index) in administeredPrescriptions" :key="index">
                      <div v-if="data?.administered_by || data?.drug || data?.dosage">
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <h4>{{ formatDate(data.created_at) }}</h4>
                            <!-- <span class="time">{{ formattedTime(created_at) }}</span> -->
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <h3>
                              {{ data?.administered_by }}
                              <!-- && data?.note=='' -->
                              <!-- <span v-if="data?.patient_result_image">  Uploaded some photo(s)</span> -->
                              <span> Administered A Prescription</span>
                            </h3>
                            <p><i>On {{ formatDate(data.created_at, true) }}</i></p>

                            <div>
                              <span style="color: #14a79e; display: block">Administer</span>
                            </div>

                            <div class="prescription">
                              <ul style="margin-bottom: 20px">
                                <li><span>Drug Name :</span> {{ data.drug }}</li>
                                <li><span>Dosage :</span> {{ data.dosage }}</li>

                                <!-- <li></li> -->
                              </ul>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- <hr /> -->
                  <ul class="activity-list lab_cont all">
                    <li v-for="(labTest, index) in lab" :key="index">
                      <div v-if="labTest?.note">
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <h4>{{ formatDate(labTest.created_at) }}</h4>
                            <!-- <span class="time">{{ formattedTime(created_at) }}</span> -->
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <h3>
                              {{ labTest?.doctor_id }}

                              <span v-if="labTest?.note"> Added a Laboratory note</span>
                            </h3>
                            <span v-if="labTest?.is_external"><i class="badge badge-sm badge-primary">External</i> | {{ labTest?.external_institution	 }}</span>

                            <p v-for="(note, index) in labTest?.note_history" :key="index">
                              <i>
                                {{ note.is_current ? "Added" : "Updated" }} on {{ formatDate(note.created_at, true) }}
                              </i>.
                              <br>
                            <p v-html="note.note"></p>
                            <hr>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul class="activity-list rad_cont all">
                    <li v-for="(radiologyTest, index) in rad" :key="index">
                      <div v-if="radiologyTest?.note">
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <h4>{{ formatDate(radiologyTest.created_at) }}</h4>
                            <span class="time">{{ formattedTime(created_at) }}</span>
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <h3>
                              {{ radiologyTest?.doctor_id }}

                              <span v-if="radiologyTest?.note"> Added a Radiology note.</span>
                            </h3>
                            <span v-if="radiologyTest?.is_external"><i class="badge badge-sm badge-primary">External</i> | {{ radiologyTest?.external_institution	 }}</span>

                            <p v-for="(note, index) in radiologyTest?.note_history" :key="index">
                              <i>
                                {{ note.is_current ? "Added" : "Updated" }} By {{ note.admin.name }} on
                                {{ formatDate(note.created_at, true) }} </i>:<br> <span v-html="note.note"></span>
                              <hr>
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul class="activity-list mis_cont all" v-if="checkins.length!=0 || print_history.length!=0 || view_report_history.length!=0">
                    <li>
                      <div v-if="checkins">
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <!-- <h4>{{ formatDate(radiologyTest.created_at) }}</h4>
                            <span class="time">{{ formattedTime(created_at) }}</span> -->
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <div>
                              <p v-for="(checkin, index) in checkins" :key="index">
                                <span class="d-flex flex-wrap gap-2 align-items-center">
                                  <span class="badge badge-primary">Check in</span>
                                  <span class="fs-6">Checked in on
                                    <b>{{ formatDate(checkin.checked_in_at, true) }}</b> by {{ checkin.check_in_admin }}
                                  </span>
                                  <br>
                                  <br>
                                  <span v-if="checkin.checked_out_at != null">
                                    <span class="badge badge-primary">Check out</span>
                                    <span class="fs-6">Checked out on
                                      <b>{{ formatDate(checkin.checked_out_at, true) }}</b> by {{
                                        checkin.checked_out_admin }} </span>
                                  </span>
                                  <span v-else>
                                    <span class="badge badge-primary">Check out</span>
                                    <span class="fs-6"> Not checked out yet</span>
                                  </span>

                                </span>
                                <hr />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="print_history.length > 0">
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <!-- <h4>{{ formatDate(radiologyTest.created_at) }}</h4>
                            <span class="time">{{ formattedTime(created_at) }}</span> -->
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <p v-for="(ph, index) in print_history" :key="index">
                              <span class="d-flex flex-wrap gap-2 align-items-center">
                                <span class="badge badge-primary">Who Printed</span>
                                <span class="fs-6">{{ ph.admin_id }}, printed a {{ ph.target }} report on
                                  <b>{{ formatDate(ph.created_at, true) }}</b> </span>

                              </span>
                              <hr />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div v-if="view_report_history.length > 0">
                        <div class="activity-user">
                          <a href="#" title="" data-bs-toggle="tooltip" class="avatar">
                            <img alt="" src="../../assets/assets/img/user-06.jpg" class="img-fluid rounded-circle" />
                          </a>
                        </div>
                        <div class="activity-content timeline-group-blk">
                          <div class="timeline-group flex-shrink-0">
                            <!-- <h4>{{ formatDate(radiologyTest.created_at) }}</h4>
                            <span class="time">{{ formattedTime(created_at) }}</span> -->
                          </div>
                          <div class="comman-activitys flex-grow-1">
                            <p v-for="(ph, index) in view_report_history" :key="index">
                              <span class="d-flex flex-wrap gap-2 align-items-center">
                                <span class="badge badge-primary">Who Viewed Report</span>
                                <span class="fs-6">{{ ph.admin_id }}, viewed {{ ph.target }} report on
                                  <b>{{ formatDate(ph.created_at, true) }}</b> </span>

                              </span>
                              <hr />
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <!-- v-if="data?.note || data?.prescription || data?.examination || data?.diagnosis" -->
                </div>

                <!-- <div
                      style="text-align: center; margin-top: 12%; padding-bottom: 20px"
                      v-if="result == '' || result == null"
                    >
                      <i
                        class="fas fa-box-open"
                        style="font-size: 120px; color: #14a79e; opacity: 0.4"
                      ></i>
                      <div class="font-size:24px">No data yet</div>
                    </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          style="text-align: center; margin-top: 12%"
          v-if="result == '' || result == null"
        >
          <i
            class="fas fa-box-open"
            style="font-size: 150px; color: #14a79e; opacity: 0.4"
          ></i>
          <div class="font-size:24px">No data yet</div>
        </div> -->
      </div>

      <!-- sample modal content ;overflow-y:scroll-->

      <div id="con-close-modal" class="modal modal-lg animate__animated animate__zoomIn animate__faster"
        style="margin-top:80px;margin-left:100px;margin-right" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <!-- <h4 class="modal-title">Send patient to GOPD</h4> -->
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-4">
              <!-- body starts here -->
              <div class="row">
                <div class="col-12 col-sm-12">
                  <img id="previewer" src="" alt="" width="100%" height="400px" />
                </div>
              </div>

              <!-- body ends here -->
            </div>
          </div>
          <!-- /.modal -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/formatter";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      formatDate,

      patient_info: JSON.parse(localStorage.getItem("patient_info") ?? '{}'),
      result: JSON.parse(localStorage.getItem("result")),
      lab: JSON.parse(localStorage.getItem("lab")),
      checkins: JSON.parse(localStorage.getItem("checkins")),
      rad: JSON.parse(localStorage.getItem("rad")),
      print_history: JSON.parse(localStorage.getItem("print_history")),
      view_report_history: JSON.parse(localStorage.getItem("view_report_history")),

      administeredPrescriptions: JSON.parse(
        localStorage.getItem("administeredPrescriptions")
      ),
      currentTab: 'all',
      timelineData: {
        all: [],
        note: [],
        prescriptions: [],
        diagnosis: [],
        examination: [],
        operation: [],
        summary: [],
      }
    };
  },

  methods: {

    getTitle(data) {
      const availableTexts = [
        data?.note?.note_text && 'Note',
        data?.prescriptions?.length > 0 && 'Prescriptions',
        data?.examination?.examination_text && 'Examination',
        data?.diagnosis?.diagnosis_text && 'Diagnosis',
        data?.operation?.operation_text && 'Operation Note',
        data?.summary?.summary_text && 'Nurse Summary'
      ].filter(Boolean); // Filter out any falsey values

      if (availableTexts.length === 0) return 'No available data';

      // Join available texts into a single string
      const title = availableTexts.length === 1
        ? availableTexts[0]
        : `${availableTexts.slice(0, -1).join(', ')} and ${availableTexts.slice(-1)}`;

      // Determine the correct prefix based on the first letter
      const prefix = /^[aeiouAEIOU]/.test(title) ? 'an' : 'a';

      return `${prefix} ${title}`;
    },

    imageviewer(E) {
      var previewer = document.getElementById("previewer");

      var img = E.target;
      img = img.src;
      previewer.src = img;
      $("#con-close-modal").modal("show");
    },
    formattedTime(created_at) {
      const dateObject = new Date(created_at);
      // Check if the date is valid
      if (isNaN(dateObject.getTime())) {
        return "";
      }
      const options = { hour: "numeric", minute: "numeric", second: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(dateObject);
    },
    showTab(tabId, group) {
      this.currentTab = tabId;
      // Hide all tab content
      const contents = document.querySelectorAll(".all");
      contents.forEach((content) => content.style.display = 'none');

      // Remove active class from all buttons
      const buttons = document.querySelectorAll(".tab-button");
      buttons.forEach((button) => button.classList.remove("active"));

      // Show the selected tab and set the corresponding button to active
      if (group == 1) {
        document.getElementById('doctor_main_note').style.display = 'block'
        const target_el = document.querySelectorAll(`.${tabId}`);
        target_el.forEach((target) => target.style.display = 'block');
        this.loopThroughChildrenWithClass()

      }
      else {
        const target_el = document.querySelectorAll(`.${tabId}`);
        target_el.forEach((target) => target.style.display = 'block');
      }

      event.currentTarget.classList.add("active");


      // const target_el = document.querySelectorAll(`.all_cont .${tabId}`);

      // if(group==1){document.getElementById('doctor_main_note').style.display='none';}
      // else{document.getElementById('doctor_main_note').style.display='block';}

    },

    loopThroughChildrenWithClass() {
      // Get the parent element by ID
      var parent = document.getElementById('sections_in_doctor_main_note');

      // If the parent doesn't exist, exit the function
      if (!parent) return;

      // Select all child elements of the parent with the specific class
      var childrenWithClass = parent.querySelectorAll('.in_doctor_main_note');
      // Loop through each child element with the specific class
      childrenWithClass.forEach(function (child) {
        // You can check any condition here. For example:
        const main_deal = child.querySelectorAll('.mcont')
        console.log('main_deal:', main_deal);



        // var isHidden = (child.style.display === 'none');
        // var isEmpty = child.innerHTML.trim() === '';  // Check if the content is empty

        // if (isHidden || isEmpty) {
        //   parent.style.display = 'none'
        //   console.log('This child is either hidden or empty:', child);
        // }
        // else {
        //   parent.style.display = 'block'

        // }
      });
    },
    loadTimelineData() {

      for (const resultKey in this.result) {
        const data = this.result[resultKey];
        for (const key in data) {

          // Check if the key exists in timelineData
          if (this.timelineData.hasOwnProperty(key)) {
            // Skip entries with null values in specific fields
            if (['note', 'diagnosis', 'examination', 'operation', 'laboratory', 'summary', 'radiology'].includes(key) && data[key][`${key}_text`] == null) {
              continue; // Skip to the next iteration if the text is null
            }
            if (key == 'prescriptions' && data[key].length == 0) {
              continue;
            }
            // Add the corresponding data to the specific timeline array
            const entry = {
              [key]: data[key],
              created_at: data.created_at,
              doctor_name: data.doctor_name
            };

            this.timelineData[key].push(entry);
            this.timelineData.all.push(entry);
          }
        }
      }
    }
  },

  mounted() {
    jQuery(document).ready(function ($) {
      //Use this inside your document ready jQuery
      $(window).on("popstate", function () {
        location.reload(true);
      });
    });
    this.loadTimelineData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view_more {
  font-size: 14px;
  text-align: right;
  color: #14a79e;
}

.imgcontsm {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  max-width: 200px;
  min-width: 200px;
  /* min-height:200px */
}

.image {
  height: 130px;
  width: 100%;
}

.imgcont {
  overflow-x: scroll;
  width: auto;
  overflow-y: hidden;
  display: flex;
  height: auto;
}

.comman-activitys {
  background: rgba(51, 52, 72, 0.05);
  border-radius: 0px 16px 16px 16px;
  padding: 20px;
  width: 70%;
}

*::-webkit-scrollbar {
  scrollbar-width: 20px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  flex: 1;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: #f9f9f9;
  color: #333;
  border-right: 1px solid #14a79e ;
  border-top-right-radius: 15px;
  /* border-top-left-radius: 15px; */
  /* box-shadow: 2px 2px rgba(228, 228, 228, 0.329); */
}

.tab-button.active {
  background-color: #14a79e !important;
  color: #fff !important;
}

.tab-content {
  display: none;
  padding: 15px;
}


/* .all_cont.active {
  display: block;
} */

@media (max-width: 1280px) {
  #tyrabanks {
    overflow: scroll !important;
    /* Enable horizontal scrolling */
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  .timeline-group-blk {
    display: block;
  }
}

@media (max-width: 800px) {
  #tyrabanks {
    overflow: scroll !important;
    /* Enable horizontal scrolling */
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  .timeline-group-blk {
    display: block;
  }

  .tabs {
    overflow-x: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    width: 100% !important;
  }

  #highx {
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    justify-content: space-between;
    gap: 20px;
  }

  .add-group {
    width: 100%;
  }

  #plusbutt {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #tyrabanks {
    overflow: scroll !important;
    /* Enable horizontal scrolling */
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
  }

  .tabs {
    overflow-x: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: #14a79e #3334480d;
    width: 100% !important;
  }

  #highx {
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    justify-content: space-between;
    gap: 20px;
  }

  .add-group {
    width: 100%;
  }

  #plusbutt {
    width: 100%;
  }
}
</style>
