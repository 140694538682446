<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />


      <div class="page-wrapper">
        <div class="content">
          <!-- Page Header -->
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Department </a></li>
                  <li class="breadcrumb-item"><i class="feather-chevron-right"></i></li>
                  <li class="breadcrumb-item active">Add Department</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /Page Header -->
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <form>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-heading">
                          <h4>Add Department</h4>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Department Name <span class="login-danger">*</span></label
                          >
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms">
                          <label
                            >Department Head <span class="login-danger">*</span></label
                          >
                          <input class="form-control" type="text" />
                        </div>
                      </div>
                      <div class="col-12 col-sm-12">
                        <div class="input-block local-forms">
                          <label>Description <span class="login-danger">*</span></label>
                          <textarea class="form-control" rows="3" cols="30"></textarea>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block local-forms cal-icon">
                          <label
                            >Department Date <span class="login-danger">*</span></label
                          >
                          <input class="form-control datetimepicker" type="text" />
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-6">
                        <div class="input-block select-gender">
                          <label class="gen-label"
                            >Status <span class="login-danger">*</span></label
                          >
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                class="form-check-input"
                              />Active
                            </label>
                          </div>
                          <div class="form-check-inline">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                class="form-check-input"
                              />In Active
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="doctor-submit text-end">
                          <button type="submit" class="btn btn-primary submit-form me-2">
                            Create Department
                          </button>
                          <button
                            type="submit"
                            class="btn btn-primary cancel-form bg-danger text-light cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
       
    </div>
  </div>
</template>
<script>


import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Index_Page",
  components: {
    
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {};
  },

  methods: {},

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
