<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Accounts </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Pending Payments</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Payment List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                              v-model="search"
                              @input="search_the_list()"
                            />
                            <a class="btn text-end" @click="search_the_list()"
                              ><i
                                class="fa fa-search"
                                style="color: #14a79e; font-size: 18px"
                              ></i
                            ></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="/Add-Payment"
                            class="btn btn-primary add-pluss ms-2"
                            ><img
                              src="../../assets/assets/img/icons/plus.svg"
                              alt=""
                          /></router-link>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            hidden=""
                            ><img
                              src="../../assets/assets/img/icons/re-fresh.svg"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-01.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-02.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-03.svg"
                        alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img
                        src="../../assets/assets/img/icons/pdf-icon-04.svg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" style="overflow: visible">
            <a-table :dataSource="main_data" :columns="columns" :loading="isloading">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'acct_number'">
                  <span style="cursor: pointer; color: #14a79e">
                    {{ record.acct_number }}
                  </span>
                </template>

                <template v-if="column.key == 'total_amount'">
                  <span>
                    ₦ {{ formatNumberWithCommas(record.total_amount) }}
                  </span>
                </template>
                <template v-if="column.key == 'paid_amount'">
                  <span>
                    ₦ {{ formatNumberWithCommas(record.paid_amount) }}
                  </span>
                </template>
                <template v-if="column.key == 'due'">
                  <span> ₦ {{ formatNumberWithCommas(record.due) }} </span>
                </template>
                <template v-if="column.key == 'date'">
                  <span>
                    {{ getDatetime(record.date) }}
                  </span>
                </template>
                <template v-if="column.key == 'target'">
                  <span>
                    {{ record.invoice.target }}
                  </span>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

import { mapActions } from "vuex";
import { createToaster } from "@meforma/vue-toaster";
const columns = [
  {
    title: "Account Number/Patient ID/Folder Number",
    key: "acct_number",
    dataIndex: "acct_number",
  },
  {
    title: "Patient",
    key: "patient",
    dataIndex: "patient",
  },

  {
    title: "Total Amount",
    key: "total_amount",
    dataIndex: "total_amount",
  },
  {
    title: "Paid Amount",
    key: "paid_amount",
  },
  {
    title: "Due Amount",
    key: "due",
  },

  {
    title: "Purpose",
    key: "purpose",
    dataIndex: "purpose",
  },
  {
    title: "Date",
    key: "date",
    dataIndex: "date",
  },
];

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },
  data() {
    return {
      reponse: "",
      main_data: [],
      no_of_trans: 0,
      search: "",
      main_data_reserve: [],
      columns,
      isloading:null
    };
  },

  methods: {
    ...mapActions({
      _pending_payments: "finance/pending_payments",
    }),
    getDatetime(date) {
      let datetime = new Date(date);
      return `${datetime.toDateString()} ${datetime.toLocaleTimeString()}`;
    },
    pending_payments() {
      this.isloading=true

      this._pending_payments()
        .then((response) => {
          this.isloading=false

          this.response = response.data;
          if (this.response["status"] == true) {
            this.main_data = this.response.data;
            this.main_data = this.main_data;
            // this.main_data_reserve = this.main_data;
            this.main_data_reserve = this.main_data;
            this.no_of_trans = this.response.data.length;
            console.log(this.main_data);
          }
        })
        .catch((error) => {
          this.isloading=false

          console.log(error);
          console.log("Error fetching data", error);
        });
    },
    formatNumberWithCommas(number) {
      if(number){

      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    search_the_list() {
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = this.main_data_reserve;
        var searchLetter = this.search.toLowerCase();
        var searchResult = [];

        for (var i = 0; i < this.main_data.length; i++) {
          var Sname = this.main_data[i].name.toLowerCase();
          var Stype = this.main_data[i].type.toLowerCase();
          if (Sname.includes(searchLetter) || Stype.includes(searchLetter)) {
            searchResult.push(this.main_data[i]);
          }
          // if (searchResult == []) {
          //   this.main_data = this.main_data;
          // }
        }
      }
      if (this.search == "") {
        this.main_data = this.main_data_reserve;
      } else {
        this.main_data = searchResult;
      }
    },
    go_to_invoice(invoice) {
      window.location = "/View-Invoice";
      localStorage.setItem("invoice", JSON.stringify(invoice));
    },
  },
  mounted() {
    this.pending_payments();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
