<template>
  <!-- <div class="sidebar" id="sidebar" style="background-color: #14a79e; color: white"> -->
  <div>
    <div>
      <div class="sidebar-overlay" data-reff="#sidebar"></div>

      <div class="sidebar" id="sidebar">
        <div class="sidebar-inner slimscroll">
          <SidebarRoutes id="sidebar-menu-mobile" />
        </div>
      </div>
      <!-- -->
    </div>
    <div id="overlay"></div>
  </div>
</template>

<script>
import { store } from "@/store";
import { flutterEventKeys, postFlutterEvent } from "@/utils/flutterEvents";
import SidebarRoutes from "./SidebarRoutes.vue";

export default {
  name: "SideBar",

  data() {
    return {
    };
  },

  components: {
    // Subscription,
    SidebarRoutes
  },

  methods: {

  },
  computed: {

  },

  mounted() {

    $('#sidebar-menu-mobile li:not(.submenu)').click(function () {
      $('#sidebar_res').hide();
    });

    $('#overlay').click(function () {
      $('#sidebar_res').hide();
    });

    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.none,
.hide_for_now,
.nnavhide,
.only_for_super_admin {
  display: none;
  cursor: pointer;
}

.nav_hide,
.nnavhide {
  cursor: pointer;
}

.sidebar-inner {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Handle portion of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #14a79d7e;
  /* Set the color of the scrollbar thumb */
}

/* Track portion of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the color of the scrollbar track */
}

.router-link-active[aria-current="page"] {
  color: #14a79e;
  cursor: pointer;
  font-weight: 900;
}

/*  */

.noti-content {
  /* background-color: #14a79e; */
  background-color: #14a79d;
  border-radius: 10px;
  width: 90%;
  text-align: center;
  color: white;
  padding: 20px;
  margin-left: 5%;
  height: auto;
  margin-bottom: 30px;
}

.noti-header .icon {
  font-size: 40px;
  text-align: center;
}

.noti-body {
  margin-top: 20px;
}

.noti-body .main-text {
  font-size: 26px;
  font-weight: bold;
}

.noti-body .sub-text {
  font-size: 16px;
  margin-top: 10px;
}

.noti-footer {
  margin-top: 20px;
}

.request-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.request-button:hover {
  background-color: black;
}

#overlay {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}
</style>
<!-- #2E37A4 old color-->
<!-- #14a79e new color -->
