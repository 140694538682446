<template>
  <div class="cont_forscale">
    <!-- <Loader id="loadercomp" /> -->
    <Inloader class="inloader" id="inloader" />
    <!-- Main Wrapper -->
    <div class="main-wrapper login-body">
      <div class="container-fluid px-0">
        <div class="row">
          <!-- Login logo -->
          <div class="col-lg-6 login-wrap" style="height: 100%">
            <div class="login-sec">
              <div class="log-img">
                <img class="img-fluid" src="../../assets/assets/img/login-02.png" alt="Logo" />
              </div>
            </div>
          </div>
          <!-- /Login logo -->

          <!-- Login Content -->
          <div class="col-lg-6 login-wrap-bg">
            <div class="login-wrapper">
              <div class="loginbox">
                <div class="login-right">
                  <div class="login-right-wrap">
                    <div class="account-logo text-center">
                      <img src="../../assets/assets/img/login-logo.png" alt="" style="padding-bottom: 10px" />
                      <div class="logo-text">StellaSync</div>
                    </div>
                    <h2>Login</h2>
                    <!-- Form -->
                    <form>
                      <div class="input-block">
                        <label>Email <span class="login-danger">*</span></label>
                        <input class="form-control email-input" type="text" v-model="email" @change="removeerror()"
                          style="outline: none;" />
                      </div>
                      <div class="input-block">
                        <label>Password <span class="login-danger">*</span></label>
                        <input class="form-control pass-input" :type="showPassword ? 'text' : 'password'"
                          style="outline: none;" v-model="password" @change="removeerror()" @keydown.enter="login" />
                        <div class="profile-views">
                          <i class="fa fa-eye toggle" v-if="showPassword" @click="toggle_input_type"></i>
                          <i class="fa fa-eye-slash toggle" v-else @click="toggle_input_type"></i>
                        </div>
                      </div>
                      <div class="forgotpass">
                        <div class="remember-me">
                          <label class="custom_check mr-2 mb-0 d-inline-flex remember-me">
                            Remember me
                            <input type="checkbox" name="radio" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <router-link to="/Forgot-Password">Forgot Password?</router-link>
                      </div>
                      <div class="input-block login-btn">
                        <div class="btn btn-primary btn-block" id="subbutton" @click="login()">
                          <span id="btntext">Login</span>
                          <div id="samall_loader"></div>
                        </div>
                      </div>
                    </form>
                    <!-- /Form -->

                    <!-- <div class="next-sign">
                      <p class="account-subtitle">
                        Need an account? <a href="#">Sign Up</a>
                      </p>

                      <div class="social-login">
                        <a href="javascript:;"
                          ><img
                            src="../../assets/assets//img/icons/login-icon-01.svg"
                            alt=""
                        /></a>
                        <a href="javascript:;"
                          ><img
                            src="../../assets/assets//img/icons/login-icon-02.svg"
                            alt=""
                        /></a>
                        <a href="javascript:;"
                          ><img
                            src="../../assets/assets//img/icons/login-icon-03.svg"
                            alt=""
                        /></a>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Login Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import { mapActions } from "vuex";
import { store } from "@/store";
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";

export default {
  name: "Login",
  components: {
    Loader,
    Inloader,
  },
  data() {
    return {
      email: "",
      password: "",
      response: "",
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      _login: "general/login",
      // _set_satus: "general/set_satus",
    }),
    toggle_input_type() {
      this.showPassword = !this.showPassword;
    },
    login() {
      localStorage.clear();

      if (this.email == "" && this.password == "") {
        this.$toast.error("Inputs cannnot be empty");
        $(".form-control").css("border-color", "red");
      } else if (this.email == "") {
        this.$toast.error("Email input cannnot be empty");
        $(".email-input").css("border-color", "red");
      } else if (this.password == "") {
        this.$toast.error("Password input cannnot be empty");
        $(".pass-input").css("border-color", "red");
      } else {
        $(".subbutton").css("background-color", "black");
        // $('#samall_loader').show()
        $("#btntext").hide();
        document.getElementById("samall_loader").style.display = "inline-block";
        this._login({
          email: this.email,
          password: this.password,
        })
          .then((response) => {
            document.getElementById("samall_loader").style.display = "none";
            $("#btntext").show();
            this.response = response.data;
            //   this.response
            if (this.response["status"] == true) {
              var data = this.response.data;
              var token = this.response.data.bearer_token;
              console.log(data);
              // pasword is saved in local storage it will be hashed
              localStorage.setItem("password", this.password);
              localStorage.setItem("decrypted_pin", data.decrypted_pin);
              localStorage.setItem("accessToken", token);
              localStorage.setItem("back_up_accessToken", token);
              // localStorage.setItem("avatar", data.avatar);
              // Save the admin details
              store.commit("SET_ADMIN_DETAILS", data);
              store.commit("SET_ADMIN_PERMISSIONS", data.permissions);

              // localStorage.setItem("role", data.role_name);
              localStorage.setItem(
                "name",
                `${data.first_name + " " + data.last_name}`
              );
              // localStorage.setItem("Lname", data.last_name);
              localStorage.setItem("email", data.email);
              localStorage.setItem("phone_number", data.phone_number);
              localStorage.setItem("role", data.role_name);
              localStorage.setItem("doc_id", data.id);
              localStorage.setItem("hospital", data.hospital);
              localStorage.setItem("hospital_id", data.hospital_id);
              localStorage.setItem("pin", data.pin);
              localStorage.setItem("password_change", data.password_change);
              localStorage.setItem("institution", data.institution);
              localStorage.setItem("trial_period", data.trial_period);
              localStorage.setItem("isInstitution", data.isInstitution);
              localStorage.setItem("settings", data.settings);
              localStorage.setItem("isAdmin", data.is_admin);
              localStorage.setItem(
                "settings_info",
                JSON.stringify(data.settings_info)
              );
              localStorage.setItem("role_name", data.role_name);
              localStorage.setItem("role_name_special", data.role_name);

              localStorage.setItem("checker", 1);
              localStorage.setItem("has_active_subscription",data.has_active_subscription);
              localStorage.setItem("current_plan",JSON.stringify(data.plan)
              );
              // localStorage.setItem("password_change", data.password_change);


              // console.log(localStorage.getItem("pin"))
              // console.log(typeof localStorage.getItem("pin"))

              // localStorage.setItem("hospital", data.hospital_name);

              // alert(data.role_name)
              //  console.log(data)
              // if (data.has_active_subscription != true && data.trial_period == 0) {
                if (data.has_active_subscription == false) {
                if (
                data.role_name == "Super Admin"
              ) {
                this.$toast.success("Login successful");
                window.location = "Hospital-Dashboard";
              }
              else{
                window.location = "/Suspension-Page";

              }
              }
              else{
              if (
                data.role_name == "Super Admin" ||
                data.role_name == "Admin" ||
                data.role_name == "Hospital"
              ) {
                this.$toast.success("Login successful");
                window.location = "Hospital-Dashboard";
              } else if (
                data.role_name == "Doctor" ||
                data.role_name == "doctor"
              ) {
                this.$toast.success("Login successful");
                window.location = "Doctor-Dashboard";
                // console.log(data.permissions);
              } else if (data.role_name == "Finance") {
                this.$toast.success("Login successful");
                window.location = "Finance-Dashboard";
              } else if (data.role_name == "GOPD") {
                this.$toast.success("Login successful");
                window.location = "GOPD-Dashboard";
              } else if (data.role_name == "Pharmacy" | data.role_name == "Pharmaceutical") {
                this.$toast.success("Login successful");
                window.location = "Pharmacy-Dashboard";
              } else if (data.role_name == "Lab" || data.role_name == "Laboratory") {
                this.$toast.success("Login successful");
                window.location = "Laboratory-Dashboard";
              } else if (data.role_name == "Radiology" || data.role_name == "Radiology Institution") {
                this.$toast.success("Login successful");
                window.location = "Radiology-Dashboard";
              } else if (data.role_name == "Nurse") {
                this.$toast.success("Login successful");
                window.location = "Nurse-Dashboard";
              }
              // special case for institution
              else if (data.role_name == "Front Desk") {
                this.$toast.success("Login successful");
                if (data.institution == "Admin") {
                  window.location = "FrontDesk-Dashboard";
                } else if (data.institution == "Clinic") {
                  window.location = "Clinic-FrontDesk-Dashboard";
                }
              } else if (data.role_name == "Clinic") {
                this.$toast.success("Login successful");
                window.location = "Clinic-Dashboard";
              } else {
                this.$toast.success("Login successful");
                window.location = "Clinic-Dashboard";
              }
            }
              // other actions
              // this.set_satus()
            }
          })
          .catch((error) => {
            console.log("Error logging in", error);
            document.getElementById("samall_loader").style.display = "none";
            $("#btntext").show();
            console.log(error);
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (
                status == 400 ||
                status == 422 ||
                status == 401 ||
                status == 404
              ) {
                this.$toast.error(error.response.data.message);
              } else if (status === 500) {
                this.$toast.error(
                  "Network Error, Check your internet conectivity."
                );
              }
            }
          });
      }
    },
    removeerror() {
      $(".form-control").css("border-color", "#f6f7fb");
    },
    // set_satus() {
    //   this._set_satus({
    //     status: "online",
    //   })
    //     .then((response) => {
    //       this.response = response.data;
    //       if (this.response["status"] == true) {
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       if (error.response) {
    //         const status = error.response.status;
    //         const data = error.response.data;

    //         if (status == 400 || status == 422 || status == 401) {
    //           this.$toast.error(error.response.data.message);
    //         } else if (status === 500) {
    //           this.$toast.error(
    //             "Network Error, Check your internet conectivity."
    //           );
    //         }
    //       }
    //     });
    // },
  },
  mounted() {
    localStorage.clear();

    $(document.body).css("zoom", "180%");
    // localStorage.clear();
    // (async function () {
    //   localStorage.clear();
    // })();
    $("#loadercomp").hide();
    // $('#btntext').hide()

    document.addEventListener("DOMContentLoaded", function () {
      // Password
      // if ($(".toggle-password").length > 0) {
      $(".toggle-passwordd").on("click", function () {
        $(this).toggleClass("feather-eye-off feather-eye");
        var input = $(".pass-input");
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
      // }
    });
  },
};

//  function showLoader() {
//       document.querySelector('button').disabled = true;
//       // Show the loader
//       document.getElementById('loader').style.display = 'inline-block';

//       // Simulate some asynchronous task (e.g., an API request)
//       setTimeout(function() {
//         // Enable the button after the task is completed
//         document.querySelector('button').disabled = false;

//         // Hide the loader
//         document.getElementById('loader').style.display = 'none';
//       }, 2000); // Adjust the time based on your actual task duration
//     }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-text {
  font-size: 30px;
  color: black;
  padding-top: 0px;
  margin-left: 10px;
  font-weight: bolder;
}

#samall_loader {
  display: none;
  border: 6px solid #f3f3f3;
  border-top: 4px solid #14a79e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.login-wrap {
  background-color: #14a79e;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cont_forscale {
  /* zoom: 50%; */
  zoom: 60%;
}

.toggle {
  font-size: 18px;
  color: black;
  margin-top: -20px;
}
</style>
