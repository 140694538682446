<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <div class="row">
        <div class="col-sm-7 col-6">
          <ul class="breadcrumb">
            <li class="breadcrumb-item"><a href="">Dashboard </a></li>
            <li class="breadcrumb-item">
              <i class="feather-chevron-right"></i>
            </li>
            <li class="breadcrumb-item active">My Profile</li>
          </ul>
        </div>

        <div class="col-sm-5 col-6 text-end m-b-30">
          <router-link to="Edit-profile" class="btn btn-primary btn-rounded"
            ><i class="fa fa-plus"></i> Edit Profile</router-link
          >
        </div>
      </div>
      <div class="card-box profile-header">
        <div class="row">
          <div class="col-md-12">
            <div class="profile-view">
              <div class="profile-img-wrap">
                <div class="profile-img">
                  <a href="#">
                    <img
                      v-if="profile_data.avatar == null"
                      class="avatar"
                      src="../../assets/assets/img/user-512.png"
                      alt="user"
                      id="preview"
                    />
                    <img
                      class="inline-block avatar"
                      :src="profile_data.avatar"
                      :alt="profile_data?.first_name[0]"
                      v-else
                    />
                  </a>
                </div>
              </div>
              <div class="profile-basic">
                <div class="row">
                  <div class="col-md-5">
                    <div class="profile-info-left">
                      <h3 class="user-name m-t-0 mb-0">
                        {{ profile_data.first_name }}
                        {{ profile_data.last_name }}
                      </h3>
                      <small
                        class="text-muted"
                        :hidden="profile_data.isAnInstitution"
                        >Department: {{ profile_data.department }}</small
                      >
                      <div class="staff-id">
                        Role : {{ profile_data.role_name }}
                      </div>
                      <!-- <div class="staff-msg">
                            <a href="#" class="btn btn-primary">Send Message</a>
                          </div> -->
                    </div>
                    <!-- <ul class="personal-info">
                          <li>
                            <span class="title">Phone:</span>
                            <span class="text"
                              ><a href="">{{ profile_data.phone_number }}</a></span
                            >
                          </li>
                          <li>
                            <span class="title">Email:</span>
                            <span class="text"
                              ><a href="">{{ profile_data.email }}</a></span
                            >
                          </li></ul> -->
                  </div>
                  <div class="col-md-7">
                    <ul class="personal-info d-flex flex-column gap-2">
                      <li>
                        <span class="title">Phone:</span>
                        <span class="home-addresshere">{{ profile_data.phone_number }}</span>
                      </li>
                      <li>
                        <span class="title">Email:</span>
                        <span class="email-address">{{
                          profile_data.email
                        }}</span>
                      </li>
                      <li v-if="profile_data.isAnInstitution">
                        <span class="title">Public Phone:</span>
                        <span class="home-addresshere">{{ profile_data.public_number }}</span>
                      </li>
                      <li v-if="profile_data.isAnInstitution">
                        <span class="title">Public Email:</span>
                        <span class="email-address">{{
                          profile_data.public_email
                        }}</span>
                      </li>
                      <li>
                        <span class="title" v-if="profile_data.isAnInstitution"
                          >Date started:</span
                        >
                        <span class="title" v-else>Date of Birth:</span>
                        <span class="home-addresshere">{{
                          profile_data.dob ?? profile_data.date_started
                        }}</span>
                      </li>
                      <li>
                        <span class="title">Address:</span>
                        <span class="home-addresshere">{{ profile_data.address }}</span>
                      </li>
                      <li :hidden="profile_data.isAnInstitution">
                        <span class="title">Gender:</span>
                        <span class="gender-type">{{ capitalize(profile_data.sex) }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-tabs">
        <ul class="nav nav-tabs nav-tabs-bottom">
          <li class="nav-item" :hidden="profile_data.isAnInstitution">
            <a class="nav-link active" href="#about-cont" data-bs-toggle="tab"
              >About</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#bottom-tab2" data-bs-toggle="tab"
              >Biography</a
            >
          </li>
          <!--      <li class="nav-item">
            <a class="nav-link" href="#bottom-tab3" data-bs-toggle="tab">Messages</a>
          </li>
          -->
        </ul>

        <div class="tab-content">
          <div
            class="tab-pane"
            id="about-cont"
            :hidden="profile_data.isAnInstitution"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="card-box">
                  <h3 class="card-title">Education Informations</h3>
                  <div class="experience-box">
                    <ul class="experience-list">
                      <!-- {{ profile_data.education }} -->
                      <li
                        v-for="(edu, index) in profile_data.education"
                        v-bind:key="index"
                      >
                        <div class="experience-user">
                          <div class="before-circle"></div>
                        </div>
                        <div class="experience-content">
                          <div class="timeline-content">
                            <a href="#/" class="name"
                              >Institution: {{ edu.institution }}</a
                            >
                            <div>Course: {{ edu.course }}</div>
                            <div>
                              Degree: {{ edu.degree }} | Grade: {{ edu.grade }}
                            </div>
                            <span class="time"
                              >Date (Start to end): {{ edu.starting_date }} -
                              {{ edu.complete_date }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-box">
                  <h3 class="card-title">Experience</h3>
                  <div class="experience-box">
                    <ul class="experience-list">
                      <li
                        v-for="(exp, index) in profile_data.experience"
                        v-bind:key="index"
                      >
                        <div class="experience-user">
                          <div class="before-circle"></div>
                        </div>
                        <div class="experience-content">
                          <div class="timeline-content">
                            <a href="#/" class="name"
                              >Position : {{ exp.job_position }}</a
                            >
                            <div>
                              Company name : {{ exp.company_name }} | Location:
                              {{ exp.location }}
                            </div>
                            <span class="time"
                              >Date (Start to end): {{ exp.period_from }} -
                              {{ exp.period_to }}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane show active" id="bottom-tab2">
            <div class="row">
              <div class="col-md-12">
                <div class="card-box">
                  <h3 class="card-title">Biography</h3>
                  {{ profile_data.biography }}
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="bottom-tab3">content 3</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { store } from "../../store";
import { capitalize } from "@/utils/formatter";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      capitalize,
      profile_data: "",
    };
  },

  methods: {
    assign() {
      this.profile_data = JSON.parse(localStorage.getItem("ADMIN_DETAILS"));
    },
  },

  mounted() {
    store.dispatch("getAdminDetails");

    setTimeout(() => {
      this.assign();
    }, 2500);
    // $("input[type=file]").change(function () {
    //   var input = document.getElementById("imageInput");
    //   this.avatar = input.files[0];
    // });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 800px) {
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /*  */
  .email-address {
    display: inline-block;
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* font-size: 18px; */
    font-family: Arial, sans-serif;
    color: #333;
  }

  /* .email-address:hover {
  text-overflow: clip;
  overflow: visible;
  z-index: 1;
  position: relative;
} */

  /* .email-address:hover {
    position: relative;
    width: auto; 
    flex-basis: 100%; 
    overflow-wrap: break-word; 
    text-overflow: clip;
    overflow: visible;
    z-index: 1;
  } */

  

  .home-addresshere {
    display: inline-block;
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Arial, sans-serif;
    color: #333;
  }

  .gender-type{
    display: inline-block;
    width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Arial, sans-serif;
    color: #333;
  }

  

  /* .home-addresshere:hover {
    position: relative;
    width: auto; 
    flex-basis: 100%; 
    overflow-wrap: break-word; 
    text-overflow: clip;
    overflow: visible;
    z-index: 1;
  } */

  /*  */
}
@media (max-width: 480px) {
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
