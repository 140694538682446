<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Dashboard </a></li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Laboratory Dashboard</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="good-morning-blk not_for_admin">
        <div class="row">
          <div class="col-md-6">
            <div class="morning-user">
              <h2>
                {{ getGreeting() }}, <span>{{ dash_name }}</span>
              </h2>
              <p>Have a nice day at work.</p>
            </div>
          </div>
          <div class="col-md-6 position-blk">
            <div class="morning-img">
              <img src="../../assets/assets/img/undraw_scientist_ft.svg" class="dashimg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d1">
            <div class="dash-boxs comman-flex-center">
              <i class="fa dash_card_icons">&#xf271;</i>
            </div>
            <div class="dash-content dash-count">
              <h4>Total Requests</h4>
              <h2 class="text-light">
                <span class="conter-up">
                  {{
                    laboratory_data.request == 0 ||
                      laboratory_data.request == null ||
                      laboratory_data.request == ""
                      ? 0
                      : laboratory_data.request
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"><i class="feather-arrow-up-right me-1"></i>40%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d2">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-check dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Completed</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  laboratory_data.completed == 0 ||
                    laboratory_data.completed == null ||
                    laboratory_data.completed == ""
                    ? 0
                    : laboratory_data.completed
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="passive-view"><i class="feather-arrow-up-right me-1"></i>20%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d3">
            <div class="dash-boxs comman-flex-center">
              <i class="fa dash_card_icons">&#xf0a3;</i>
            </div>
            <div class="dash-content dash-count">
              <h4 class="text-light">Signed Reports</h4>
              <h2 class="text-light">
                <span class="counter-up">{{
                  laboratory_data.signed == 0 ||
                    laboratory_data.signed == null ||
                    laboratory_data.signed == ""
                    ? 0
                    : laboratory_data.signed
                }}</span>
              </h2>
              <p class="sub_design" hidden>
                <span class="negative-view"><i class="feather-arrow-down-right me-1"></i>15%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
          <div class="dash-widget d4">
            <div class="dash-boxs comman-flex-center">
              <i class="fa fa-wallet dash_card_icons"></i>
            </div>
            <div class="dash-content dash-count">
              <h4>Earnings</h4>
              <h2 class="text-light">
                ₦
                <span class="counter-up">
                  {{
                    laboratory_data.earnings == 0 ||
                      laboratory_data.earnings == null ||
                      laboratory_data.earnings == ""
                      ? 0
                      : formatNumberWithCommas(laboratory_data.earnings)
                  }}
                </span>
              </h2>
              <p class="sub_design" hidden>
                <!-- Number.parseInt(dashboard_data?.earnings).toLocaleString("en-US") -->
                <span class="passive-view"><i class="feather-arrow-up-right me-1"></i>30%</span>
                vs last month
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 col-xl-12">
          <div class="card">
            <div class="card-header pb-0">
              <h4 class="card-title d-inline-block">
                Recent Laboratory Requests
              </h4>
              <router-link to="Scientist-List" class="float-end">Show all</router-link>
            </div>
            <div class="card-block table-dash">
              <div class="table-responsive" style="overflow: visible">
                <!-- /Table Header -->
                <a-table :dataSource="Lab" :columns="columns" :loading="isloading" :pagination="false">
                  <template #bodyCell="{ column, record }">
                    <!-- <template v-if="column.key == 'sample_number'">
                      <div class="tooltip-container">
                        <span @click="view_patient_details(record)" class="tb_name">{{ record.sample_number }}</span>

                        <div>
                          <span class="tooltip-text">
                            <span>Patient Name - {{ record.patient_name }}</span>
                            <span>Moblie Number - {{ record.mobile_number }}</span>

                            <span>Patient Age - {{ record.patient_age }}</span>
                            <span>Patient Gender - {{ record.patient_sex }}</span>
                          </span>
                        </div>
                      </div>
                    </template> -->
                    <template v-if="column.key == 'priority'">
                      <span class="badge badge-danger w-100" v-if="record?.priority == 'High'">{{ record?.priority
                        }}</span>
                      <span class="badge badge-warning w-100" v-if="record?.priority == 'Moderate'">{{ record?.priority
                        }}</span>
                      <span class="badge badge-primary w-100" v-if="record?.priority == 'Low'">{{ record?.priority
                        }}</span>
                      <span class="badge badge-dark w-100" v-if="record?.priority == 'QS' || record?.priority == null">Quick Service</span>
                    </template>
                    <template v-if="column.key == 'payment_status'">
                      <span :class="`badge p-2 ${record?.payment_status == 'Paid' ? 'badge-success' : 'badge-danger'}`">
                        {{ record.payment_status }}</span>
                    </template>
                    <template v-if="column.key == 'cost'">
                      ₦ {{ formatNumberWithCommas(record.cost) }}
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LabRequest />
    <div class="sidebar-overlay" data-reff=""></div>
  </div>
</template>
<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

const columns = [
  {
    title: "Sample Number",
    dataIndex: "sample_number",
    key: "sample_number",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Test",
    dataIndex: "test",
    key: "test",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Status",
    dataIndex: "payment_status",
    key: "payment_status",
  },
  {
    title: "Handler",
    dataIndex: "handled_by",
    key: "handled_by",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

// import { store } from '@/store';
export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
    // Footer,
    // FloatSideBar,
    // Chat,
    // Loader
  },

  data() {
    return {
      isloading: null,
      columns,
      hospital_name:
        localStorage.getItem("hospital") == "null"
          ? ""
          : localStorage.getItem("hospital"),
      dash_name:
        localStorage.getItem("name") == "null"
          ? ""
          : localStorage.getItem("name"),
      laboratory_data: "",
      laboratorychartdata: "",
      monthly_stat: [],
      Lab: null,
    };
  },

  methods: {
    ...mapActions({
      _getlaboratoryDashboard: "dashboards/getlaboratoryDashboard",
    }),
    getlaboratoryDashboard() {
      this.isloading = true

      //   this.getchartdata();
      this._getlaboratoryDashboard()
        .then((response) => {
          this.isloading = false

          this.laboratory_data = response.data.data;
          this.Lab = this.laboratory_data.lab;
          this.Lab = this.Lab.slice(0, 5);
          console.log(this.laboratory_data);
        })
        .catch((error) => {
          this.isloading = false

          console.log(error);
          console.log("Error fetching data", error);
        });
    },

    getGreeting() {
      const currentTime = new Date();
      const hours = currentTime.getHours();

      let greeting;

      if (hours < 12) {
        greeting = "Good morning";
      } else if (hours < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      return greeting;
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      if (number) {
        const numString = number.toString();

        // Use regular expression to add commas
        return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatNumberWithCommas(number) {
      // Convert the number to a string
      const numString = number.toString();

      // Use regular expression to add commas
      return numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  mounted() {
    setTimeout(() => {
      this.getlaboratoryDashboard();
    }, 3000);
    var role = localStorage.getItem("role");
    if (role == "Super Admin" || role == "Admin") {
      $(".not_for_admin").hide();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h2,
.h4 {
  color: white;
}

.d1 {
  /* background-color: #fc8955; */
  background-color: #14a79e;

  color: white;
}

.d2 {
  /* background-color: #0583d2; */
  background: rgb(255, 69, 96);
  /* background: #fbbc06; */
  color: white;
}

.d3 {
  /* background-color: #4fd6f7; */
  background: #2c50ed;
  color: white;
}

.d4 {
  /* background-color: #b8e3ff; */
  background: #222437;
  color: white;
}

.dash-boxs {
  /* background: #e8eff4; */
  background: #eff0f144;
}

.counter-up {
  color: white;
}

.dash_card_icons {
  color: white;
  font-size: 30px;
}

.sub_design {
  width: 53%;
  padding: 2px;
  border: 2px solid white;
  border-radius: 12px;
  background-color: white;
  color: black;
}

.dashimg {
  width: 100%;
  height: 130%;
  margin-left: -20%;
  /* margin-top: 650px; */
}
</style>
