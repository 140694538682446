<template>
  <Loader id="loadercomp" />
  <Inloader class="inloader" id="inloader" />

  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="Subscription">Subscription </a>
              </li>
              <li class="breadcrumb-item">
                <i class="feather-chevron-right"></i>
              </li>
              <li class="breadcrumb-item active">Add Plan</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add plan - {{title }}</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Name
                        <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="features.name" placeholder="" maxlength="17" />
                    </div>
                  </div>

                 
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Discount <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="features.discount" maxlength="15" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Monthly Price (₦) <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="features.monthly_price" maxlength="15" placeholder="" />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Yearly Price (₦) <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" v-model="features.yearly_price" maxlength="15" placeholder="" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Plan Features</h4>
                      <div class="addbtn">
                        <div class="add-group" @click="addMonthlySubcriptions()">
                          <span class="btn btn-primary fa fa-plus ms-1" style="font-size: 18px">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <!-- <label>Features
                    <span class="login-danger">*</span></label> -->
                  <div class="col-12 col-md-12 col-xl-12" v-for="subscription in features.features"
                    :key="subscription">
                    <div class="feature_block_cont">
                      <div class="input-block local-forms">
                        <input class="form-control" type="text" v-model="subscription.name" placeholder=""
                           />
                      </div>

                    </div>

                    <div class="remove_cont" v-if="features.features.length > 1">
                      <div class="add-group" @click="removeMonthlySubcriptions(subscription.id)">
                        <span class="btn btn-primary fa fa-minus ms-1" style="font-size: 18px">
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- yearly sub -->
        <div class="col-sm-6" hidden>
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Yearly Plan</h4>
                      <div class="addbtn">
                        <div class="add-group" @click="addYearlySubcriptions()">
                          <span class="btn btn-primary fa fa-plus ms-1" style="font-size: 18px">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <label>Features
                    <span class="login-danger">*</span></label>
                  <div class="col-12 col-md-12 col-xl-12" v-for="subscription in subscriptions_yearly"
                    :key="subscription">
                    <div class="feature_block_cont">
                      <div class="input-block local-forms">
                        <input class="form-control" type="text" v-model="subscription.name" placeholder=""
                           />
                      </div>

                    </div>

                    <div class="remove_cont" v-if="subscriptions_yearly.length > 1">
                      <div class="add-group" @click="removeYearlySubcriptions(subscription.id)">
                        <span class="btn btn-primary fa fa-minus ms-1" style="font-size: 18px">
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        <br>
          <div class="col-12" style="text-align: center;">
          <div class="doctor-submit">
            <span @click="Edit_Subscription()" class="btn btn-primary submit-form me-2">Submit</span>
            <span type="submit" class="btn btn-primary cancel-form bg-danger text-light cancel-form"
              @click="$router.go(-1)">
              Cancel
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../plugins/Loader.vue";
import Inloader from "../plugins/inloader";
import { mapActions } from "vuex";

export default {
  name: "Index_Page",
  components: {
    Loader,
    Inloader,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
     
      hospital_list: [],
      features:[]
     
    };
  },

  methods: {
    ...mapActions({
      _Edit_Subscription: "general/Edit_Subscription",


    }),
    stringToArray(str) {
      return str.split(",");
    },

    array_converter(){
      this.features.features= this.features.features.map((feature, index) => ({
    id: index + 1,
    name: feature,
}));
    },
    Edit_Subscription() {

      if (this.features.name == null && this.discount == null &&  this.features.length>0&& this.features.monthly_price == null && this.features.yearly_price == null) {
        this.$toast.error('Fill all the input fields to proceed.');

      }
      else {
        $(".inloader").show();
        
        const features = this.features.features.map(subscription => subscription.name);
console.log(features)
        this._Edit_Subscription({
          plan_id:this.features.id,
          payload:{
          plan_name: this.features.name,
          monthly_price: this.features.monthly_price,
          yearly_price: this.features.yearly_price,
          features: features
        }})
          .then((response) => {
            this.response = response.data;
      window.location = "/Plans";

            $(".inloader").hide();

          })
          .catch((error) => {
            $(".inloader").hide();
            if (error.response) {
              const status = error.response.status;
              const data = error.response.data;

              if (status == 400 || status == 422) {
                this.$toast.error(error.response.data.message);
              }

            }
          });
      }
    },
 

    removeMonthlySubcriptions(id) {
      const index = this.features.features.findIndex(
        (subcriptions) => subcriptions.id === id
      );
      if (index !== -1) {
        this.features.features.splice(index, 1);
      } else {
        console.log(`Subcriptions with id ${id} not found.`);
      }
    },
    addMonthlySubcriptions() {
      if (this.features.features.length < 7) {
        this.increaser++;
        this.features.features.push({
          id: null,
          name: null,

        },);
      }
      else {
        this.$toast.error('You can`t add more features.');

      }
    },
   

  },

  mounted() {
this.features=JSON.parse(localStorage.getItem("subscriptionData"))
this.array_converter()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.feature_block_cont {
  inline-size: 93%;
  margin-inline-end: 2%;
  display: inline-block;
}

.remove_cont {
  inline-size: 5%;
  display: inline-block;
}

.addbtn {
  position: absolute;
  inset-inline-end: 1%;
  inset-block-start: 10px;
  z-index: 999;
}
</style>
