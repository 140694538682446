<template>
  <div :class="['card', 'card-table', 'show-entire', ...extraClass]">
    <div class="card-body">
      <slot :filteredData="filtered" name="top"> </slot>
      <!-- Table Header -->
      <div class="page-table-header mb-2">
        <div class="row align-items-center" id="bangerhere">
          <div class="col">
            <div class="doctor-table-blk">
              <h3>{{ title }}</h3>
              <div class="doctor-search-blk">
                <div class="top-nav-search table-search-blk">
                  <form v-if="search">
                    <input type="text" class="form-control" :placeholder="searchTitle" v-model="filter.value" />
                    <a class="btn text-end"><i class="fa fa-search" style="color: #14a79e; font-size: 18px"></i></a>
                  </form>
                </div>
                <div class="add-group">
                  <slot :filteredData="filtered" name="addGroup"> </slot>
                </div>
              </div>
            </div>
          </div>
          <!-- Other Element To show on the right of the table this have access to the filtered data incase u want to filter -->
          <slot :filteredData="filtered" name="rightContent"> </slot>
          <div class="col-2 ms-auto d-flex gap-2 align-items-center" v-if="filterBy" id="hangerhow">
            <a-select class="w-100" :options="processedfilterBy" placeholder="Filter"
              @select="(event) => applyTableFilter(event)" v-model:value="filterByValue">
            </a-select>
            <span v-if="isFiltered" class="btn btn-primary" @click="clearFilter()">&times;</span>
          </div>
        </div>
      </div>
      <!-- /Table Header -->
      <div class="table-responsive" style="overflow: visible">
        <a-table :loading="loading" :pagination="noPagination ? false : pagination" :dataSource="filtered"
          :columns="columns">
          <template v-slot:bodyCell="{ column, record }">
            <template v-for="(_, name) in $slots">
              <!-- Skip Slot if Name is not bodyCell -->
              <slot v-if="name == 'bodyCell'" v-bind="{ column, record }" :name="name"></slot>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, computed } from "vue";

const emit = defineEmits([
  "fetchData",
  "filter",
  "update:currentPage",
  "update:currentFilter",
]);
const props = defineProps({
  loading: Boolean,
  title: String,
  columns: Array,
  searchKeys: Array,
  data: Array,
  extraClass: { default: [], type: Array },
  searchClass: { default: [], type: Array },
  paginationConfig: Object,
  noPagination: { default: false, type: Boolean },
  tableStyles: Object,
  pageData: Object,
  filterBy: {
    default: null,
    type: Array,
  },
});

const isFiltered = ref(false);
const filterByValue = ref("");
const search = ref(false);
const searchTitle = ref(null);
const filter = reactive({ value: null });
const filtered = ref(props.data ?? []);
const pagination = reactive({});
const processedfilterBy = computed(() => {
  const filterByOptions = props.filterBy.map((_filterBy, index) => ({
    value: index,
    label: _filterBy.title,
    ..._filterBy,
  }));
  return [
    {
      value: null,
      label: "Select an option", // Placeholder label
      disabled: true, // This option is disabled
    },
    ...filterByOptions,
  ];
});

const handlePageChange = (page, pageSize) => {
  emit("update:pageData", { page, pageSize });
  emit("fetchData", { page, pageSize });
};

const filterTable = (_filter) => {
  emit("filter", _filter);
  const filters = props.searchKeys || [];
  const canFilter = _filter === "" || filters.length === 0;
  filtered.value = canFilter
    ? props.data
    : props.data.filter((item) =>
      filters.some((filter) =>
        (item[filter]?.toLowerCase() || "").includes(_filter?.toLowerCase())
      )
    );
};

const applyTableFilter = (filterIndex) => {
  if (filterIndex == null) {
    return;
  }
  filterIndex++;
  const { key, match, exclude } = processedfilterBy.value[filterIndex];

  // Emit the title for the selected filter option
  const filterTitle = processedfilterBy.value.find(
    (filter) => filter.key === key
  )?.title;
  emit("update:currentFilterTitle", filterTitle);

  // Apply filter based on the provided match or exclude criteria
  isFiltered.value = true;
  filtered.value = props.data?.filter((item) => {
    if (exclude) {
      return item[key] !== exclude;
    } else if (match) {
      return item[key] === match;
    }
  });
};
const clearFilter = () => {
  isFiltered.value = false;
  filterByValue.value = null;
  filtered.value = props.data;
  emit("update:currentFilterText", null);
};

const updatePagination = () => {
  pagination.onChange = handlePageChange;
  if (props.paginationConfig) {
    pagination = { ...props.paginationConfig };
  }
};

watch(
  () => props.data,
  (_data) => {
    filtered.value = _data;
  }
);

watch(
  [() => filtered.value, () => props.data],
  () => {
    // add index to the data
    filtered.value.forEach((item, index) => {
      item.index = index + 1;
    });
  },
  { deep: true, immediate: true }
);
watch(
  () => filter.value,
  (_new) => {
    filterTable(_new);
  }
);

watch(
  () => props.paginationConfig,
  () => {
    updatePagination();
  },
  { deep: true }
);

onMounted(() => {
  updatePagination();
  filtered.value = props.data;
  if (props.searchKeys) {
    search.value = true;
    searchTitle.value =
      "Search by " +
      props.searchKeys.flatMap((_filter) => {
        let filterName = " " + _filter.replace("_", " ");
        return filterName;
      });
  } else {
    searchTitle.value = "Search Not Supported";
    search.value = false;
  }
});
</script>

<style scoped>
@media (max-width: 800px) {
  #bangerhere {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  #hangerhow {
    width: 100% !important;
    margin-bottom: 3%;
  }
}

@media (max-width: 480px) {
  #bangerhere {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  #hangerhow {
    width: 100% !important;
    margin-bottom: 3%;
  }
}
</style>
